import React from "react";
import "../data/data.scss";
import { useNavigate } from "react-router-dom";

function DirectDebitCreated({setIsDirectDebitLink,createDirectDebitLink,setIsDirectDebitCreated}) {
const navigate= useNavigate()
 const handlePropagation = (e) => {
    e.stopPropagation();
 };

  const handleSubmit = (e) => {
    e.preventDefault();
    // createDirectDebitLink();
    setIsDirectDebitCreated(false);
    setIsDirectDebitLink(false);
    // navigate('/direct-debit')
  };

  return (
    <div className="link-modal" onClick={setIsDirectDebitCreated.bind(this, false)}>
      <div className="link-box" onClick={handlePropagation}>
        <div className="link-box-wrapper" style={{display:'flex', flexDirection:"column", justifyContent:"center", alignItems:"center", marginTop:"100px"}}>
            <div>
                <img src="/images/direct-done.svg" alt="f"/>
            </div>
            <form onSubmit={handleSubmit}>
                <div className="statement_link" style={{ marginTop: "14px" }}>
                <div className="direct-done">
                    <p className="initiated">Direct debit Initiated</p>
                    <p className="your-direct">Your direct debit is being processed and should be active after 24 hours of linking.</p>
                </div>
                <div style={{width:"100%",display:'flex', justifyContent:"center", alignItems:"center"}}>
                    <button className="btn" style={{width:"50%"}}>Done</button>
                </div>
                </div>
            </form>
        </div>
      </div>
    </div>
  );
}
export default DirectDebitCreated;
