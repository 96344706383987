import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { apiPost } from "../../utilities/api/axios";

function SendInvite() {

    const { currentUser } = useSelector((state) => state?.user);
    const [isLoading, setIsLoading] = useState(false);
    const [role, setRole] = useState("");

    const initialState = {
      email: '',
    };
    const [formData, setFormData] = useState(initialState);

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData((prev) => ({ ...prev, [name]: value }));
  // };

  const  handleInputChange = (event)=>{
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }
  const handleSelectRole = (e) => {
    setRole(e.target.value);
  };

  const handleSubmit = async (e)=>{
    e.preventDefault();
    
    try{
        setIsLoading(true);
        const data = {
          token: currentUser.user.token,
          teamMemberEmail: formData.email.trim(),
          teamMemberRole: role.trim()
        }

        const url = "https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/create-team-member"
        const res = await apiPost(url,data,{},true,{})
        console.log(res)
        console.log(currentUser.user.token)
        setIsLoading(false);
        toast.success("Invite sent successfully!");
        setFormData(initialState);
        setRole("");
    }catch(err){
      setIsLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.message || err?.response?.data?.msg || "failed"
      toast.error(errorMessage || "Failed")
      console.log(err)
    }
  }

  return (
    <div className="whitelist">
      <div className="whitelist-border">
        <div className="invite-header">
          <h2 className="-send">Send an invite to new members</h2>
          <h3 className="enter-email-add">Enter the email address of the user you want to invite, and choose the role they should have.</h3>
        </div>
      </div>

      <div className="email-role">
        <form onSubmit={handleSubmit} className="emaail">
            <div style={{display:"flex", flexDirection:"column", gap:"23px"}}>
              <div className="profile-form-groups">
                <label className="lbl" style={{margin:"0 0 6px 0"}}>Email address</label>
                <input
                  type="email"
                  name="email"
                  placeholder="Enter email address"
                  className="fixed-form-control"
                  onChange={handleInputChange}
                  value={formData.email}
                  required
                />
              </div>

              <div className="profile-form-groups">
                <label className="lbl" style={{margin:"0 0 6px 0"}}>Role</label>
                <div className="form-select-group">
                  <select className="input-select" 
                    name="role" 
                    onChange={handleSelectRole}
                    value={role}
                    required
                    >
                    <option value="" disabled selected hidden>Role</option>
                    <option value="admin">Administrator </option>
                    <option value="operate">Operations</option>
                    <option value="user">User</option>
                    <option value="dev">Developer</option>
                    <option value="customer">Customer support</option>
                  </select>
                </div>
              </div>
            </div>

              <button className="btn" style={{ width: "fit-content", marginTop:"24px"}}>
                {isLoading ? (
                  <BeatLoader color="#FFF" cssOverride={{}} />
                ) : (
                  <>Continue</>
                )}
              </button>
        </form>
        <div className="roles-description">
            <div className="roles-">
                <h2 className="role-desc">Role descriptions</h2>
                <div className="role-permission">
                    <img src={process.env.PUBLIC_URL+"/images/star.svg"} alt='star'/>
                    <p className="role-">Admin - <span style={{color:"#8C8F97"}}> an executive team member that will require full admin access.</span><span style={{color:"#2EBDB6", textDecoration:"underline"}}> View permissions</span></p>
                </div>
                <div className="role-permission">
                    <img src={process.env.PUBLIC_URL+"/images/star.svg"} alt='star'/>
                    <p className="role-">Operations - <span style={{color:"#8C8F97"}}>customer and beneficiary lists, and can manage transfers.</span><span style={{color:"#2EBDB6", textDecoration:"underline"}}>View permissions</span></p>
                </div>
                <div className="role-permission">
                    <img src={process.env.PUBLIC_URL+"/images/star.svg"} alt='star'/>
                    <p className="role-">Customer support - <span style={{color:"#8C8F97"}}>access and permission to perform actions like refunds, and dispute resolutions.</span><span style={{color:"#2EBDB6", textDecoration:"underline"}}>View permissions</span></p>
                </div>
                <div className="role-permission">
                    <img src={process.env.PUBLIC_URL+"/images/star.svg"} alt='star'/>
                    <p className="role-">Developer - <span style={{color:"#8C8F97"}}>Flutterwave APIs or making team changes at the API level.</span><span style={{color:"#2EBDB6", textDecoration:"underline"}}>View permissions</span></p>
                </div>
            </div>
            {/* <button className="btn" style={{ width: "50%", display:"flex", alignItems:"center", gap:"6px", marginTop:"29px" }}>Add a custom role <span><img src={process.env.PUBLIC_URL + "/images/settlement-plus.svg"} alt="plus"/></span></button> */}
        </div>
      </div>
    </div>
  );
}

export default SendInvite;
