export const formatCustomersData = async (data) => {
    const formattedDataArray = [];

    for (const key in data) {
        const item = data[key];
        const formattedItem = {
          bvn: item.info.bvn,
          CustomerCode: item.info.CustomerCode,
          fullName: item.info.fullName,
          email: item.info.email,
          dated: item.info.dated,
          accountCounts: item.accountCounts,
          currencyBalances: {
            NGN: item.currencyBalances.NGN || 0,
            USD: item.currencyBalances.USD || 0,
          },
        };
      
        formattedDataArray.push(formattedItem);
      }
    return formattedDataArray;
};