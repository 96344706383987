import React, { useEffect, useState } from 'react'
import NameControl from '../components/common/NameControl'
import AccountBalance from '../components/data/AccountBalance'
import { useSelector } from 'react-redux'
import axios from 'axios'

function DataAccountBalance() {

  useEffect(()=>{
    getAccounts();
    // eslint-disable-next-line
  },[])
  const {currentUser} = useSelector((state)=>state?.user)

  const [isAction,setIsAction] = useState(false);
  const [accountData,setAccountData] = useState([])
  const [isAccountLoading,setIsAccountLoading] = useState(false)
  const ToggleAction = ()=>{
    setIsAction(!isAction);
  }

  const getAccounts = async ()=>{
    setIsAccountLoading(true)
    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        "sweetapi": currentUser.user.merchantCode  
      }
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-accounts';
      let res = await axios.get(url,{
        headers:headers
      })

      const data = res?.data?.data
      console.log("Account Data: ",data)
      setAccountData(data);
      setIsAccountLoading(false)
      // setBalanceData(newValue || []);
       
    }catch(err){
      setIsAccountLoading(false)
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
     
    }
  }
  return (
    
    <div className='main-inner'>
        
        <NameControl 
        btn
        name="Accounts"
        desc="All linked Accounts and Balances"
        />
        
        <AccountBalance isAccountLoading={isAccountLoading} accountData={accountData} ToggleAction={ToggleAction} isAction={isAction}   />
    </div>
       
  )
}

export default DataAccountBalance
