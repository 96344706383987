import React, { useState } from 'react'
import BusinessAddress from '../../components/otc/BusinessAddress'
import DoubleInputGroup from '../../components/otc/DoubleInputGroup'
import SelectInputGroup from '../../components/otc/SelectInputGroup'
import { useNavigate } from 'react-router-dom'

function ConfirmDetails() {
    const navigate = useNavigate()

    const handleClick = ()=>{
        navigate('/confirm-details')
    }
    const handleBack = ()=>{
        navigate('/beneficiary-details')
    }
    const [bankDetailInput,setBankDetailInput] = useState({
        bankName:"",
        address1:"",
        address2:"",
        city:"",
        state:"",
        country:"",
        postalCode:""
    });
    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
        setBankDetailInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
    }
  return (
    <div className='weblink'>
        <div className='weblink_log'>
            <img className='otc_flick_logo' src='/images/otc/flickLogo.svg' alt=''/>
        </div>
        <div className='weblink_container'>
            <div className='weblink_box'>
                <div className='weblink_box_wrapper'>
                    <div className='weblink_title_container'>
                        <div className='weblink_arrow'>
                            <img src='/images/otc/arrowBack.svg' alt='' onClick={handleBack}/>
                            <p className='weblink_title'>Bank Details</p>
                        </div>
                        <img src='/images/otc/step_two.svg' alt='' />
                    </div>
                
                    
                    {/*  */}
                    <div className='otcform_container' style={{marginTop:"18px"}}>
                        <form onSubmit={handleSubmit} >
                
                                    <SelectInputGroup
                                    name="bankName"
                                    handleChange={handleInputChange}
                                    label="Bank Name"
                                    >
                                        <option value="" disabled>Wells Fargo</option>
                                            <option value="NGN">Wells Fargo</option>
                                    </SelectInputGroup>
                                
                                    <BusinessAddress label="Bank Address" />

                                    <div style={{display:"flex",flexDirection:"column",marginTop:"20px"}}>
                                        <DoubleInputGroup label1="Address Line 1" label2="Address Line 2" handleChange={handleInputChange} name1="address1" name2="address2" />
                                        <DoubleInputGroup label1="City" label2="State" handleChange={handleInputChange} name1="city" name2="state" />
                                        <DoubleInputGroup select label1="Country" label2="Postal Code" handleChange={handleInputChange} name1="city" name2="state">
                                            <option value="" disabled>USA</option>
                                            <option value="USD">USA</option>
                                        </DoubleInputGroup>
                                    </div>

                        </form>
                    </div>

                    <button className='btn' style={{marginTop:"15px"}} onClick={handleClick}>Confirm</button>


                </div>
            </div>
        </div>
    </div>
  )
}

export default ConfirmDetails