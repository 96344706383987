import React, { useRef } from 'react'
import Details from '../../common/Details';
import { copyToClipboard } from '../../utilities/clipboardUtils';
import Status from '../../common/Status';
import TableHeaderSearch from '../../common/TableHeaderSearch';
import { getIconByName } from '../../utilities/getBankIcon';
import { format } from 'timeago.js';
function CustomerStatement({statement,account}) {
  const refLink = useRef(null);

  return (
    <div className='data_customer'>
      <div className='topbar_identity'>
        <div className='link-bar-left'>
            <img src="/images/Avatar.svg" alt='avater' />
            <div className='link-right'>
                <h5 className='text_h3'>{account?.fullName || "N/V"}</h5>
                <div className='link-right-copy'>
                    <input ref={refLink} className='input_link_copy' type='text' value="64a85678gshs65678sh6s8678" />
                    <img onClick={copyToClipboard.bind(null,refLink)} src='/images/copy.svg' alt="copy" />
                </div> 
            </div>
        </div>
      </div>
        
      <div className='balance-overview'>
          <table className='tbl'>
          <tr>
              <td colSpan="7" className='tbl-heading'>
                  <TableHeaderSearch
                  title="Statements"
                  placeholder="Search by bank..."
                  />     
              </td>
          </tr>
          {
             Array.isArray(statement) && statement.length > 0  &&
                <tr>
                    <th className='th-text'>Customer</th>
                    <th className='th-text'>Bank</th>
                    <th className='th-text'>Period</th>
                    <th className='th-text'>From</th>
                    <th className='th-text'>To</th>
                    <th className='th-text'>Collected Via</th>
                    <th className='th-text'>Date Collected</th>
                </tr>
          }
          
          {
             Array.isArray(statement) && statement.length > 0 && statement.map((item,index)=>(
                <tr key={item.index}>
                <td className='td-text'>{item?.customer || "N/A"}</td>

                    <td>
                        <div className='customer-name'>
                            <img className='customer-name-img' src={getIconByName(item?.bank)} alt="" />
                            <h2 className='customer-name-text'>{` ${item?.bankName} |  ${item.Nuban}` || "N/A"}</h2>
                        </div>
                    </td> 
                    <td className='td-text'>{item?.period || "N/A"}</td>
                    
                    <td className='td-text'>{item?.from || "N/A"}</td>
                    <td className='td-text'>{item?.to || "N/A"}</td>
                    <td className='td-text'>
                       
                        <p className='link_status'>{item?.collectedVia}</p>

                    </td>
                    <td className='td-text'>{format(item?.dateCollected)}</td>
                </tr>

             ))
          }

         {
            statement.length <= 0 &&
            <tr>
             <td  colSpan={7} style={{width:"100%"}}>
                <div className='empty_tbl'>
                    <img src='/images/emptyPage.svg' alt='emptuy' />
                    <h3 className='num_trans'>No Transactions Yet</h3>
                    <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                </div>
             </td>
            </tr>
         }
          </table>
        </div>
    </div>
  )
}

export default CustomerStatement
