import React, { useState,createRef } from 'react'
import styled from 'styled-components'
import '../common/tables.scss'
import useFilterAndPagination from '../hooks/useFilterAndPagination'
import { useNavigate } from 'react-router-dom'
import { format } from 'timeago.js';
import { toast } from 'react-toastify';
import TableLoader from '../common/TableLoader'
import TableHeaderSearch from '../common/TableHeaderSearch'
import DataTable from 'react-data-table-component'
import CustomPagination from '../common/CustomPagination'
import { NumericFormat } from 'react-number-format';


const MainRecurring = styled.div`
width:100%;
height:250px;
display:flex;
justify-content: center;
align-items: center;
border-top-right-radius: 12px;
border-top-left-radius: 12px;
background: #FFF;

.recurring-wrapper{
    width:31%;
    display:flex;
    align-self:center;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    .rec-h{
        color: var(--gray-900, #101828);
        text-align: center;
        font-family: Circular Std;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
    }
    .rec-p{
        color: var(--Flick-Subtext, #8C8F97);
        text-align: center;

        /* Text sm/Regular */
        font-family: Circular Std;
        font-size: 14px;
        font-style: normal;
        font-weight: 450;
        line-height: 20px; /* 142.857% */
    }
}


`


function Reccurring({toggleDirectDebit,directDebitData}) {
  const navigate = useNavigate()
  const lineRefs = React.useRef([]);
  const rowsPerPage = 10;
 
  const [isLoading,setIsLoading] = useState(false);

   lineRefs.current = directDebitData.map((_, i) => lineRefs.current[i] ?? createRef());
   const {
    currentPage,
    searchTerm,
    setSearchTerm,
    dateRange,
    handleDateChange,
    handlePageChange,
    paginatedRecords,
    filteredRecords,
    setIsSearchTermChanged
  } = useFilterAndPagination(directDebitData, rowsPerPage);
   
  const customStyles = {
    header: {
      style: {
        backgroundColor: '#f9fafb',
      },
    },

    headRow: {
      style: {
        backgroundColor: '#f9fafb',
      },
    }
  };


  const columns = [
    {
      name: 'Date Created',
      selector: row => format(new Date(row.dated), 'yyyy-MM-dd HH:mm:ss'),
      sortable: true,
    },
    {
      name: 'Page Name',
      selector: row => row.pagename || "N/A",
      sortable: true,
    },
    {
      name: 'Amount',
      cell: row => (
        <NumericFormat
          value={Number(row.amount / 100).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          // prefix={`${row.currency_settled} `}
          renderText={value => <p className='td-text'>{value}</p>}
        />
      ),

      // selector: row => (row.amount / 100).toFixed(2).toLocaleString() +" ("+ row.tennor+")" || "N/A",
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: row => row.start_date || "N/A",
      sortable: true,
    },
    {
      name: 'End Date',
      selector: row => row.end_date || "N/A",
      sortable: true,
    },

    {
      name: 'Link',
      cell: (row, index) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <input ref={lineRefs.current[index]} className='link_table' value={row.url} readOnly />
          <img
            style={{ cursor: 'pointer' }}
            onClick={handleCopyToClipboard.bind(null, lineRefs.current[index])}
            src={process.env.PUBLIC_URL + "/images/copy.svg"}
            alt='copy'
          />
        </div>
      ),
      sortable: true,
    },
   
   
  ];


  const handleCopyToClipboard = (ref) => {
    navigator.clipboard.writeText(ref.current.value);
    toast.success('Copied successfully');
  };


  return (
    <div className='balance-overview tbl_overflow'>
      {
            isLoading &&
            <TableLoader />
        }
        {Array.isArray(directDebitData) && directDebitData.length > 0 ? (
        <>
          <DataTable
            columns={columns}
            data={paginatedRecords}
            highlightOnHover
            subHeader
            paginationTotalRows={filteredRecords.length}
            paginationDefaultPage={currentPage}
            paginationPerPage={rowsPerPage}
            customStyles={customStyles}
            onChangePage={handlePageChange}
            pagination={false}
            subHeaderComponent={
              <TableHeaderSearch
                title="All payment links"
                placeholder="Search by Page Name..."
                direct
                toggleLink={toggleDirectDebit}
                searchTerm={searchTerm}
                setSearchTerm={(term) => {
                  setSearchTerm(term);
                  setIsSearchTermChanged(true);
                }}
                dateRange={dateRange}
                handleDateChange={handleDateChange}
              />
            }
          />
          <table className='tbl'>
            <tr className='export-data' >
              <td className='td-export' colSpan="7">
                <div className='tbl-export'>
                  <h6>Export Data</h6>
                  <img className="export-img" src={process.env.PUBLIC_URL + "/images/export.svg"} alt='export' />
                </div>
              </td>
            </tr>
          </table>
          <CustomPagination
            totalRows={filteredRecords.length}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
          
        </>
      ):(
        !isLoading &&(

          <table className='tbl'>
            <tr>
              <td colSpan="7" className='tbl-heading'>
                <TableHeaderSearch
                  title="All Direct Debit Links"
                  placeholder="Search..."
                  direct
                  toggleLink={toggleDirectDebit}
                />
              </td>
            </tr>

                <MainRecurring>
                    <div className='recurring-wrapper'>
                    <img style={{marginBottom:"16px"}} className='rec-img' src='/images/emptyPage.svg' alt='recurring'/>
                    <h4 className='rec-h'>No Direct Debit Link  Yet</h4>
                    {/* <p className='rec-p'>Anticipate the recurring, we are currently fixing it.</p> */}
                    </div>
                </MainRecurring>

                <tr className='export-data' >
                  <td className='td-export' colSpan="7">
                    <div className='tbl-export'>
                      <h6 className='export-text-faded'>Export Data</h6>
                      <img className='export-img-faded' src={process.env.PUBLIC_URL + "/images/export.svg"} alt='export' />
                    </div>
                  </td>
                </tr>


          </table>
        )
      )
          }

    </div>
  )
}

export default Reccurring
