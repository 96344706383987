import React, { useEffect, useRef, useState } from 'react'
import './payment.scss'
import Status from '../common/Status'
import { copyToClipboard } from '../utilities/clipboardUtils'

import { ToastContainer, toast } from 'react-toastify';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
function PayoutOverviewModal({toggleModal,data}) {
  const [htmlContent, setHtmlContent] = useState('');
  const accRef = useRef(null);
  const IdRef = useRef(null);
  const pdfRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const templateRef = useRef(null);

  

 const allData = data;
  
  
  
  // const handleGeneratePDFClick = () => {
  //   const iframe = templateRef.current;
  //   if (!iframe || !iframe.contentWindow) return;

  //   const doc = new jsPDF({
  //     orientation: 'portrait',
  //     format: 'letter',
  //     unit: 'px',
  //     hotfixes: ['px_scaling']
  //   });

  //   const html = iframe.contentWindow.document.body;
  //   doc.html(html, {
  //     callback: (doc) => {
  //       doc.save('document.pdf');
  //     },
  //     margin: [40, 20, 40, 20]
  //   });
  // };


  const [copiedText, setCopiedText] = useState('');

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopiedText(text);
        toast.success('Copied successfully');
    };

  const handletoggle =(e)=>{
    e.stopPropagation();
    toggleModal()
  }
  const handlePropagation = (e)=>{
    e.stopPropagation();
  }

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  };


const downloadPDF = () => {
  const input = pdfRef.current;

  // Use html2canvas to capture the content
  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');

    // Create a new jsPDF instance with A4 dimensions
    const pdf = new jsPDF('p', 'px', 'a4');

    // Calculate the aspect ratio of the captured image
    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = canvas.height * imgWidth / canvas.width;

    // Add the captured content to PDF
    pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

    // Download the PDF
    pdf.save('receipt.pdf');
  });
};






// const handleGeneratePDFClick = () => {
//   const doc = new jsPDF({
//     orientation: "portrait",
//     format: "letter",
//     unit: "px",
//     hotfixes: ["px_scaling"]
//   });


  
//   templateRef.current?.contentWindow?.setTemplateData(data);
//   const html = templateRef.current.contentWindow.document.body;

//   doc.html(html, {
//     callback(doc) {
//       //To Save Document locally
//       doc.save("document");

     
//     },
//     margin: [40, 20, 40, 20]
//   });
// };

  // const downloadPDF = () => {
  //   const input = pdfRef.current;
  //   const pdf = new jsPDF('p', 'mm', 'a4', true);
  //   const pdfWidth = pdf.internal.pageSize.getWidth();
  //   const pdfHeight = pdf.internal.pageSize.getHeight();
  
  //   // Set the height of the canvas to cover the entire content
  //   const inputHeight = input.scrollHeight;
  //   const scaleFactor = pdfHeight / inputHeight;
  
  //   html2canvas(input, { scale: scaleFactor }).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const imgWidth = canvas.width;
  //     const imgHeight = canvas.height;
  //     const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
  //     const imgX = (pdfWidth - imgWidth * ratio) / 2;
  //     const imgY = 30;
  //     pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
  //     pdf.save('receipt.pdf');
  //   });
  // };

  // const handleGeneratePDFClick = () => {
  //   const doc = new jsPDF({
  //     orientation: "portrait",
  //     format: "letter",
  //     unit: "px",
  //     hotfixes: ["px_scaling"]
  //   });

  //   const data = {
  //     beneficiary_name : allData?.beneficiary_name,
  //     currency_settled: allData?.currency_settled,
  //     type: allData?.type,
  //     beneficiary_bank: allData?.beneficiary_bank,
  //     beneficiary_account: allData?.beneficiary_account,
  //     currency_settled: allData?.currency_settled,
  //     transactionid: allData?.transactionid,
  //     narration: allData?.narration,
  //     dated :formatDate(allData?.dated),
  //     delivery :formatDate(allData?.dated),

  //     total_amount : allData?.total_amount,
    
  //   };
  //   templateRef.current?.contentWindow?.setTemplateData(data);
  //   const html = templateRef.current.contentWindow.document.body;

  //   doc.html(html, {
  //     callback(doc) {
  //       //To Save Document locally
  //       doc.save("document");

  //       //To open as blob url in new tab
  //       // const blobUrl = doc.output("bloburi", {
  //       //   filename: "output_document.pdf"
  //       // });
  //       // window.open(blobUrl, "_blank");

  //       //To upload the pdf as blob/arraybuffer
  //       // const fileBlob = doc.output("blob", {filename:"output_document.pdf"});
  //       // const fileByteArray = doc.output("arraybuffer", {filename:"output_document.pdf"});
  //     },
  //     margin: [40, 20, 40, 20]
  //   });
  // };

  const handleGeneratePDFClick = () => {
    const doc = new jsPDF({
      orientation: "portrait",
      format: "letter",
      unit: "px",
      hotfixes: ["px_scaling"]
      
    });
  
    const data = {
      beneficiary_name: allData?.beneficiary_name,
      currency_settled: allData?.currency_settled,
      type: allData?.type,
      beneficiary_bank: allData?.beneficiary_bank,
      beneficiary_account: allData?.beneficiary_account,
      transactionid: allData?.transactionid,
      narration: allData?.narration,
      dated: formatDate(allData?.dated),
      delivery: formatDate(allData?.dated),
      total_amount: allData?.total_amount,
    };
  
    templateRef.current?.contentWindow?.setTemplateData(data);
    const html = templateRef.current.contentWindow.document.body;
  
    // Get the content as string and wrap it in a div to control its size
    const content = `<div style="width: 100%; max-width: 612px; height: auto; overflow: hidden; margin: 0; padding: 20px;">${html.innerHTML}</div>`;
  
    doc.html(content, {
      callback: (doc) => {
        doc.save("document.pdf");
      },
      margin: [0, 0, 0, 0],
      x: 0,
      y: 0,
      width: 612, // Letter size width in px (8.5 inches * 72 points/inch)
      windowWidth: 612, // Match the width to prevent scaling issues
      html2canvas: {
        scale: 1, // Scale down to fit the content better
      }
    });
  };
  
  
  
    
  return (
    <div className='payment-modal' onClick={handletoggle}>
     
      <div  className='modal-box' onClick={handlePropagation}>
      
        <div className='modal-box-wrapper' onClick={handlePropagation} style={{flexDirection:"column"}}>


          {/*  */}
          <div  className='modal-box-wrapper' onClick={handlePropagation} style={{width:"100%"}}>
            <img onClick={handletoggle} className='payment-modal-close' src='/images/x-close.svg' alt='f'/>
            <div className='payment-model-title'>

                <h3>Transaction Summary</h3>
                <p>Here is an overview of your transaction</p>
            </div>
            <div  className='payment-model-line'></div>

            <div className='payment-model-amount'>
                <p>Transaction amount</p>
                <h4> {`${data?.currency_settled==="NGN"?"₦":data?.currency_settled==="USD"?"$":data?.currency_settled} ${Number(data.total_amount)/100}`}</h4>
            </div>

            <div  className='payment-model-line'></div>

            <div className='payment-model-overview'>
                <div className='payment-model-content'>
                    <p className='payment-model-th'>Recipient</p>
                    <p className='payment-model-th'>Bank</p>
                </div>
                <div className='payment-model-content'>
                    <p className='payment-modal-td'>{data?.beneficiary_name|| "N/A" }</p>
                    <p className='payment-modal-td'>{data.beneficiary_bank || data?.beneficiarybank}</p>
                </div>
                <div className='payment-model-content space'>
                    <p className='payment-model-th'>Status</p>
                    <p className='payment-model-th'>Account number</p>
                </div>
                <div className='payment-model-content '>
                    <Status 
                     icon="/images/success.svg"
                     mgs={data?.status}
                    />
                    <p className='payment-modal-td'>{data?.beneficiary_account}</p>
                </div>

                <div className='payment-model-content space'>
                    <p className='payment-model-th'>Initiated by</p>
                    <p className='payment-model-th'>Wallet debited</p>
                </div>
                <div className='payment-model-content '>
                    <p className='payment-modal-td'>{data?.initiator || "N/A"}</p>
                    <p className='payment-modal-td'>{data?.currency_settled || "N/A"}</p>
                </div>

                <div className='payment-model-content space'>
                    <p className='payment-model-th'>Charges</p>
                    <p className='payment-model-th'>Reference</p>
                </div>
                
                <div className='payment-model-content '>
                <p className='payment-modal-td'>{Number(data?.fee_charged)/100}</p>
                    <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                      <input ref={IdRef} value={data.transactionid} className='payment-modal-td copyInput inputSize'/>
                      <img 
                      onClick={() => handleCopyToClipboard(data.transactionid)}
                      style={{display:"inline", cursor: 'pointer'}} 
                      src='/images/copy.svg' 
                      alt='payment' 
                      />
                    </div>
                </div>

                <div className='payment-model-content space'>
                    <p className='payment-model-th'>Decription</p>  
                    <p className='payment-model-th'>Proof</p>  
                </div>
                <div className='payment-model-content '>
                    <p className='payment-modal-td'>{data?.narration}</p>   
                    <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                        <input ref={accRef} value={data?.transactionid} className='payment-modal-td copyInput inputSize'/>
                        <img onClick={() => handleCopyToClipboard(data.transactionid)} style={{display:"inline", cursor: 'pointer'}} src='/images/copy.svg' alt='payment' />
                    </div> 
                </div> 
            </div>

          </div>

          
          


            <div className='payment-overview-btn' >
                <button className='sec-btn'>
                  Resend webhook 
                </button>
                <button className='btn' onClick={handleGeneratePDFClick}>
                   Download reciept
                </button>
            </div>
        </div>
      </div>
      <ToastContainer />

      <div id="hidden-iframe">
      {/* eslint-disable-next-line */}
          <iframe
            ref={templateRef}
            style={{ width: "210mm", height: "297mm" }}
            src="templates/sample.html"
          ></iframe>
        </div>

{/* <div id='hidden-content'  ref={templateRef} dangerouslySetInnerHTML={{ __html: htmlContent }}></div> */}
    </div>
  )
}

export default PayoutOverviewModal
