import React from 'react'

function DetailText({desc1,value1,desc2,value2,textBold,color,fontWeight,fontSize}) {
  return (
    <div className='detail_container'>
        <div className='detail_item'>
            <p className='detail_desc '>{desc1}</p>
            <p className='detail_value' >{value1}</p>
        </div>

        <div className='detail_item left'>
            <p className='detail_desc left_align'>{desc2}</p>
            <p className='detail_value left_align' style={{color:color,fontStyle:textBold,fontWeight:fontWeight,fontSize:fontSize}}>{value2}</p>
        </div>
    </div>
  )
}

export default DetailText