import React, { useState } from 'react'
import "../Settings.scss"
import CollectionsData from "./KycData.json"
import PayoutData from "./KybData.json"
import Status from '../../common/Status';

function Identity() {


const allCollectionsData = CollectionsData;
const allPayoutData = PayoutData;
// const allOtherAfriceData = OtherAfricaData;
// const allNorthAmericaData = NorthAmericaData;
const [isNigeriaVisible, setIsNigeriaVisible] = useState(false);
// const [isOtherAfricaVisible, setIsOtherAfricaVisible] = useState(false);
// const [isNorthAmericaVisible, setIsNorthAmericaVisible] = useState(false);


const toggleNigeriaVisibility = () => {
    setIsNigeriaVisible((prevState) => !prevState);
  };
// const toggleOtherAfricaVisibility = () => {
//     setIsOtherAfricaVisible((prevState) => !prevState);
//   };
// const toggleNorthAmericaVisibility = () => {
//     setIsNorthAmericaVisible((prevState) => !prevState);
//   };

  return (
    <div style={{width:"100%", paddingRight:"19px"}}>
        <div className='pricing_mobile'>
            <table className="pricing-table identity_price " >
                <tbody>
                    <td colSpan={7} style={{padding: 0}}>
                        <div className='payment-table-title' onClick={toggleNigeriaVisibility}>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    (isNigeriaVisible
                                    ? "/images/arrow-side-s-fill.svg"
                                    : "/images/arrow-down-s-fill.svg")
                                }
                                alt="icon"
                            />
                            <div className='flag' style={{border: "none", background:"#fff"}}>
                                <img src={process.env.PUBLIC_URL+"/images/ng-pric.svg"} alt="flag" />
                                <h1 className='ng-pric'>Nigeria</h1>
                            </div>
                        </div>
                    </td>

                    {isNigeriaVisible && ( 
                        <>
                            <tr>
                                <th className='th-text-pric'>KYC</th>
                                <th className='th-text-pric'>Pricing/call</th>
                                <th className='th-text-pric'>Status</th>
                                <th className='th-text-pric'>Live</th>
                                <th className='th-text-pric'></th>
                                <th className='th-text-pric'></th>
                            </tr>
                            {allCollectionsData.map((item, index) => (
                            <tr key={index}>
                                <td className='td-text-pric'>{item.title}</td>
                                <td className='td-text-pric'>{item.pricing}</td>
                                <td className='td-text-pric'>
                                {
                                    item.status === "Soon" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                }
                                {
                                    item.status === "Available" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#ECFDF3"
                                    border="#ABEFC6"
                                    color="#067647"
                                    />
                                }
                                </td>
                                <td className='td-text-pric'>
                                    <div style={{display:"flex", gap:"3px", alignItems:"center"}}>
                                    {item.live === "Request Access" ? (
                                        <span style={{ textDecoration: "underline", color: "var(--Gray-800, #1D2939)", textUnderlineOffset: "3px"}}>
                                            {item.live}
                                        </span>
                                    ) : (
                                        item.live
                                    )}
                                        <img src={process.env.PUBLIC_URL + item.icon} alt='ic' />
                                    </div>
                                </td>
                            </tr>
                            
                            ))}
                            <td></td>
                            <tr>
                                <th className='th-text-pric'>KYB</th>
                                <th className='th-text-pric'>Pricing</th>
                                <th className='th-text-pric'>Status</th>
                                <th className='th-text-pric'>Live</th>
                                <th className='th-text-pric'></th>
                                <th className='th-text-pric'></th>
                            </tr>
                            {allPayoutData.map((item, index) => (
                            <tr key={index}>
                                <td className='td-text-pric'>{item.title}</td>
                                <td className='td-text-pric'>{item.pricing}</td>
                                <td className='td-text-pric'>
                                {
                                    item.status === "Soon" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                }
                                {
                                    item.status === "Available" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#ECFDF3"
                                    border="#ABEFC6"
                                    color="#067647"
                                    />
                                }
                                </td>
                                <td className='td-text-pric'>
                                    <div style={{display:"flex", gap:"3px", alignItems:"center"}}>
                                    {item.live === "Request Access" ? (
                                        <span style={{ textDecoration: "underline", color: "var(--Gray-800, #1D2939)", textUnderlineOffset: "3px"}}>
                                            {item.live}
                                        </span>
                                    ) : (
                                        item.live
                                    )}
                                    {item.live !== "Nil" && (
                                        <img src={process.env.PUBLIC_URL + item.icon} alt='ic' />
                                    )}
                                    </div>
                                </td>
                            </tr>
                            
                            ))}
                            <td></td>

                            <tr>
                                <th className='th-text-pric'>Selfie</th>
                                <th className='th-text-pric'>Pricing</th>
                                <th className='th-text-pric'>Status</th>
                                <th className='th-text-pric'></th>
                                <th className='th-text-pric'></th>
                                <th className='th-text-pric'></th>
                            </tr>
                            {/* {allPayoutData.map((item, index) => ( */}
                            <tr>
                                <td className='td-text-pric'>Face/Image Comparison</td>
                                <td className='td-text-pric'>₦30</td>
                                <td className='td-text-pric'>
                                    <Status 
                                    mgs="Soon"
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-text-pric'>3D Liveness Check</td>
                                <td className='td-text-pric'>₦80</td>
                                <td className='td-text-pric'>
                                    <Status 
                                    mgs="Soon"
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                </td>
                            </tr>
                            {/* // ))} */}

                            <td></td>
                            <tr>
                                <th className='th-text-pric'>Smart Onboarding Flow</th>
                                <th className='th-text-pric'>Pricing</th>
                                <th className='th-text-pric'>Status</th>
                                <th className='th-text-pric'></th>
                                <th className='th-text-pric'></th>
                                <th className='th-text-pric'></th>
                            </tr>
                            {/* {allPayoutData.map((item, index) => ( */}
                            <tr>
                                <td className='td-text-pric'>Non-Doc Verification (BVN–ID Data–Liveness)</td>
                                <td className='td-text-pric'>₦150</td>
                                <td className='td-text-pric'>
                                    <Status 
                                    mgs="Soon"
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='td-text-pric'>Non-Doc Verification (BVN–ID Physical–Liveness)</td>
                                <td className='td-text-pric'>₦180</td>
                                <td className='td-text-pric'>
                                    <Status 
                                    mgs="Soon"
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                </td>
                            </tr>
                        </>
                    )}
                </tbody>
            </table>
        </div>

    </div>
  )
}

export default Identity