import React, { useEffect, useState } from "react";
import "../components/signup/SignUp.scss";
import AsideOverView from "../components/login/AsideOverView";
import { BeatLoader } from "react-spinners";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { apiPost } from "../components/utilities/api/axios";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess } from "../redux/userSlice";

function SignUpSuccessPage() {
  const {currentUser} = useSelector((state)=>state?.user)
  const navigate = useNavigate();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResend, setIsLoadingResend] = useState(false);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(currentUser?.user) navigate("/getstarted");
  });
  const handleInputChange = (index, e) => {
    const value = e.target.value;
    if (value.match(/^[0-9]*$/) && value.length <= 1) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      if (index < 5 && value) {
        inputRefs.current[index + 1].current.focus();
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && !otp[index]) {
      if (index > 0) {
        inputRefs.current[index - 1].current.focus();
      }
    }
  };

  
  // const otpGenerator = localStorage.getItem('otpGenerator')
  const otpGenerator = String(localStorage.getItem('otpGenerator'));
  const message = localStorage.getItem('action')
  console.log(otpGenerator)
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const otpValue = otp.join("");
      const data =   {
        otp: otpValue,
        otpGenerator: otpGenerator,
      }
      const url =  "https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/validate-signup-token"
      const response = await apiPost(url,data,{},true,{})
      console.log("OTP res:",response)
      localStorage.setItem("bisEmail", response.data.user.business_email);
      // localStorage.setItem("otpPassword", response.data.user.password);
      if ( response.data.status === 200 && response.data.success === true) {
        // const businessEmail = localStorage.getItem('bisEmail')
        // const otpPassword = localStorage.getItem('otpPassword')
        // const data = {
        //   business_email: businessEmail,
        //   password: otpPassword
        // }
        // console.log(businessEmail)
        // console.log(otpPassword)
      
        // const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/login'
        // const res = await apiPost(url,data,{},true,{})
        // console.log(res)
        // setIsLoading(false);
        // navigate("/getstarted");
        dispatch(loginSuccess(response.data))
        setIsLoading(false);
        navigate('/getstarted');
      } else {
        setIsLoading(false);
        toast.error("Please enter the correct OTP")
        console.error("OTP validation failed:", response.data.error);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error validating OTP:", error);
      const errorMessage = error?.response?.data?.message || "Error validating OTP"
      toast.error(errorMessage || "Error validating OTP")
    }
  };
  const handleResend = async (e) => {
    e.preventDefault();
    try {
      setIsLoadingResend(true);
      const data =   {
        otpGenerator: otpGenerator,
      }
      console.log(otpGenerator)
      const url =  "https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/resend-signup-otp"
      const response = await apiPost(url,data,{},true,{})
      console.log("OTP:",response)
    if (response.data.status === 200){
      setIsLoadingResend(false);
    }
    } catch (error) {
      setIsLoadingResend(false);
      console.error("Error resending OTP:", error);
      const errorMessage = error?.response?.data?.message || "Error resending OTP"
      toast.error(errorMessage || "Error resending OTP")
    }
  }

  return (
    <div className="dashboard-container">
      <div>
        <AsideOverView />
      </div>
      <div className="sign-up-successful">
        <div className="sign-up-success">
          <img
            src={process.env.PUBLIC_URL + "/images/handshake.svg"}
            alt="hand"
          />
          <div className="modal-wrapper">
            <p className="enter-otp">
              {message}
            </p>
            <div className="input-otp">
              <div className="input-otp-field">
                <input
                  type="text"
                  name="otp"
                  maxLength="1"
                  className="otp-"
                  value={otp[0]}
                  onChange={(e) => handleInputChange(0, e)}
                  onKeyDown={(e) => handleKeyDown(0, e)}
                  ref={inputRefs.current[0]}
                />
                <input
                  type="text"
                  name="otp"
                  maxLength="1"
                  className="otp-"
                  value={otp[1]}
                  onChange={(e) => handleInputChange(1, e)}
                  onKeyDown={(e) => handleKeyDown(1, e)}
                  ref={inputRefs.current[1]}
                />
                <input
                  type="text"
                  name="otp"
                  maxLength="1"
                  className="otp-"
                  value={otp[2]}
                  onChange={(e) => handleInputChange(2, e)}
                  onKeyDown={(e) => handleKeyDown(2, e)}
                  ref={inputRefs.current[2]}
                />
                <span className="dash"></span>
                <input
                  type="text"
                  name="otp"
                  maxLength="1"
                  className="otp-"
                  value={otp[3]}
                  onChange={(e) => handleInputChange(3, e)}
                  onKeyDown={(e) => handleKeyDown(3, e)}
                  ref={inputRefs.current[3]}
                />
                <input
                  type="text"
                  name="otp"
                  maxLength="1"
                  className="otp-"
                  value={otp[4]}
                  onChange={(e) => handleInputChange(4, e)}
                  onKeyDown={(e) => handleKeyDown(4, e)}
                  ref={inputRefs.current[4]}
                />
                <input
                  type="text"
                  name="otp"
                  maxLength="1"
                  className="otp-"
                  value={otp[5]}
                  onChange={(e) => handleInputChange(5, e)}
                  onKeyDown={(e) => handleKeyDown(5, e)}
                  ref={inputRefs.current[5]}
                />
              </div>
            </div>
           

            <div style={{display:"flex",gap:"10px", width:"50%" }}>
              <button className="otp-btn" style={{width:"50%"}} onClick={handleSubmit} disabled={isLoadingResend}>
                  {isLoading ? (
                    <BeatLoader color="#FFF" cssOverride={{}} />
                  ) : (
                    <>Submit</>
                  )}
              </button>
              <button
                onClick={handleResend} style={{color: "#161925", background: "#FFF", border: "1px solid #EAECF0", width:"50%"}} className="sec-btn">
                 {isLoadingResend ? (
                    <BeatLoader cssOverride={{}} />
                  ) : (
                    <>Resend OTP</>
                  )}
              </button>

        </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SignUpSuccessPage;
