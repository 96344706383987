import React,{useEffect, useRef, useState} from 'react'
import NameControl from '../components/common/NameControl'
import '../components/payment/payment.scss'
import { useSelector } from "react-redux";
import PayLinks from '../components/payment/PayLinks';
import Reccurring from '../components/payment/Reccurring';
import CreatePayLinkForm from '../components/payment/CreatePayLinkForm';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import CreateDirectDebitForm from '../components/payment/CreateDirectDebitForm';
import DirectDebitCreated from '../components/payment/DirectDebitCreated';


function PayLinksPage({payLink, toggleModal}) {
  const location = useLocation();
  const [btnActive,setBtnActive] = useState(false);

  useEffect(() => {
    if (location.state?.recurring) {
      setSelectedButton("Direct Debit");
      setIsDirectDebitLink(false)
    }

    getStatementLink();
    getDirectDebitList();
    // eslint-disable-next-line
  }, [location.state]);

    const [selectedButton, setSelectedButton] = useState("One Time");
    const {currentUser} = useSelector((state)=>state?.user);
    const [isPayLink,setIsPayLink] = useState(false) 
    const [isLoading, setIsLoading] = useState(false);
    const [paymentData, setPaymentData] = useState([]);
    const [payLinkInput,setPayLinkInput] = useState({
      pageName:"", 
      productType:"",
      // currency:"NGN",
      currency:"",
      amount:"",
      successmsg:"",
      description:"",
      customLink:"",
      redirectLink:"",
      currency_collected:"",
      currency_settled:""

    })
    const [isPayLinkLoading,setIsPayLinkLoading] = useState(false);
    

    // Direct debit
  const [isDirectDebitLink,setIsDirectDebitLink] = useState(false) 
  const [isDirectDebitCreated,setIsDirectDebitCreated] = useState(false) 
  const [directLinkInput,setDirectLinkInput] = useState({
    productType: "recurring",
    transactionId: "",
    startDate: "",
    endDate: "",
    changeDate: "",
    email: "",
    phoneNumber: "",
    currency: "NGN",
    amount: "",
    frequency: "",
    redirectLink: "",
    webhookUrl:"",
    is_open_banking:false,
    no_of_accounts:""

  })
  const [isDirectDebitLoading,setIsDirectDebitLoading] = useState(false);
  const [directDebitData, setDirectDebitData] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);

    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    const toggleLink =()=>{
      setIsPayLink(!isPayLink);
    }

    const toggleDirectDebit =()=>{
      setIsDirectDebitLink(!isDirectDebitLink);
    }
  

    const getDesc = () => {
      if (selectedButton === 'One Time') {
        return "One Time transactions appear here";
      }
      if (selectedButton === 'Direct Debit') {
        return "Direct Debit transactions appear here";
      }
    };

    const getStatementLink = async ()=>{
      setIsPayLinkLoading(true);
      try{
        
        const headers = {  
          "api_key": currentUser.user.merchantKey, 
          "sweetapi": currentUser.user.merchantCode  
        }

        const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-payment-pages';
        let res = await axios.get(url,{
          headers:headers
        })
  
        
        setPaymentData(res.data?.data || [])
        setIsPayLinkLoading(false)
      }catch(err){
        setIsPayLinkLoading(false);
        console.log(err)
        const errorMessage = err?.message || "unable to fetch data"
      }
    }


    const addStatementLink = async ()=>{

      try{
        setIsLoading(true);

        const headers = {  
          "api_key": currentUser?.user?.merchantKey, 
          "sweetapi": currentUser?.user?.merchantCode  
        }
        const data = {
          ...payLinkInput,
          amount: `${Number(payLinkInput?.amount) * 100}`,
          currency:payLinkInput?.currency,
          currency_settled:payLinkInput?.currency_settled,
          currency_collected:payLinkInput?.currency
        }
        // console.log("datas: ",data)
        
        const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/create-payment-page';
        let res = await axios.post(url,{...data},{
          headers:headers
        })

        
       
        getStatementLink();
        setIsLoading(false);
        setPayLinkInput(false);
        toast.success(res.data.message);
        setIsPayLink(false);
        
      }catch(err){
        setIsLoading(false);
        console.log(err);
        const errorMessage = err?.response?.data?.message || "Server error"
        toast.error(errorMessage,{position: "top-left", pauseOnHover: true});

      }
     
    }

    // direct debit link


  const getDirectDebitList = async ()=>{
    setIsDirectDebitLoading(true);
    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        // "api_key": 'pk-U2FsdGVkX1+i2IXLRQDL4rNPqeJGDhpdHJ/jSzDf8AiVsrEh6BB2tQHvbI3pVkXY4lPonli/Xuscb0QDSS/+JfIex2Gi8dABLmyvXSRTZf7xYn3xUWp1TjT42ZneiicW', 
      }

      const url = 'https://flick-app.com/direct-debit/listLinks';
      let res = await axios.get(url,{
        headers:headers
      })

      setDirectDebitData(res.data?.data || [])
      setIsDirectDebitLoading(false)
    }catch(err){
      setIsDirectDebitLoading(false);
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
    }
  };


  const createDirectDebitLink = async ()=>{
    let data;
    console.log("banks: ",directLinkInput)
    try{
      setIsLoading(true);
      const headers = {  
        "api_key": currentUser?.user?.merchantKey, 
        'Content-Type': 'application/json' 
      }
     

      if(directLinkInput.productType === "onetime"){
        data = {
          amount:`${Number(directLinkInput?.amount) * 100}`,
          currency: directLinkInput.currency,
          pagename: directLinkInput.pagename,
          // webhookUrl:directLinkInput.webhookUrl,
          type: directLinkInput.productType,
          charge_date: directLinkInput.changeDate,

        }

      }else{
        
        data = {
          amount: `${Number(directLinkInput?.amount) * 100}`,
          start_date:directLinkInput.startDate,
          end_date: directLinkInput.endDate,
          frequency: directLinkInput.frequency,
          currency: directLinkInput.currency,
          pagename: directLinkInput.pagename,
          type: directLinkInput.productType,
          is_open_banking: directLinkInput.is_open_banking,
          no_of_accounts: directLinkInput.no_of_accounts
        }

      }
       
      // console.log("initialize-dashboard data", data)
      const url = 'https://flick-app.com/direct-debit/initialize-dashboard';
      let res = await axios.post(url,{...data},{
        headers:headers
      });
      

      getDirectDebitList();
      setIsLoading(false);
      setDirectLinkInput(false);
      setIsDirectDebitLink(false);
  
      // setIsDirectDebitCreated(true)
      
    }catch(err){
      setIsLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.message || "Server error"
      toast.error(errorMessage,{position: "top-left", pauseOnHover: true});

    }
    
  };

  const renderContent = () => {
    if(selectedButton === 'One Time') return  <PayLinks paymentData={paymentData} toggleLink={toggleLink} payLink={payLink} toggleModal={toggleModal} isPayLinkLoading={isPayLinkLoading}/>
    
    if(selectedButton ==='Direct Debit') return <Reccurring toggleDirectDebit={toggleDirectDebit} directDebitData={directDebitData}/>
   
  };

  return (
    
          <div className='main-inner'>
           
              <NameControl 
              name="PayLinks"
              desc={getDesc()}
              btnFirst="One Time"
              btnSecond="Direct Debit"
              btnActive={selectedButton}
              handleButtonClick ={handleButtonClick}
              />    
              
              {renderContent()}
              {isPayLink && <CreatePayLinkForm payLinkInput={payLinkInput} setPayLinkInput={setPayLinkInput} setIsPayLink={setIsPayLink} isLoading={isLoading} addStatementLink={addStatementLink}/>}
              {isDirectDebitLink && <CreateDirectDebitForm directLinkInput={directLinkInput} setDirectLinkInput={setDirectLinkInput} setIsDirectDebitLink={setIsDirectDebitLink} isLoading={isLoading} createDirectDebitLink={createDirectDebitLink} setIsDirectDebitCreated={setIsDirectDebitCreated}/>}
              {isDirectDebitCreated && <DirectDebitCreated setIsDirectDebitCreated={setIsDirectDebitCreated} setIsDirectDebitLink={setIsDirectDebitLink} />}
          </div>

  )

}

export default PayLinksPage
