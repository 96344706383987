import React, { useRef } from "react";
import './Modal.scss'
import { copyToClipboard } from "../utilities/clipboardUtils";

function TransactionSuccessful({setIsTransactionSuccess,inputCardDetails,amountInput,transactionId,setIsAmount,setIsPaymentMethod,setIsCardMethod,setIsCardDetails,setIsOtp,setIsPinOtp,setIsAmountNormal,setIsPayment,setIsBalanceOpen,setIsOtherBalance}) {
//   if (!open) return null;
  const accRef = useRef();

  const handleClose = ()=>{
    setIsTransactionSuccess(false)
    setIsPinOtp(false)
    setIsOtp(false)
    setIsCardDetails(false)
    setIsCardMethod(false)
    // setIsPaymentMethod(false)
    // setIsAmount(false)
    setIsOtherBalance(false);
    setIsAmountNormal(false);
    setIsPayment(false)
      if(setIsBalanceOpen){
          setIsBalanceOpen(false)
    }
  }
  return (
    <div className="overlay" >
    <div className="enter-amount-modal">
      <div className="modal-wrapper">

        <div>
        <img src={process.env.PUBLIC_URL + "/images/iconSuccess.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          <p className="enter-amount">Transaction Successful</p>
          
          <p className="enter-an-amount">Your transaction has been initiated, check your balance</p>
        </div>

        <div className="transact-amount">
          <p className="t-amount">Transaction amount</p>
         
          <p className="amount">{`₦${amountInput}`}</p>
        </div>

        <div className="account-details">
            {/* <div className="number-name">
                <div className="number">
                    <p className="title">Recipient</p>
                    <p className="sub-title">{inputCardDetails?.name}</p>
                </div>

                <div className="transact-name">
                    <p className="title">Recipient bank</p>
                    <p className="sub-title">GTBank - 0224048917</p>
                </div>
            </div> */}

            <div className="number-name">
             
                <div className="number">
                    <p className="title">Charges</p>
                    <p className="sub-title">0.00</p>
                </div>

                <div className="transact-name">
                  <p className="title">Reference</p>
                <div style={{display:"flex",alignItems:"center"}}>

                    <input 
                    ref={accRef}
                    type='text' 
                    value={transactionId} 
                    className='account-td copyInput'
                    name='account_number'
                    />
                    <img 
                    src='/images/copyBalance.svg' 
                    alt="copy"
                    onClick={copyToClipboard.bind(null,accRef)}
                    />
                </div>
                {/* 1905 */}
                   
                    {/* <p className="sub-title">{transactionId} <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p> */}
                </div>
            </div>

        </div>

        <div className="modal-btn">
          <button onClick={handleClose} className="first-btn">
            Cancel
          </button>
          <button className="btn" onClick={setIsTransactionSuccess.bind(this,true)}>
            Download Receipt
          </button>
        </div>
        </div>
    </div>
    </div>
  );
}

export default TransactionSuccessful;
