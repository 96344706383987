import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';

function FlickAccountModal({setFlickModal,flickPayoutInput,setFlickPayoutInput,balanceData,handleBeneficiary,setIsPayout,setIsConfirmFlickPayout,setPayoutId,setPayoutResult}) {
    const { currentUser } = useSelector((state) => state?.user);
    const [page,setPage] = useState("")
    const [isLoading,setIsLoading] = useState(false);
    const [isNairPayout,setIsNairaPayout] = useState(false)
    const [amount,setAmount] = useState('')


    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
         
        if (name === 'amount') {
            
            // Update the state with the formatted value
            const valueWithoutCommas = value.replace(/,/g, '');
            setAmount(valueWithoutCommas);
           
            // Add commas
            const formattedValue = valueWithoutCommas.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Update the state with the formatted value
            setFlickPayoutInput((prevFormData) => ({
            ...prevFormData,
            [name]: formattedValue,
            }));
            
            
          } else {
            // For other fields, just update the state normally
            setFlickPayoutInput((prevFormData) => ({
                ...prevFormData,
                [name]: value,
                ...(name === 'balance' && { currency: value })
            }));
           
          }
        
    }

    
    const handleSubmit = async(e)=>{
        e.preventDefault();
        setIsLoading(true)

        const {balance,amount,currency,beneBusinessCode,beneficiary_name,narration} = flickPayoutInput;
        if(amount === "" || amount === NaN || amount === undefined) return toast.error("Amount must not be empty",{position: "top-left",pauseOnHover: true})
        if(beneBusinessCode === "") return toast.error("Merchant ID must not be empty",{position: "top-left", pauseOnHover: true})
        if(beneficiary_name === "") return toast.error("Merchant Name must not be empty",{position: "top-left", pauseOnHover: true})
        try{
            const headers = {
                'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
            };

            const data ={
                
                amount: `${Number(flickPayoutInput.amount.replace(/,/g, "")) * 100}`,
                currency,
                beneBusinessCode,
                beneficiary_name,
                narration,
                bank_code:"P2P"
            }
            const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/payout";
            
            let res = await axios.post(url,{...data},{ headers: headers});
           
            setPayoutResult(res.data)            
            setPayoutId(res.data?.Id)
            setIsLoading(false)
            setIsConfirmFlickPayout(true)
    
        }catch(err){
            setIsLoading(false)
            console.log(err)
        }
        

       


    }
  return (
    <div className='balance_modal'  onClick={setIsPayout.bind(this, false).bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsPayout.bind(this, false).bind(this, false)}
              className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
              />
           
            <div className="modal-title">
                <div className='step_title'>
                    <div >
                        <p className="enter-amount">Transfer to Flick Account</p>
                        <p className="enter-an-amount">Please provide the details of the Merchant for this payout.</p>
                    </div>
                </div>
            </div>
            <form  onSubmit={handleSubmit}>

                <div className='input_banks_container'>
                        <div 
                        // onClick={() => changeActive("card_num")}
                        className={`input_group ${page==="card_num" ? 'clicked' : ''}`} 
                        >
                            <select 
                            className='balance_select' 
                            style={{background:"transparent"}}
                            name='balance'
                            value={flickPayoutInput.balance} 
                            onChange={handleInputChange}
                            required
                            >
                            {balanceData.map((balance, index) => (
                                <option 
                                    key={index} 
                                    value={balance.currency}
                                >
                                    {`${balance.currency} ${Number(balance.payout_balance) / 100}`}
                                </option>
                            ))}
                            </select>
                        </div>
                        <p className='card_label'>Choose balance to transfer from</p>
                </div>

                <div className='api_select_container' style={{marginTop:"16px",marginBottom:"16px"}}>
                    <div className='api_wallet_select_input'>
                        <img src={`/images/${flickPayoutInput.currency}.svg`} alt=""/>    
                        <select 
                            className='api_select_val' 
                            name='currency'
                            value={flickPayoutInput.currency} 
                            onChange={handleInputChange}
                            disabled
                        >
                            <option value={flickPayoutInput.balance}>{flickPayoutInput.balance}</option> 
                        </select>
                    </div>
                    <input  name='amount' value={flickPayoutInput.amount} className='api_input_amountss'  placeholder="5.00" onChange={handleInputChange} required/>
                </div> 
 
                <div className='api_wallet_input'>
                    <input className='portfolio_amount_input' placeholder='Merchant ID' name='beneBusinessCode' onChange={handleInputChange} style={{marginTop:"16px"}} required/>
                    <input className='portfolio_amount_input' placeholder='Merchant name' name='beneficiary_name' onChange={handleInputChange} style={{marginTop:"16px"}} required/>
                    
                    <p className='select_exist' style={{cursor:"pointer"}} onClick={handleBeneficiary}>Or select existing beneficiary  <img style={{display:"inline",marginLeft:"8px"}} src='/images/selectBeneficiary.svg' alt='ben'/></p>       
                    {/* <div className='account_loader'>
                        {
                            isLoading ?
                            <div className='loader_overlay'>  
                            <CircleLoader
                                color="#259792"
                                size={15}
                            />
                            </div>:
                            <input value={transferBankInput.beneficiary_name} className='portfolio_amount_input' placeholder='Account name' name='beneficiary_name' onChange={handleInputChange} style={{marginTop:"16px"}} readOnly/>
                        }
                    </div> */}
                    <input className='portfolio_amount_input' placeholder='Description (optional)' name='narration' onChange={handleInputChange} style={{marginTop:"16px"}} required/>
                    <div className="modal-btn" style={{display:"flex", gap:"12px", marginTop:"32px"}}>
                    <button onClick={setIsPayout.bind(this, false).bind(this,false)} className="btn otc_btn_cancel">
                        Cancel
                    </button>
                    {
                        isLoading ?
                        <button  className="btn" onClick={handleSubmit}>   
                             <BeatLoader
                                color="#FFF"
                                cssOverride={{}}
                            />
                        </button>:
                        <button  className="btn" onClick={handleSubmit}>   
                            Confirm transfer
                        </button>
                       
                    }

                </div>
                </div>
               
                </form>
               
            
          </div>
        </div>
    </div>
  )
}

export default FlickAccountModal