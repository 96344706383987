import React from 'react'

function BusinessAddress({label}) {
  return (
    <div className='input_group_desc'>
        <p className='input_text'>{label}</p>
        <div className='input_rule'></div>
    </div>
  )
}

export default BusinessAddress