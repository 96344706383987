import React,{useState} from 'react'
import { useSelector } from 'react-redux';
import './Sidebar.scss'
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoMdMenu } from "react-icons/io";
import DropDownItem from '../utilities/DropDownItem';
function Nav({name,email,avatar,setIsNotification,toggleProfile,MobileMenuHandler,setSelectedOption,toggleModal}) {
    const {currentUser} = useSelector((state)=>state?.user)
    const [mode, setMode] = useState(currentUser.user.isVerified);
    const [isMoveMoney, setIsMoveMoney] = useState(false);
    
   const toggleLiveMode =()=>{
    if (currentUser.user.isVerified) {
        setMode((prevMode) => !prevMode); // Using functional update to avoid stale state
      } else {
        setMode(false);
        toast.warning("Incomplete KYC: Please complete your KYC so you can go live")
      }
   }

   const handleProfile = ()=>{
    toggleProfile();
   }

   const sendSupportEmail = () => {
    window.open = 'mailto:support@getflick.co'; 
  };

    //   Move money
  
  const handleMoveMoney = ()=>{
    setIsMoveMoney(!isMoveMoney)
    
  }

  const handleSelectedOption =(option)=>{
   
    setIsMoveMoney(false)
    setSelectedOption(option)
    toggleModal()

  }
  return (
    <div className='nav'>
        <div className='nav-wrapper'>
            <div className="header_action" onClick={MobileMenuHandler}>
               <IoMdMenu size={30} />
            </div>
            <div className='nav-right'>
                <div className='nav-content'>
                    
                    <div>
                        <button onClick={handleMoveMoney} className='btn' style={{padding:"10px 16px",font:"12px",width:"fit-content",borderRadius:"border-radius: 8px",marginLeft:"24px"}}>
                        Move Money  <img src="/images/chevron-downs.svg" alt="plus" style={{display:"inline",marginLeft:"8px"}}/>
                        </button>

                        {
                            isMoveMoney &&
                            <DropDownItem handleSelectedOption={handleSelectedOption }  setSelectedOption={setSelectedOption}/>
                        }
                        
                    </div>
                    {/*  eslint-disable-next-line */}
                    <a href="https://docs.getflick.co/reference/introduction" target='_blank' className='nav-documentation nav-btn'>
                        <img src={process.env.PUBLIC_URL+"/images/api.svg"} alt="support"/>
                        <h1 className='nav-heading'>API Docs</h1>
                    </a>

                    <div className='divider'></div>
                    <div className='live-test-mode' >
                        <img  className="nav-toggle-img" onClick={toggleLiveMode} src={!mode? "/images/mode.svg" : "/images/toggleLive.svg"} alt="support"/>
                        <h1 className='nav-heading'>{!mode?"Test Mode":"Live Mode"}</h1>
                    </div>
                    <div className='divider'></div>
                    <div onClick={setIsNotification.bind(this,true)} className='nav-notification'>   
                        <img  src={process.env.PUBLIC_URL+"/images/navBell.svg"} alt="Bell"/>
                    </div>
                </div>
           
            </div>
        </div>
        
      <ToastContainer />
    </div>
  )
}

export default Nav
