import React, { useState } from 'react'
import DetailText from './DetailText';

function WalletConfirmDetail({setIsWalletConfirmDetail,setIsWalletSubmitted}) {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Toggle the checked state
      };
  return (
    <div className='balance_modal'  onClick={setIsWalletConfirmDetail.bind(this, false)} >
       
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsWalletConfirmDetail.bind(this, false)}
              className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
              />

            <div className="modal-title">
                <p className="enter-amount">Confirm Details</p>
                <p className="enter-an-amount">Please confirm the following details regarding this conversion and ensure all information provided is correct.</p>
            </div>

            <div className='otc_info_container'>
                <img src='/images/otc/info-circle.svg' alt='' />
                <p className='otc_info'>No changes are possible after proceeding</p>
            </div>

            <div className='otcform_container'>
                <div className='desc_container'>
                    <DetailText desc1="Wire Type" value1="Domestic U.S wire (same/next day)"/>
                    <DetailText desc1="Account Number" value1="22488136902667" desc2="Routing Number" value2="23690867"/>
                    <DetailText desc1="Beneficiary Account Name" value1="Ayomide Ajibade" desc2="Bank Name" value2="Wells Fargo"/>
                    <DetailText desc1="Beneficiary Address " value1="1234 Unity Street Los Angeles, CA 90001"/>
                    <DetailText desc1="Bank Address" value1="567 Carl Street, Los Angeles, CA 93021"/>
                </div>
            </div>
            <div className='consent_to'>
                <input 
                type="checkbox" 
                checked={isChecked} 
                onChange={handleCheckboxChange} 
                />
                 {/* eslint-disable-next-line */}
                <p className='confirm_desc'>I confirm the above payment details are accurate and consent to Flick's <a href='#'>Policy</a> </p>
            </div>

            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={setIsWalletConfirmDetail.bind(this,false)} >
                    Cancel
                </button>
                <button className='btn' onClick={setIsWalletSubmitted.bind(this,true)} >
                    Proceed
                </button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default WalletConfirmDetail