import React, { useState } from 'react'
import TableHeaderSearch from '../common/TableHeaderSearch'
import Status from '../common/Status'
import '../common/tables.scss'

function OverviewDataTable() {
    const [isChecked, setIsChecked] = useState(false)
    // Handle checkbox change
    const handleCheckboxChange = (event) => {
      setIsChecked(event.target.checked);
    };
  return (
    <div className='balance-overview'>
      <table className='tbl'>
        <tr>
            <td colSpan="7" className='tbl-heading'>
                <TableHeaderSearch 
                title="API Logs"
                placeholder="Search by name, acc num or reff..."
                users=" records"
                numUsers={0}
                btnFirst
                />
            </td>
        </tr>
        <tr>
            <th className='th-text'>customers</th>
            <th className='th-text'>cost</th>
            <th className='th-text'>Products</th>
            <th className='th-text'>Type</th>
            <th className='th-text'>Date</th>
            
        </tr>
        {/* <tr>
            <td className='td-text'>
                <div className='checked-input'>
                    <input 
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    />
                    <div className='customer-td'>
                     
                      <p className='checked-th'></p>
                      <p className='td-text'></p>

                    </div>
                </div>
            </td>
            <td className='td-text'>
                0.00
            </td>
            <td>
                <div className='payment-status'>
                    <Status 
                    bg="#EEF4FF"
                    border="#C7D7FE"
                    color="#3538CD"
                    mgs="Successful "
                    />
                    
                </div>
            </td>
           
            <td className='td-text' >Api  Logs</td>
            <td className='td-text'></td>
            
        </tr> */}
        {/* duplicate */}

        <tr>
             <td  colSpan={7} style={{width:"100%"}}>
             <div className='empty_tbl'>
                 <img src='/images/emptyPage.svg' alt='emptuy' />
                 <h3 className='num_trans'>No Data Yet</h3>
                
             </div>
             </td>
         </tr>
        
        
        
      </table>
    </div>
  )
}

export default OverviewDataTable
