import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import '../common/tables.scss';
import TableHeaderSearch from '../common/TableHeaderSearch';
import Status from '../common/Status';
import { useNavigate } from 'react-router-dom';
import TableLoader from '../common/TableLoader';
import { toast } from 'react-toastify';

function DirectDebitTransaction({ transactionsData, isTransactionLoading }) {
  const navigate = useNavigate();
  const [copiedText, setCopiedText] = useState('');

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    toast.success('Copied successfully');
  };
  const customStyles = {
    header: {
      style: {
        backgroundColor: '#f9fafb',
      },
    },
    headRow: {
      style: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: '#f2f4f7',
        '&:hover': {
          backgroundColor: '#f9fafb',
        },
      },
    },
    headCells: {
      style: {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '12px',
        paddingBottom: '12px',
        backgroundColor: '#f9fafb',
      },
    },
    
  };
  const columns = [
    {
      name: 'Date',
      selector: row => row.dated,
      sortable: true,
    },
    {
      name: 'Transaction ID',
      cell: row => (
        <div style={{ display: "flex", gap: "5px" }}>
          <input className='link_table' value={row.transactionId} hidden readOnly />
          <h1 className='td-text'>
            {row.transactionId.length > 7 ? `${String(row.transactionId).slice(0, 7)}...` : row.transactionId}
          </h1>
          <img
            src={process.env.PUBLIC_URL + "/images/copy-key-icon.svg"}
            alt="ic"
            onClick={() => handleCopyToClipboard(row.transactionId)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Session ID',
      cell: row => (
        <div style={{ display: "flex", gap: "5px" }}>
          <input className='link_table' value={row.sessionId} hidden readOnly />
          <h1 className='td-text'>
            {row.sessionId.length > 10 ? `${String(row.sessionId).slice(0, 9)}...` : row.sessionId}
          </h1>
          <img
            src={process.env.PUBLIC_URL + "/images/copy-key-icon.svg"}
            alt="ic"
            onClick={() => handleCopyToClipboard(row.sessionId)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Name',
      selector: row => row.account_name,
      sortable: true,
    },
    {
      name: 'Amount',
      selector: row => `NGN ${row.amount}`,
      sortable: true,
      right: true,
    },
    {
      name: 'Status',
      cell: row => row.status === "success" ? (
        <Status mgs="Successful" bg="#ECFDF3" border="#ABEFC6" color="#067647" />
      ) : (
        <Status mgs="Pending" bg="#F9FAFB" border="#EAECF0" color="#344054" />
      ),
      sortable: true,
    },
    {
      name: '',
      cell: row => (
        <img src='/images/elipsis.svg' alt='dot' />
      ),
    },
  ];

  return (
    <div className='balance-overview'>
      {isTransactionLoading && <TableLoader />}
      
      <DataTable
        columns={columns}
        data={transactionsData}
        pagination
        highlightOnHover
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
        <TableHeaderSearch
            title="All Transactions"
            placeholder="Search by name..."
            numUsers={transactionsData?.length}
            users=" total"
            btnFirst
        />
        }
        noDataComponent={
          <div className='empty_tbl'>
            <img src='/images/emptyPage.svg' alt='empty' />
            <h3 className='num_trans'>No Transactions Yet</h3>
            <p className='num_desc'>It is necessary to perform transactions on your Flick account</p>
          </div>
        }
      />
    </div>
  );
}

export default DirectDebitTransaction;