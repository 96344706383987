import React,{useEffect, useRef, useState} from 'react'
import NameControl from '../components/common/NameControl'
import Statements from '../components/data/Statements';
import Links from '../components/data/Links';
import CreakLinkForm from '../components/data/statements/CreakLinkForm';
import axios from 'axios';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

function DataStatements() {
  const {currentUser} = useSelector((state)=>state?.user)
  const [statementData,setStatementData] = useState([])
  const [isStatementLoading, setIsStatementLoading] = useState(false);
  const [isLinkLoading,setIsLinkLoading] = useState(false)
  useEffect(()=>{
    getStatements();
    getStatementLink(); 
    // eslint-disable-next-line 
  },[])
   const [btnActive,setBtnActive] = useState(false);
   const [selectedButton, setSelectedButton] = useState("Statement");
   const [isLink,setIsLink] = useState(false) 
   const [linkInput,setLinkInput] = useState({
    pageName:"",
    description:"",
    customLink:"",
    selectedApps:"",
    selectedPeriod:"",
    redirectLink:"",
    emailaddress:""
   })


   const [statementLink,setStatementLink] = useState([])
   const [isLoading, setIsLoading] = useState(false);

    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };
    
    const toggleLink =()=>{
      setIsLink(!isLink);
    }
    const renderContent = () => {
    if(selectedButton === 'Statement') return <Statements statementLink={statementLink} 
    statementData={statementData}
    isStatementLoading={isStatementLoading}
    />;
    
    if(selectedButton ==='Links') return <Links isLinkLoading={isLinkLoading} statementLink={statementLink} toggleLink={toggleLink}/>;
   
    };

    const getStatements = async ()=>{
      setIsStatementLoading(true)
      try{
        
        const headers = {  
          "api_key": currentUser.user.merchantKey, 
          "sweetapi": currentUser.user.merchantCode  
        }
        const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/linked-statements-data';
        let res = await axios.get(url,{
          headers:headers
        })


        
        const data = res?.data?.data
        
        setStatementData(data);
        setIsStatementLoading(false)
        // setBalanceData(newValue || []);
        
      }catch(err){
        setIsStatementLoading(false)
        console.log(err)
        const errorMessage = err?.message || "unable to fetch data"
      
      }
    }


    const getStatementLink = async ()=>{
      setIsLinkLoading(true);
      try{
        
        const headers = {  
          "api_key": currentUser.user.merchantKey, 
          "sweetapi": currentUser.user.merchantCode  
        }
        const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-job-pages';
        let res = await axios.get(url,{
          headers:headers
        })
  
        
        setStatementLink(res.data.data || [])

        setIsLinkLoading(false)
         
      }catch(err){
        setIsLinkLoading(false)
        console.log(err)
        const errorMessage = err?.message || "unable to fetch data"
       
      }
    }

    const addStatementLink = async ()=>{

      try{
        setIsLoading(true);

        const headers = {  
          "api_key": currentUser?.user?.merchantKey, 
          "sweetapi": currentUser?.user?.merchantCode  
        }
        console.log("datas: ",linkInput)
        const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/create-job-page';
        let res = await axios.post(url,{...linkInput},{
          headers:headers
        })
        console.log(res.data)
        getStatementLink();
        setIsLoading(false);
        setIsLink(false);
        toast.success(res.data.message,{position: "top-left", pauseOnHover: true});
      }catch(err){
        setIsLoading(false);
        console.log(err);
        const errorMessage = err?.response?.data?.message || "Server error"
        toast.error(errorMessage,{position: "top-left", pauseOnHover: true});

      }
      console.log(linkInput)
    }
  return (
 
    <div className='main-inner'>
    
        <NameControl 
        name="Statements"
        desc="All statements collected by Flick"
        btntext="Quick check"
        btnFirst="Statement"
        btnSecond="Links"
        btnActive={btnActive}
        handleButtonClick ={handleButtonClick}
        />
        
        {
          renderContent()
        }
        {isLink && <CreakLinkForm  isLoading={isLoading}  addStatementLink={addStatementLink} linkInput={linkInput} setLinkInput={setLinkInput} setIsLink={setIsLink}/>}



    </div>
        
  )
}

export default DataStatements
