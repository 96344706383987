import React, { useRef, useState } from 'react'
import '../modals/Modal.scss'
import './Disputes.scss'
import axios from 'axios';
import Progress from '../utilities/Progress';
function AddDisputeModal({setIsAddDispute,setDisputeInput,disputeInput,setIsCollectionModal,selectedButton,setIsPayoutModal}) {

  const coRef = useRef();
  const [coPercentage,setCoPercentage] = useState(0);
  const [co,setCo] = useState(null);
  const [coFile,setCoFile] = useState(null);
  const [isProgress,setIsProgress] = useState(false)
  const handlefile = async (e)=>{
    const { name, files } = e.target;
    if (!e.target.files || e.target.files.length === 0) {
      setCo(undefined)
      return
    }

    const file = files[0];
    setCoFile(file);
    const form = new FormData();
    form.append("file",file);
    setIsProgress(true)
    try{
      const url = 'https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test';
      let res = await axios.post(url,form,{
        onUploadProgress: progressEvent => {
          setCoPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        }
      });
      let data = {...disputeInput}
      data[name] = res?.data?.url
      setDisputeInput(data)
     setTimeout(() =>{
        setCoPercentage(0);
        setIsProgress(false)
      } , 3000);
      setCo(res?.data?.url)
    }catch(err){
      setIsProgress(false)
      console.log(err)
    }
  
  }
  const handleSubmite = (e)=>{
    e.preventDefault();
  }
  
  return (
    <div className="overlay" >
    <div className="enter-amount-modal">
      <div className="modal-wrapper">
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}> 
          <p className='disput_title'>Add dispute</p>
          <img style={{cursor:"pointer"}} className='verify-close' onClick={setIsAddDispute.bind(this,false)} src={process.env.PUBLIC_URL + "/images/x-close.svg"} alt="icon" />
        </div>
        <p className='disput_desc'>Your transaction has been initiated, check your balance</p>

        <form className='dispute_form' onSubmit={handleSubmite}>
            <div>
                <p className='dispute_lbl'>Transaction ID</p>
                <input className='dispute_input' name='transactionId' placeholder='dvdsv425523fd35d3...'/>
            </div>
            <div>
                <p className='dispute_lbl'>Message (optional)</p>
                <textarea className='dispute_input' placeholder='Type in a message...'/>
            </div>
            <div>
              <p className='dispute_lbl'>Attach Document (e.g. bank receipt)</p>

              <div 
                  className="dispute_input"
                  style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}
                  onClick={() => coRef.current.click()}
              >
                  <input 
                    type="file"
                    onChange={handlefile}
                    name="doc"
                    hidden
                    ref={coRef}
                  />
                      <img
                        onClick={() => coRef.current.click()}
                        src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                        alt="upload"
                        style={{cursor:"pointer"}}
                      />
                        {
                        coFile  ?
                        (

                          <>
                        
                            <p>{coFile?.name}</p>
                            <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change document</p>
                          </>
                        ):
                        !coFile && co ?
                        <>
                        
                          <p>{co?.split("/")[co?.split("/").length-1]}</p>
                          <p className="file-type" style={{marginTop:"10px"}}>Click on the icon to change document</p>
                        </>:
                        <>
                          
                        <div className="click" >
                          <p className='dispute_upload_text'> Tap to Upload Bank receipt <span> (optional)</span></p>
                          <p className='dispute_svg'>SVG, PNG, JPG, GIF | 10MB max.</p>
                        </div>

                        <button className='btn' style={{width:"71px"}} onClick={() => coRef.current.click()}>Upload</button>
                        </>
                          
                      }
                      {
                        isProgress &&
                        <Progress percentage={coPercentage} />
                      }
              </div>
            </div>
        </form>
         
        <div className='otc_btn_modal'>
            <button className='btn otc_btn_cancel' onClick={setIsAddDispute.bind(this,false)} >
                Cancel
            </button>
            <button className='btn' onClick={selectedButton==="Collections"?setIsCollectionModal.bind(this,true):selectedButton==="Payout" && setIsPayoutModal.bind(this,true)} >
              Confirm
            </button>
        </div>

      </div>
    </div>
    </div>
  )
}

export default AddDisputeModal