import React from 'react'

function WalletSubmitted({setIsWalletSubmitted,setIsRejected}) {
    const handleClose = ()=>{
        setIsWalletSubmitted(false)
    }
  return (
    <div className='balance_modal'  onClick={setIsWalletSubmitted.bind(this, false)} >
       
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
        <div className='balance_modal_wrapper'>
            <img 
            onClick={setIsWalletSubmitted.bind(this, false)}
            className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
            />
            <div className='otc_payout_logo'>
                <img src='/images/otc/flickRound.svg' alt='' />
                <p className='payout_logo_title'>Payment Submitted</p>
                <p className='payout_logo_desc'>Your payment is being processed and should be credited in beneficiary account within 12-business hours</p>
                <button className='btn' style={{width:"210px",marginTop:"24px"}} onClick={handleClose}>Done</button>
            </div>

        </div>
        </div>
    </div>
  )
}

export default WalletSubmitted