import React from 'react'

function SelectInputGroup({children,label,handleChange,name,...otherProps}) {
  return (
    <div className='input_group_otc' >
        <p className='input_text'>{label}</p>
        <select 
        className='otc_select_full' 
        name={name}
        {...otherProps}
        onChange={handleChange}>
        {children}

        </select>
    {/* <input  name='email' className='input_accept'  placeholder="mide@getflick.app" onChange={handleInputChange}/> */}
    </div>
  )
}

export default SelectInputGroup