import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import DropDownContainer from '../utilities/DropDownContainer';
import DropDownItem from '../utilities/DropDownItem';
import '../utilities/dropDownStyle.scss'
import Status from './Status';

function NameControl({
    name,
    desc,
    btnFirst,
    btnSecond,
    image,
    btn,
    handleButtonClick,
    customer,
    data,
    btntext,
    verifyIdentity,
    balance,
    selectedOption,
    setSelectedOption,
    selectedCurrency,
    setSelectedCurrency,
    toggleModal,
    identity,
    backbtnPath,
    profile,
    trans,
    setIsOpenPopup,
    setIsPayout,
    setIsCurrency,
    paymentCurrency,
    getCollection,
    dispute,
    setIsAddDispute,
    payoutCurrency,
    balanceData,
    numUsers,
    users,
    direct,
    btnActive,


}) {
    const [activeButton, setActiveButton] = useState(btnFirst);
    const [selectedButton, setSelectedButton] = useState("Collections");
    const navigate = useNavigate()
    
    
    useEffect(() => {
      if (btnActive) {
        setActiveButton(btnActive);
      }
    }, [btnActive]);

  
  const handleClick = (button) => {
    setActiveButton(button);
    handleButtonClick(button)
  };
  const handleNavigate = ()=>{
    navigate('/data-profile')
  }
  const handleVerifyIdentity = ()=>{
    verifyIdentity();
    
  }
  const handleSelectChange = (event) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
    toggleModal();
  };
  const handleSelectcurrency = (e)=>{
    setSelectedCurrency(e.target.value)
    getCollection(e.target.value)
    
  }
  
  const handleBack = ()=>{
     navigate(backbtnPath);
  }
  
  
  return (
    <div className={`name-control ${customer && 'customer-bg'}`}>
     
      {
        !customer ? 
        <div className="name-control-title">
          <div className='name-control-title-content'>
              <h1 className='name-control-title'>
                {name}{image && <span>🔆</span>}
              </h1>
              <p className='name-control-desc' style={{display:"flex",gap:"12px"}}>{desc}

              <span>
                {direct && <Status num={numUsers} mgs={users} />}
              </span> 
              </p>
              
          </div>
          {
            balance && activeButton==="All Balances" ?
            // <div className='balance-transfer-options'>
            //   <select value={selectedOption} onChange={handleSelectChange} className='transfer-options'>
            //     <option value="" disabled hidden>Transfer</option>
            //     <option value='bank_account'>Transfer to Bank Account</option>
            //   <option value='balance'>Transfer between Balances</option>
            //   <option value='currency'>Add New Currency</option>
            //   </select>
            // </div> 

            <div className='add-currency'>

              {/* <DropDownContainer setSelectedOption={setSelectedOption} toggleModal={toggleModal}/> */}
              <p className='currency' onClick={setIsCurrency.bind(this,true)} sty>Add Currency +</p>
            </div>
            : 
            balance && activeButton !=="All Balances"?
            <div className='all-balancey-currency'>
              <img src={`/images/${selectedCurrency === undefined ? "NGN" : selectedCurrency }.svg`} alt='icon' />

              <select value={selectedCurrency} onChange={handleSelectcurrency} className='curreny-option'>
                {/* <option value='NGN'>NGN</option>
                <option value='USD'>USD</option> */}
                {
                  balanceData?.length > 0 ? 
                  balanceData.map((item)=>(
                    <option value={item.currency}>{item.currency}</option>
                  )) :
                  <>
                  
                  <option value='NGN'>NGN</option> 
                  <option value='USD'>USD</option> 
                  <option value='CAD'>CAD</option> 
                  <option value='GBP'>GBP</option> 
                  </>
                }
              </select>

            </div>
            : paymentCurrency && selectedButton === "Collections"?
              <div className='all-balancey-currency'>
               
              <img src={`/images/${selectedCurrency === undefined ? "NGN" : selectedCurrency }.svg`} alt='icon' />
              <select value={selectedCurrency} onChange={handleSelectcurrency} className='curreny-option'>
                {
                  balanceData?.length > 0 ? 
                  balanceData.map((item)=>(
                    <option value={item.currency}>{item.currency}</option>
                  )) :
                  <>
                  
                  <option value='NGN'>NGN</option> 
                  <option value='USD'>USD</option> 
                  <option value='CAD'>CAD</option> 
                  <option value='GBP'>GBP</option> 
                  <option value='EUR'>EUR</option> 
                  </>
                }
              </select>
            </div>:  ""
            }

            {
              dispute  && 
              <div className='add-currency'>
                <p className='currency' onClick={setIsAddDispute.bind(this,true)} sty>Add Dispute +</p>
              </div>
            }
        </div>
        :
         <div style={{cursor:"pointer"}} className="customer-back" onClick={handleBack}>
          <img src={process.env.PUBLIC_URL+"/images/arrowBack.svg"} alt="arrow back"/>
          <h1 className='customer-arrowBack'>Go Back</h1>
         </div>
      }


      {!btn &&
        <>
        {
        !data ?

          <div className='name-control-btn'>
              <button 
              onClick={() => handleClick(btnFirst)}
              className={`btn-control ${activeButton === btnFirst ? 'active-btn' : ''}`}
              >{btnFirst}</button>
              <button 
              onClick={() => handleClick(btnSecond)}
              className={`btn-control ${activeButton === btnSecond ? 'active-btn' : ''}`}
              >{btnSecond}</button>
          </div> :
          profile?
          <div className='data-nav-btn'>
            <span  className='dataItembtn active'>Account</span>
            <span className='dataItembtn '>Identity</span>
            <span className='dataItembtn '>Transactions</span>
            <span className='dataItembtn '>Statement</span>
          </div>
          :
          !trans ?
          <div onClick={handleVerifyIdentity} className='btn-name-control-container'>
            <span style={{color:"#FFF"}}>{btntext}</span>
            <img src={process.env.PUBLIC_URL+"/images/dataBtnArrowUp.svg"} alt="arrow" />
          </div> :
          <div onClick={handleNavigate} className='btn-name-control-container'>
            <span style={{color:"#FFF"}}>{btntext}</span>
            <img src={process.env.PUBLIC_URL+"/images/dataBtnArrowUp.svg"} alt="arrow" />
          </div>
        }
        </>
      }

    </div>
  )
}

export default NameControl
