import React from "react";
import '../../modals/Modal.scss'

function MemberDeleted({setIsMemberDeleted}) {

    // const handleClick = ()=>{
    // }

  return (
    <div className="overlay">
    <div className="update-modal" style={{background:"#ED1C24"}}>
      <div className="update-modal-wrapper">
            <div className="role-update">
                <div className="member-rolee">
                    <p className="member-update">Member has been removed</p>
                    <p className="update-msg">Your role as been removed</p>
                </div>
                <div>
                    <img style={{cursor:"pointer"}} onClick={() => setIsMemberDeleted(false)} src={process.env.PUBLIC_URL + "/images/x-closee.svg"} alt="icon" />
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}

export default MemberDeleted;
