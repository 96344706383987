import React, { useEffect, useState } from "react";
import SettingsSideMenu from "./SettingsSideMenu";
import SettingsSideData from "./SettingsSideData.json";
import "./Settings.scss";
import BusinessPreference from "./BusinessPreference";
import SettlementAccounts from "./settlementAccounts/SettlementAccounts";
import Whitelisted from "./whitelisted/Whitelisted";
import Developers from "./Developers";
import ProductPricing from "./ProductPricing";
import TeamPermissions from "./teamPermissions/TeamPermissions";
import SendInvite from "./teamPermissions/SendInvite";

function Settings() {
  const allSettingsSideData = SettingsSideData;
  const [sendInvite,setSendInvite] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("Business preference");
  useEffect(() => {
    if (sendInvite) {
      window.history.pushState({ sendInvite: true }, "");
    } else {
      window.history.pushState({}, "");
    }

    const handlePopState = (event) => {
      if (event.state && event.state.sendInvite) {
        setSendInvite(true);
      } else {
        setSendInvite(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [sendInvite]);

  const renderSelectedComponent = () => {
    if (sendInvite) return null;
    switch (selectedMenuItem) {
      case "Business preference":
        return <BusinessPreference />;
      case "Product & Pricing":
        return <ProductPricing />;
      case "Settlement accounts":
        return <SettlementAccounts />;
      case "Team & Permissions":
        return <TeamPermissions setSendInvite={setSendInvite}/>;
      case "Whitelisted IP addresses":
        return <Whitelisted />;
      case "Developers":
        return <Developers />;
      default:
        return <BusinessPreference />;
    }
  };

  return (
    <div className="settings">
      <div className="settings-inner">
        <div className="side-header">
          <div className="settings-side">
            {allSettingsSideData.map((menu, index) => (
              <SettingsSideMenu menu={menu} key={index} setSelectedMenuItem={setSelectedMenuItem} selectedMenuItem={selectedMenuItem}/>
            ))}
          </div>
          {renderSelectedComponent()}
          {
            sendInvite &&
            <SendInvite setSendInvite={setSendInvite}/>
          }
        </div>
      </div>
    </div>
  );
}

export default Settings;
