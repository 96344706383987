import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './emailVerify.scss'
import { DotLoader } from 'react-spinners';
import { toast } from 'react-toastify';

function EmailVerification() {
   const [isLoading,setIsLoading] = useState(false);
   const [success,setSuccess] = useState(false)

    const navigate = useNavigate();

    const redirect = async ()=>{

         setIsLoading(true)

        const fullUrl = window.location.href;
        const urlParts = fullUrl.split('/');
        const token = urlParts[urlParts.length - 1];
        const url =  `https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/verify-email/${token}`
        
        try{
            const res = await axios.get(url)
            setIsLoading(false)
            setSuccess(true)
            // if(res.data.status==="success"){
            //     navigate('/');
            // }
        }catch(err){
            console.log(err)
            toast.success("verification failed: please try again..")
        }

    }

    
    
    useEffect(() => { 
        redirect() 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const handleRedirect = ()=>{
      navigate('/')
    }
  return (
    <div className="verify_page">
      <div className='overlay_verify'></div>
      <div className='content_email'>
        {
          isLoading &&
          <DotLoader color="#259792" /> 
        }
        {
          success &&
          <div className='verify_content'>

            <h1 className='verify_success'>Verified Successfully</h1>
            <button onClick={handleRedirect} className='btn' style={{width:"fit-content"}}>
              Proceed to Login
            </button>
          </div>

        }
      </div>
    </div>
  )
}

export default EmailVerification
