import React from 'react'
import Status from '../common/Status'

function NinReciept({identity}) {
  return (
    <div className='identityVerify'>
        {console.log('identity: ',identity)}
          <div className='verify-top'>
              <h3>{identity?.firstname + " " + identity?.lastname}</h3>
              <div style={{display:'flex',gap:"5px"}}>
                <p>Verification Status:</p>
                <Status 
                icon="/images/success.svg"
                mgs="Successful"
                />
              </div>
          </div>

          <div className='verify-img'>
              <img src={identity?.photo} alt="" />
          </div>
          <div className='verify-content'>
              <table className='tbl'>
                <tr>
                  <td className='verify-text-sm'>NIN</td>
                  <td className='verify-text-sm left-mg'>Full name</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.nin || "Not Available"}</td>
                  <td className='verify-text-md' style={{textAlign:'end'}}>{`${identity?.firstname ? identity?.firstname :''} ${identity?.middlename ? identity?.middlename:''} ${identity?.lastname ? identity?.lastname:''}`}</td>
                  
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Customer</td>
                  <td className='verify-text-sm left-mg mg-top'>Gender</td>
                </tr>
                <tr>
                    <td className='verify-text-md left-mg' style={{textAlign:'start'}}>{identity?.customer || "Not Available"}</td>
                    <td className='verify-text-md left-mg'>{identity?.gender || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Date of birth</td>
                  <td className='verify-text-sm left-mg mg-top'>Email</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.date_of_birth || "Not Available" }</td>
                  <td className='verify-text-md left-mg'>{identity?.email || "Not Available" }</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Residential address</td>
                  <td className='verify-text-sm left-mg mg-top'>Marital status</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.address || identity?.address || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.marital_status || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Phone number</td>
                  <td className='verify-text-sm left-mg mg-top'>Origin location</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.phone_number || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.lga_of_origin || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Registered on</td>
                  <td className='verify-text-sm left-mg mg-top'>Name on card</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.enrollment?.registration_date || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.fullname || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Nationality</td>
                  <td className='verify-text-sm left-mg mg-top'>Watchlisted</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.nationality || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.watchlisted || "Not Available"}</td>
                </tr>
              </table>
          </div>
    </div>
  )
}

export default NinReciept