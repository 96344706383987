import React, { useContext, useEffect, useState } from 'react'
import NameControl from '../components/common/NameControl'
import TableHeaderSearch from '../components/common/TableHeaderSearch'
import { useNavigate } from 'react-router-dom'
import { format } from 'timeago.js';
import TableLoader from '../components/common/TableLoader';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { formatCustomersData } from '../components/utilities/formateData';
import DataContext from '../components/context/DataContext';

function DataCustomers() {
  const {currentUser} = useSelector((state)=>state?.user)
  const [allCustomerData,setAllCustomerData] = useState([])
  const navigate = useNavigate();
  const handleRowClick = (account)=>{
    navigate('/data-customer',{state:{account}})
  }
  const {setCustomerData,customerData} = useContext(DataContext)
  
  const [isAllCustomerLoading,setIsAllCustomerLoading] = useState(false)
  useEffect(()=>{
    getAllCustomers();
    // eslint-disable-next-line 
  },[])
  
  const getAllCustomers = async ()=>{
    setIsAllCustomerLoading(true)
    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        "sweetapi": currentUser.user.merchantCode  
      }
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-merchant-customers';
      let res = await axios.get(url,{
        headers:headers
      })
        
      const data = res?.data?.data
      
      console.log("customer data: ",res?.data)
      const result = await formatCustomersData(data);
      console.log("customer new formatted: ",result)
      // setAllCustomerData(result);
      setCustomerData(result)
      setIsAllCustomerLoading(false)
      // setBalanceData(newValue || []);
       
    }catch(err){
      setIsAllCustomerLoading(false)
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
     
    }
  }
  const handleDelete = (e)=>{
    e.stopPropagation();
    
  }
  return (
   
    <div className='main-inner'>
      
        <NameControl 
        name={currentUser?.user?.business_name}
        desc="Manage all your settlements here"
        btn
        />
        <div className='balance-overview collection_load'>
        {
            isAllCustomerLoading &&
            <TableLoader />
        }
          <table className='tbl'>
            <tbody>

              <tr>
                  <td colSpan="7" className='tbl-heading'>
                      <TableHeaderSearch 
                      title="Customers"
                      placeholder="Search by name or date..."
                      numUsers={customerData?.length}
                      users={"users"}
                      btnFirst
                      />     
                  </td>
              </tr>

              {
                Array.isArray(customerData) && customerData.length > 0 &&
                  <tr>
                      <th className='th-text'>Name</th>
                      <th className='th-text'>Account</th>
                      <th className='th-text'>Est. Balance</th>
                      <th className='th-text'>Currency</th>
                      <th className='th-text'>Date Added</th>
                    
                  </tr>
              }

              {
                Array.isArray(customerData) && customerData.length > 0 && customerData.map((account,index)=>(

                  <tr  key={account?.bvn} onClick={() => handleRowClick(account)}>
                    <td>
                        <div className='customer-name'>
                            <div className='avater_template'>
                            {/* <span>{`${((String(account?.firstName)).slice(0,1)).toUpperCase()}${((String(account?.lastName)).slice(0,1)).toUpperCase()}`}</span> */}
                            <span>{account?.fullName ?(`${String(String(account?.fullName).split(" ")[0]).slice(0,1)} ${String(String(account?.fullName).split(" ")[1]).slice(0,1)}`):"N/A"}</span>
                            </div> 
                          
                            <h2 className='customer-name-text'>{account?.fullName ? (String((account?.fullName)).toUpperCase()):"N/A"}</h2>
                        </div>
                    </td> 
                    
                    <td>
                      {account?.accountCounts || "N/A"}
                    </td>
                    
                    <td className='td-text'>{`${(Object.keys(account?.currencyBalances)[0])} ${account.currencyBalances[(Object.keys(account.currencyBalances)[0])]}`}</td>
                    <td>
                      <div className='flag'>
                        <img src={process.env.PUBLIC_URL+`/images/${(Object.keys(account?.currencyBalances)[0])}.svg`} alt="flag" />
                        <h1 className='ng'>{(Object.keys(account?.currencyBalances)[0])}</h1>
                      </div>
                    </td>
                    <td className='td-text'>
                      <div style={{display:"flex",justifyContent:"space-between",alignContent:"center"}}>
                        <p className='td-text'>{format(account.dated)}</p>
                        <img  src={process.env.PUBLIC_URL+"/images/eye.svg"} alt="verified" />
                      </div>
                      
                    </td>
                    <td>
                    </td>
                  </tr>
                ))
              }


              {
               !isAllCustomerLoading && customerData.length <= 0 &&
                <tr>
                  <td  colSpan={7} style={{width:"100%"}}>
                  <div className='empty_tbl'>
                      <img src='/images/emptyPage.svg' alt='emptuy' />
                      <h3 className='num_trans'>No Customer Yet</h3>
                      <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                  </div>
                  </td>
                </tr>
              }

            </tbody>
          </table>
        </div>
    </div>
      
  )
}

export default DataCustomers
