import React, {useRef, useState } from 'react'
import '../../modals/Modal.scss'
import "../Settings.scss";
import { copyToClipboard } from '../../utilities/clipboardUtils';



function ReviewApiKeyModal({setIsReviewApi,apiResult,setIsOtp}) {
  const livePublicRef = useRef(null);
  const liveSecretRef = useRef(null);
  const liveEncrytRef = useRef(null);
  const testPublicRef = useRef(null);
  const testSecretRef = useRef(null);
  const testEncrytRef = useRef(null);

  const handleCancle = ()=>{
    setIsReviewApi(false);
    setIsOtp(false);
  }
  return (
    <div className="overlay"> 
    <div className="enter-amount-modal">
      <div className="modal-wrapper">
        <div>
          <img
              src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"}
              alt="icon"
              style={{cursor:"pointer"}}
              onClick={setIsReviewApi.bind(this,false)}
            />
        </div>

        <div className="modal-title">
          <p className="enter-amount">Below are your Api keys</p>
        </div>

        {/* <div className='pin_container'>
           <input type='password' className='input_otps' onChange={handleChange} placeholder='xxxxxxxx'/>
        </div> */}
            {/* live keys */}
        <div className='live-api' style={{width:"100%"}}>
              <h3 className='api-key-text'>Live API keys</h3>
              <div className='api-key-forms' >
                  <form className="api-key-form">

                        <div className="api-form-groups">
                            <label className="lbl">Public key</label>
                            <div className="input-group">
                            <input
                              ref={livePublicRef}
                              type="text"
                              name="livePublicKey"
                              placeholder="***********"
                              className="input-form-control"
                              value={apiResult?.livePublicKey}
                              
                              readOnly
                            />
                          
                            <img
                              src={process.env.PUBLIC_URL + "/images/copy-key-icon.svg"}
                              alt="ic"
                              className="copy-key-icon"
                              onClick={copyToClipboard.bind(null,livePublicRef)}
                            />
                            </div>
                        </div>



                        <div className="api-form-groups">
                          <label className="lbl">Secret key</label>
                          <div className='input-group'>

                          <input
                          ref={liveSecretRef}
                          type="text"
                          name="liveSecretKey"
                          placeholder="*********"
                          className="input-form-control"
                          value={apiResult?.liveSecretKey}
                          readOnly
                          />
                          <img
                          src={process.env.PUBLIC_URL + "/images/copy-key-icon.svg"}
                          alt="ic"
                          className="copy-key-icon"
                          onClick={copyToClipboard.bind(null,liveSecretRef)}

                          />
                          </div>
                        </div>


                        <div className="api-form-groups">
                          <label className="lbl">Encryption key</label>
                          <div className='input-group'>
                            <input
                              ref={liveEncrytRef}
                              type="text"
                              name="livePublicEncKey"
                              placeholder="**********"
                              className="input-form-control"
                              value={apiResult?.livePublicEncKey}
      
                              readOnly
                            />
                            <img
                            src={process.env.PUBLIC_URL + "/images/copy-key-icon.svg"}
                            alt="ic"
                            className="copy-key-icon"
                            onClick={copyToClipboard.bind(null,liveEncrytRef)}

                            />
                          </div>
                        </div>
                  </form>

                  

              </div>
        </div>

          {/* test keys */}
        


        <div className="modal-btn">
          <button  onClick={handleCancle} className="btn">
            Done
          </button>
         
        </div>
      </div>
    </div>
  </div>
  )
}

export default ReviewApiKeyModal