import React from 'react'

function PayoutBenSumbited({setIsPayoutBenSubmited,setIsBenAdd,setIsBankDetail,setIsPayout}) {
    const handleDone = ()=>{
        setIsPayoutBenSubmited(false);
        setIsBankDetail(false);
        setIsBenAdd(false)
        // setIsPayout(false)
      }
      return (
        <div className='balance_modal'  onClick={setIsPayoutBenSubmited.bind(this, false)} >
           
            <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
            <div className='balance_modal_wrapper'>
                <img 
                onClick={setIsPayoutBenSubmited.bind(this, false)}
                className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
                />
                <div className='otc_payout_logo'>
                    <img src='/images/otc/flickRound.svg' alt='' />
                    <p className='payout_logo_title'>Beneficiary Added</p>
                    <p className='payout_logo_desc'>Beneficiary has been added successfully; you can now proceed to payout</p>
                    <button className='btn' style={{width:"210px",marginTop:"24px"}} onClick={handleDone}>Done</button>
                </div>
    
            </div>
            </div>
        </div>
      )
}

export default PayoutBenSumbited