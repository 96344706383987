import React from 'react'
import { BeatLoader } from 'react-spinners';

function WebhookModal({setWebhookInput,webhookInput,setIsWebhook,addWebhook,isLoading}) {
    const handleChange = (e)=>{
        const { name, value } = e.target;
        setWebhookInput({
            ...webhookInput,
            [name]: value,
        });
      }
    
      const handleSubmit = (e)=>{
          e.preventDefault();
          addWebhook()
        //   addApi();
      }
  return (
    <div className="overlay">
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="settlement-modal"
      >
        <div className="settlement-modal-inner">
          <div className="close-icon">
            <img
              onClick={setIsWebhook.bind(this,false)}
              src={process.env.PUBLIC_URL + "/images/modal-close-icon.svg"}
              alt=""
            />
          </div>
          <div className="add-account">
            <h2 className="add-account-text">Add Webhook</h2>
            <h5 className="authenticate">
              Manually add a webhook.
            </h5>
          </div>
          <form onSubmit={handleSubmit} className="settlement-form">
            <div className="settlement-form-wrapper">
              <div className="settlement-form-groups">
                <label className="lbl">Url</label>
                <input
                  type="text"
                  name="webhookArray"
                  placeholder="https://www.flick.com"
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
              <div className="settlement-form-groups">
                <label className="lbl">Secret Hash</label>
                <input
                  type="text"
                  name="secretHash"
                  placeholder=""
                  className="form-control"
                  onChange={handleChange}
                />
              </div>
            </div>
            
            <button style={{width:"fit-content"}} className="btn">
              {
                isLoading? (
                  <BeatLoader
                  color="#FFF"
                  cssOverride={{}}
                />
                  
                ) : (
                  <> Add Webhook </>
                )
              }
            </button>

            
          </form>
        </div>
      </div>
    </div>
  )
}

export default WebhookModal