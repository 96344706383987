import React, { useState } from 'react'
import { toast } from 'react-toastify';

function FundNGNBalanceModal({nubanData,setIsVertualNuban}) {
    const [copiedText, setCopiedText] = useState('');

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopiedText(text);
        toast.success('Copied successfully');
    };
  return (
    <div className="overlay" >
    <div className="enter-amount-modal">
      <div className="modal-wrapper scrollNuban">

        <div> 
        <img style={{cursor:"pointer"}} onClick={setIsVertualNuban.bind(this,false)} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
            <p className="enter-amount">Fund NGN Balance</p>
            <p className="enter-an-amount">Bank transfer using the provided information below.</p>
        </div>

        <div className="scrollNuban">
        {
         (nubanData?.length > 0) && nubanData.map((item,index)=>(

            <div className="account-details ">
                <div className="number-name">
                    <div className="number">
                        <p className="title" >Account number</p>
                        <p className="sub-title">{item?.account_number} <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} onClick={() => handleCopyToClipboard(item?.account_number)} alt="icon" /></p>
                    </div>

                    <div className="name">
                        <p className="title" style={{textAlign:"end"}}>Account name</p>
                        <p className="sub-title">{item?.account_name} <img onClick={() => handleCopyToClipboard(item?.account_name)} src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p>
                    </div>
                </div>

                <div className="number-name">
                    <div className="number">
                        <p className="title">Bank name</p>
                        <p className="sub-title">{item?.bank_name} <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} onClick={() => handleCopyToClipboard(item?.bank_name)} alt="icon" /></p>
                    </div>

                    <div className="name">
                        <p className="title">Account type</p>
                        <p className="sub-title">Current </p>
                    </div>
                </div>

                

            </div>
          ))
        }
        </div>

        
        {
          (nubanData?.length < 0) &&
          <div className="account-details">
             <p className="title">No Available Nuban</p>
          </div>
        }
        
        <div className="modal-btn">
          <button onClick={setIsVertualNuban.bind(this,false)} className="first-btn" style={{width:"50%",height:"52px"}}>
           Cancel
          </button>
          
          <button className='btn' style={{width:"50%",height:"52px"}}>
            share 
          </button>
        </div>
      </div>
    </div>
    </div>
  )
}

export default FundNGNBalanceModal