import React,{useState} from 'react'
import NameControl from '../components/common/NameControl'
import '../components/payment/payment.scss'
import { useSelector } from "react-redux";
import DisputCollections from '../components/disputes/DisputCollections';
import Refund from '../components/disputes/Refund';
import AddDisputeModal from '../components/disputes/AddDisputeModal';
import CollectionDisputeModal from '../components/disputes/CollectionDisputeModal';
import PayoutDisputeModal from '../components/disputes/PayoutDisputeModal';


function DisputePage({toggleTransfer, disputesData}) {

    const [btnActive,setBtnActive] = useState(false);
    const [selectedButton, setSelectedButton] = useState("Collections");
    const {currentUser} = useSelector((state)=>state?.user);
    const [isAddDispute,setIsAddDispute] = useState(false);
    const [isCollectionModal,setIsCollectionModal] = useState(false)
    const [isPayoutModal,setIsPayoutModal] = useState(false)
    const [refundData,setRefundData] = useState([]);

    const [disputeInput,setDisputeInput] = useState({
      transactionId:"",
      message:"",
      doc:""
    })

    

    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };


    
    const renderContent = () => {
    if(selectedButton === 'Collections') return  <DisputCollections toggleTransfer={toggleTransfer} disputesData={disputesData}/>
    
    if(selectedButton ==='Payout') return <Refund toggleTransfer={toggleTransfer} refundData={refundData}/>
   
    };

    const getDesc = () => {
        if (selectedButton === 'Collections') {
          return "All disputes are shown here";
        }
        if (selectedButton === 'Payout') {
          return "All refunds are shown here";
        }
      };

  return (
    
                <div className='main-inner'>
                  
                    <NameControl 
                    name="Dispute"
                    desc="Manage all your settlements here"
                    btnFirst="Collections"
                    btnSecond="Payout"
                    btnActive={btnActive}
                    dispute
                    setIsAddDispute={setIsAddDispute}
                    handleButtonClick ={handleButtonClick}
                    />    
                    
                    {renderContent()}
                    {
                      isAddDispute && <AddDisputeModal 
                      setIsAddDispute={setIsAddDispute} 
                      setDisputeInput={setDisputeInput} 
                      disputeInput={disputeInput}
                      setIsCollectionModal={setIsCollectionModal}
                      selectedButton ={selectedButton}
                      setIsPayoutModal={setIsPayoutModal}
                      />
                    }
                    {
                      isCollectionModal &&
                      <CollectionDisputeModal
                        setIsCollectionModal={setIsCollectionModal}
                      />
                    }
                    {
                      isPayoutModal &&
                      <PayoutDisputeModal setIsPayoutModal={setIsPayoutModal}/>
                    }
                </div>

  )
}

export default DisputePage
