import React from 'react'
import CustomerOverView from '../../common/CustomerOverView'
import TableHeaderSearch from '../../common/TableHeaderSearch'
import { getIconByName } from '../../utilities/getBankIcon'
import { formatDate } from '../../utilities/formatDate'

function CustomerTransaction({transactions,account}) {
  return (
    <div className='sdata_customer'>
     
      <CustomerOverView customer  account={account}/>
      <div className='balance-overview'>
          <table className='tbl'>
          <tr>
              <td colSpan="7" className='tbl-heading'>
                  <TableHeaderSearch
                  title="All transactions"
                  placeholder="Search by bank..."
                  numUsers={transactions?.length}
                  users={"connected"}
                  btnFirst
                  sync
                  stats
                  />     
              </td>
          </tr>
          {
              Array.isArray(transactions) && transactions.length > 0  &&
            <tr>
            
                <th className='th-text'>Account</th>
                <th className='th-text'>Customer</th>
                <th className='th-text'>Count</th>
                <th className='th-text'>Date Added</th>
                <th className='th-text'>Last Synced</th>
                <th className='th-text'>Payments</th>
            </tr>
          }
          {
            Array.isArray(transactions) && transactions.length > 0 && transactions.map((item,index)=>(
              <tr>
                <td>
                    <div className='customer-name'>
                        <img className='customer-name-img' src={getIconByName(item?.bank)} alt="customer" />
                        <h2 className='customer-name-text'>{` ${item?.bankName} |  ${item.Nuban}` || "N/A"}</h2>
                    </div>
                </td> 
                <td className='td-text'>{item?.customer || "N/A"}</td>
                <td className='td-text'>{item?.transactionCount || "N/A"}</td>
                <td className='td-text'>{formatDate(item?.dated) || "N/A"}</td>
                
                <td className='td-text'>{item?.last_synced ? formatDate(item?.last_synced): "N/A"}</td>
                <td className='td-text'>
                    <div style={{display:"flex",justifyContent:"space-between",alignContent:"center"}}>
                      <p className='td-text'>{item?.payment || "N/A"}</p>
                      <img  style={{cursor:"pointer"}}  src={process.env.PUBLIC_URL+"/images/SyncBadge.svg"} alt="verified" />  
                    </div>
                </td>
                
                
              </tr>

            ))
          }

          {
            transactions.length <= 0 &&
            <tr>
             <td  colSpan={7} style={{width:"100%"}}>
                <div className='empty_tbl'>
                    <img src='/images/emptyPage.svg' alt='emptuy' />
                    <h3 className='num_trans'>No Transactions Yet</h3>
                    <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                </div>
             </td>
            </tr>
           }
          </table>
        </div>
    </div>
  )
}

export default CustomerTransaction
