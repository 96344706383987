import React, { useState } from "react";
import "./Modal.scss";
import axios from "axios";
import { useSelector } from 'react-redux';
import { encryptWithPublicKey } from "../utilities/encrypt";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

function CardDetails({setIsCardDetails,setIsCardMethod,transactionId,livePublicEncKey,setIsOtp,setIsPinOtp,inputCardDetails,setInputCardDetails,setIsCardLoading,apiWallet,setIsAmountNormal,setIsPayment,setIsBalanceOpen,setIsOtherBalance}) {
  const { currentUser } = useSelector((state) => state?.user);
  // const [formData,setFormData] = useState({
  //   name:"",
  //   card_number:"",
  //   card_date:"",
  //   card_cvv:""
  // });
  const [isChecked, setChecked] = useState(false);

  const [expiry, setExpiry] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  const handleCancel = ()=>{
    setIsCardDetails(false);
    setIsOtherBalance(false);
      setIsAmountNormal(false);
      setIsPayment(false)
        if(setIsBalanceOpen){
            setIsBalanceOpen(false)
     }
  }
  const handleCheckboxChange = () => {
    setChecked(!isChecked); // Toggle the checkbox state
  };

  const  handleChange = (event)=>{
    event.preventDefault();
      const { name, value } = event.target;
      setInputCardDetails((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));

   }

  const handleInputChange = (e) => {
    let value = e.target.value;
    // Remove any non-digit characters
    value = value.replace(/\D/g, '');
    // Format the value with a slash
    if (value.length > 2) {
      value = value.slice(0, 2) + '/' + value.slice(2);
    }
    setExpiry(value);
  };

  const handleSubmite = async (e)=>{
    e.preventDefault();
  
    setIsLoading(true);
    setIsCardLoading(true)
    console.log("card payload: ",{...inputCardDetails,transactionId,expiry})
    const {name,card_number,card_cvv,freqTransCard} = inputCardDetails;
    const data = `${card_number}|${card_cvv}|${expiry}|${name}`
    const encrypted = encryptWithPublicKey(data,livePublicEncKey);
    let cardData;
    try{

      const addHeaders = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };
      const headers = {  
        Authorization:`Bearer ${currentUser?.user?.merchantKey}`
      }
      const addCard = {
        cardName:name,
        freqTransCard:isChecked,
        cardDetails:encrypted
      }
      if(apiWallet==="api"){
          cardData = {
          cardDetails: encrypted,
          transactionId: transactionId,
          dest:"apiWallet"
        }
      }else{
        cardData = {
          cardDetails: encrypted,
          transactionId: transactionId,
        }
      }

      console.log("card Payload",cardData)
      
      if(isChecked){
        const addUrl ="https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/add-payment-card"
        let authres = await axios.post(addUrl,{...addCard},{
              headers:addHeaders
        });
      }
  
      const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/charge"
      
      let res = await axios.post(url,{...cardData},{
        headers:headers
      }) ;
      
      console.log("card response",res?.data)
      const {authorizationMode} = res.data
      setIsCardLoading(false)
      setIsLoading(false);

      if(authorizationMode==="pin"){
        setIsOtp(true)
        setIsCardLoading(false)
        
        //  console.log("condition pin: ",res.data)
      }
      else if(authorizationMode === "otp"){
        setIsPinOtp(true);
        setIsCardLoading(false);
        // console.log("otp",res.data)
      }
      else if((authorizationMode).toLowerCase() === "3ds"){
        if((res.data.authMessage).toUpperCase() === "3DS URL" && res.data?.responseUrl){  
          setIsLoading(false);
          setIsCardLoading(false)
          const url = res.data.responseUrl;
          window.location.href = url;
          // console.log(res?.data);
          
        }else if((res.data.authMessage).toLowerCase()==="3ds" && !res.data.responseUr){
          if(authorizationMode === "otp"){
            setIsPinOtp(false)
            setIsCardLoading(false)
          }
          setIsLoading(false);
          setIsCardLoading(false)
          toast.error("unable to complete payment");
        }else{
          setIsLoading(false);
          setIsCardLoading(false);
          toast.error("unable to complete payement");
        } 
      }
      else{
        setIsCardLoading(false)
        console.log("condition not met ")
      }
    }catch(err){
      setIsLoading(false);
      setIsCardLoading(false)
      console.log(err)
      const errorMessage = err?.response?.data?.message || "Server error"
      toast.error(errorMessage)
    }
  }
  return (
    <div className="overlay" >
      <div className="enter-amount-modal">
        <div className="modal-wrapper">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"}
              alt="icon"
              style={{cursor:"pointer"}}
              onClick={setIsCardDetails.bind(this,false)}
            />
          </div> 

          <div className="modal-title">
            <p className="enter-amount">Input Card Details</p>

            <p className="enter-an-amount">
              Your card will be automatically be debited.
            </p>
          </div>

          <form onSubmit={handleSubmite} className="modal-form">
            <div className="modal-form-left">
              <div className="modal-form-groups">
                <label className="form-lbl">Name on card</label>
                <input
                  type="text"
                  name="name"  
                  onChange={handleChange}
                  placeholder="Mide Ajibade"
                  className="modal-form-control"
                />
              </div>
              <div className="modal-form-groups">
                <label className="form-lbl">Card number</label>
                <span className="modal-form-control">
                  <img
                    src={process.env.PUBLIC_URL + "/images/modal-card-icon.svg"}
                    alt="icon"
                  />
                  <input
                    type="text"
                    name="card_number" 
                    onChange={handleChange} 
                    placeholder="1234 1234 1234 1234"
                    className="modal-input"
                  />
                </span>
              </div>
            </div>

            <div className="modal-form-right">
              <div className="modal-form-groups">
                <label className="form-lbl">Expiry</label>
                <input
                  type="text"
                  placeholder="MM/YYYY"
                  className="modal-form-control"
                  id="expiry"
                  name="card_date" 
                  value={expiry}
                  maxLength="5"   
                  onChange={handleInputChange} 
                />
              </div>
              <div className="modal-form-groups">
                <label className="form-lbl">CVV</label>
                <input
                  type="password"
                  placeholder="***"
                  className="modal-form-control"
                  maxLength="3"
                  name='card_cvv' 
                  onChange={handleChange} 
                />
              </div>
            </div>
          </form>
          <div className="save-card" style={{alignItems:"center"}}>
            <input
              type="checkbox"
              className="modal-checkbox"
              id="myCheckbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="card" className="save-card-text">
              Save card details for frequent transaction
            </label>
          </div>

          <div className="modal-btn">
            <button onClick={handleCancel} className="first-btn">
              Cancel
            </button>
            {
               isLoading ? 
               <button   className="btn">  
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />
               </button> :
                <button onClick={handleSubmite}  className="btn">  
                  Confirm
                </button>

            }
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardDetails;
