import React, { createRef } from 'react'
import TableHeaderSearch from '../common/TableHeaderSearch'
import '../common/tables.scss'

function Refund({toggleTransfer,refundData}) {
  
    
   const lineRefs = React.useRef([]);
   lineRefs.current = refundData.map((_, i) => lineRefs.current[i] ?? createRef());

  return (
  <div className='balance-overview tbl_overflow'>
      
    <table className='tbl'>
     
        <tr>
          <td colSpan="7" className='tbl-heading'>
            <TableHeaderSearch
              title="Collection Disputes"
              placeholder="Search..."
              numUsers={refundData?.length}
              users="users"
              dispute
              toggleTransfer={toggleTransfer}
              btnFirst
            />
          </td>
        </tr>

        <tr>
            
            <th className='th-text'>Date completed</th>
            <th className='th-text'>Transaction ID</th>
            <th className='th-text'>Channel</th>
            <th className='th-text'>Email</th>
            <th className='th-text'>Message</th>
            <th className='th-text'>Amount</th>
            <th className='th-text'>Status</th>
        </tr>
    
         {
             Array.isArray(refundData) && refundData.length > 0 && refundData.map((item,index)=>(
                 <tr>
                     <td className='td-text'>2 hours ago</td>
                     <td className='td-text'>dvdsv425523f3...</td>
                     <td className='td-text'>Transfer</td>
                     <td className='td-text'>mide@getflick.co</td>
                     <td className='td-text'>Please refund...</td>
                     <td className='td-text'>NGN 40,400.00</td>
                     <td className='td-text'> 
                          <div className='customer-email'>
                              <p className='td-text'> Resolved</p>
                              <img  src={process.env.PUBLIC_URL+"/images/eye.svg"} alt="eye" />
                          </div>
                    </td>

                 </tr>
             ))
         }

      {refundData.length <= 0 && (
        <tr>
          <td colSpan={7} style={{ width: "100%" }}>
            <div className='empty_tbl'>
              <img src='/images/emptyPage.svg' alt='empty' />
              <h3 className='num_trans'>No Recent Payout Disputes!</h3>
              <p className='num_desc'>You have no outstanding disputes</p>
            </div>
          </td>
        </tr>
      )}

      <tr className='export-data' >
        <td className='td-export' colSpan="7">
          <div className='tbl-export'>
            <h6 className={`tbl-export-text ${refundData.length <= 0 ? 'faded' : ''}`}>Export Data</h6>
            <img className={`export-img ${refundData.length <= 0 ? 'faded' : ''}`} src={process.env.PUBLIC_URL + "/images/export.svg"} alt='export' />
          </div>
        </td>
      </tr>
    </table>
  </div>
  )
}

export default Refund
