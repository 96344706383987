import React from 'react'
import Active from '../common/Active'
import '../common/tables.scss'
import TableHeaderSearch from '../common/TableHeaderSearch'
import { useNavigate } from 'react-router-dom'
import { format } from 'timeago.js'
import TableLoader from '../common/TableLoader'
import useFilterAndPagination from '../hooks/useFilterAndPagination'
import CustomPagination from '../common/CustomPagination'
import DataTable from 'react-data-table-component'
function Identities({users,btnFirst,numUsers,identityResponse,isLoading,identityResult,isIdentityLoading}) {
  const navigate = useNavigate();

  const rowsPerPage = 10;

  const {
    currentPage,
    searchTerm,
    setSearchTerm,
    dateRange,
    handleDateChange,
    handlePageChange,
    paginatedRecords,
    filteredRecords,
    setIsSearchTermChanged
  } = useFilterAndPagination(identityResult, rowsPerPage);

  const columns = [
    {
      name: 'Name',
      selector: row => row.fullname || 'N/A',
      // cell: row => (
      //   <div className='customer-name'>
      //     <h2 className='customer-name-text'>{row.fullname || 'N/A'}</h2>
      //   </div>
      // ),
      sortable: true,
    },
    {
      name: 'ID Type',
      selector: row => row.idType || 'N/A',
      sortable: true,
    },
    {
      name: 'ID Number',
      selector: row => row.IdNumber || 'N/A',
      sortable: true,
    },
    {
      name: 'Date Checked',
      selector: row => format(new Date(row.updatedAt), 'yyyy-MM-dd'),
      sortable: true,
    },
    {
      name: 'Channel',
      selector: row => row.channel || '',
      sortable: true,
    },
    {
      name: 'Status',
      cell: row => (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Active title={row.status || 'N/A'} />
          {/* <img src={process.env.PUBLIC_URL + "/images/eye.svg"} alt="verified" /> */}
        </div>
      ),
      sortable: true,
    },
  ];

  const handleIdentity = (item)=>{
    navigate('/data-identity-detial',{state:{item}})
    
  }
  return (
    <div className='balance-overview collection_load'>
        {
            isIdentityLoading &&
            <TableLoader />
        }

{!isIdentityLoading && (
        <>
          {Array.isArray(identityResult) && identityResult.length > 0 ? (
            <>
            <DataTable
              columns={columns}
              data={paginatedRecords}
              highlightOnHover
              onRowClicked={handleIdentity}
              
              paginationPerPage={rowsPerPage}
              paginationDefaultPage={currentPage}
              paginationTotalRows={filteredRecords.length}
              subHeader
              subHeaderComponent={
                <TableHeaderSearch
                  title="Customers"
                  placeholder="Search by name or ID Type..."
                  users={users}
                  btnFirst={btnFirst}
                  numUsers={filteredRecords.length || 0}
                  searchTerm={searchTerm}
                  setSearchTerm={(term) => {
                    setSearchTerm(term);
                    setIsSearchTermChanged(true);
                  }}
                  dateRange={dateRange}
                  handleDateChange={handleDateChange}
                />
              }
            />
            <CustomPagination
             totalRows={filteredRecords.length}
             rowsPerPage={rowsPerPage}
             currentPage={currentPage}
             setCurrentPage={handlePageChange}
           />
           </>
          ) : (
            <div className='empty_tbl'>
              <img src='/images/emptyPage.svg' alt='empty' />
              <h3 className='num_trans'>No Verified Identity Yet</h3>
              <p className='num_desc'>Click on the Quick Check button to verify your Identity</p>
            </div>
          )}
        </>
      )}




      
    </div>
  )
}

export default Identities
