import React from 'react'

function OrderSubmitedModal({setIsOrderSubmited,setIsOrder,setIsClientResponse,orderInput,orderOtcSuccess}) {
    const handleClose = ()=>{
        setIsOrderSubmited(false)
        setIsOrder(false)
    }

  return (
    <div className='balance_modal'  onClick={setIsOrderSubmited.bind(this, false)} >
       
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
            <div className='balance_modal_wrapper'>
                <img 
                    onClick={handleClose}
                    className='verify-close' src="images/otc/x-close.svg" alt='close' 
                />
                <img 
                    onClick={setIsOrderSubmited.bind(this, false)}
                    className='otc_rembo' src="images/otc/rembo.svg" alt='close' 
                />


                <div className="modal-title">
                    <p className="enter-amount">Submitted</p>
                    <p className="enter-an-amount">{orderOtcSuccess.message}</p>
                </div>

                <div className='otcform_container'>
                    <p className='amount_requested'>Amount requested</p>
                    <h6 className='amount_value'>{orderInput.to} {orderInput.amount}</h6>
                </div>
                
                {/* <div className='otc_rule lin_margin'></div> */}
               

                {/* <div className='otc_rule'></div> */}
                
                <button className='btn otc_btn_cancel' onClick={handleClose} style={{width:"100%",marginTop:"46px"}}>
                        Close
                </button>
            </div>
        </div>
    </div>
  )
}

export default OrderSubmitedModal