import React from 'react'
import '../common/Pages.scss'
function CustomerOverViewItem({icon,desc,amount,profile,name,email,initial}) {
  return (
    <div className='customer-profile'>
      {
        profile ?
        <div className='ben_initial'>
            <p className='ben_initial_val'>{initial}</p>
        </div> :
        <img className='customer-profile-avater' src={process.env.PUBLIC_URL+icon} alt='customer'/>
      }
        <div className='customer-profile-content'>
           {
            profile ?
            <>
            <h3 className='name_overview_text'>{name}</h3>
            <p className='customer_email_text'>{email}</p>
             </>
             :
             <>
             <h3 className='customer-profile-flow'>{desc}</h3>
             <p className='customer-profile-amount'>{amount}</p>
           </>
           }
        </div>
    </div>
  )
}

export default CustomerOverViewItem
