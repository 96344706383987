import React from 'react'
import '../common/Pages.scss'
import CustomerOverViewItem from './CustomerOverViewItem'
import Active from '../common/Active'
import CustomerBalanceItem from './CustomerBalanceItem'
function CustomerInflowOverview({item,customerData}) {
  return (
    <div className='customer-overview' style={{marginBottom:"32px"}}>
      
    
    <CustomerOverViewItem 
     name={item && `${item?.firstname} ${item?.lastname}`}
     email={item?.email || "N/A"}
     initial={item && `${item?.firstname.slice(0, 1).toUpperCase()} ${item?.lastname.slice(0, 1).toUpperCase()}`}
     profile
     />    
   
    <div className='customer-line'></div>
     
    
    <div className='customer_balance_values'>
        <div className='customer_balance_content'>
            <img src="/images/NGN.svg" alt='' />
            <p className='customer_balance_title'>NGN Balance</p>
        </div>
        <h6 className='customer_balance'>{item.wallet && `${item.wallet[0].currency} ${item.wallet[0].payout_balance}`}</h6>
    </div>

     <div className='customer-line'></div>

     <div className='customer_balance_values'>
        <div className='customer_balance_content'>
            <img src="/images/USD.svg" alt='' />
            <p className='customer_balance_title'>USD Balance</p>
        </div>
        <h6 className='customer_balance'>{item.wallet && `${item.wallet[1].currency} ${item.wallet[1].payout_balance}`}</h6>
    </div>

    <div className='customer-line'></div>

    
    <div className='customer-date'>
        <Active
        title="Active"
        />
        <p className='customer-date-text'>Jan 6, 2023, 02:45:23 AM</p>
    </div>
     
    </div>
  )
}

export default CustomerInflowOverview