import React, { createRef, useRef } from 'react'
import TableHeaderSearch from '../common/TableHeaderSearch'
import Status from '../common/Status'
import '../common/tables.scss'
import { format } from 'timeago.js';

import { useNavigate } from 'react-router-dom'
import { NumericFormat } from 'react-number-format';
import { useState } from 'react';
import { toast } from 'react-toastify';
import useFilterAndPagination from '../hooks/useFilterAndPagination';
import TableLoader from '../common/TableLoader';
import DataTable from 'react-data-table-component';
import CustomPagination from '../common/CustomPagination';

function OverviewPaymentTable({collectionData,records,selectedCurrency,getCollection,setSelectedCurrency,balanceData,isCollectionLoading,toggleTransactionHistory,setSelectedRowData}) {
   const navigate = useNavigate()
   const [copiedText, setCopiedText] = useState('');
   const [selectedValue,setSelectedValue] = useState('all')
   const rowsPerPage = 10;

   const customStyles = {
    header: {
      style: {
        backgroundColor: '#f9fafb',
      },
    },

    headRow: {
      style: {
        backgroundColor: '#f9fafb',
       
      },
    }
  
    
  };


     // Use the custom hook and pass collectionData
  const {
    currentPage,
    searchTerm,
    setSearchTerm,
    dateRange,
    handleDateChange,
    handlePageChange,
    paginatedRecords,
    filteredRecords,
    setIsSearchTermChanged
  } = useFilterAndPagination(collectionData, rowsPerPage);

    // const handleCopyToClipboard = (text) => {
    //     navigator.clipboard.writeText(text);
    //     setCopiedText(text);
    //     toast.success('Copied successfully');
    // };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopiedText(text);
        toast.success('Copied successfully');
    };



    const handleNavigate =()=>{
        navigate("/payment-collections")
    }


   const handleToggleModal = ()=>{
    // toggleModal();
   }
   const handleTableRowClick = (rowData) => {
    setSelectedRowData(rowData);
    toggleTransactionHistory();
    };


   const columns = [
    {
      name: 'Timestamp',
      selector: row => format(new Date(row.dated), 'yyyy-MM-dd HH:mm:ss'),
      sortable: true,
    },
    {
      name: 'Reference',
      cell: (row, index) => (
        <div style={{ display: 'flex', gap: '5px' }}>
          <input className='link_table' value={row.transactionid} hidden readOnly />
          <h1 className='td-text'>{row.transactionid.length > 10 ? `${row.transactionid.slice(0, 11)}...` : row.transactionid}</h1>
          <img
            src={process.env.PUBLIC_URL + "/images/copy-key-icon.svg"}
            alt="ic"
            onClick={() => handleCopyToClipboard(row.transactionid)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ),
      sortable: true,
    },
    {
      name: 'Amount',
      cell: row => (
        <NumericFormat
          value={Number(row.total_amount / 100).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={`${row.currency_settled} `}
          renderText={value => <p className='td-text'>{value}</p>}
        />
      ),
      sortable: true,
    },

    {
      name: 'Balance',
      cell: row => (
        <NumericFormat
          value={Number(row.balance_after / 100).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          prefix={`${row.currency_settled} `}
          renderText={value => <p className='td-text'>{value}</p>}
        />
      ),
      sortable: true,
    },

    {
      name: 'Type',
      cell: row => (
        <Status
          mgs={row.type}
          bg="#FDF2FA"
          border="#FCCEEE"
          color="#C11574"
        />
      ),
      sortable: true,
    },

    {
      name: 'Description',
      selector: row => row.narration.length > 30 ? `${row.narration.slice(0, 31)}...` : row.narration,
      sortable: true,
    },

    {
      name: 'Status',
      cell: row => (
        <div className='payment-status'>
          {row.status === "success" || row.status === "Success" ? (
            <Status
              icon="/images/success.svg"
              mgs="Successful"
            />
          ) : row.status === "pending" ? (
            <Status
              icon="/images/Dot.svg"
              mgs="Pending"
              bg="#FFF8F0"
              border="#F79009"
              color="#F79009"
            />
          ) : row.status === "failed" ? (
            <Status
              icon="/images/x.svg"
              mgs="Failed"
              bg="#FEF3F2"
              border="#FECDCA"
              color="#B42318"
            />
          ) : null}
          <img
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row click event
                  handleTableRowClick(row);
                }}
                src={process.env.PUBLIC_URL + "/images/eye.svg"}
                alt='eye'
          />
        </div>
      ),
      sortable: true,
    },
  ];

  return (
    <div className='balance-overview'>
      
         {isCollectionLoading && <TableLoader />}
 
       {Array.isArray(collectionData) && collectionData.length > 0 ? (
         <>
         <DataTable
           columns={columns}
           data={paginatedRecords}
           pagination={false} 
           highlightOnHover
          //  striped
           subHeader
           paginationTotalRows={filteredRecords.length}
           paginationDefaultPage={currentPage}
           paginationPerPage={rowsPerPage}
           onChangePage={handlePageChange}
           customStyles={customStyles}
           onRowClicked={handleTableRowClick}
           subHeaderComponent={
             <TableHeaderSearch
               title="All transactions"
               placeholder="Search by Reference, Timestamp or Type..."
               selectedCurrency={selectedCurrency}
               setSelectedCurrency={setSelectedCurrency}
               getCollection={getCollection}
               setSelectedValue={setSelectedValue}
               balanceData={balanceData}
               overview
               searchTerm={searchTerm}
                 setSearchTerm={(term) => {
                   setSearchTerm(term);
                   setIsSearchTermChanged(true);
                 }}
                 dateRange={dateRange}
                 handleDateChange={handleDateChange}
             />
           }
         />
         <CustomPagination
             totalRows={filteredRecords.length}
             rowsPerPage={rowsPerPage}
             currentPage={currentPage}
             setCurrentPage={handlePageChange}
           />
         
         </>
       ) : 
       (
         !isCollectionLoading && (
             <table className='tbl'>
             <tr>
             <td colSpan="7" className='tbl-heading'>
             <TableHeaderSearch
               title="All transactions"
               placeholder="Search by name, acc num or reff..."
               selectedCurrency={selectedCurrency}
               setSelectedCurrency={setSelectedCurrency}
               getCollection={getCollection}
               setSelectedValue={setSelectedValue}
               balanceData={balanceData}
               overview
               searchTerm={searchTerm}
                 setSearchTerm={(term) => {
                   setSearchTerm(term);
                   setIsSearchTermChanged(true);
                 }}
                 dateRange={dateRange}
                 handleDateChange={handleDateChange}
             />
             </td>
         </tr>
 
         <tr>
             <td  colSpan={7} style={{width:"100%"}}>
             <div className='empty_tbl'>
                 <img src='/images/emptyPage.svg' alt='emptuy' />
                 <h3 className='num_trans'>No Transactions Yet</h3>
                 <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
             </div>
             </td>
         </tr>

         </table>
         )
       )
       }

     </div>
  )
}

export default OverviewPaymentTable
