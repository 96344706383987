import React, { useState } from "react";
import "../components/login/Login.scss";
import AsideView from "../components/login/AsideView";
import "../components/signup/SignUp.scss";
import { BeatLoader, FadeLoader } from "react-spinners";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] =  useState('')

  const submitRest = async (e)=>{
    e.preventDefault();
    setIsLoading(true)
    try{
      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/initiate-reset'
      const res = await axios.post(url,{email})
      setIsLoading(false)
      console.log(res.data);
      toast.success(`Password resent Link sent Successfully to ${email}`)
    }catch(err){
      console.log(err)
      setIsLoading(false)
      const errorMessage = err?.response?.data?.msg || "Unable to reset password"
      toast.error(errorMessage);
    }
  }

  const  handleChange = (e)=>{
     setEmail(e.target.value);
  }
 
  
  return (
    <div className="dashboard-container">
      <div className="aside-container">
        <AsideView />
      </div>
      <div className="dashboard-main">
      <div className="sign-up-wrapper">
      <p className="sign-up-title">Reset password</p>
        <form onSubmit={submitRest} className="sign-up-form">
            <div className="sign-up-inner">
              <input
                className="input-field"
                type="email"
                placeholder="Email Address"
                name="business_email"
                onChange={handleChange}
                required
              />
              
            </div>
           
            <button className="btn" style={{background:"#2EBDB6"}}>
              {isLoading ? (
                <BeatLoader
                color="#FFF"
                cssOverride={{}}
              />
                
              ) : (
                <>
                  Reset Password
                  <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
                </>
              )}
            </button>
            <p className="dont-have-account">
            Don’t have an account? 
              <Link to={"/signUp"} className="signIn-link">Sign In
                <img style={{ display: "inline" }} src={process.env.PUBLIC_URL + "/images/coloredArrowForward.svg"} alt="colored"/>
              </Link>
            </p>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ForgotPassword;
