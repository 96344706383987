import React, { useState } from "react";
import "../../data/connectAccounts/connectAccount.scss";
import { useNavigate, useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { apiPost } from "../../utilities/api/axios";

function AcceptTeamMember() {
  const { encryptedTeamId } = useParams();
// const { "*": wildcardParams } = useParams();
// const paramsArray = wildcardParams.split('/');
// const id = paramsArray[0];
// const encryptedTeamId = paramsArray.slice(1).join('/'); 

// const fullEncryptedTeamId = `${id}/${encryptedTeamId}`;
// console.log("Encrypt:", fullEncryptedTeamId);
console.log("Encrypt:", encryptedTeamId);

//   const params  = window.location.href;
//   const urlId = params.split('/');
//   const encryptedTeamId = urlId[urlId.length - 1]; 
// //   const teamId = encryptedTeamId.replace(/\//, '.');

  
//   console.log("Encrypt:", encryptedTeamId)

  const pageStyle = {
    backgroundImage: `url(${
      process.env.PUBLIC_URL + "/images/green-bg.svg"
    })`,
  };

  const navigate = useNavigate();

  const initialState = {
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  };

  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [isInputClicked, setIsInputClicked] = useState({
    firstName: false,
    lastName: false,
    password: false,
    confirmPassword: false,
  });

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConPassword(!showConPassword);
  };

  const  handleInputChange = (event)=>{
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };

  const handleSubmit = async (e)=>{
    e.preventDefault();
    
    try{
        setIsLoading(true);
        
        const data = {
            encryptedTeamId: encryptedTeamId,
            password: formData.password,
            confirmPassword: formData.confirmPassword,
            fullName: `${formData.firstName} ${formData.lastName}`,
        }

        const url = "https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/accept-team-invite"
        const res = await apiPost(url,data,{},true,{})
        console.log(res)
        setIsLoading(false);
        setFormData(initialState);
        toast.success("Team invite accepted successfully!");
        navigate("/");
    }catch(err){
      setIsLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.msg || "failed"
      toast.error(errorMessage || "Failed")
      console.log(err)
    }
  }

  return (
    <div className="connect-account-overlay" style={pageStyle}>
      <div className="connect-account-main">
        <div className="connect-account-box">
            <div className="account-setup">
                <div className="img-logo">
                <img src={process.env.PUBLIC_URL + "/images/round-flickk.svg"} alt="logo"/>
                </div>
                <div className="enter-credentials">
                <p className="enter-your-flick-">Flick Account Setup</p>
                </div>
            </div>
          <div className="enter-credentials-box">
            <form className="flick-link-form" style={{ marginTop: "10px" }} onSubmit={handleSubmit}>
               <div className="flick-link-inner" style={{ display: "flex", flexDirection: "column", gap: "16px", marginBottom: "23.5px",}}>
                    <div className="input_container" onBlur={() => handleInputBlur('name')}>
                        <div className={`input-group ${isInputClicked.name ? "clicked" : ""}`} onClick={() => handleInputContainerClick('name')} onBlur={() => handleInputBlur('name')}>
                        <input
                            type="text"
                            name="name"
                            placeholder="Mide"
                            className="input-name"
                            onChange={handleInputChange}
                            required
                        />
                        </div>
                        <p className={`input-label ${isInputClicked.name ? "clicked" : ""}`}>First Name</p>
                    </div>

                    <div className="input_container" onBlur={() => handleInputBlur('lastName')}>
                        <div className={`input-group ${isInputClicked.name ? "clicked" : ""}`} onClick={() => handleInputContainerClick('lastName')} onBlur={() => handleInputBlur('lastName')}>
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Ajibade"
                            className="input-name"
                            onChange={handleInputChange}
                            required
                        />
                        </div>
                        <p className={`input-label ${isInputClicked.lastName ? "clicked" : ""}`}>Last Name</p>
                    </div>

                    <div className="input_container" onBlur={() => handleInputBlur('password')}>
                        <div className={`input-group ${isInputClicked.password ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('password')} onBlur={() => handleInputBlur('password')} style={{justifyContent:"space-between"}}>
                            <input  
                                onChange={handleInputChange} 
                                type={showPassword ? 'text' : 'password'} 
                                placeholder="Password" 
                                name="password" 
                                className="input-name" 
                                required
                            />
                            <img onClick={handleTogglePassword} src="/images/eye.svg" alt="card" style={{width:"16px", height:"16px"}}/>
                        </div>
                        <p className={`input-label ${isInputClicked.password ? 'clicked' : ''}`}>Password</p>
                    </div>

                    <div className="input_container" onBlur={() => handleInputBlur('confirmPassword')}>
                        <div className={`input-group ${isInputClicked.confirmPassword ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('confirmPassword')} onBlur={() => handleInputBlur('confirmPassword')} style={{justifyContent:"space-between"}}>
                            <input  
                                onChange={handleInputChange} 
                                type={showConPassword ? 'text' : 'password'} 
                                placeholder="Confirm Password" 
                                name="confirmPassword" 
                                className="input-name" 
                                required
                            />
                            <img onClick={handleToggleConfirmPassword} src="/images/eye.svg" alt="card" style={{width:"16px", height:"16px"}}/>
                        </div>
                            <p className={`input-label ${isInputClicked.confirmPassword ? 'clicked' : ''}`}>Confirm Password</p>
                        </div>
                    </div>
                    <button
                        className="btn"
                        style={{ background: "#2EBDB6" }}
                        type="submit"
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                        <BeatLoader color="#FFF" cssOverride={{}} />
                        ) : (
                        <>
                            Join the Team
                            <img
                            src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"}
                            alt="arrow btn"
                            style={{ display: "inline", marginLeft: "5px" }}
                            />
                        </>
                        )}
                    </button>
                    <div className="secured-flick">
                        <img
                        src={process.env.PUBLIC_URL + "/images/secured-by-flick.svg"}
                        alt="flick"
                        />
                    </div>
            </form>
          </div>
        </div>
        <p className="any-questions">If you have any questions, contact <span style={{color:"#fff", fontWeight:"500", cursor:"pointer"}}>hello@getflick.app</span></p>
      </div>
    </div>
  );
}

export default AcceptTeamMember;
