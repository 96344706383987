import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { BeatLoader } from "react-spinners";

function PortFolioConfirm({setIsConfirmPortfolio,setIsPortfolioSuccess,setIsOpenPopup,setToken,payoutId,payoutToken,transferBankInput,payoutResult,setIsPayout}) {
 const { currentUser } = useSelector((state) => state?.user);
 const [isLoading,setIsLoading] = useState(false);
 const [isDisabled, setIsDisabled] = useState(false);
  const [waitTime, setWaitTime] = useState(60);




  useEffect(() => {
    let timer;
    if (isDisabled) {
      timer = setInterval(() => {
        setWaitTime(prev => {
          if (prev === 1) {
            clearInterval(timer);
            setIsDisabled(false);
            setWaitTime(60);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [isDisabled]);

 const handleCancel = ()=>{
    setIsPayout(false);
    // setIsOpenPopup(false);
    setIsConfirmPortfolio(false);
   
 }
 
 const  handleInputChange = (e)=>{
    setToken(e.target.value);
 }

 const handleBox =(e)=>{
   e.stopPropagation();
   setIsConfirmPortfolio(false)
 }

 const handleSuccess = async (e)=>{
    e.stopPropagation();
    setIsLoading(true);
    try{

      const headers = {
        'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
      };

      const data = {
        Id:payoutId,
        token: payoutToken,   
      }
  
      console.log("otp data: ",data)
      // Make an API call to fetch accountName using bankCode and accountNumber.
      const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/complete-payout";

      let res = await axios.post(url,{...data},{ headers: headers});
      console.log("Confirm transaction",res?.data)
      setIsLoading(false);
      toast.success(res?.data.message,{position: "top-left", pauseOnHover: true})

      setIsPortfolioSuccess(true);

      }catch(err){
        setIsLoading(false);
        console.log(err);
        toast.error("unable to confirm transfer",{ position: "top-left", pauseOnHover: true})
      }
  }

  const resendToken = async (e)=>{
    e.stopPropagation();
    try{

      const headers = {
        'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
      };

      const data = {
        Id:payoutId  
      }
  
      // Make an API call to fetch accountName using bankCode and accountNumber.
     
      const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/resend-token";
      let res = await axios.post(url,{...data},{ headers: headers});

      toast.success('code resend successfully',{ position: "top-left", pauseOnHover: true})

      setIsDisabled(true);

      }catch(err){
        console.log(err);
        toast.success('unable to resend code', {position: "top-left", pauseOnHover: true})
      }
  }
  return (
    <div className='balance_modal' >
       <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>

            <div>
            <img style={{cursor:"pointer"}} className='verify-close' onClick={handleBox} src={process.env.PUBLIC_URL + "/images/x-close.svg"} alt="icon" />
            </div>
            
            <div className="modal-title">
            <p className="enter-amount">Confirm Transfer</p>
            
            <p className="enter-an-amount">Sending  <span style={{color:"#151F32",fontWeight:"700"}}> NGN {transferBankInput?.amount} </span> to <span style={{color:"#151F32",fontWeight:"700"}}>  {transferBankInput?.beneficiary_name} </span> {` ${payoutResult?.bank_name} - ${transferBankInput?.account_number}`}.</p>
            </div>

            <div className="api_wallet_input">
                <p className="portfolio_enter_amount" >Enter the confirmation code sent to <span style={{color:"#151F32",fontWeight:"500"}}>{payoutResult?.email}</span> and <span style={{color:"#151F32",fontWeight:"500"}}> {`+${payoutResult?.phone}`}</span>  </p>
                <input  name='token' className='api_input_amounts'  placeholder="Enter your confirmation code for verification" onChange={handleInputChange}/>
                <div>
                  <p className="enter-an-amount" style={{ textAlign: "center", marginTop: "16px", cursor: isDisabled ? 'not-allowed' : 'pointer', pointerEvents: isDisabled ? 'none' : 'auto' }} onClick={resendToken}><img src="/images/resend.svg" alt='resend' style={{display:"inline"}}/> <span className='api_resend'> Resend code</span> </p>
                  {isDisabled && <p className="otpmsg">Please wait for <span className='otpmsg_time'>{waitTime}</span> seconds to resend the code.</p>}
                </div>
                <div className="portfolio-btn">
                  <button onClick={handleCancel} className="sec-btn">
                      Cancel
                  </button>
                  {
                    !isLoading?
                    <button onClick={handleSuccess} className="btn">
                    Confirm transfer
                    </button>:
                    <button className='btn'>  
                      <BeatLoader
                          color="#FFF"
                          cssOverride={{}}
                        />
                    </button>
                    }
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default PortFolioConfirm
