import React from 'react'

function AcceptModal({setIsAccept,acceptInput,setAcceptInput,setIsAcceptSuccess,setIsClientResponse,setIsOrderSubmited,setIsOrder}) {

    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
        setAcceptInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        
    }

    const handleSubmit = (e)=>{
        e.preventDefault()
    }

    const handleClose = ()=>{
        setIsAccept(false)
        setIsClientResponse(false)
        setIsOrderSubmited(false)
        setIsOrder(false)
    }
  return (
    <div className='balance_modal'  onClick={setIsAccept.bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsAccept.bind(this, false)}
              className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
              />

            <div className="modal-title">
                <p className="enter-amount">Share to</p>
                <p className="enter-an-amount">Please enter the email and/or phone number to share this order</p>
            </div>

           

            <div className='otcform_container'>
               <form onSubmit={handleSubmit}>
                        <div>
                            <p className='otcForm_desc'>Email</p>
                            <input  name='email' className='input_accept'  placeholder="mide@getflick.app" onChange={handleInputChange}/>
                        </div>
                        <div className='phone_cont'>
                            <p className='otcForm_desc'>Phone Number</p>
                            <input  name='phone' className='input_accept'  placeholder="+234 08136902667" onChange={handleInputChange}/>
                        </div>
               </form>

                
            </div>

            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={handleClose} >
                    Cancel
                </button>
                <button className='btn' onClick={setIsAcceptSuccess.bind(this,true)} >
                    Share
                </button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default AcceptModal