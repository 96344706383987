import React from "react";
import './Modal.scss'

function Success({setIsSuccess}) {
//   if (!open) return null;

  return (
    <div className="overlay" onClick={setIsSuccess.bind(this,false)} >
    <div className="success-modal">
      <div className="success-wrapper">

        <div>
        <img src={process.env.PUBLIC_URL + "/images/iconSuccess.svg"} alt="icon" />
        </div>

      <div className="success-ful">
        <p className="success">Successful</p>
        <p className="check-balance">Check your balance to confirm the transaction made </p>
      </div>

          <button onClick={setIsSuccess.bind(this,false)} className="btn">
            Confirm
          </button>
        </div>
    </div>
    </div>
  );
}

export default Success;
