import React from 'react'
import DoubleInputGroup from './DoubleInputGroup';
import BusinessAddress from './BusinessAddress';
import SingleInputGroup from './SingleInputGroup';
import SelectInputGroup from './SelectInputGroup';

function WalletBeneficiaryModal({setIsWalletBeneficiary,setIsFundBalance,setWalletBeneficiaryInput,setIsWalletBankDetail}) {

    const handleClose = ()=>{
        setIsWalletBeneficiary(false);
        setIsFundBalance(false);
    }
    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
        setWalletBeneficiaryInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
    }

  return (
    <div className='balance_modal'  onClick={setIsWalletBeneficiary.bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsWalletBeneficiary.bind(this, false)}
              className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
              />

            <div className='title_otc'>
                <div className="modal-title" >
                    <p className="enter-amount">Beneficiary Details</p>
                    <p className="enter-an-amount">Please provide the beneficiary details</p>
                </div>
                <img src='/images/otc/step_one.svg' alt=''/>
            </div>

           

            <div className='otcform_container'>
               <form onSubmit={handleSubmit} >
      
                        <SelectInputGroup
                        name="wireType"
                        handleChange={handleInputChange}
                        label="Wire Type"
                        >
                             <option value="" disabled>Domestic U.S wire (same/next day)</option>
                                <option value="NGN">Domestic U.S wire (same/next day)</option>
                        </SelectInputGroup>
                        <DoubleInputGroup label1="Account Number" label2="Routing Number" handleChange={handleInputChange} name1="accountNumber" name2="routingNumber" placeholder1="22488136902667" placeholder2="23690867"/>
                        
                        <SingleInputGroup label="Beneficiary Account Name" handleChange={handleInputChange} name="beneficiaryName" placeholder="Ayomide Ajibade"/>
                        <BusinessAddress label="Beneficiary Address" />
                        <div style={{display:"flex",flexDirection:"column",marginTop:"20px"}}>
                            <DoubleInputGroup label1="Address Line 1" label2="Address Line 2" handleChange={handleInputChange} name1="address1" name2="address2" />
                            <DoubleInputGroup label1="City" label2="State" handleChange={handleInputChange} name1="city" name2="state" />
                            <DoubleInputGroup select label1="Country" label2="Postal Code" handleChange={handleInputChange} name1="city" name2="state">
                                <option value="" disabled>USA</option>
                                <option value="USD">USA</option>
                            </DoubleInputGroup>
                        </div>

               </form>

                
            </div>

            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={handleClose} >
                    Cancel
                </button>
                <button className='btn' onClick={setIsWalletBankDetail.bind(this,true)} >
                    Proceed
                </button>
            </div>
          </div>
        </div>
    </div>
  )
}

export default WalletBeneficiaryModal