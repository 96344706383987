import React from 'react'
import TableHeaderSearch from '../common/TableHeaderSearch'
import { format } from 'timeago.js'
import { getIconByName } from '../utilities/getBankIcon'
import TableLoader from '../common/TableLoader'

function Statements({statementData,isStatementLoading}) {

  return (
    <div className='balance-overview collection_load'>
        {
            isStatementLoading &&
            <TableLoader />
        }
      <table className='tbl'>
        <tr>
            <td colSpan="7" className='tbl-heading'>
                <TableHeaderSearch 
                title="All statements"
                placeholder="Search..."
                />
            </td>
            
        </tr>
        {
               Array.isArray(statementData) && statementData.length > 0  &&
            <tr>
                <th className='th-text'>Customer</th>
                <th className='th-text'>Bank</th>
                <th className='th-text'>Period</th>
                <th className='th-text'>From</th>
                <th className='th-text'>To</th>
                <th className='th-text'>Collected Via</th>
                <th className='th-text'>Date Collected</th>
            </tr>
        }
        {
            Array.isArray(statementData) && statementData.length > 0 && statementData.map((item,index)=>(

                <tr>
                    <td className='td-text'>{item?.customer?item.customer:"N/A"}</td>
                    <td className='td-text'>
                        <div className='tbl-account'>
                            <img className='account-bank-logo' src={item.bank? getIconByName(item.bank):"N/A"} alt="" />
                            <div className='payment-account-text'>
                                <h1 className='td-text'>{item?.bankName?item?.bankName:"N/A"} </h1>
                                <span className='td-text'> | {item?.Nuban?item?.Nuban:"N/A"}</span>
                            </div>
                        </div>
                    </td>
                    <td className='td-text'>{item?.period?item.period:"N/A"} Months</td>
                    <td className='td-text'>{item?.from?item.from:"N/A"}</td>
                    <td className='td-text'>{item?.to?item.to:"N/A"}</td>
                    <td className='td-text'>
                    {/* <img src={process.env.PUBLIC_URL+"/images/btnLink.svg"} alt='active' /> */}
                    <p className='link_status'>{item?.collectedVia?item.collectedVia:"N/A"}</p>
                    </td>
                    <td className='td-text'>
                        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                            <p>{format(item.dateCollected)}</p>
                            <img src="/images/button.svg" alt=''/>
                        </div>
                    </td>
                </tr>
            ))
        }
      
        {
            Array.isArray(statementData) && statementData.length > 0 &&
            <tr>
                <td className='td-export' colspan="7">
                    <div className='tbl-export'>
                        <h6 className='tbl-export-text'>Export Data</h6>
                        <img src={process.env.PUBLIC_URL+"/images/export.svg"} alt='export' />               
                    </div>
                </td>
            </tr>
        }
        {
             (!isStatementLoading && (!Array.isArray(statementData) || statementData.length <= 0))  &&
            <tr>
             <td  colSpan={7} style={{width:"100%"}}>
                <div className='empty_tbl'>
                    <img src='/images/emptyPage.svg' alt='emptuy' />
                    <h3 className='num_trans'>No Transactions Yet</h3>
                    <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                </div>
             </td>
            </tr>
        }
      </table>
    </div>
  )
}

export default Statements
