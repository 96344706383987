import React, { createRef, useRef, useState } from 'react'
import TableHeaderSearch from '../common/TableHeaderSearch'
import { useNavigate } from 'react-router-dom'
import { format } from 'timeago.js';
import { copyToClipboard } from '../utilities/clipboardUtils';
import TableLoader from '../common/TableLoader';
import useFilterAndPagination from '../hooks/useFilterAndPagination';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';
import CustomPagination from '../common/CustomPagination';
var nf = new Intl.NumberFormat();




function PayLinks({toggleLink,payLink, toggleModal,paymentData,isPayLinkLoading}) {
   const navigate = useNavigate()
   const lineRefs = React.useRef([]);
   const rowsPerPage = 10;

   lineRefs.current = paymentData.map((_, i) => lineRefs.current[i] ?? createRef());
   const {
    currentPage,
    searchTerm,
    setSearchTerm,
    dateRange,
    handleDateChange,
    handlePageChange,
    paginatedRecords,
    filteredRecords,
    setIsSearchTermChanged
  } = useFilterAndPagination(paymentData, rowsPerPage);

  const customStyles = {
    header: {
      style: {
        backgroundColor: '#f9fafb',
      },
    },

    headRow: {
      style: {
        backgroundColor: '#f9fafb',
       
      },
    }
  
    
  };

  const handleCopyToClipboard = (ref) => {
    navigator.clipboard.writeText(ref.current.value);
    toast.success('Copied successfully');
  };

   
  const handleToggleModal = ()=>{
    toggleModal();
  }
 

  const columns = [
    {
      name: 'Date Created',
      selector: row => format(new Date(row.dated), 'yyyy-MM-dd HH:mm:ss'),
      sortable: true,
    },
    {
      name: 'Page Name',
      selector: row => row.pageName || "N/A",
      sortable: true,
    },
    {
      name: 'currency collected',
      selector: row => row.currency,
      sortable: true,
    },
    {
      name: 'currency settled',
      selector: row => row.currency_settled == undefined || row.currency_settled == null ? row.currency :row.currency_settled,
      sortable: true,
    },
    {
      name: 'Amount settled',
      selector: row =>   nf.format((row.amount / 100)).replace("NaN", "Flexible").toLocaleString() || "N/A",
      // selector: row => getSymbolFromCurrency(row.currency_settled) + "" + nf.format((row.amount / 100)).replace("NaN", "Flexible").toLocaleString() || "N/A",
      sortable: true,
    },
    // {
    //   name: 'Products Type',
    //   selector: row => row.productType || "N/A",
    //   sortable: true,
    // },
    {
      name: 'Link',
      cell: (row, index) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <input ref={lineRefs.current[index]} className='link_table' value={row.paymentUrl} readOnly />
          <img
            style={{ cursor: 'pointer' }}
            onClick={handleCopyToClipboard.bind(null, lineRefs.current[index])}
            src={process.env.PUBLIC_URL + "/images/copy.svg"}
            alt='copy'
          />
        </div>
      ),
      sortable: true,
    },
    // {
    //   name: 'Date Created',
    //   selector: row => format(new Date(row.dated), 'yyyy-MM-dd HH:mm:ss'),
    //   sortable: true,
    // },
  ];

  return (
    <div className='balance-overview tbl_overflow'>
       {
            isPayLinkLoading &&
            <TableLoader />
        }
       
      {Array.isArray(paymentData) && paymentData.length > 0 ? (
        <>
          <DataTable
            columns={columns}
            data={paginatedRecords}
            highlightOnHover
            subHeader
            paginationTotalRows={filteredRecords.length}
            paginationDefaultPage={currentPage}
            paginationPerPage={rowsPerPage}
            customStyles={customStyles}
            onChangePage={handlePageChange}
            pagination={false}
            subHeaderComponent={
              <TableHeaderSearch
                title="All payment links"
                placeholder="Search by Page Name..."
                paylink
                toggleLink={toggleLink}
                searchTerm={searchTerm}
                setSearchTerm={(term) => {
                  setSearchTerm(term);
                  setIsSearchTermChanged(true);
                }}
                dateRange={dateRange}
                handleDateChange={handleDateChange}
              />
            }
          />
          <table className='tbl'>
            <tr className='export-data' >
              <td className='td-export' colSpan="7">
                <div className='tbl-export'>
                  <h6>Export Data</h6>
                  <img className="export-img" src={process.env.PUBLIC_URL + "/images/export.svg"} alt='export' />
                </div>
              </td>
            </tr>
          </table>
          <CustomPagination
            totalRows={filteredRecords.length}
            rowsPerPage={rowsPerPage}
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
          
        </>
      ) : (
        !isPayLinkLoading && (
          <table className='tbl'>
            <tr>
              <td colSpan="7" className='tbl-heading'>
                <TableHeaderSearch
                  title="All payment links"
                  placeholder="Search..."
                  paylink
                  toggleLink={toggleLink}
                />
              </td>
            </tr>
            <tr>
              <td colSpan={7} style={{ width: "100%" }}>
                <div className='empty_tbl'>
                  <img src='/images/emptyPage.svg' alt='empty' />
                  <h3 className='num_trans'>No Payment link Yet</h3>
                </div>
              </td>
            </tr>
          </table>
        )
      )}
    
    </div>
  )
}

export default PayLinks
