import axios from 'axios';
import React from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'

function ChangeRoleModal({setIsChangeRole, setIsOpen,setIsRemoveMember, selectedTeamMemberEmail}) {
  const {currentUser} = useSelector((state)=>state?.user)

  const navigate = useNavigate();

  const handleChange = () => {
    setIsOpen(false)
    setIsChangeRole(true)
  }
  // const handleRemove= () => {
  //   setIsOpen(false)
  //   setIsRemoveMember(true)
  // }

  const handleRemove = async ()=>{
    try{
      
      const headers = {  
      //   api_key: currentUser?.user?.merchantKey,
      //   sweetapi: currentUser?.user?.merchantCode,
        "content-type": "application/json",
        // // "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZlbGl4dGVtaWtvdGFuODlAZ21haWwuY29tIiwiaWF0IjoxNzE4MDA5MjQ4fQ.Wkr4DIDg408MtsDWWQZvUCHnPyPZhX-POB-qVy6yaBc", 
      }
      const data = {
          teamMemberEmail: selectedTeamMemberEmail,
          token: currentUser.user.token,
      }
      console.log("payload", data)
  
      const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/remove-team-member';
      let res = await axios.post(url, {...data},
        {
        headers:headers
      })
  
      console.log (res)
      const response = res.data.data
      console.log("DeleteRole",response)
      setIsOpen(false);
      setIsRemoveMember(true)
    }catch(err){
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
    }
  }
  return (
    <div className='change_modal'>
      <div className='change-role' onClick={handleChange}>
        <p className='change'>Change role</p>
        <div>
            <img src='/images/change-role.svg' alt='change'/>
        </div>
      </div>

      <div className='change-role'>
        <p className='change' style={{color:"#ED1C24"}} onClick={handleRemove}>Remove team member</p>
        <div>
         <img src='/images/delete-role.svg' alt='delete'/>
        </div>
      </div>
       
    </div>
  )
}

export default ChangeRoleModal
