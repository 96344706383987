import React, { useState } from 'react'
import '../common/tables.scss'
import TableWalletModal from '../overview/TableWalletModal'
import TableLoader from '../common/TableLoader'
import { NumericFormat } from 'react-number-format'

function BalanceTable({ openSetLimitModal,setIsAmount,setIsModalOpen,setIsPaymentMethod,balanceData,isBankLoading,setIsApiWallet,setPayoutCurrency,setApiWallet,setIsPayment }) {
  const [isOpen, setIsOpen] = useState(false)
 const toggleModal = ()=>{
  setIsOpen(!isOpen)
 }
 const handleApiAmount = (api)=>{
  setPayoutCurrency("")
  setApiWallet(api)
  
  setIsAmount(true);
 }
 const handleDollarAmount = (currency)=>{
  setApiWallet("")
  
  setPayoutCurrency(currency)
  setIsApiWallet(true);
  setIsPayment(true)
  // setIsAmount(true);
 }

 const balance = balanceData?.[0];
 const currency = balance?.currency;
 const apiBalance = balance?.api_balance;

 const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "NGN":
      return "₦";
    case "USD":
      return "$";
    case "GBP":
      return "£";
    case "CAD":
      return "C$";
    case "EUR":
      return "€";
    case "GHS":
      return "GH₵";
    case "KES":
      return "KSh";
    default:
      return "";  // Default to ₦ if the currency is not recognized
  }
  };

  const displayBalance = `${getCurrencySymbol(currency)} ${apiBalance !== undefined && apiBalance !== null ? (apiBalance / 100).toFixed(2) : "0"}`;

  return (
    <div className='balance-overview'>
      {
            isBankLoading &&
            <TableLoader />
      }
      <div className='wallet_tb'>
        <table className='tbl'>
          <tr>
              <th className='wallet_col wallet_text' colSpan={6}>API Wallet</th>
              <th className='wallet_text'>Actions</th>
          </tr>
          <tr>
              <td wallet_col colSpan={6} className='balance_amounts wallet_col'>{displayBalance}</td> 
              <td className='col_actions'>
                  <div className='balance-gap'>
                      <p onClick={openSetLimitModal} className='set-limit'>Set low limit</p>
                      <p onClick={()=>handleApiAmount('api')} className='set-limit'>Fund balance</p>
                      {/* <p onClick={setIsCommingSoon.bind(this,true)} className='set-limit'>Fund balance</p> */}
                      <img onClick={toggleModal} src='/images/linearDot.svg' alt='dot' />
                  </div>
                  {isOpen && <TableWalletModal setIsSetLimit={setIsModalOpen} setIsPaymentMethod={setIsPaymentMethod} />}
              </td>
          </tr>
          
          
        </table>
      </div>

      <table className='tbl'>
        <tr>
            <th className='wallet_text'>Currency</th>
            <th className='wallet_text'>Collection Bal.</th>
            <th className='wallet_text'>Payout Bal.</th>
            <th className='wallet_text'>Actions.</th>    
        </tr>
        {
          Array.isArray(balanceData) && balanceData.length > 0 && balanceData.map((item,index)=>(
            <tr key={index}>
                <td>
                    <div className='flag'>
                        <img src={process.env.PUBLIC_URL+`/images/${item.currency}.svg`} alt="flag" />
                        <h1 className='ng'>{item.currency}</h1>
                    </div>
                </td>
                <td className='balance-amount bold_bal'>
                
                  <NumericFormat value={Number((item?.collection_balance/100)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={`${item.currency} `} 
                    renderText={(value) => <p >{value}</p>}
                    />
                </td>
                <td className='balance-amount bold_bal'>
                  
                  <NumericFormat value={Number((item?.payout_balance/100)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={`${item.currency} `} 
                    renderText={(value) => <p >{value}</p>}
                  />
                </td>
                <td className='col_width'>
                    <div className='balance-gap'>
                        <p onClick={openSetLimitModal} className='set-limit'>Set low limit</p>
                        <p onClick={()=>handleDollarAmount(item.currency)} className='set-limit'>Fund balance</p>
                    </div>
                </td>
            </tr>
          ))
        }

        {
            balanceData.length <= 0 &&
            <tr>
              <td  colSpan={7} style={{width:"100%"}}>
                  <div className='empty_tbl'>
                      <img src='/images/emptyPage.svg' alt='emptuy' />
                      <h3 className='num_trans'>No Transactions Yet</h3>
                      <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                  </div>
              </td>
            </tr>
        }

        
        
        
      </table>

      {/* <ApiWalletBalance align="center" mt="32px"  amount="N20,000.12" setIsSetLimit={setIsModalOpen} setIsPaymentMethod={setIsPaymentMethod}  /> */}
      
    </div>
  )
}

export default BalanceTable
