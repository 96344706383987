import React, {useState} from 'react'
import ApiKeys from './developers/ApiKeys';
import Webhooks from './developers/Webhooks';
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import WebhookModal from './developers/WebhookModal';
import axios from 'axios';
import { toast } from 'react-toastify';

function Developers() {
  const {currentUser} = useSelector((state)=>state?.user)
  const [activeTab, setActiveTab] = useState("apiKeys");
  const [isLoading,setIsLoading] = useState(false);
  const [isWebhook,setIsWebhook] = useState(false);
  const [webhookInput ,setWebhookInput] = useState({
    webhookArray: '',
    secretHash:''
  })

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const addWebhook = async ()=>{
   
    setIsLoading(true);
    try{
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        "sweetapi": currentUser.user.merchantCode  
      }
      const payload = {
        webhookArray : webhookInput?.webhookArray,
        secretHash : webhookInput?.secretHash
      }
      
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/add-webhook-url';
      let res = await axios.post(url,{...payload},{
        headers:headers
      })

      setIsLoading(false);
      console.log("response data: ",res?.data)
      toast.success(res?.data.message);
      setIsWebhook(false);
      
    }catch(err){
      setIsLoading(false);
      console.log(err)
    }
   
  }

  return (
    <div className="header-form-upload">
    <div className="header-border" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
      <div className="settings-header">
        <p
          className={activeTab === "apiKeys" ? "active" : ""}
          onClick={() => handleTabClick("apiKeys")}
        >
          API Keys
        </p>
        <p
          className={activeTab === "webhooks" ? "active" : ""}
          onClick={() => handleTabClick("webhooks")}
        >
          Webhooks
        </p>
        <p
          className={activeTab === "developerDocs" ? "active" : ""}
          onClick={() => handleTabClick("developerDocs")}
        >
          Developer docs
        </p>
      </div>

      {
        activeTab === "webhooks" && <button onClick={setIsWebhook.bind(this,true)} className='btn ' style={{width:"200px", marginRight:"10px",marginBottom:"5px"}}>Add webhook</button>
      }
    </div>
      <div className="form-upload">
        {activeTab === "apiKeys" && <ApiKeys />}
        {activeTab === "webhooks" && <Webhooks /> }
        {/* {activeTab === "developerDocs" && <EnableServices /> } */}
      </div>

      {
        isWebhook && 
        <WebhookModal setWebhookInput={setWebhookInput} webhookInput={webhookInput} setIsWebhook={setIsWebhook} addWebhook={addWebhook} isLoading={isLoading}/>
      }
    </div>
  )
}

export default Developers