import React, { useState } from "react";
import '../../modals/Modal.scss'
import axios from "axios";
import { useSelector } from "react-redux";

function ChangeRole({setIsChangeRole, setIsOpen, setIsConfirmChange, selectedTeamMemberEmail, selectedTeamMemberRole}) {
    const {currentUser} = useSelector((state)=>state?.user)
    const [operations, setOperations] = useState(false);
    const [owner, setOwner] = useState(false);
    const [customer, setCustomer] = useState(false);
    const [developer, setDeveloper] = useState(false);
    // const [custom, setCustom] = useState(false);

   

    const [selectedOption, setSelectedOption] = useState('');

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
      };
    const handleCancelChange = () => {
        setIsChangeRole(false)
        setIsOpen(true)
    }

    // const handleCreateRole = ()=>{
    //     setIsChangeRole(false)
    //     setIsConfirmChange(true)
    // }
    const handleOperations = () => {
        setOperations(!operations);
    }

    const handleOwner = () => {
    setOwner(!owner);
    };

    const handleCustomer = () => {
    setCustomer(!customer);
    };

    const handleDeveloper = () => {
    setDeveloper(!developer);
    };

    // const handleCustom = () => {
    // setCustom(!custom);
    // };

    const handleCreateRole = async ()=>{
      try{
        
        const headers = {  
        //   api_key: currentUser?.user?.merchantKey,
        //   sweetapi: currentUser?.user?.merchantCode,
          "content-type": "application/json",
          // // "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZlbGl4dGVtaWtvdGFuODlAZ21haWwuY29tIiwiaWF0IjoxNzE4MDA5MjQ4fQ.Wkr4DIDg408MtsDWWQZvUCHnPyPZhX-POB-qVy6yaBc", 
        }
        const data = {
            teamMemberEmail: selectedTeamMemberEmail,
            teamMemberRole: selectedOption,
            token: currentUser.user.token,
        }
        console.log("payload", data)
    
        const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/change-team-member-role';
        let res = await axios.post(url, {...data},
          {
          headers:headers
        })
    
        console.log (res)
        const response = res.data.data
        console.log("ChangeRole",response)
        setIsChangeRole(false);
        setIsConfirmChange({ show: true, prevRole: selectedTeamMemberRole, newRole: selectedOption });
      }catch(err){
        console.log(err)
        const errorMessage = err?.message || "unable to fetch data"
      }
    }


  return (
    <div className="overlay">
    <div className="enter-amount-modal" style={{maxWidth:"498px"}}>
      <div className="modal-wrapper" style={{width:"100%"}}>
        <div style={{display:"flex", justifyContent:"flex-end"}}>
            <img style={{cursor:"pointer"}} onClick={handleCancelChange} src={process.env.PUBLIC_URL + "/images/x-close.svg"} alt="icon" />
        </div>
            <div className="member-role" style={{paddingBottom:"16px"}}>
                <p className="change-member">Change member role</p>
                <p className="manually">Manually authenticate KYCs in real-time.</p>
            </div>
            <div className="details-info" style={{height: "auto", marginBottom:"25px"}}>

            <div className="detail-info" onClick={handleOwner}>
                <div className={`detail-head- ${owner ? 'clicked' : ''}`}>
                    <h3 className="head-text">Admin</h3>
                    {/* {owner ? (
                    <img src={process.env.PUBLIC_URL + "/images/expand-arrow.svg"} alt="icon"/>
                    ) : (
                    <img src={process.env.PUBLIC_URL + "/images/collapse-arrow.svg"} alt="icon"/>
                    )} */}
                    <input 
                        type="radio" 
                        name="role"
                        value="admin"
                        checked={selectedOption === 'admin'}
                        onChange={handleOptionChange}
                        className="role-custom-radio"
                    />
                </div>
                {owner && (
                    <div className="detail-body"> 
                        
                    </div>
                )}
            </div>
                <div className="detail-info" onClick={handleOperations}>
                    <div className={`detail-head- ${operations ? 'clicked' : ''}`} >
                        <h3 className="head-text">Operations</h3>
                        {/* {operations ? (
                            <img src={process.env.PUBLIC_URL + "/images/expand-arrow.svg"} alt="icon" onClick={handleOperations} />
                            ) : (
                            <img src={process.env.PUBLIC_URL + "/images/collapse-arrow.svg"} alt="icon" onClick={handleOperations} />
                        )} */}
                        <input 
                        type="radio" 
                        name="role"
                        value="operate"
                        checked={selectedOption === 'operate'}
                        onChange={handleOptionChange}
                        className="role-custom-radio"
                    />
                    </div>
                    {operations && (
                        <div className="detail-body"> 
                           {/* <div className="detailll">
                                <input type="radio"/>
                                <p className="detail-namee">API Logs</p>
                           </div> */}
                        </div>
                    )}
                </div>
                    
                    <div className="detail-info" onClick={handleCustomer}>
                        <div className={`detail-head- ${customer ? 'clicked' : ''}`}>
                            <h3 className="head-text">Customer Support</h3>
                            {/* {customer ? (
                                <img src={process.env.PUBLIC_URL + "/images/expand-arrow.svg"} alt="icon"/>
                            ) : (
                                <img src={process.env.PUBLIC_URL + "/images/collapse-arrow.svg"} alt="icon"/>
                            )} */}
                            <input 
                                type="radio" 
                                name="role"
                                value="customer"
                                checked={selectedOption === 'customer'}
                                onChange={handleOptionChange}
                                className="role-custom-radio"
                            />
                        </div>
                        {customer && (
                            <div className="detail-body"> 
                               
                            </div>
                        )}
                    </div>
                    <div className="detail-info" onClick={handleDeveloper}>
                        <div className={`detail-head- ${developer ? 'clicked' : ''}`}>
                            <h3 className="head-text">Developer</h3>
                            {/* {developer ? (
                                <img src={process.env.PUBLIC_URL + "/images/expand-arrow.svg"} alt="icon"/>
                            ) : (
                                <img src={process.env.PUBLIC_URL + "/images/collapse-arrow.svg"} alt="icon"/>
                            )} */}
                            <input 
                                type="radio" 
                                name="role"
                                value="dev"
                                checked={selectedOption === 'dev'}
                                onChange={handleOptionChange}
                                className="role-custom-radio"
                            />
                        </div>
                        {developer && (
                            <div className="detail-body"> 
                                
                            </div>
                        )}
                    </div>
                    {/* <div className="detail-info" onClick={handleCustom}>
                        <div className={`detail-head- ${custom ? 'clicked' : ''}`}>
                            <h3 className="head-text">Custom Faith</h3>
                            {custom ? (
                                <img src={process.env.PUBLIC_URL + "/images/expand-arrow.svg"} alt="icon"/>
                            ) : (
                                <img src={process.env.PUBLIC_URL + "/images/collapse-arrow.svg"} alt="icon"/>
                            )}
                        </div>
                        {custom && (
                            <div className="detail-body"> 
                               
                            </div>
                        )}
                    </div> */}
                </div>
                    <button onClick={handleCreateRole} className="btn" style={{width:"50%"}}>
                    Create role
                    </button>
        </div>
    </div>
    </div>
  );
}

export default ChangeRole;
