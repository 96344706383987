import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import TableHeaderSearch from '../common/TableHeaderSearch';
import '../common/tables.scss';
import Status from '../common/Status';
import { useNavigate } from 'react-router-dom';
import TableLoader from '../common/TableLoader';
import { toast } from 'react-toastify';
import useFilterAndPagination from '../hooks/useFilterAndPagination';
import CustomPagination from '../common/CustomPagination';
import { NumericFormat } from 'react-number-format';

function DirectDebitMandate({ directDebitData, isDirectDebitLoading, toggleLink, direct }) {
  const navigate = useNavigate();
  const [copiedText, setCopiedText] = useState('');
  const [selectedOption, setSelectedOption] = useState('All Mandates');
  const rowsPerPage = 10;


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(date);
  };

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    toast.success('Copied successfully');
  };

  const handleView = (transactionId, name) => {
    navigate(`/direct-debit-details/${transactionId}`, { state: { name } });
  };
  
  // const customStyles = {
  //   header: {
  //     style: {
  //       backgroundColor: '#f9fafb',
  //     },
  //   },
  //   headRow: {
  //     style: {
  //       borderTopStyle: 'solid',
  //       borderTopWidth: '1px',
  //       borderTopColor: '#f2f4f7',
  //       '&:hover': {
  //         backgroundColor: '#f9fafb',
  //       },
  //     },
  //   },
  //   headCells: {
  //     style: {
  //       paddingLeft: '24px',
  //       paddingRight: '24px',
  //       paddingTop: '12px',
  //       paddingBottom: '12px',
  //       backgroundColor: '#f9fafb',
  //     },
  //   },
    
  // };



  const customStyles = {
    header: {
      style: {
        backgroundColor: '#f9fafb',
      },
    },

    headRow: {
      style: {
        backgroundColor: '#f9fafb',
       
      },
    }
  
    
  };

  const columns = [
    {
      name: 'Initiated date',
      selector: row => formatDate(row.dated),
      sortable: true,
    },

    {
      name: 'Transaction ID',
      cell: row => (
        <div style={{ display: "flex", gap: "5px" }}>
          <input className='link_table' value={row.transactionId} hidden readOnly />
          <h1 className='td-text'>
            {row.transactionId.length > 7 ? `${String(row.transactionId).slice(0, 10)}...` : row.transactionId}
          </h1>
          <img
            src={process.env.PUBLIC_URL + "/images/copy-key-icon.svg"}
            alt="ic"
            onClick={() => handleCopyToClipboard(row.transactionId)}
            style={{ cursor: 'pointer' }}
          />
        </div>
      ),
      sortable: true,
    },

    {
      name: 'Name',
      selector:  row => row.name.length > 7 ? `${String(row.name).slice(0, 20)}...` : row.name,
      sortable: true,
    },

    {
      name: 'Amount (NGN)',
      cell: row => (
        <NumericFormat
          value={Number(row.amount / 100).toFixed(2)}
          displayType={'text'}
          thousandSeparator={true}
          // prefix={`${row.currency_settled} `}
          renderText={value => <p className='td-text'>{value}</p>}
        />
      ),
      sortable: true,
    },
    
    {
      name: 'Tenor',
      selector: row => row.tennor,
      sortable: true,
    },
    {
      name: 'Start date',
      selector: row => row.start_date,
      sortable: true,
    },
    {
      name: 'End date',
      selector: row => row.end_date,
      sortable: true,
    },
    
    {
      name: 'Status',
      cell: row => (
        <div className='payment-status'>
          {row.status === "active"  ? (
            <Status
            mgs="Active" bg="#ECFDF3" border="#ABEFC6" color="#067647"
            />
          )  :  <Status mgs={row.active} bg="#F9FAFB" border="#EAECF0" color="#344054" />}
          <img
                onClick={(e) => {
                  e.stopPropagation(); // Prevent row click event
                  handleView(row.transactionId, row.name);
                }}
                src={process.env.PUBLIC_URL + "/images/eye.svg"}
                alt='eye'
          />
        </div>
      ),
    },
  ];

  const filteredData = () => {
    switch (selectedOption) {
      case 'Active Mandates':
        return directDebitData.filter(item => item.active === 'active');
      case 'Expired Mandates':
        return directDebitData.filter(item => item.active == 'expired');
      case 'Pending Mandates':
        return directDebitData.filter(item => item.active == 'pending');
      case 'Deactivated Mandates':
        return directDebitData.filter(item => item.active == 'deactivated');
      default:
        return directDebitData;
    }
  };

  const emptyMessage = () => {
    switch (selectedOption) {
      case 'Active Mandates':
        return (
          <div className='empty_tbl'>
            <img src='/images/emptyPage.svg' alt='empty' />
            <h3 className='num_trans'>No Active Direct Debit!</h3>
          </div>
        );
      case 'Expired Mandates':
        return (
          <div className='empty_tbl'>
            <img src='/images/emptyPage.svg' alt='empty' />
            <h3 className='num_trans'>No Expired Direct Debit!</h3>
          </div>
        );
        case 'Pending Mandates':
          return (
            <div className='empty_tbl'>
              <img src='/images/emptyPage.svg' alt='empty' />
              <h3 className='num_trans'>No Pending Direct Debit!</h3>
            </div>
          );
        case 'Deactivated Mandates':
          return (
            <div className='empty_tbl'>
              <img src='/images/emptyPage.svg' alt='empty' />
              <h3 className='num_trans'>No Deactivated Direct Debit!</h3>
            </div>
          );
              
      default:
        return (
          <div className='empty_tbl'>
            <img src='/images/emptyPage.svg' alt='empty' />
            <h3 className='num_trans'>No Recent Direct Debit!</h3>
            <p className='num_desc'>You have no Direct Debit</p>
          </div>
        );
    }
  };
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const {
    currentPage,
    searchTerm,
    setSearchTerm,
    dateRange,
    handleDateChange,
    handlePageChange,
    paginatedRecords,
    filteredRecords,
    setIsSearchTermChanged
  } = useFilterAndPagination(filteredData(), rowsPerPage);



  return (
    <div className='balance-overview tbl_overflow'>
     
      {isDirectDebitLoading && <TableLoader />}
      <>
     
      <DataTable
        columns={columns}
        
        data={paginatedRecords}
        pagination ={false}
        highlightOnHover
        customStyles={customStyles}
        subHeader
        paginationTotalRows={filteredRecords.length}
        paginationDefaultPage={currentPage}
        paginationPerPage={rowsPerPage}
        onChangePage={handlePageChange}
        subHeaderComponent={
          <TableHeaderSearch
            title="All Mandates"
            placeholder="Search by name or ID..."
            directDebit
            btnFirst
            toggleLink={toggleLink}
            // handleOptionClick={handleOptionClick}
            onRowClicked={handleView}
            searchTerm={searchTerm}
            setSearchTerm={(term) => {
              setSearchTerm(term);
              setIsSearchTermChanged(true);
            }}
            dateRange={dateRange}
            handleDateChange={handleDateChange}
          />
        }
        noDataComponent={emptyMessage()}
      />
      <CustomPagination
        totalRows={filteredRecords.length}
        rowsPerPage={rowsPerPage}
        currentPage={currentPage}
        setCurrentPage={handlePageChange}
      />
      </>
     
     
    </div>
  );
}

export default DirectDebitMandate;