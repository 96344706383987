import React, { useState } from 'react'
import './Overview.scss'
import PaymentBalance from './PaymentBalance'
import Chart from './Chart'
import OverviewPaymentTable from './OverviewPaymentTable'
import { useNavigate } from 'react-router-dom'
import { changeCpage, nextPage, prePage } from '../utilities/pagination/pagination'
function OverViewPayment({data,balanceNav,balanceData,setGraphCurrency,setBalanceModal,collectionData,setApiWallet,getGraph,setSelectedValue,selectedValue,getCollection,setSelectedCurrency,selectedCurrency,isCollectionLoading,balanceDatas,toggleTransactionHistory,setSelectedRowData}) { 
  
  const [currentPage,setCurrentPage] = useState(1)

 
  const recordsPerPage = 20;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = collectionData?.slice(firstIndex,lastIndex);
  const nPage = Math.ceil(collectionData?.length/recordsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1);
  
  const navigate = useNavigate()
  const handleNavBalance = ()=>{
    navigate('/balance')
  }

  const handleSelectChange = (e) => {
    const selectedOption = e.target.value;
    setSelectedValue(e.target.value)
    setGraphCurrency(selectedOption);
    getGraph(e.target.value)

    
  };
  // yAxisTickFormatter =(tick) => `₦ ${tick.toLocaleString()}`
  const currency = selectedValue==="USD"? "$" : "₦" 
  const yAxisTickFormatter =(tick) => `${currency} ${tick.toLocaleString()}`
   return (
    <div className='overview-payment'>
     
      <div className='payment-balance-container'>
        <div className='payment-bal-content'>
          <h1 className='balance-title'>Your balances</h1>
          <span onClick={handleNavBalance}>See all balance <img style={{display:"inline"}} src='/images/arrowUpward.svg' alt='balance'/></span>
        </div>
        
        <div className='payment-balance-wrapper'>
            {
              balanceDatas.length>0 && balanceDatas.map((balance,index)=>(

                <PaymentBalance 
                  key={index}
                  flag={`/images/${balance.currency==="NGN"?"NigeriaFlag":balance.currency==="USD"?"UsFlag":balance.currency==="KES"?"kenyaFlag": balance.currency==="GBP"?"GBP": balance.currency==="CAD"?"CAD":balance.currency==="GHS"?"GHS":balance.currency}.svg`}
                  country={balance.currency==="NGN"? "Nigerian Naira":balance.currency==="USD"?"United States Dollar":balance.currency==="KES"? "Kenyan Shilling":balance.currency==="GHS"? "Ghanaian Cedi": balance.currency==="CAD"? "Canadian Dollar": balance.currency==="GBP"? "British pounds": balance.currency==="EUR"? "Euro": balance.currency}
                  symbole= {balance.currency==="NGN"? "₦" : balance.currency==="USD"?"$":balance.currency==="KES"? "K": balance.currency==="CAD"?"C$": balance.currency==="GBP"?"£": balance.currency==="EUR"?"€":balance.currency==="GHS"?"₵": balance.currency }
                  amount={Number(balance?.payout_balance)/100}
                  bgColor={balance.currency==="NGN"? "#FEF4F1":balance.currency==="USD"?"#F4FBFB":balance.currency==="KES"? "#FEF0F5":balance.currency==="GBP"? "#FEF0F5":balance.currency==="CAD"? "#FEF4F1":"#FEF4F1"}
                  balanceNav={balanceNav}
                  setBalanceModal={setBalanceModal}
                  balance = {balance}
                  setApiWallet={setApiWallet}
                  
                />
              ))
            }
            {
              balanceDatas.length <= 0 &&

                <>
                <PaymentBalance 
                  flag="/images/NigeriaFlag.svg"
                  country="Nigerian Naira"
                  symbole="&#8358;"
                  amount="0.00"
                  bgColor="#FEF4F1"
                  balanceNav={balanceNav}
                />
                <PaymentBalance 
                  flag="/images/UsFlag.svg"
                  country="United States Dollar"
                  symbole="&#36;"
                  amount="0.00"
                  bgColor="#F4FBFB"
                  balanceNav={balanceNav}
                />
              
                </>
            }
        </div>
      </div>
      <div className='overview-chart'>
       
          <div className='chart-heading'>
            <h1 className='chart-title'>Transactions over time</h1>
            <img src="/images/ellipsVertical.svg" alt='ellips' />
          </div>
          <div className='chart-select-currency'>
             
            {/* <img src={`/images/${selectedValue==="USD"?"USD":selectedValue==="NGN"?"NGN":selectedValue==="KES"?"KES": selectedValue==="GBP"?"GBP": selectedValue==="CAD"?"CAD": balanceData[0]?.currency}.svg`} alt='currency'/> */}
            <img src={`/images/${selectedValue === undefined ? "NGN" : selectedValue }.svg`} alt='icon' />
              <select value={selectedValue} className='sel_drop' onChange={handleSelectChange}>
                {
                  balanceData.length > 0 && balanceData.map((item)=>(
                  <option value={item.currency}>
                    {item.currency}
                  </option>
                  ))
                }
              </select>
          </div>
          
          <div className='chart'>

              <Chart 
              main 
              data={data}
              yAxisTickFormatter={yAxisTickFormatter}
              />
          </div>
       
      </div>
      <OverviewPaymentTable collectionData={collectionData}  records={records}  setSelectedCurrency={setSelectedCurrency} getCollection={getCollection} selectedCurrency={selectedCurrency} balanceData={balanceData} isCollectionLoading={isCollectionLoading} toggleTransactionHistory={toggleTransactionHistory} setSelectedRowData={setSelectedRowData}/>

      {/* <div className='pagination_container'>
          <div className='page-item' onClick={()=>prePage(currentPage,setCurrentPage)}>
            <img src='/images/pageLeft.svg' alt='' />
            <p className='Page_prev'>Previous</p>
          </div>   

          <div className='pageNumbers'>
            {
              numbers?.length > 0 && numbers.map((n,i)=>(
                <span key={i} 
                className={`page_num ${currentPage === n ? 'active':''}`}
                onClick={()=>changeCpage(setCurrentPage,n)}
                >
                  {n}
                </span>
              ))
            }

          </div> 

          <div className='page-item' onClick={()=>nextPage(currentPage,setCurrentPage,nPage)}>
            <img src='/images/pageRight.svg' alt='' />
            <p className='Page_prev'>Next</p>
          </div> 
      </div> */}
    </div>
  )
}

export default OverViewPayment
