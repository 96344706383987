import React, { useContext, useRef } from 'react'
import './Pages.scss'
import CustomerOverViewItem from '../payment/CustomerOverViewItem'
import Active from './Active'
import { useSelector } from 'react-redux';

import { copyToClipboard } from '../utilities/clipboardUtils'
import DataContext from '../context/DataContext';
function CustomerOverView({customer,account}) {
  const {currentUser} = useSelector((state)=>state?.user)

  const refLink = useRef(null);
  const {customerSummary} = useContext(DataContext)
  return (
    <div className='customer-overview'>
      
       {
        customer ?  <div className='link-bar-left'>
        <img src="/images/Avatar.svg" alt='avater' />
        <div className='link-right'>
            <h5 className='customer-profile-name'>{account?.fullName}</h5>
            <div className='link-right-copy'>
                <input ref={refLink} className='input_link_copy' type='text' value="64a85678gshs65678sh6s8678" />
                <img onClick={copyToClipboard.bind(null,refLink)} src='/images/copy.svg' alt="copy" />
            </div> 
        </div>
        </div>:
        <div className='customer-profile'>
            <p className='customer-profile-email'>{account?.email} </p>
           
        </div>
       }
    
        <div className='customer-line'></div>
        <CustomerOverViewItem 
        icon="/images/inflow.svg"
        desc="Total inflows"
        amount={customerSummary?.totalInflow || "N/A"}
        />
 
        <div className='customer-line'></div>
        <CustomerOverViewItem 
        icon="/images/outflow.svg"
        desc="Total outflows"
        amount={customerSummary?.totalOutflow || "N/A"}
        />

        <div className='customer-line'></div>
        {
          customer ?
          <CustomerOverViewItem 
            icon="/images/numTransaction.svg"
            desc="No of transactions"
            amount={customerSummary?.totalTransactions || "N/A"}
          />:
          <div className='customer-date'>
              <Active
              title="Active"
              />
              <p className='customer-date-text'>Jan 6, 2023, 02:45:23 AM</p>
          </div>
        }
    </div>
  )
}

export default CustomerOverView
