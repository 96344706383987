import React from 'react';
import '../common/Pages.scss';
import DetailsOverviewItem from './DetailsOverviewItem';
import Status from '../common/Status';

function DetailsOverview({ data, transactionId, name }) {
  // const substringName = name.substring(0, 2);
  // const substringName = name.split(" ")[0].slice(0,1);
  const nameParts = name.split(' ');
  const firstName = nameParts[0] || '';
  const lastName = nameParts[1] || '';

  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();


  return (
    <div className='customer-overview' style={{ marginBottom: "32px" }}>
      <DetailsOverviewItem
        name={`${name}`}
        email={`Trans ID: ${transactionId}`}
        initial={initials || "N/A"}
        profile
      />
      <div className='customer-line'></div>
      <div style={{ display: "flex", alignItems: "flex-start", gap: "32px" }}>
        <DetailsOverviewItem
          icon="/images/inflow.svg"
          desc="Total amount expected"
          amount={`NGN ${data.mandate_stat?.total_amount_expected || 0}`}
        />
        <Status
          mgs={`${data.mandate_stat?.total_expected_deductions || 0}`}
          bg="#ECFDF3"
          border="#ABEFC6"
          color="#067647"
        />
      </div>
      <div className='customer-line'></div>
      <div style={{ display: "flex", alignItems: "flex-start", gap: "32px" }}>
        <DetailsOverviewItem
          icon="/images/outflow.svg"
          desc="Outstanding balance"
          amount={`NGN ${data.mandate_stat?.total_amount_outstanding || 0}`}
        />
        <Status
          mgs={`${data.mandate_stat?.total_outstanding_deductions || 0}`}
          bg="#FEF3F2"
          border="#FECDCA"
          color="#B42318"
        />
      </div>
    </div>
  );
}

export default DetailsOverview;
