import React from 'react'
import Status from '../common/Status'

function CacReciept({identity}) {
  return (
    <div className='identityVerify'>
        <div className='verify-top'>
            <h3>{identity?.fullname || "Not Available"}</h3>
            <div style={{display:'flex',gap:"5px"}}>
            <p>Verification Status:</p>
            <Status 
            icon="/images/success.svg"
            mgs="Successful"
            />
            </div>
        </div>

        <div className='verify-content'>
            <table className='tbl'>
            <tr>
                <td className='verify-text-sm'>Company name</td>
                <td className='verify-text-sm left-mg'>RC number</td>
            </tr>
            <tr>
                <td className='verify-text-md'>{identity?.fullname || "Not Available"}</td>
                <td className='verify-text-md left-mg'>{identity?.rcNumber || "Not Available"}</td>
            </tr>
            <tr>
                <td className='verify-text-sm mg-top'>Date of registration</td>
                <td className='verify-text-sm left-mg mg-top'>Type of company</td>
            </tr>
            <tr>
                <td className='verify-text-md'>{identity?.registrationDate || "Not Available"}</td>
                <td className='verify-text-md left-mg'>{identity?.type || "Not Available"}</td>
            </tr>
            <tr>
                
                    
                    <td className='verify-text-sm mg-top'>Business</td>
                    <td className='verify-text-sm left-mg mg-top'>Status</td>
            </tr>
            <tr>
                <td className='verify-text-md'>{identity?.id || "Not Available" }</td>
                <td className='verify-text-md left-mg'>
                    <Status 
                     mgs="Active"
                    />
                </td>
            </tr>
            <tr>
                <td className='verify-text-sm mg-top'>Address</td>
               
            </tr>
            <tr>
                <td className='verify-text-md'>{identity?.address || "Not Available"}</td>
               
            </tr>
            </table>
        </div>
    </div>
  )
}

export default CacReciept