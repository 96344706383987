
import React,{useEffect, useRef, useState} from 'react'
import axios from 'axios';
import { useSelector } from "react-redux";
import CollectionModal from '../../components/payment/CollectionModal';
import NameControl from '../../components/common/NameControl';
import SettlementItem from '../../components/payment/SettlementItem';
import InFlowPayment from '../../components/payment/InFlowPayment';
import { copyToClipboard } from '../../components/utilities/clipboardUtils';

function TransactionInflow() {
    const [selectedCurrency,setSelectedCurrency] = useState("NGN");
    useEffect(()=>{
      getCollection();
      getSettlement(selectedCurrency);
      // eslint-disable-next-line 
    },[])
   
  
      const [btnActive,setBtnActive] = useState(false);
      const [selectedButton, setSelectedButton] = useState("Collections");
      const [modalCollections,setModelCollections] = useState(false)
     
      const {currentUser} = useSelector((state)=>state?.user);
      const [selectedRowData, setSelectedRowData] = useState(null);
     
      const [collectionData,setCollectionData] = useState([]);
      const [settlementData,setSettlementData] = useState([]);
      const [isCollectionLoading,setIsCollectionLoading] = useState(false)
      const [isSettlementLoading,setIsSettlementLoading] = useState(false)
     const linkRef = useRef(null);
      const toggleModal =()=>{
        setModelCollections(!modalCollections)
      }
      const handleButtonClick = (button) => {
          setSelectedButton(button);
      };
      
  
        //collection
        const getCollection = async (currency="NGN")=>{
          setIsCollectionLoading(true)
          try{
           
            const headers = {  
              "api_key": currentUser?.user?.merchantKey, 
              "sweetapi": currentUser?.user?.merchantCode  
            }
      
            const data = {
              category: "collection", //required  
              currency: currency, // required
              limit:200 // optional
            }
            
            const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transactions';
            let res = await axios.post(url,{...data},{
              headers:headers
            })
          
            const newValue = res?.data?.data
            setCollectionData(newValue);
         
            setIsCollectionLoading(false);
            // toast.success(res.data.message);
          }catch(err){
            setIsCollectionLoading(false);
            console.log(err);
            const errorMessage = err?.response?.data?.data?.message || "Server error"
          }
        }
  
        // settlement
        const getSettlement = async ()=>{
          setIsSettlementLoading(true)
          try{
            
      
            const headers = {  
              "api_key": currentUser?.user?.merchantKey, 
              "sweetapi": currentUser?.user?.merchantCode  
            }
            const data = {
              category: "settlement", //required  
              currency: "NGN", // required
              limit:200 // optional
            }
            const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transactions';
            let res = await axios.post(url,{...data},{
              headers:headers
            })
            
            const newValue = res?.data?.data
           
            setSettlementData(newValue)
            setIsSettlementLoading(false);
            // toast.success(res.data.message);
          }catch(err){
            setIsSettlementLoading(false);
            console.log(err);
            // const errorMessage = err?.response?.data?.data?.message || "Server error"
            
          }
        }
  
      
  
  
     
  
      
  
    return (
      
                  <div className='main-inner'>
                    {
                      modalCollections && <CollectionModal data={selectedRowData} toggleModal={toggleModal}/>
                    }
                    
                      <NameControl 
                      btn
                      name="Collections"
                      setSelectedCurrency={setSelectedCurrency}
                      selectedCurrency={selectedCurrency}
                      desc="list of collections"
                      btnFirst="Collections"
                      paymentCurrency
                      getCollection={getCollection}
                      /> 
  
                      {
                        selectedButton === "Settlement" &&
                           <div className='settlement-summary-container'>
                              <div className='settlement-summary'>
                                <SettlementItem 
                                icon="/images/settlementTotal.svg"
                                title="Total collected"
                                amount="0"
                                transNum="0"
                                bgColor="#F4FBFB"
                                />
                                
                                <SettlementItem 
                                icon="/images/settlementPending.svg"
                                title="Pending settlements"
                                amount="0"
                                transNum="0"
                                bgColor="#FEF0F5"
                                />
                                
                              </div>
  
                              <div className='settlement-right'>
                                <div className='settlement-wrapper'>
                                   <img src='/images/referLink.svg' alt='ellips' />
                                   {/* <h1 className='ellips-title'>Refer a Company and Earn</h1>
                                   <p className='ellips-desc'>Refer a company to use Flick verification APIs and earn 400 free api calls when they make their first API call.</p> */}
  
                                    {/* eslint-disable-next-line */}
                                   {/* <a href='#' className='ellips-refer'>Click to refer</a> */}
                                   <p className='refer-title'>Copy the Link Below</p>
                                   <input 
                                   ref={linkRef} 
                                   type='text' 
                                   className='refer-link-input' 
                                   placeholder='6GSexbTSV73v&BS&BD#VDVdvd...' 
                                   value="6GSexbTSV73v&BS&BD#VDVdvd..." 
                                   />
                                   <img style={{display:"inline"}} onClick={copyToClipboard.bind(null,linkRef)} src="/images/copyRefer.svg" alt="refer" />
                                </div>
                              </div>
                           </div>
                      }   
                      
                      <InFlowPayment setSelectedRowData={setSelectedRowData} collectionData = {collectionData} toggleModal={toggleModal} isCollectionLoading={isCollectionLoading}/>
                  </div>
  
    )
}

export default TransactionInflow