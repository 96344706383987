import React, { useEffect, useState } from 'react'
import TableHeaderSearch from '../common/TableHeaderSearch'
import Status from '../common/Status'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux';
import { format } from 'timeago.js'
function Transaction() {
  const location = useLocation()
  const transData = location?.state.item || {}
  const [transactionData,setTransactionData] = useState()
  const {currentUser} = useSelector((state)=>state?.user)
 
  useEffect(()=>{
    getTransaction();
    // eslint-disable-next-line
  },[])

  const getTransaction = async ()=>{

    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        "sweetapi": currentUser.user.merchantCode  
      }
      const dated = transData?.dated
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-single-transaction';
      let res = await axios.post(url,{dated},{
        headers:headers
      })

      const data = res?.data?.data
      
     
      setTransactionData(data);
      // setBalanceData(newValue || []);
       
    }catch(err){
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
     
    }
  }
  return (
    <div className='balance-overview'>
     
      <table className='tbl'>
        <tr>
            <td colSpan="7" className='tbl-heading'>
                <TableHeaderSearch 
                title="All transactions"
                placeholder="Search by name or date..."
                />     
            </td>
        </tr>
        <tr>
          <th className='th-text'>Timestamp</th>
          <th className='th-text'>Amount</th>
          <th className='th-text'>Channel</th>
          <th className='th-text'>Transaction Details</th>
          <th className='th-text'>Status</th>
        </tr>
        {
           Array.isArray(transactionData) && transactionData.length > 0 && transactionData.map((item,index)=>(
              <tr>
                  <td className='td-text'>{format(item?.datedStamp)}</td> 
                  <td className='td-text'>{Number(item?.Amount)/100|| "N/A"}</td>
                  <td className='td-text'> <p className='link_status'>{transData?.collectedVia || "N/A"}</p></td>
                
                  <td className='td-text narration_area' >{item?.Narration || "N/A"}</td>
                  <td>
                    {item?.status || "N/A"}
                  </td>
              
              </tr>
           ))
        }
      </table>
    </div>
  )
}

export default Transaction
