import React, { useRef } from 'react'
import DetailTextDisput from './DetailTextDisput';

function PayoutDisputeModal({setIsPayoutModal}) {
    const ref = useRef();
    return (
      <div className="overlay" >
      <div className="enter-amount-modal">
        <div className="modal-wrapper">
          <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}> 
            <p className='disput_title'>Payout Dispute</p>
            <img style={{cursor:"pointer"}} className='verify-close' onClick={setIsPayoutModal.bind(this,false)} src={process.env.PUBLIC_URL + "/images/x-close.svg"} alt="icon" />
          </div>
          <p className='disput_desc'>Your transaction has been initiated, check your balance</p>
  
          <div className='line_dispute'>
            <p className='col_descs'>Transaction amount</p>
            <h4 className='col_titles'>$4,000.00</h4>
          </div>
          
          <div className='otcform_container'>
              <div className='desc_container'>
                  <DetailTextDisput desc1="Customer" value1="Mide Ajibade" desc2="Date created" value2="2 hours ago"/>
                  <DetailTextDisput desc1="Customer" value1="Mide Ajibade" desc2="Session ID"   status="Resolved" leftCopy/>
                  
                  <DetailTextDisput desc1="Messages" value1="Please refund the money. Thank you so much guys!" /> 
              </div>
          </div>
           
         
  
        </div>
      </div>
      </div>
    )
}

export default PayoutDisputeModal