import React from 'react'
import { copyToClipboard } from '../utilities/clipboardUtils'
import Status from '../common/Status'

function DetailTextCopy({desc1,value1,desc2,value2,ref,current}) {
   
  return (
    <div className='detail_container'>
        <div className='detail_item'>
            <p className='detail_desc '>{desc1}</p>
            <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                {
                    current ? 
                    <>
                        <p className='detail_desc left_align'>{desc2}</p>
                        <img src='/images/otc/infoIcon.svg' alt=''/>
                        </>:
                    <>
                    
                        <input ref={ref} value={value1} className='detail_desc copyInput' style={{color:"#151F32",fontWeight:"500"}}/>
                        <img 
                        onClick={ copyToClipboard.bind(null,ref)} 
                        style={{display:"inline"}} 
                        src='/images/copy.svg' 
                        alt='payment' 
                        />
                    </>
                }
            </div>
            
        </div>

        <div className='detail_item left'>
            <p className='detail_desc left_align'>{desc2}</p>
            {
                current ?
                <div style={{display:"flex",alignItems:"end"}}>
                    <Status mgs="current" align="auto"/>
                </div>:
                <p className='detail_value left_align'>{value2}</p>
            }
        </div>
    </div>
  )
}

export default DetailTextCopy