import React from "react";
import '../../modals/Modal.scss'

function RoleUpdated({setIsRoleUpdated}) {

    // const handleClick = ()=>{
    // }

  return (
    <div className="overlay">
    <div className="update-modal">
      <div className="update-modal-wrapper">
            <div className="role-update">
                <div className="member-rolee">
                    <p className="member-update">Member role update </p>
                    <p className="update-msg">Your role as been updated </p>
                </div>
                <div>
                    <img style={{cursor:"pointer"}} onClick={() => setIsRoleUpdated(false)} src={process.env.PUBLIC_URL + "/images/x-closee.svg"} alt="icon" />
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}

export default RoleUpdated;
