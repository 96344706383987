import React, { useEffect, useRef, useState } from "react";



import NameControl from "../components/common/NameControl";
import "../components/payment/payment.scss";
import SettlementTable from "../components/payment/SettlementTable";
import PayoutOverviewModal from "../components/payment/PayoutOverviewModal";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import BankAccountModal from "../components/balance/modal/BankAccountModal";
import BeneficiaryValue from "../components/balance/modal/BeneficiaryValue";
import PortFolioConfirm from "../components/balance/modal/PortFolioConfirm";

import PortfolioSuccess from "../components/balance/modal/PortfolioSuccess";
import PayoutModal from "../components/otc/PayoutModal";
import PayoutBeneficiaryAdd from "../components/otc/PayoutBeneficiaryAdd";
import BankDetailModal from "../components/otc/BankDetailModal";
import ConfirmBankDetailModal from "../components/otc/ConfirmBankDetailModal";
import PayoutSumbited from "../components/otc/PayoutSumbited";
import PayoutBenSumbited from "../components/otc/PayoutBenSumbited";



function PaymentSettlement() {
  useEffect(() => {
    getBalance();
    getCollection();
    getBanks();
    getBeneficiary();
    // eslint-disable-next-line
  }, []);


  useEffect(()=>{
    loadCountries();
 // eslint-disable-next-line
  },[])
  
  const templateRef = useRef(null);
  const [selectedCurrency,setSelectedCurrency] = useState("NGN");
  const [balanceData,setBalanceData] = useState([])
  const [isPayouts, setIsPayouts] = useState(false);
  const [isPayoutReceipt,setIsPayoutReceipt] = useState(false)
  const { currentUser } = useSelector((state) => state?.user);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [payoutData, setPayoutData] = useState([]);
  const [isBeneficiary, setIsBeneficiary] = useState(false);
  const [isConfirmPortfolio, setIsConfirmPortfolio] = useState(false);
  const [isPortfolioSuccess, setIsPortfolioSuccess] = useState(false);
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  const [isTransferPortfolioAmount, setIsTransferPortfolioAmount] = useState(false);
  const [banks,setBank] = useState([])
  
  const [payoutId, setPayoutId] = useState('');
  const [payoutToken,setToken] = useState('');
  const [payoutResult, setPayoutResult] = useState({})
  const [isPayoutLoading,setIsPayoutLoading] = useState(false);




  // Payout states
  const [isPayout,setIsPayout] = useState(false)
  const [isBankDetial,setIsBankDetail] = useState(false)
  const [isConfirmBankDetial,setIsConfirmBankDetial] = useState(false)
  const [isPayoutSubmitted,setIsPayoutSubmited] = useState(false)
  const [isPayoutBenSubmitted,setIsPayoutBenSubmited] = useState(false)
  const [selectedBeneficiary, setSelectedBeneficiary] = useState({}); 
  const [beneficiaryData,setBeneficiaryData] = useState([]);
  const [isBenAdd,setIsBenAdd] = useState(false);
  const [isAddBeneficiary,setIsAddBeneficiary] = useState(false)
  const [isGetBeneficiary,setIsGetBeneficiary] = useState(false)
  const [countries, setCountries] = useState([]);
  const [isFxPayoutLoading,setIsFxPayoutLoading] = useState(false)
  const [fxAmount,setFxAmount] = useState('')

  const excludedCountries = ['Cuba', 'Iran', 'North Korea', 'Syria', 'Russia', 'Belarus', 'Ukraine','United Kingdom','United States','China'];
  const [beneficiaryInput, setBeneficiaryInput] = useState({
    account_no:"",
    routing:"",
    beneficiary_name:"",
    beneficiary_address_1:"",
    beneficiary_city:"",
    beneficiary_state:"",
    beneficiary_country:"United States",
    beneficiary_postal_code:"",
    bank_name:"Wells Fargo",
    bank_address_1:"",
    bank_address_2:"",
    bank_city:"",
    bank_state:"",
    bank_country:"United States",
    bank_postal_code:"",
    transfer_type:"",
    iban:"",
    swift:""
  })

  const [payoutInput,setPayoutInput] = useState({
    wireType:"",
    accountNumber:"",
    routingNumber:"",
    beneficiaryAccName:"",
    address1:"",
    address2:"",
    city:"",
    state:"",
    country:"",
    postalCode:"",
    currency:""
});
const [bankDetailInput,setBankDetailInput] = useState({
  bankName:"",
  address1:"",
  address2:"",
  city:"",
  state:"",
  country:"",
  postalCode:""
});

const [walletBeneficiaryInput,setWalletBeneficiaryInput] = useState({
  wireType:"",
  accountNumber:"",
  routingNumber:"",
  beneficiaryAccName:"",
  address1:"",
  address2:"",
  city:"",
  state:"",
  country:"",
  postalCode:""
});
const [transferBankInput, setTransferBankInput] = useState({
  balance:"NGN",
  amount:"",
  currency: "NGN",
  bank_code: "",
  narration: "",
  account_number: "",
  beneficiary_name:"",
});
  

// Payout functions

const loadCountries = () => {
  axios.get('https://api.countrystatecity.in/v1/countries', {
      headers: { 'X-CSCAPI-KEY': 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==' }
  })
  .then(response => {
      const filteredCountries = response.data.filter(country => 
          !excludedCountries.includes(country.name)
      );
      setCountries(filteredCountries);
  })
  .catch(error => console.error('Error loading countries:', error));
};


// 

const getBeneficiary = async () => {
  setIsGetBeneficiary(true);
  try {
    const headers = {  
      // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
      api_key: currentUser?.user?.merchantKey,
    }
      
    const url = "https://flick-app.com/merchant/get-beneficiary";
  
    let res = await axios.get(url, { headers });
    // console.log("beneficiarys: ",res.data.data)
    setBeneficiaryData(res.data.data);
    setIsGetBeneficiary(false);
  } catch (err) {
    setIsGetBeneficiary(false);
    console.log(err);
    const errorMessage = err?.response?.data?.message || "Network error";
    // toast.error(errorMessage);
  } finally {
    
    setIsGetBeneficiary(false);
  }
};

const addBeneficiary = async () => {
  setIsAddBeneficiary(true);
  
  try {
    const headers = {  
      // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
      api_key: currentUser?.user?.merchantKey,
    }

    const data = {
      account_no:beneficiaryInput.account_no,
      routing:beneficiaryInput.routing,
      beneficiary_name:beneficiaryInput.beneficiary_name,
      beneficiary_address_1:beneficiaryInput.beneficiary_address_1,
      beneficiary_city:beneficiaryInput.beneficiary_city,
      beneficiary_state:beneficiaryInput.beneficiary_state,
      beneficiary_country:beneficiaryInput.beneficiary_country,
      beneficiary_postal_code:beneficiaryInput.beneficiary_postal_code,
      bank_name:beneficiaryInput.bank_name,
      bank_address_1:beneficiaryInput.bank_address_1,
      bank_address_2:beneficiaryInput.bank_address_2,
      bank_city:beneficiaryInput.bank_city,
      bank_state:beneficiaryInput.bank_state,
      bank_country:beneficiaryInput.bank_country,
      bank_postal_code:beneficiaryInput.bank_postal_code,
      transfer_type:beneficiaryInput.transfer_type

    }

    const url = "https://flick-app.com/merchant/save-beneficiary"

    
    let res = await axios.post(url,{...data},{
      headers:headers
    })

    
    getBeneficiary();
    // setIsAddBeneficiary(false);
    // setIsPayoutBenSubmited(true);

  } catch (err) {
    setIsAddBeneficiary(false);
    console.log(err);
    const errorMessage = err?.response?.data?.message ;
    // toast.error(errorMessage,{ position: "top-left", pauseOnHover: true});
  } finally {
    setIsAddBeneficiary(false);
  }
};

const fxPayout = async () => {
  setIsFxPayoutLoading(true);
  try {
    const headers = {  
      // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
      api_key: currentUser?.user?.merchantKey,
    }
    // console.log("selected ben: ",selectedBeneficiary);

    const data = {
      from_currency: transferBankInput.currency,
      benficiary_id: selectedBeneficiary.beneficiary_id,
      amount: fxAmount
    }

   

    // console.log("input data: ",data)
    const url = "https://flick-app.com/merchant/complete-payout"
    
    let res = await axios.post(url,{...data},{
      headers:headers
    })
    // console.log("response: ",res.data)
    setIsFxPayoutLoading(false)
    setIsPayoutSubmited(true);
  } catch (err) {
    setIsFxPayoutLoading(false);
    console.log(err)
    const errorMessage = err.response?.data?.message || "An error has occured"   
    toast.error(errorMessage,{position: "top-left", pauseOnHover: true})
  } finally {
    setIsFxPayoutLoading(false);
  }
};


// ========== End of Payout =============

  
  const getBalance = async ()=>{
      
    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        "sweetapi": currentUser.user.merchantCode  
      }
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/balances';
      let res = await axios.get(url,{
        headers:headers
      })
      const newValue = res.data.data
     
      setBalanceData(newValue || []);
       
    }catch(err){
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
    }
  }

  const toggleModal = () => {
    setIsPayoutReceipt(!isPayoutReceipt)
    // setIsPayouts(!isPayouts);
  };
  
  const toggleTransfer = () => {
    // setIsOpenPopup(!isOpenPopup);
    setIsPayout(!isPayout)
  };
 
  const getCollection = async (currency="NGN") => {
    setIsPayoutLoading(true);
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      const data = {
        category: "payout", //required
        currency: currency, // required
        limit: 200, // optional
      };

      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transactions";
      let res = await axios.post(
        url,
        { ...data },
        {
          headers: headers,
        }
      );
      const newValue = res?.data?.data;
     
      setPayoutData(newValue);
      setIsPayoutLoading(false);
      // toast.success(res.data.message);
    } catch (err) {
      setIsPayoutLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error";
      toast.error(errorMessage);
    }
  };


  const getBanks = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };


      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/banks";
      let res = await axios.get(url,{ headers: headers});
      
      setBank(res?.data?.data)
      
    } catch (err) {
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error";
    }
  };


 

  return (
    <div className="main-inner">
      {console.log("data row: ",selectedRowData)}
      <NameControl 
      btn 
      name="Outflow" 
      desc="Manage all your settlements here" 
      selectedCurrency={selectedCurrency}
      getCollection={getCollection}
      setSelectedCurrency={setSelectedCurrency}
      paymentCurrency
      balanceData={balanceData}
      />
      <SettlementTable
        payoutData={payoutData}
        setSelectedCurrency={setSelectedCurrency}
        setIsPayout={setIsPayout}
        toggleTransfer={toggleTransfer}
        setSelectedRowData={setSelectedRowData}
        isPayoutLoading={isPayoutLoading}
        setIsPayoutReceipt={setIsPayoutReceipt}
      />
      {isPayoutReceipt && (
        <PayoutOverviewModal toggleModal={toggleModal} data={selectedRowData} templateRef={templateRef} />
      )}

      
      {isOpenPopup && (
        <BankAccountModal
          setIsOpenPopup={setIsOpenPopup}
          transferBankInput={transferBankInput}
          setTransferBankInput={setTransferBankInput}
          setIsBeneficiary={setIsBeneficiary}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsPortfolioSuccess={setIsPortfolioSuccess}
          balanceData={balanceData}
          banks={banks}
          payoutId={payoutId}
          setPayoutId={setPayoutId}
          setPayoutResult={setPayoutResult}
        />
      )}
      {  isPayout && 
        <PayoutModal 
            setIsPayout={setIsPayout} 
            payoutInput={payoutInput} 
            setPayoutInput={setPayoutInput} 
            setIsBankDetail={setIsBankDetail}
            transferBankInput={transferBankInput}
            setTransferBankInput={setTransferBankInput}
            setIsBeneficiary={setIsBeneficiary}
            setIsConfirmPortfolio={setIsConfirmPortfolio}
            setIsPortfolioSuccess={setIsPortfolioSuccess}
            balanceData={balanceData}
            banks={banks}
            setPayoutId={setPayoutId}
            setPayoutResult={setPayoutResult}
            setSelectedBeneficiary={setSelectedBeneficiary}
            setIsConfirmBankDetial={setIsConfirmBankDetial}
            setIsBenAdd={setIsBenAdd}
            addBeneficiary={addBeneficiary}
            beneficiaryData={beneficiaryData}
            countries={countries}
            setFxAmount ={setFxAmount}
            
        />
      }

      {  isBenAdd && <PayoutBeneficiaryAdd  setIsBenAdd={setIsBenAdd} setIsPayout={setIsPayout} bankDetailInput={bankDetailInput} setBankDetailInput={setBankDetailInput} setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial}  beneficiaryInput={beneficiaryInput} setBeneficiaryInput={setBeneficiaryInput} countries={countries} transferBankInput={transferBankInput} setIsPayoutBenSubmited={setIsPayoutBenSubmited} getBeneficiary={getBeneficiary}/>}
      {  isBankDetial && <BankDetailModal setIsPayout={setIsPayout} bankDetailInput={bankDetailInput} setBankDetailInput={setBankDetailInput} setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsBenAdd={setIsBenAdd} setBeneficiaryInput={setBeneficiaryInput} addBeneficiary={addBeneficiary} isAddBeneficiary={isAddBeneficiary} beneficiaryInput={beneficiaryInput} countries={countries} setIsPayoutBenSubmited={setIsPayoutBenSubmited}/>}
      {  isConfirmBankDetial && <ConfirmBankDetailModal setIsPayout={setIsPayout}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutSubmited={setIsPayoutSubmited} selectedBeneficiary={selectedBeneficiary} fxPayout={fxPayout} isFxPayoutLoading={isFxPayoutLoading} transferBankInput={transferBankInput}/>}
      {  isPayoutSubmitted && <PayoutSumbited setIsPayout={setIsPayout}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutSubmited={setIsPayoutSubmited} />}
      {  isPayoutBenSubmitted && <PayoutBenSumbited setIsPayout={setIsPayout} setIsBenAdd={setIsBenAdd}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutBenSubmited={setIsPayoutBenSubmited} />}




      {isBeneficiary && (
        <BeneficiaryValue
          setIsBeneficiary={setIsBeneficiary}
          setIsTransferPortfolioAmont={setIsTransferPortfolioAmount}
          setIsOpenPopup={setIsOpenPopup}
        />
        )
      }
      {isConfirmPortfolio && (
        <PortFolioConfirm
          setIsBeneficiary={setIsBeneficiary}
          setIsPortfolioSuccess={setIsPortfolioSuccess}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsOpenPopup={setIsOpenPopup}
          setToken={setToken}
          payoutId={payoutId}
          payoutToken={payoutToken}
          transferBankInput={transferBankInput}
          payoutResult ={payoutResult}
        />
      )}
      {isPortfolioSuccess && (
        <PortfolioSuccess
          setIsTransactionSuccess={setIsTransactionSuccess}
          setIsPortfolioSuccess={setIsPortfolioSuccess}
          setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
          setIsConfirmPortfolio={setIsConfirmPortfolio}
          setIsOpenPopup={setIsOpenPopup}
          transferBankInput={transferBankInput}
          payoutResult ={payoutResult}
        />
      )}

     
    </div>
    // {isNewTransaction <NewTransferModal setNewTransaction={setNewTransaction}  />}
  );
}

export default PaymentSettlement;
