import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import OtcOverview from '../../components/otc/OtcOverview';
import ConvertModal from '../../components/otc/ConvertModal';
import ConfirmConvertModal from '../../components/otc/ConfirmConvertModal';
import ConvertSuccess from '../../components/otc/ConvertSuccess';
import OrderOtcModal from '../../components/otc/OrderOtcModal';
import OrderSubmitedModal from '../../components/otc/OrderSubmitedModal';
import ClientResponseModal from '../../components/otc/ClientResponseModal';
import AcceptModal from '../../components/otc/AcceptModal';
import AcceptSuccessModal from '../../components/otc/AcceptSuccessModal';
import PayoutModal from '../../components/otc/PayoutModal';
import BankDetailModal from '../../components/otc/BankDetailModal';
import ConfirmBankDetailModal from '../../components/otc/ConfirmBankDetailModal';
import PayoutSumbited from '../../components/otc/PayoutSumbited';
import FundWalletOtc from '../../components/otc/FundWalletOtc';
import WalletBeneficiaryModal from '../../components/otc/WalletBeneficiaryModal';
import WalletBankDetail from '../../components/otc/WalletBankDetail';
import WalletConfirmDetail from '../../components/otc/WalletConfirmDetail';
import WalletSubmitted from '../../components/otc/WalletSubmitted';
import DailRejected from '../../components/otc/DailRejected';
import PortFolioConfirm from '../../components/balance/modal/PortFolioConfirm';
import PortfolioSuccess from '../../components/balance/modal/PortfolioSuccess';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PayoutBeneficiaryAdd from '../../components/otc/PayoutBeneficiaryAdd';
import PayoutBenSumbited from '../../components/otc/PayoutBenSumbited';
import FundNGNBalanceModal from '../../components/otc/FundNGNBalanceModal';
import FundUSDBalanceModal from '../../components/otc/FundUSDBalanceModal';


function OtcDashboardContent() {
    useEffect(() => {
        getBalance();
        getBanks();
        getBeneficiary();
        getPermanentNuban();
        getCollection();
        getBalances();
     
        // eslint-disable-next-line
      }, []);
    
      useEffect(()=>{
        loadCountries();
     // eslint-disable-next-line
      },[])
    
    
        const {currentUser} = useSelector((state)=>state?.user)
        const [balanceData,setBalanceData] = useState([])
        const [balanceDatas,setBalanceDatas] = useState([])
     
        const [collectionData,setCollectionData] = useState([]);
    
    
        const [isConvert,setIsConvert] = useState(false)
        const [isConfirmConvert,setConfirmConvert] = useState(false)
        const [isConvertSuccess,setIsConvertSuccess] = useState(false)
        const [isOrder,setIsOrder] = useState(false)
        const [isOrderSubmited,setIsOrderSubmited] = useState(false)
        const [isClientResponse,setIsClientResponse] = useState(false)
        const [isAccept,setIsAccept] = useState(false)
        const [isAcceptSuccess,setIsAcceptSuccess] = useState(false)
        const [isPayout,setIsPayout] = useState(false)
        const [isBankDetial,setIsBankDetail] = useState(false)
        const [isConfirmBankDetial,setIsConfirmBankDetial] = useState(false)
        const [isPayoutSubmitted,setIsPayoutSubmited] = useState(false)
        const [isFundBalance,setIsFundBalance] = useState(false)
        const [isWalletBeneficiary,setIsWalletBeneficiary] = useState(false)
        const [isWalletBankDetail,setIsWalletBankDetail] = useState(false)
        const [isWalletConfirmDetail,setIsWalletConfirmDetail] = useState(false)
        const [isWalletSubmitted,setIsWalletSubmitted] = useState(false)
        const [isRejected,setIsRejected] = useState(false)
        const [isBenAdd,setIsBenAdd] = useState(false);
    
        const [isOpenPopup, setIsOpenPopup] = useState(false);
        const [payoutData, setPayoutData] = useState([]);
        const [isBeneficiary, setIsBeneficiary] = useState(false);
        const [isConfirmPortfolio, setIsConfirmPortfolio] = useState(false);
        const [isPortfolioSuccess, setIsPortfolioSuccess] = useState(false);
        const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
        const [isTransferPortfolioAmount, setIsTransferPortfolioAmount] = useState(false);
        const [banks,setBank] = useState([])
    
        // Nuban
        const [isVirtualNuban,setIsVertualNuban] = useState(false);
        const [nubanData,setNubanData] = useState([]);
        const [selectedCurrency,setSelectedCurrency] = useState("NGN");
    
        const [rateMsg,setRateMsg] = useState('')
        const [isRateLoading, setIsRateLoading] = useState(false);
        const [initiatData,setInitiateData] = useState({});
        const [rateInitial,setRateInitial] = useState({})
        const [convertInput,setConvertInput] = useState({
            from_currency:"NGN",
            from_currency_amount:"",
            to_currency:"USD",
            to_currency_amount:""
        });
        const [orderInput,setOrderInput] = useState({
            from:"NGN",
            to:"USD",
            amount:"",
        });
    
        const [acceptInput,setAcceptInput] = useState({
            email:"",
            phone:"",
        });
    
        const [payoutInput,setPayoutInput] = useState({
            wireType:"",
            accountNumber:"",
            routingNumber:"",
            beneficiaryAccName:"",
            address1:"",
            address2:"",
            city:"",
            state:"",
            country:"",
            postalCode:"",
            currency:""
        });
        const [bankDetailInput,setBankDetailInput] = useState({
            bankName:"",
            address1:"",
            address2:"",
            city:"",
            state:"",
            country:"",
            postalCode:""
        });
        
        const [walletBeneficiaryInput,setWalletBeneficiaryInput] = useState({
            wireType:"",
            accountNumber:"",
            routingNumber:"",
            beneficiaryAccName:"",
            address1:"",
            address2:"",
            city:"",
            state:"",
            country:"",
            postalCode:""
        });
        const [walletBankDetailInput,setWalletBankDetailInput] = useState({
            bankName:"",
            address1:"",
            address2:"",
            city:"",
            state:"",
            country:"",
            postalCode:""
        });
    
        
        const [transferBankInput, setTransferBankInput] = useState({
          balance:"NGN",
          amount:"",
          currency: "NGN",
          bank_code: "",
          narration: "",
          account_number: "",
          beneficiary_name:"",
        });
    
        const [payoutId, setPayoutId] = useState('');
        const [payoutToken,setToken] = useState('');
        const [payoutResult, setPayoutResult] = useState({})
        const [isPayoutLoading,setIsPayoutLoading] = useState(false);
        const [isGetBeneficiary,setIsGetBeneficiary] = useState(false)
        const [isAddBeneficiary,setIsAddBeneficiary] = useState(false)
        const [isPayoutRateLoading,setIsPayoutRateLoading] = useState(false)
        const [isFxPayoutLoading,setIsFxPayoutLoading] = useState(false)
        const [isPayoutBenSubmitted,setIsPayoutBenSubmited] = useState(false)
        const [fxAmount,setFxAmount] = useState('')
        
        const [payoutRate,setPayoutRate] = useState(null);
        const [selectedBeneficiary, setSelectedBeneficiary] = useState({}); 
        const [beneficiaryData,setBeneficiaryData] = useState([]);
        const [countries, setCountries] = useState([]);
        const [rateInfo, setRateInfo] = useState(null);
        const [orderOtcSuccess, setOrderOtcSuccess] = useState(null);
        const [isOtcCollectionLoading, setIsOtcCollectionLoading] = useState(false);
    
       
        
        
        const excludedCountries = ['Cuba', 'Iran', 'North Korea', 'Syria', 'Russia', 'Belarus', 'Ukraine','United Kingdom','United States','China'];
        const [beneficiaryInput, setBeneficiaryInput] = useState({
          account_no:"",
          routing:"",
          beneficiary_name:"",
          beneficiary_address_1:"",
          beneficiary_city:"",
          beneficiary_state:"",
          beneficiary_country:"United States",
          beneficiary_postal_code:"",
          bank_name:"Wells Fargo",
          bank_address_1:"",
          bank_address_2:"",
          bank_city:"",
          bank_state:"",
          bank_country:"United States",
          bank_postal_code:"",
          transfer_type:"",
          iban:"",
          swift:""
        })
        const [selectedFxCurrency,setSelectedFxCurrency] = useState('NGN')
    
        useEffect(() => {
          getExchange(convertInput.from_currency, convertInput.to_currency);
          // eslint-disable-next-line
        }, []);
    
        
    
        const toggleModal = () => {
          setIsPayout(!isPayout);
        };
        
        const toggleTransfer = () => {
          setIsOpenPopup(!isOpenPopup);
        };
    
        const toggleConvert = ()=>{
            setIsConvert(!isConvert)
        }
        const toggleOrder = ()=>{
            setIsOrder(!isOrder)
        }
        const togglePayout = ()=>{
            setIsPayout(!isPayout)
        }
    
        const loadCountries = () => {
          axios.get('https://api.countrystatecity.in/v1/countries', {
              headers: { 'X-CSCAPI-KEY': 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==' }
          })
          .then(response => {
              const filteredCountries = response.data.filter(country => 
                  !excludedCountries.includes(country.name)
              );
              setCountries(filteredCountries);
          })
          .catch(error => console.error('Error loading countries:', error));
      };
    
    
        const getBalance = async ()=>{
            try{
                const headers = {  
                "api_key": currentUser.user.merchantKey, 
                "sweetapi": currentUser.user.merchantCode  
                }
                const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/balances?IsDash=1';
                let res = await axios.get(url,{
                headers:headers
                })
    
                
                const newValue = res.data.data
                setBalanceDatas(newValue || []);
                
            }catch(err){
                console.log(err)
                const errorMessage = err?.message || "unable to fetch data"
                
            }
        }

        const getBalances = async ()=>{
          try{
              const headers = {  
              "api_key": currentUser.user.merchantKey, 
              "sweetapi": currentUser.user.merchantCode  
              }
  
              const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/balances';
  
              let res = await axios.get(url,{
              headers:headers
              });
  
              const newValue = res.data.data
              setBalanceData(newValue || []);
              
              
          }catch(err){
              console.log(err)
              const errorMessage = err?.message || "unable to fetch data"
              
          }
      }
    
        
    
        
        const getCollection = async (currency="NGN")=>{
            setIsOtcCollectionLoading(true)
            try{
             
              const headers = {  
                "api_key": currentUser?.user?.merchantKey, 
                "sweetapi": currentUser?.user?.merchantCode  
              }
        
              const data = {
                // category: "collection", //required  
                category: "all", //required  
                currency: currency, // required
                limit:200 // optional
              }
              
              const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transactions';
              let res = await axios.post(url,{...data},{
                headers:headers
              })
            
              const newValue = res?.data?.data
             
              setCollectionData(newValue);
              setIsOtcCollectionLoading(false);
           
              // toast.success(res.data.message);
            }catch(err){
              console.log(err);
              setIsOtcCollectionLoading(false);
              const errorMessage = err?.response?.data?.data?.message || "Server error"
            }
        }
    
       
        const getBanks = async () => {
          try {
            const headers = {
              api_key: currentUser?.user?.merchantKey,
              sweetapi: currentUser?.user?.merchantCode,
            };
      
      
            const url =
              "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/banks";
            let res = await axios.get(url,{ headers: headers});
            
            setBank(res?.data?.data)
            
          } catch (err) {
            console.log(err);
            const errorMessage = err?.response?.data?.data?.message || "Server error";
          }
        };
        
      
       
    
        const getExchange = async (fromCurrency, toCurrency) => {
          setIsRateLoading(true);
          try {
            const headers = {  
              // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
              api_key: currentUser?.user?.merchantKey,
            }
              
            const url = `https://flick-app.com/otc-dash/exchange_rate?from_currency=${fromCurrency}&to_currency=${toCurrency}`;
            // console.log("url :",url)
            let res = await axios.get(url, { headers });
            console.log(res.data?.data)
            setRateInitial(res.data.data)
            setRateInfo(res.data?.data);
            setRateMsg(res.data.data?.rate);
          } catch (err) {
            console.log(err);
            const errorMessage = err?.response?.data?.data?.message || err?.response?.data?.message || "error has occured" 
            // toast.error(errorMessage,{ position: "top-left", pauseOnHover: true})
          } finally {
            setIsRateLoading(false);
          }
        };
      
    
      
    
        const getBeneficiary = async () => {
          setIsGetBeneficiary(true);
          try {
            const headers = {  
              // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
              api_key: currentUser?.user?.merchantKey,
            }
              
            const url = "https://flick-app.com/merchant/get-beneficiary";
          
            let res = await axios.get(url, { headers });
            // console.log("beneficiarys: ",res.data.data)
            setBeneficiaryData(res.data.data);
            setIsGetBeneficiary(false);
          } catch (err) {
            setIsGetBeneficiary(false);
            console.log(err);
            const errorMessage = err?.response?.data?.message || "Network error";
            // toast.error(errorMessage);
          } finally {
            
            setIsGetBeneficiary(false);
          }
        };
    
    
        const addBeneficiary = async () => {
          setIsAddBeneficiary(true);
          try {
            const headers = {  
              // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
              api_key: currentUser?.user?.merchantKey,
            }
    
            const data = {
              account_no:beneficiaryInput.account_no,
              routing:beneficiaryInput.routing,
              beneficiary_name:beneficiaryInput.beneficiary_name,
              beneficiary_address_1:beneficiaryInput.beneficiary_address_1,
              beneficiary_city:beneficiaryInput.beneficiary_city,
              beneficiary_state:beneficiaryInput.beneficiary_state,
              beneficiary_country:beneficiaryInput.beneficiary_country,
              beneficiary_postal_code:beneficiaryInput.beneficiary_postal_code,
              bank_name:beneficiaryInput.bank_name,
              bank_address_1:beneficiaryInput.bank_address_1,
              bank_address_2:beneficiaryInput.bank_address_2,
              bank_city:beneficiaryInput.bank_city,
              bank_state:beneficiaryInput.bank_state,
              bank_country:beneficiaryInput.bank_country,
              bank_postal_code:beneficiaryInput.bank_postal_code
            }
    
            
            // console.log("allData",data);
              
            const url = "https://flick-app.com/merchant/save-beneficiary"
            // console.log("url :",url)
    
            
            let res = await axios.post(url,{...data},{
              headers:headers
            })
    
            // console.log("response: ",res.data)
            
            // toast.success(res.data?.message);
            getBeneficiary();
            // setIsAddBeneficiary(false);
            setIsPayoutBenSubmited(true);
    
            // setIsAddBeneficiary(false);
          } catch (err) {
            setIsAddBeneficiary(false);
            console.log(err);
            const errorMessage = err?.response?.data?.message ;
            // toast.error(errorMessage,{ position: "top-left", pauseOnHover: true});
          } finally {
            setIsAddBeneficiary(false);
          }
        };
    
    
      
    
        const fxPayout = async () => {
          setIsFxPayoutLoading(true);
          try {
            const headers = {  
              // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
              api_key: currentUser?.user?.merchantKey,
            }
            // console.log("selected ben: ",selectedBeneficiary);
    
            const data = {
              from_currency: transferBankInput.currency,
              benficiary_id: selectedBeneficiary.beneficiary_id,
              amount: fxAmount
            }
    
           
    
            // console.log("input data: ",data)
            const url = "https://flick-app.com/merchant/complete-payout"
            
            let res = await axios.post(url,{...data},{
              headers:headers
            })
            // console.log("response: ",res.data)
            setIsFxPayoutLoading(false)
            setIsPayoutSubmited(true);
          } catch (err) {
            setIsFxPayoutLoading(false);
            console.log(err)
            const errorMessage = err.response?.data?.message || "An error has occured"   
            toast.error(errorMessage,{position: "top-left", pauseOnHover: true})
          } finally {
            setIsFxPayoutLoading(false);
          }
        };
    
    
    
        // Nuban functions
    
        const getPermanentNuban = async ()=>{
       
      
          const id = uuidv4(); 
          try{
            const headers = {  
              Authorization:`Bearer ${currentUser?.user?.merchantKey}`
              }
             
              const payLoad = { 
                  transactionId: id,
                  nubanType: "business"
              }
             
            // Make an API call to fetch accountName using bankCode and accountNumber.
              const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-create-merchant"
              let res = await axios.post(url,payLoad,{
                headers:headers
              });
              // console.log("nuban Data: ",res.data);
              setNubanData(res.data.data);
           
          }catch(err){
            console.log(err)
          }
          
        }
        
       const displayFxFundModal = ()=>{
        switch(selectedFxCurrency){
          case 'NGN':
            return  <FundNGNBalanceModal nubanData={nubanData} setIsVertualNuban={setIsVertualNuban}/> 
          case 'USD':
            return <FundUSDBalanceModal setIsVertualNuban={setIsVertualNuban}/>
    
          default:
            return null;
        }
       }
    
        
        
       
          
      return (
        <div className='main-inner'>
    
         <OtcOverview 
            collectionData={collectionData}
            balanceData={balanceData}
            balanceDatas={balanceDatas}
            toggleConvert={toggleConvert}
            toggleOrder={toggleOrder}
            togglePayout = {togglePayout}
            setIsFundBalance = {setIsFundBalance}
            otc
            getExchange={getExchange}
            setRateMsg={setRateMsg}
            setIsVertualNuban={setIsVertualNuban}
            selectedFxCurrency={selectedFxCurrency}
            setSelectedFxCurrency={setSelectedFxCurrency}
            selectedCurrency={selectedCurrency}
            getCollection={getCollection}
            setSelectedCurrency={setSelectedCurrency}
            isOtcCollectionLoading={isOtcCollectionLoading}
            setIsPayoutBenSubmited={setIsPayoutBenSubmited}
          />
    
          {isConvert && <ConvertModal  
          setIsPayout={setIsConvert} 
          convertInput={convertInput} 
          setConvertInput={setConvertInput} 
          setConfirmConvert={setConfirmConvert} 
          rateMsg={rateMsg}
          setRateMsg={setRateMsg}
          setInitiateData={setInitiateData}
          isRateLoading={isRateLoading}
          setIsRateLoading={setIsRateLoading}
          getExchange={getExchange}
          countries={countries}
          rateInfo={rateInfo}
          rateInitial={rateInitial}
          balanceData={balanceData}
          />
          }
    
          {isConfirmConvert && <ConfirmConvertModal  setConfirmConvert={setConfirmConvert} setIsConvertSuccess = {setIsConvertSuccess}  setIsPayout={setIsConvert} initiatData={initiatData} getBalance={getBalance} convertInput={convertInput}/>  }
          {isConvertSuccess && <ConvertSuccess setConfirmConvert={setConfirmConvert} setIsConvertSuccess = {setIsConvertSuccess}  setIsPayout={setIsConvert} initiatData={initiatData}/>}
    
          { isOrder && <OrderOtcModal setIsOrder={setIsOrder} setOrderInput={setOrderInput} orderInput={orderInput} setIsOrderSubmited={setIsOrderSubmited} setOrderOtcSuccess={setOrderOtcSuccess} balanceData={balanceData}/>}
          { isOrderSubmited && <OrderSubmitedModal setIsOrderSubmited={setIsOrderSubmited} setIsOrder={setIsOrder} setIsClientResponse={setIsClientResponse} orderInput={orderInput} orderOtcSuccess={orderOtcSuccess}/>}
          {  isClientResponse && <ClientResponseModal setIsOrderSubmited={setIsOrderSubmited} setIsOrder={setIsOrder} setIsClientResponse={setIsClientResponse} setIsAccept={setIsAccept} setIsFundBalance={setIsFundBalance} setIsRejected={setIsRejected} />}
          {  isAccept && <AcceptModal setIsAccept={setIsAccept} acceptInput={acceptInput} setAcceptInput={setAcceptInput} setIsAcceptSuccess={setIsAcceptSuccess} setIsClientResponse={setIsClientResponse} setIsOrderSubmited={setIsOrderSubmited} setIsOrder={setIsOrder}/>}
          {  isAcceptSuccess && 
            <AcceptSuccessModal 
              setIsAccept={setIsAccept} 
              acceptInput={acceptInput} 
              setAcceptInput={setAcceptInput} 
              setIsAcceptSuccess={setIsAcceptSuccess} 
              setIsClientResponse={setIsClientResponse} 
              setIsOrderSubmited={setIsOrderSubmited} 
              setIsOrder={setIsOrder}
            />
          }
          {  isPayout && 
            <PayoutModal 
                setIsPayout={setIsPayout} 
                payoutInput={payoutInput} 
                setPayoutInput={setPayoutInput} 
                setIsBankDetail={setIsBankDetail}
                transferBankInput={transferBankInput}
                setTransferBankInput={setTransferBankInput}
                setIsBeneficiary={setIsBeneficiary}
                setIsConfirmPortfolio={setIsConfirmPortfolio}
                setIsPortfolioSuccess={setIsPortfolioSuccess}
                balanceData={balanceData}
                banks={banks}
                setPayoutId={setPayoutId}
                setPayoutResult={setPayoutResult}
                setSelectedBeneficiary={setSelectedBeneficiary}
                setIsConfirmBankDetial={setIsConfirmBankDetial}
                setIsBenAdd={setIsBenAdd}
                addBeneficiary={addBeneficiary}
                beneficiaryData={beneficiaryData}
                countries={countries}
                setFxAmount ={setFxAmount}
                
            />
          }
          
          {  isBenAdd && <PayoutBeneficiaryAdd  setIsBenAdd={setIsBenAdd} setIsPayout={setIsPayout} bankDetailInput={bankDetailInput} setBankDetailInput={setBankDetailInput} setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial}  beneficiaryInput={beneficiaryInput} setBeneficiaryInput={setBeneficiaryInput} countries={countries} getBeneficiary={getBeneficiary} transferBankInput={transferBankInput} setIsPayoutBenSubmited={setIsPayoutBenSubmited}/>}
          {  isBankDetial && <BankDetailModal setIsPayout={setIsPayout} bankDetailInput={bankDetailInput} setBankDetailInput={setBankDetailInput} setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsBenAdd={setIsBenAdd} setBeneficiaryInput={setBeneficiaryInput} addBeneficiary={addBeneficiary} isAddBeneficiary={isAddBeneficiary} beneficiaryInput={beneficiaryInput} countries={countries} setIsPayoutBenSubmited={setIsPayoutBenSubmited}/>}
          {  isConfirmBankDetial && <ConfirmBankDetailModal setIsPayout={setIsPayout}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutSubmited={setIsPayoutSubmited} selectedBeneficiary={selectedBeneficiary} fxPayout={fxPayout} isFxPayoutLoading={isFxPayoutLoading} transferBankInput={transferBankInput}/>}
          {  isPayoutSubmitted && <PayoutSumbited setIsPayout={setIsPayout}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutSubmited={setIsPayoutSubmited} />}
          {  isPayoutBenSubmitted && <PayoutBenSumbited setIsPayout={setIsPayout} setIsBenAdd={setIsBenAdd}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutBenSubmited={setIsPayoutBenSubmited} />}
          {  isFundBalance && <FundWalletOtc setIsFundBalance={setIsFundBalance} setIsWalletBeneficiary={setIsWalletBeneficiary} setIsClientResponse={setIsClientResponse} setIsOrderSubmited={setIsOrderSubmited} setIsOrder={setIsOrder} />}
          {  isWalletBeneficiary && 
            <WalletBeneficiaryModal 
            setIsWalletBeneficiary={setIsWalletBeneficiary} 
            walletBeneficiaryInput={walletBeneficiaryInput} 
            setWalletBeneficiaryInput={setWalletBeneficiaryInput} 
            setIsWalletBankDetail={setIsWalletBankDetail} 
            setIsFundBalance={setIsFundBalance} 
            setIsClientResponse={setIsClientResponse} 
            setIsOrderSubmited={setIsOrderSubmited} 
            setIsOrder={setIsOrder}
           
            />
          }
          {isWalletBankDetail && <WalletBankDetail setIsFundBalance={setIsFundBalance} setIsWalletBeneficiary={setIsWalletBeneficiary} walletBeneficiaryInput={walletBeneficiaryInput} setWalletBeneficiaryInput={setWalletBeneficiaryInput} setIsWalletBankDetail={setIsWalletBankDetail} setWalletBankDetailInput={setWalletBankDetailInput} setIsWalletConfirmDetail={setIsWalletConfirmDetail} />}
          {isWalletConfirmDetail && <WalletConfirmDetail setIsFundBalance={setIsFundBalance} setIsWalletBeneficiary={setIsWalletBeneficiary}   setIsWalletConfirmDetail={setIsWalletConfirmDetail} setIsWalletSubmitted={setIsWalletSubmitted} />}
          {isWalletSubmitted && <WalletSubmitted setIsFundBalance={setIsFundBalance} setIsWalletBeneficiary={setIsWalletBeneficiary}   setIsWalletConfirmDetail={setIsWalletConfirmDetail} setIsWalletSubmitted={setIsWalletSubmitted} setIsRejected={setIsRejected} />}
          {isRejected && <DailRejected setIsFundBalance={setIsFundBalance} setIsWalletBeneficiary={setIsWalletBeneficiary}   setIsWalletConfirmDetail={setIsWalletConfirmDetail} setIsWalletSubmitted={setIsWalletSubmitted} setIsRejected={setIsRejected} />}
          {isConfirmPortfolio && (
            <PortFolioConfirm
              setIsBeneficiary={setIsBeneficiary}
              setIsPortfolioSuccess={setIsPortfolioSuccess}
              setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
              setIsConfirmPortfolio={setIsConfirmPortfolio}
              setIsTransactionSuccess={setIsTransactionSuccess}
              setIsOpenPopup={setIsOpenPopup}
              setToken={setToken}
              payoutId={payoutId}
              payoutToken={payoutToken}
              transferBankInput={transferBankInput}
              payoutResult ={payoutResult}
            />
          )}
          {isPortfolioSuccess && (
            <PortfolioSuccess
              setIsTransactionSuccess={setIsTransactionSuccess}
              setIsPortfolioSuccess={setIsPortfolioSuccess}
              setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
              setIsConfirmPortfolio={setIsConfirmPortfolio}
              setIsOpenPopup={setIsOpenPopup}
              transferBankInput={transferBankInput}
              payoutResult = {payoutResult}
            />
          )}
          {
            isVirtualNuban &&
           displayFxFundModal()
          }
    
        </div>
      )
}

export default OtcDashboardContent