import React from 'react'
import NameControl from '../components/common/NameControl'
import Transaction from '../components/data/Transaction'
import { useLocation } from 'react-router-dom'
import TransactionOverview from '../components/common/TransactionOverview'

function DataTransaction() {
  const location = useLocation();
  const transData = location?.state?.item || {}
  return (
    
    <div className='main-inner'>
      {console.log("transData: ",transData)}
        <NameControl 
        backbtnPath="/data-transactions"
        customer
        data
        btntext="View customer profile"
        trans
        />
        <TransactionOverview 
        transData={transData}
        name={transData?.customer || "N/A"}
        inflow={`${transData?.currency} ${transData?.totalCredit || "N/A"}`}
        outflow={`${transData?.currency} ${transData?.totalDebit || "N/A"}`}
        />
        <Transaction />
    </div>
       
  )
}

export default DataTransaction
