import React,{useEffect, useState} from 'react'
import NameControl from '../components/common/NameControl'
import '../components/payment/payment.scss'
import { useSelector } from "react-redux";
import DirectDebitMandate from '../components/payment/DirectDebitMandate';
import DirectDebitTransaction from '../components/payment/DirectDebitTransaction';
import axios from 'axios';
import CreateDirectDebitForm from '../components/payment/CreateDirectDebitForm';
import { toast } from 'react-toastify';
import DirectDebitCreated from '../components/payment/DirectDebitCreated';
import { useNavigate } from 'react-router-dom';

function DirectDebitPage() {
  useEffect(()=>{
    getDirectDebitList(); 
    getDirectDebitTransactions(); 
    // getDirectDebitDetails();
    // eslint-disable-next-line 
  },[])
    const navigate = useNavigate();
    const [btnActive,setBtnActive] = useState(false); 
    const [selectedButton, setSelectedButton] = useState("Mandate");
    const [directDebitData, setDirectDebitData] = useState([]);
    const [transactionsData, setTransactionsData] = useState([]);
    const [isDirectDebitLink,setIsDirectDebitLink] = useState(false) 
    const [isDirectDebitCreated,setIsDirectDebitCreated] = useState(false) 
    const {currentUser} = useSelector((state)=>state?.user);
    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };

    const [directLinkInput,setDirectLinkInput] = useState({
      productType: "",
      transactionId: "",
      startDate: "",
      endDate: "",
      changeDate: "",
      email: "",
      phoneNumber: "",
      currency: "NGN",
      amount: "",
      frequency: "",
      redirectLink: "",

    })
    const [isDirectDebitLoading,setIsDirectDebitLoading] = useState(false);
    const [isTransactionLoading,setIsTransactionLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const toggleLink =()=>{
      navigate('/payment-recurring', { state: { recurring: true } });
      
    }
    
    const renderContent = () => {
    if(selectedButton === 'Mandate') return  <DirectDebitMandate directDebitData={directDebitData} isDirectDebitLoading={isDirectDebitLoading} toggleLink={toggleLink} />
    
    if(selectedButton ==='Transaction') return <DirectDebitTransaction transactionsData={transactionsData} isTransactionLoading={isTransactionLoading}/>
   
    };

    // const getDesc = () => {
    //   if (selectedButton === 'One Time') {
    //     return "One Time transactions appear here";
    //   }
    //   if (selectedButton === 'Recurring') {
    //     return "Recurring transactions appear here";
    //   }
    // };

    const getDirectDebitList = async ()=>{
        setIsDirectDebitLoading(true);
      try{
        
        const headers = {  
          "api_key": currentUser.user.merchantKey, 
          // "api_key": 'pk-U2FsdGVkX1+G0GIBy/v01n8QXOIpa8UY0TowXc0uOLCbVZcSo6qDBKUKoKjXljThxyNpswIBPzBonqGqqMk5YqXq4rGvoTCiQeJH1mv324Or+tmuTPej23p42JWdxmWS', 
        }

        const url = 'https://flick-app.com/direct-debit/list';
        let res = await axios.get(url,{
          headers:headers
        })
  
        // console.log (res)
        setDirectDebitData(res.data?.data || [])
        setIsDirectDebitLoading(false)
      }catch(err){
        setIsDirectDebitLoading(false);
        console.log(err)
        const errorMessage = err?.message || "unable to fetch data"
      }
    }

    const getDirectDebitTransactions = async ()=>{
      setIsTransactionLoading(true);
    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        // "api_key": 'pk-U2FsdGVkX1+G0GIBy/v01n8QXOIpa8UY0TowXc0uOLCbVZcSo6qDBKUKoKjXljThxyNpswIBPzBonqGqqMk5YqXq4rGvoTCiQeJH1mv324Or+tmuTPej23p42JWdxmWS', 
      }

      const url = 'https://flick-app.com/direct-debit/mandate-transactions';
      let res = await axios.get(url,{
        headers:headers
      })

      
      setTransactionsData(res.data?.data || [])
      setIsTransactionLoading(false)
    }catch(err){
      setIsTransactionLoading(false);
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
    }
  }

  const createDirectDebitLink = async ()=>{

    try{
      setIsLoading(true);

      const headers = {  
        "api_key": currentUser?.user?.merchantKey, 
        "sweetapi": currentUser?.user?.merchantCode  
      }
      const data = {
        ...directLinkInput,
        amount: `${Number(directLinkInput?.amount) * 100}`,
        currency:directLinkInput?.currency
      }
     
      
      const url = '';
      let res = await axios.post(url,{...data},{
        headers:headers
      })
     
      getDirectDebitList();
      setIsLoading(false);
      setDirectLinkInput(false);
      // toast.success(res.data.message);
      setIsDirectDebitLink(false);
      setIsDirectDebitCreated(true)
      
    }catch(err){
      setIsLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.message || "Server error"
      toast.error(errorMessage,{position: "top-left", pauseOnHover: true});

    }
   
  }

  return (
    
          <div className='main-inner'>
            
              <NameControl 
              name="Direct Debit"
              desc="Manage all your direct debit here"
              btnFirst="Mandate"
              btnSecond="Transaction"
              btnActive={btnActive}
              handleButtonClick ={handleButtonClick}
              numUsers={selectedButton === 'Mandate' ? directDebitData?.length : null}
              users={selectedButton === 'Mandate' && directDebitData?.length <= 0 ? " mandate" : " mandates"}
              direct={selectedButton === 'Mandate'}
              />    
              
              {renderContent()}
              {isDirectDebitLink && <CreateDirectDebitForm directLinkInput={directLinkInput} setDirectLinkInput={setDirectLinkInput} setIsDirectDebitLink={setIsDirectDebitLink} isLoading={isLoading} createDirectDebitLink={createDirectDebitLink} setIsDirectDebitCreated={setIsDirectDebitCreated}/>}
              {isDirectDebitCreated && <DirectDebitCreated setIsDirectDebitCreated={setIsDirectDebitCreated} setIsDirectDebitLink={setIsDirectDebitLink} />}
          </div>

  )
}

export default DirectDebitPage
