import React, {useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

import '../../modals/Modal.scss'
import "../Settings.scss";
import { toast } from 'react-toastify';
import axios from 'axios';
import { useSelector,useDispatch } from 'react-redux';
import { loginSuccess, logout } from '../../../redux/userSlice';
import { DotLoader } from 'react-spinners';
function GenerateApiKeyModal({isOpen,onClose,setLiveKeys,loginData}) {
  const {currentUser} = useSelector((state)=>state?.user)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading,setIsLoading] = useState(false);

  useEffect(() => {
    loginOut();
    // eslint-disable-next-line
  }, [isOpen]);

  const loginOut =  ()=>{
   
    setIsLoading(true)
    // setIsAgreement(false)
    dispatch(logout())
    onClose()
    const dataObj = {
      ...loginData,
      secretKey:"",
      publicEncKey:""
    }
    const liveKeys = {
      livePublicEncKey: loginData?.publicEncKey,
      liveSecretKey:loginData?.secretKey,
      livePublicKey:loginData?.user?.merchantKey
    }
    dispatch(loginSuccess(dataObj));
    console.log("login Data modal: ",liveKeys)
    setLiveKeys(liveKeys)
    setIsLoading(false)
  }
  return (
    <div className="overlay"> 
    
        <div className="modal-title">
          {
            isLoading &&
            <DotLoader color="#259792" /> 
          }
          <p className="enter-amount">Generating Api Key</p>
        </div>

    </div>
  )
}

export default GenerateApiKeyModal