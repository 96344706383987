import React from 'react'

function PortfolioSuccess({setIsPortfolioSuccess,setIsConfirmPortfolio,transferBankInput,setIsOpenPopup,payoutResult,setIsPayout}) {
    const handleCancel = ()=>{
        setIsPayout(false)
        // setIsOpenPopup(false);
        setIsPortfolioSuccess(false)
        setIsConfirmPortfolio(false)
        
    }
  return (
<div className='balance_modal' >
       <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>

        <div style={{marginTop:"16px"}}>
        <img src={process.env.PUBLIC_URL + "/images/iconSuccess.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          <p className="enter-amount">Transaction Successful</p>
          
          <p className="enter-an-amount">Your transaction has been initiated, check your balance</p>
        </div>

        <div className="transact-amount">
          <p className="t-amount">Transaction amount</p>
          <p className="amount">{`₦${transferBankInput?.amount}`}</p>
        </div>

        <div className="account-details">
            <div className="number-name">
                <div className="number">
                    <p className="title">Recipient</p>
                    <p className="sub-title">{transferBankInput?.beneficiary_name}</p>
                </div>

                <div className="transact-name">
                    <p className="title">Recipient bank</p>
                    <p className="sub-title"> {`${payoutResult?.bank_name || ''}  -  ${transferBankInput?.account_number}`}</p>
                </div>
            </div>

            <div className="number-name">
                <div className="number">
                    <p className="title">Charges</p>
                    <p className="sub-title">0.00</p>
                </div>

                <div className="transact-name">
                    <p className="title">Reference</p>
                    <div className='copy_input_container'>
                      <input className='input_copy' type='text' value={payoutResult?.Id}/>
                      <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" />
                    </div>
                </div>
            </div>

        </div>

        <div className="portfolio-btn">
          <button onClick={handleCancel} className="sec-btn">
            Cancel
          </button>
          <button className="btn" >
            Download Receipt
          </button>
        </div>
        </div>
    </div>
    </div>
  )
}

export default PortfolioSuccess


