import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';


const CustomTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <p>{`${label} : $${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

function Chart({main,data,yAxisTickFormatter}) {
  const longestValue = Math.max(...data.map(entry => entry.Total.toString().length));
    // Calculate the width of the longest value
    const allZeros = data.every(entry => entry.Total === 0);
  const longestValueWidth = longestValue*8; // Assuming an average width of each character
 
  return (
 
        <ResponsiveContainer width="100%">
         
        <AreaChart
          data={data}
          margin={{ top: 10,
            right: 30,
            left: longestValueWidth,
            bottom: 10,
          }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
          </defs>
          {main && !allZeros && (
          <YAxis axisLine={{ stroke: 'white' }} tickFormatter={yAxisTickFormatter} />
        )}
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid horizontal={true} vertical={false} strokeDasharray="3 3" className="chartGrid" />
          <Tooltip/>
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#2EBDB6"
            fillOpacity={0.06}
            fill="#2EBDB6"
            overflow="visible"
          />
        </AreaChart>
      </ResponsiveContainer>
        
  )
}

export default Chart
