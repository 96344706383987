import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { useSelector } from 'react-redux';
import { ToastContainer,toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import AsideView from "../components/login/AsideView";
import "../components/signup/SignUp.scss";
import { apiPost } from "../components/utilities/api/axios";
import axios from "axios";

function SignUpPage() {
  const initialState = {
    name: '',
    business_email: '',
    business_name: '',
    phone: '',
    password: '',
    confirmPassword: '',
    website: 'getflick.app',
    referral_code: '12345',
    consent:false
  };
  const navigate = useNavigate();
  const {currentUser} = useSelector((state)=>state?.user)
  const [country, setCountry] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("+234");
  const [business_type, setBusiness] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [formData,setFormData] = useState(initialState)
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setShowConPassword] = useState(false);
  const [isInputClicked, setIsInputClicked] = useState({
    name: false,
    businessName: false,
    password: false,
    confirmPassword: false,
  });
  const [countries, setCountries] = useState([]);


  useEffect(()=>{
    if(currentUser?.user) navigate("/getstarted");
  })
  useEffect(()=>{
    loadCountries();
  },[])
 
 

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleConfirmPassword = () => {
    setShowConPassword(!showConPassword);
  };

  const handleSelectBusiness = (e) => {
    setBusiness(e.target.value);
  };
  const handleSelectCountry = (e) => {
    setCountry(e.target.value);
  };
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const  handleInputChange = (event)=>{
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };
  const handleSelectCurrency = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const flagImage = () => {
    switch (selectedCurrency) {
      case '+234':
        return <img src={`/images/flag.svg`} alt='icon' />;
      case '+256':
        return <img src={`/images/uganda.svg`} alt='icon' />;
      case '+254':
        return <img src={`/images/kenya.svg`} alt='icon' />;
      case '+233':
        return <img src={`/images/ghana.svg`} alt='icon' />;
      default:
        return null;
    }
  };
   

  const handleValidation = () => {
    const { password, confirmPassword, name, business_email,business_name,phone,website} = formData;
   
    if ( confirmPassword=== "") {
      toast.error(
        " confirmPassword must not be empty.",
      );
      return false;
    } else if(password === ""){
      toast.error(
        "Password and confirmPassword should be same.",
      );
      return false;
    }else if (name.length <= 3) {
      toast.error(
        "name must be above 3 characters.",
      );
      return false;
    } else if (password.length < 8) {
      toast.error(
        "Password must be above 7 characters.",
      );
      return false;
    } else if (business_email.length < 10) {
      toast.error("business_email must be above 10 character");
      return false;
    }else if(business_name.length < 5){
      toast.error("business_name must be above 5 character");
      return false;
    }else if(phone.length < 10){
      toast.error("phone must be greater than 10.");
      return false;
    }
    return true;

  };
  const handleSubmit = async (e)=>{

    e.preventDefault();
    
    try{
      if(handleValidation()){
        setIsLoading(true);
        // const result = {...formData,country,business_type,}
        // const data = {...result}
        const data = {
          name: formData.name.trim(),
          business_email: formData.business_email.trim(),
          business_name: formData.business_name.trim(),
          phone: formData.phone.trim(),
          password: formData.password.trim(),
          confirmPassword: formData.confirmPassword.trim(),
          website: formData.website.trim(),
          referral_code: formData.referral_code.trim(),
          country:country.trim(),
          business_type:business_type.trim(),
          consent:isChecked
        }
        
        localStorage.setItem("otpPassword", data.password);

        const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/signup-v2'
        const res = await apiPost(url,data,{},true,{})
       
        localStorage.setItem("action", res.data.action);
        localStorage.setItem("otpGenerator", res.data.otpGenerator);
        setIsLoading(false);
        // toast.success("Successfully signUp: Please check your email to verify your Account");
        setFormData(initialState);
        navigate("/sign-up-successful");

        // To track the sign-up with Brevo
        window.sendinblue.track('Sign Up', {
          email: formData.business_email,
          name: formData.business_name,
        });
      }
    }catch(err){
      setIsLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.msg || "failed to register"
      toast.error(errorMessage || "Failed to register")
      console.log(err)
    }
  }



  const loadCountries = () => {
    axios.get('https://api.countrystatecity.in/v1/countries', {
      headers: { 'X-CSCAPI-KEY': 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==' }
    })
    .then(response => {
      // console.log(response.data)
      setCountries(response.data);
    })
    .catch(error => console.error('Error loading countries:', error));
  };

  return (
    <div className="dashboard-container sign_up_moble">
      <div className="aside-container">
        <AsideView />
      </div>
      <div className="dashboard-main signUp_main_mobile">
        <div className="sign-up-wrapper">
          <p className="sign-up-title">Create your Flick dashboard</p>
          <form onSubmit={handleSubmit} className="sign-up-form">
            <div className="sign-up-inner">
              <div className="double-input-container">
                <div className="input_container" onBlur={() => handleInputBlur('name')}>
                  <div className={`input-group ${isInputClicked.name ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('name')} >
                    <input type="text" name="name" placeholder="Mide Ajibade" className="input-name"  onChange={handleInputChange} required/>
                  </div>
                  <p className={`input-label ${isInputClicked.name ? 'clicked' : ''}`}>Name</p>
                </div>
                <div className="input_container" onBlur={() => handleInputBlur('businessName')}>
                  <div className={`input-group ${isInputClicked.businessName ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('businessName')} onBlur={() => handleInputBlur('businessName')}>
                    <input  onChange={handleInputChange} type="text" name="business_name" placeholder="Innovex" className="input-name" required/>
                  </div>
                  <p className={`input-label ${isInputClicked.businessName ? 'clicked' : ''}`}>Business Name</p>
                </div>
              </div>
              <div className="double-input-container">
                <div className="select-group">
                  <select
                    className="input-select-field"
                    value={business_type}
                    onChange={handleSelectBusiness}
                    required
                  >
                    <option value="" disabled selected hidden> Business Type</option>

                    <option value="Agriculture">Agriculture</option>
                    <option value="E-Commerce">E-Commerce</option>
                    <option value="Education">Education</option>
                    <option value="Energy">Energy</option>
                    <option value="Fintech">Fintech</option>
                    <option value="Food & Entertainment">Food & Entertainment</option>
                    <option value="Healthcare">Healthcare</option>
                    <option value="Logistics">Logistics</option>
                    <option value="Real Estate">Real Estate</option>
                    <option value="Retail & Manufacturing">Retail & Manufacturing</option>
                    <option value="Venture Capital">Venture Capital</option>
                    {/* <option value="Portfolio Company">Portfolio Company</option> */}
                    <option value="Other">Other</option>
                  </select>
                </div>
                
                <div className="select-group">
                  <select
                    className="input-select-field"
                    value={country}
                    onChange={handleSelectCountry}
                    required
                  >
                    <option value="USD">Country</option>
                    {countries.map(country => (
                      <option key={country.iso2} value={country.name}>{country.name}</option>
                    ))}
                    <option value="Nigeria">Nigeria</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Ghana">Ghana</option>
                  </select>
                </div>
              </div>
              <div>
                <div className="select-code">
                  <div className='flag-code'>
                   
                  {flagImage()}
                    <select value={selectedCurrency} onChange={handleSelectCurrency} className='input-select-code'>
                      <option value='+234'>+234</option>
                      <option value='+256'>+256</option>
                      <option value='+254'>+254</option>
                      <option value='+233'>+233</option>
                      <option value='+27'>+27</option>
                    </select>
                  </div>
                  <input
                    type="number"
                    placeholder="Phone Number"
                    name="phone"
                    className="input-phone-number"
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <input
                className="input-field"
                type="email"
                placeholder="Business Email Address"
                name="business_email"
                onChange={handleInputChange}
              />
              <div className="double-input-container">
                <div className="input_container" onBlur={() => handleInputBlur('password')}>
                  <div className={`input-group ${isInputClicked.password ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('password')} onBlur={() => handleInputBlur('password')} style={{justifyContent:"space-between"}}>
                    <input  onChange={handleInputChange} type={showPassword ? 'text' : 'password'} placeholder="Password (min. of 8 char)" name="password" className="input-name" required/>
                    <img onClick={handleTogglePassword} src="/images/eye.svg" alt="card" style={{width:"16px", height:"16px"}}/>
                  </div>
                  <p className={`input-label ${isInputClicked.password ? 'clicked' : ''}`}>Password</p>
                </div>
                <div className="input_container" onBlur={() => handleInputBlur('confirmPassword')}>
                  <div className={`input-group ${isInputClicked.confirmPassword ? 'clicked' : ''}`} onClick={() => handleInputContainerClick('confirmPassword')} onBlur={() => handleInputBlur('confirmPassword')} style={{justifyContent:"space-between"}}>
                    <input  onChange={handleInputChange} type={showConPassword ? 'text' : 'password'} placeholder="Confirm Password" name="confirmPassword" className="input-name" required/>
                    <img onClick={handleToggleConfirmPassword} src="/images/eye.svg" alt="card" style={{width:"16px", height:"16px"}}/>
                  </div>
                  <p className={`input-label ${isInputClicked.confirmPassword ? 'clicked' : ''}`}>Confirm Password</p>
                </div>
              </div>
             
              <input className="input-field" type="text" placeholder="Referral Code (Optional)" name="name"/>
            </div>
            <div className="signUp-checkbox">
              <input type="checkbox" className="custom-checkbox" checked={isChecked} onChange={handleCheckboxChange} required/>
              <h2 className="signUp-checkbox-text">
                I consent to Flick's <span><a href="https://getflick.app/privacy-policy">Privacy Policy </a></span> and <span>
                  <a href="https://getflick.app/terms-conditions">Terms of Use.</a></span>
              </h2>
            </div>

            <button className="btn" style={{background:"#2EBDB6"}} disabled={!isChecked || isLoading}>
              {isLoading ? (
                  <BeatLoader
                    color="#FFF"
                    cssOverride={{}}
                  />              ) : (
                <>
                  Join Flick
                  <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
                </>
              )}
            </button>
            <p className="dont-have-account">
              Have an account?
              <Link to={"/"} className="signIn-link">Sign In
                <img style={{ display: "inline" }} src={process.env.PUBLIC_URL + "/images/coloredArrowForward.svg"} alt="colored"/>
              </Link>
            </p>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default SignUpPage;
