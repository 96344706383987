import React, { useState } from 'react'
import "../Settings.scss"
import CollectionsData from "./CollectionsData.json"
import PayoutData from "./PayoutData.json"
import OtherAfricaData from "./OtherAfricaData.json"
import NorthAmericaData from "./NorthAmerica.json"
import Status from '../../common/Status';

function Payment() {


const allCollectionsData = CollectionsData;
const allPayoutData = PayoutData;
const allOtherAfriceData = OtherAfricaData;
const allNorthAmericaData = NorthAmericaData;
const [isNigeriaVisible, setIsNigeriaVisible] = useState(false);
const [isOtherAfricaVisible, setIsOtherAfricaVisible] = useState(false);
const [isNorthAmericaVisible, setIsNorthAmericaVisible] = useState(false);


const toggleNigeriaVisibility = () => {
    setIsNigeriaVisible((prevState) => !prevState);
  };
const toggleOtherAfricaVisibility = () => {
    setIsOtherAfricaVisible((prevState) => !prevState);
  };
const toggleNorthAmericaVisibility = () => {
    setIsNorthAmericaVisible((prevState) => !prevState);
  };

  return (
    <div style={{width:"100%", paddingRight:"19px"}}>
        <div className='pricing_mobile'>
            <table className="pricing-table" style={{width:"100%"}}>
                <tbody>
                    <td colSpan={7} style={{padding: 0}}>
                        <div className='payment-table-title' onClick={toggleNigeriaVisibility}>
                            {/* <img src={process.env.PUBLIC_URL+"/images/arrow-down-s-fill.svg"} alt="icon" />
                            <img src={process.env.PUBLIC_URL+"/images/arrow-side-s-fill.svg"} alt="icon" /> */}
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    (isNigeriaVisible
                                    ? "/images/arrow-side-s-fill.svg"
                                    : "/images/arrow-down-s-fill.svg")
                                }
                                alt="icon"
                            />
                            <div className='flag' style={{border: "none", background:"#fff"}}>
                                <img src={process.env.PUBLIC_URL+"/images/ng-pric.svg"} alt="flag" />
                                <h1 className='ng-pric'>Nigeria</h1>
                            </div>
                        </div>
                    </td>

                    {isNigeriaVisible && ( 
                        <>
                            <tr>
                                <th className='th-text-pric'>Collection</th>
                                <th className='th-text-pric'>Pricing</th>
                                <th className='th-text-pric'>Cap</th>
                                <th className='th-text-pric'>Wallet Setllement</th>
                                <th className='th-text-pric'>Status</th>
                                <th className='th-text-pric'>Live</th>
                            </tr>
                            {allCollectionsData.map((item, index) => (
                            <tr key={index}>
                                <td className='td-text-pric'>{item.title}</td>
                                <td className='td-text-pric'>{item.pricing}</td>
                                <td className='td-text-pric'>{item.cap}</td>
                                <td className='td-text-pric'>{item.walletSetllement}</td>
                                <td className='td-text-pric'>
                                {
                                    item.status === "Soon" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                }
                                {
                                    item.status === "Available" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#ECFDF3"
                                    border="#ABEFC6"
                                    color="#067647"
                                    />
                                }
                                </td>
                                <td className='td-text-pric'>
                                    <div style={{display:"flex", gap:"3px", alignItems:"center"}}>
                                    {item.live === "Request Access" ? (
                                        <span style={{ textDecoration: "underline", color: "var(--Gray-800, #1D2939)", textUnderlineOffset: "3px"}}>
                                            {item.live}
                                        </span>
                                    ) : (
                                        item.live
                                    )}
                                    {item.live !== "Nil" && (
                                        <img src={process.env.PUBLIC_URL + item.icon} alt='ic' />
                                    )}
                                    </div>
                                </td>
                            </tr>
                            
                            ))}
                            <td></td>
                            <tr>
                                <th className='th-text-pric'>Pay-Out</th>
                                <th className='th-text-pric'>Pricing</th>
                                <th className='th-text-pric'>Cap</th>
                                <th className='th-text-pric'>Wallet Setllement</th>
                                <th className='th-text-pric'>Status</th>
                                <th className='th-text-pric'>Live</th>
                            </tr>
                            {allPayoutData.map((item, index) => (
                            <tr key={index}>
                                <td className='td-text-pric'>{item.title}</td>
                                <td className='td-text-pric'>{item.pricing}</td>
                                <td className='td-text-pric'>{item.cap}</td>
                                <td className='td-text-pric'>{item.walletSetllement}</td>
                                <td className='td-text-pric'>
                                {
                                    item.status === "Soon" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                }
                                {
                                    item.status === "Available" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#ECFDF3"
                                    border="#ABEFC6"
                                    color="#067647"
                                    />
                                }
                                </td>
                                <td className='td-text-pric'>
                                    <div style={{display:"flex", gap:"3px", alignItems:"center"}}>
                                    {item.live === "Request Access" ? (
                                        <span style={{ textDecoration: "underline", color: "var(--Gray-800, #1D2939)", textUnderlineOffset: "3px"}}>
                                            {item.live}
                                        </span>
                                    ) : (
                                        item.live
                                    )}
                                        <img src={process.env.PUBLIC_URL + item.icon} alt='ic' />
                                    </div>
                                </td>
                            </tr>
                            
                            ))}
                        </>
                    )}
                </tbody>

                <tbody>
                    <td colSpan={7} style={{padding: "24px 0 0 0"}}>
                    <div className='payment-table-title' onClick={toggleOtherAfricaVisibility}>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    (isOtherAfricaVisible
                                    ? "/images/arrow-side-s-fill.svg"
                                    : "/images/arrow-down-s-fill.svg")
                                }
                                alt="icon"
                            />
                        <div className='flag' style={{border: "none", background:"#fff"}}>
                            <div className='multi-flag'>
                                <img src={process.env.PUBLIC_URL + "/images/ken.svg"} alt='ic' />
                                <img src={process.env.PUBLIC_URL + "/images/ghan.svg"} alt='ic' />
                            </div>
                            <h1 className='ng-pric'>Other Africa</h1>
                        </div>
                    </div>
                    </td>
                    {isOtherAfricaVisible && ( 
                        <>
                            <tr>
                                <th className='th-text-pric'></th>
                                <th className='th-text-pric'>Pricing</th>
                                <th className='th-text-pric'>Cap</th>
                                <th className='th-text-pric'>Wallet Setllement</th>
                                <th className='th-text-pric'>Status</th>
                                <th className='th-text-pric'>Live</th>
                            </tr>
                            {allOtherAfriceData.map((item, index) => (
                            <tr key={index}>
                                <td className='td-text-pric'>{item.title}</td>
                                <td className='td-text-pric'>{item.pricing}</td>
                                <td className='td-text-pric'>{item.cap}</td>
                                <td className='td-text-pric'>{item.walletSetllement}</td>
                                <td className='td-text-pric'>
                                {
                                    item.status === "Soon" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                }
                                {
                                    item.status === "Available" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#ECFDF3"
                                    border="#ABEFC6"
                                    color="#067647"
                                    />
                                }
                                </td>
                                <td className='td-text-pric'>
                                    <div style={{display:"flex", gap:"3px", alignItems:"center"}}>
                                    {item.live === "Request Access" ? (
                                        <span style={{ textDecoration: "underline", color: "var(--Gray-800, #1D2939)", textUnderlineOffset: "3px"}}>
                                            {item.live}
                                        </span>
                                    ) : (
                                        item.live
                                    )}
                                    {item.live !== "Nil" && (
                                        <img src={process.env.PUBLIC_URL + item.icon} alt='ic' />
                                    )}
                                    </div>
                                </td>
                            </tr>
                            
                            ))}
                        </>
                    )}
                </tbody>

                <tbody>
                    <td colSpan={7} style={{padding: "24px 0 0 0"}}>
                        <div className='payment-table-title' onClick={toggleNorthAmericaVisibility}>
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    (isNorthAmericaVisible
                                    ? "/images/arrow-side-s-fill.svg"
                                    : "/images/arrow-down-s-fill.svg")
                                }
                                alt="icon"
                            />
                            <div className='flag' style={{border: "none", background:"#fff"}}>
                                <div className='multi-flag'>
                                    <img src={process.env.PUBLIC_URL + "/images/usa.svg"} alt='ic' />
                                    <img src={process.env.PUBLIC_URL + "/images/cana.svg"} alt='ic' />
                                </div>
                                <h1 className='ng-pric'>North America</h1>
                            </div>
                        </div>
                    </td>
                    {isNorthAmericaVisible && ( 
                        <>
                            <tr>
                                <th className='th-text-pric'></th>
                                <th className='th-text-pric'>Pricing</th>
                                <th className='th-text-pric'>Cap</th>
                                <th className='th-text-pric'>Wallet Setllement</th>
                                <th className='th-text-pric'>Status</th>
                                <th className='th-text-pric'>Live</th>
                            </tr>
                            {allNorthAmericaData.map((item, index) => (
                            <tr key={index}>
                                <td className='td-text-pric'>{item.title}</td>
                                <td className='td-text-pric'>{item.pricing}</td>
                                <td className='td-text-pric'>{item.cap}</td>
                                <td className='td-text-pric'>{item.walletSetllement}</td>
                                <td className='td-text-pric'>
                                {
                                    item.status === "Soon" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#FFFAEB"
                                    border="#FEDF89"
                                    color="#B54708"
                                    />
                                }
                                {
                                    item.status === "Available" &&
                                    <Status 
                                    mgs={item.status}
                                    bg="#ECFDF3"
                                    border="#ABEFC6"
                                    color="#067647"
                                    />
                                }
                                </td>
                                <td className='td-text-pric'>
                                    <div style={{display:"flex", gap:"3px", alignItems:"center"}}>
                                    {item.live === "Request Access" ? (
                                        <span style={{ textDecoration: "underline", color: "var(--Gray-800, #1D2939)", textUnderlineOffset: "3px"}}>
                                            {item.live}
                                        </span>
                                    ) : (
                                        item.live
                                    )}
                                    {item.live !== "Nil" && (
                                        <img src={process.env.PUBLIC_URL + item.icon} alt='ic' />
                                    )}
                                    </div>
                                </td>
                            </tr>
                            
                            ))}
                        </>
                    )}
                </tbody>
            </table>
        </div>

    </div>
  )
}

export default Payment