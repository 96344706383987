import React from 'react'
import './otc.scss'
function ClientResponseModal({setIsClientResponse,setIsAccept,setIsFundBalance,setIsRejected}) {
    const handleSpread = ()=>{

    }
  return (
    <div className='balance_modal'  onClick={setIsClientResponse.bind(this, false)} >
       
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsClientResponse.bind(this, false)}
              className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
              />

            <div className="modal-title">
                <p className="enter-amount">Awaiting Client Response</p>
                {/* <p className="enter-an-amount">Please enter the amount above $5,000</p> */}
            </div>



            <div className='otcform_container'>
                <p className='otc_rate'>Currency Pair</p>
                <p className='oct_rate_text'>NGN - USD</p>
                
                <p className='otc_rate m_t'>Amount</p>
                <p className='oct_rate_text'> N5,000.00</p>
                <p className='otc_rate m_t'>Approved Rate</p>
                <p className='oct_rate_text'>N1,780</p>
                <p className='otc_rate m_t'>Add Spread (optional)</p>
                <input className='input_spread' type='text' placeholder='10' onChange={handleSpread}/>
            </div>

            <div className='otp_content_btn'>
                <button className='btn' onClick={setIsAccept.bind(this,true)} style={{width:"100%"}}>
                    Accept & Share
                </button>
                <button className='btn otc_btn_cancel btn_accept' style={{width:"100%"}} onClick={setIsFundBalance.bind(this,true)} >
                    Accept & Continue
                </button>
                <button className='btn otc_btn_cancel' onClick={setIsRejected.bind(this,true)} >
                    Reject Deal
                </button>

            </div>
            
            
          </div>
        </div>
    </div>
  )
}

export default ClientResponseModal