import React, { useEffect, useState } from 'react';
import loadingTransfer from './loadingTransfer.json';
import loadingTransferEnd from './loadingTransferEnd.json';
import Lottie from 'lottie-react'
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import axios from 'axios';

import './Modal.scss'
import { toast } from 'react-toastify';

function VirtualConfirm({transactionId,setIsVirtualConfirm,isVirtualConfirm,nubTemInput,getBalance}) {
    const [showAccountDetails, setShowAccountDetails] = useState(false);
    // eslint-disable-next-line 
    const [response, setResponse] = useState(null);
    // eslint-disable-next-line 
    const [isLoading, setIsLoading] = useState(true);
    const [isTimeLimitExceeded, setIsTimeLimitExceeded] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const [isSuccess,setIsSuccess] = useState(false)
    const { currentUser } = useSelector((state) => state?.user);

    function timeout(number) {
        return new Promise( res => setTimeout(res, number) );
    }

    useEffect(() => {
        let intervalId;
        let timeoutId;
        
        const makeRequest = async () => {

          try{
    
            const headers = {  
                Authorization:`Bearer ${currentUser?.user?.merchantKey}`
            }
            // var metrans = localStorage.getItem('nubanTrans');      
            console.log("working from here")
            const url = `https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-verify?transactionId=${transactionId}`
            
            let res = await axios.get(url,{
              headers:headers
            });

            
              
            // const message = res?.data?.message.toLowerCase();
            const trans_status = res?.data?.data?.transaction_status?.toLowerCase();
            if(trans_status ==="success"){
              console.log(res.data);
              toast.success(res.data.message);
              clearInterval(intervalId);
              clearTimeout(timeoutId);
              setIsSuccess(true)
              setIsTimeLimitExceeded(true);

              await timeout(3000)
              getBalance();
              
            }else{
              console.log('Waiting for a successful response...');
            }
          }catch(err){
            console.log(err)
            setIsTimeLimitExceeded(true);
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            const errorMessage = err?.response?.data?.message || err.message || "Server Error"
            toast.error(errorMessage);
          }
        };
    
        // Initialize the responseSuccessful flag to false
      
        const startPolling = () => {
    
          makeRequest();
          intervalId = setInterval(() => {
            makeRequest()
              .finally(() => {
                setIsLoading(false);
              });
          }, 5000); // 5 seconds
    
          // Set a timeout for 10 minutes (600,000 milliseconds)
          const timeoutDuration = 600000; // 10 minutes
          timeoutId = setTimeout(() => {
            clearInterval(intervalId);
            setIsTimeLimitExceeded(true);
            setIsLoading(false);
          }, timeoutDuration); // 10 minutes
    
          // Update timeLeft every second
          const updateTimer = () => {
            const elapsed = (timeoutDuration - (Date.now() - startTime)) / 1000;
            setTimeLeft(Math.max(0, Math.round(elapsed)));
          };
          
          const startTime = Date.now();
          const timerIntervalId = setInterval(updateTimer, 1000);
    
          // Clear the timer interval when the component unmounts
          return () => {
            clearInterval(intervalId);
            clearTimeout(timeoutId);
            clearInterval(timerIntervalId);
          };
        };
    
        startPolling();
      
        return () => {
          clearInterval(intervalId);
          clearTimeout(timeoutId);
        };
        // eslint-disable-next-line 
    }, [isVirtualConfirm]);
    
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
  return (
    <div className="overlay" >
    <div className="enter-amount-modal">
      <div className="modal-wrapper">
        <div> 
        <img style={{cursor:"pointer"}} onClick={setIsVirtualConfirm.bind(this,false)} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          <p className="enter-amount">Payment Information</p>
         
        </div>

        <div className="account-details" style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
           
            <p className="enter-an-amount" style={{fontWeight:"bold",fontSize:"2rem"}}>{`₦${nubTemInput?.amount}`}</p>
        </div>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>

            {
                !isSuccess ?
                <p className="enter-an-amount">We're waiting to confirm your transfer</p>:
                <p className="enter-an-amount">Transaction Successful</p>
            }
        </div>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
    `       {
            !isTimeLimitExceeded ?
            <div className='lottie-size'>
                <Lottie animationData={loadingTransfer} /> 
            </div>:
            <div className='lottie-size'>
                <Lottie loop={false} animationData={loadingTransferEnd} /> 
            </div>

            }
        </div>
        {
          isTimeLimitExceeded ?(

          <button className="sec-btn" onClick={setIsVirtualConfirm.bind(this,false)}>
           Close
          </button>
          ):(
          <button className="sec-btn">
            Please wait for {formatTime(timeLeft)} minutes
          </button>
          )
        }

      </div>
    </div>
    </div>
  )
}

export default VirtualConfirm