export const formatSingleCustomerData = async (inputData)=>{
    const formattedData = [];
    for (const [nuban, transactionData] of Object.entries(inputData.data)) {
        const formattedEntry = {
          nuban: nuban,
          totalDebit: transactionData.totalDebit,
          totalCredit: transactionData.totalCredit,
          totalTransaction: transactionData.totalTransaction,
          transactionList: [], // You can add transaction list here if needed
          balance: transactionData.balance
        };
        formattedData.push(formattedEntry);
    }
    return formattedData; 
} 