import React, { useState } from 'react'
import '../../components/otc/otc.scss'
import SelectInputGroup from '../../components/otc/SelectInputGroup';
import BusinessAddress from '../../components/otc/BusinessAddress';
import DoubleInputGroup from '../../components/otc/DoubleInputGroup';
import DetailText from '../../components/otc/DetailText';
import { useNavigate } from 'react-router-dom';

function BankDetails() {
    const navigate = useNavigate();
    const handleClick = ()=>{
        navigate('/payment-submitted')
    }
    const handleBack = ()=>{
        navigate('/bank-details')
    }
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Toggle the checked state
      };
    const [bankDetailInput,setBankDetailInput] = useState({
        bankName:"",
        address1:"",
        address2:"",
        city:"",
        state:"",
        country:"",
        postalCode:""
    });
    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
        setBankDetailInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
    }
  return (
    <div className='weblink'>
        <div className='weblink_log'>
            <img className='otc_flick_logo' src='/images/otc/flickLogo.svg' alt=''/>
        </div>
        <div className='weblink_container'>
            <div className='weblink_box'>
                <div className='weblink_box_wrapper'>
                    <div className='weblink_title_container'>
                        <div className='weblink_arrow'>
                            <img src='/images/otc/arrowBack.svg' alt='' onClick={handleBack} />
                            <p className='weblink_title'>Confirm Details</p>
                        </div>
                    </div>
                    <div className="modal-title">        
                        <p className="enter-an-amount">Please confirm the following details regarding this conversion and ensure all information provided is correct.</p>
                    </div>
                
                    
                    {/*  */}
                    <div className='otc_info_container'>
                        <img src='/images/otc/info-circle.svg' alt='' />
                        <p className='otc_info'>No changes are possible after proceeding</p>
                    </div>

                    <div className='otcform_container'>
                        <div className='desc_container'>
                            <DetailText desc1="Wire Type" value1="Domestic U.S wire (same/next day)"/>
                            <DetailText desc1="Account Number" value1="22488136902667" desc2="Routing Number" value2="23690867"/>
                            <DetailText desc1="Beneficiary Account Name" value1="Ayomide Ajibade" desc2="Bank Name" value2="Wells Fargo"/>
                            <DetailText desc1="Beneficiary Address " value1="1234 Unity Street Los Angeles, CA 90001"/>
                            <DetailText desc1="Bank Address" value1="567 Carl Street, Los Angeles, CA 93021"/>
                        </div>
                    </div>
                    <div className='consent_to'>
                        <input 
                        type="checkbox" 
                        checked={isChecked} 
                        onChange={handleCheckboxChange} 
                        />
                        {/* eslint-disable-next-line */}
                        <p className='confirm_desc'>I confirm the above payment details are accurate and consent to Flick's <a href='#'>Policy</a> </p>
                    </div>

                    <button className='btn' style={{marginTop:"15px"}} onClick={handleClick}>Confirm</button>


                </div>
            </div>
        </div>
    </div>
  )
}

export default BankDetails