import React from 'react'
import BeneficiaryDummy from './BeneficiaryDummy.json';
import './otc.scss'

function DropDownBeneficiaryItem({ handleBankSelection, selectedBank,setIsBenAdd,addBeneficiary,beneficiaryData }) {
    const allPaymentOptions = beneficiaryData;
    const handleAdd = ()=>{
        // addBeneficiary();
        setIsBenAdd(true);
    }
    return (
      <div className="bank-list">
        <div className="modal_container">
          <div className="scrollable_list">
            {
            allPaymentOptions.length === 0 ? (
                <div className='no_ben'>
                    <img src='/images/otc/noBen.svg' alt=''/>
                    <p className='no_ben_text'>No beneficiaries yet.</p>
                </div>
            ):
            
            allPaymentOptions.map((bank) => (
              <div
                key={bank.id}
                className={`icon-bank `}
                onClick={() => handleBankSelection(bank)}
              >
                <div className={`beneficiary_detail_container `}>
                    <div className='beneficiary_detials'>
                        <div className='ben_initial'>
                            <p className='ben_initial_val'>{bank.beneficiary_name.slice(0, 1)}</p>
                        </div>   
                        <div className='ben_acc_details'>
                            <h2 className="ben_acc_name">{bank.beneficiary_name}</h2>
                            <p className='ben_acc_num'>{bank.bank_name} | <span>{bank.account_no}</span></p>
                        </div>
                    </div>
                    
                    <img
                    src={process.env.PUBLIC_URL + "./images/otc/chevron-side.svg"}
                    alt="check"
                    />
                  
                </div>
               
              </div>
            ))}
          </div>

          <button className='btn' style={{borderRadius:"8px",background:"#259792",width:"fit-content",marginBottom:"2rem"}} onClick={handleAdd}>Add Beneficiary</button>
        </div>
        {/* ... */}
      </div>
    )
}

export default DropDownBeneficiaryItem