import React, { useContext, useEffect, useState } from 'react'
import '../components/common/Pages.scss'
import '../components/common/tables.scss'
import CustomerAccount from '../components/data/customer/CustomerAccount'
import CustomerIdentity from '../components/data/customer/CustomerIdentity'
import CustomerTransaction from '../components/data/customer/CustomerTransaction'
import CustomerStatement from '../components/data/customer/CustomerStatement'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import DataContext from '../components/context/DataContext'
import { formatSingleCustomerData } from '../components/utilities/formateSingleCustomer'

function DataCustomer() {
  // useEffect(()=>{
  //   getCustomer();
  // },[])
  const {currentUser} = useSelector((state)=>state?.user);
  const [activeTab, setActiveTab] = useState('account');
  // const [customerData, setCustomerData] = useState([])
  const [customerAccount,setCustomerAccount] = useState({})
  const [transactionList,setTransactionList] = useState([])
  const [amounts,setAmounts] = useState({})
  const [customer,setCustomer]= useState([])
  const [identity,setIdentity] = useState({})
  const [transactions,setTransactions] = useState([])
  const [statement, setStatement] = useState([])

  const navigate = useNavigate();
  const location = useLocation();
  const  {account} =  location?.state || {}

  const {setCustomerSummary,setCustomerData} = useContext(DataContext)
  const handleBack =()=>{
    navigate('/data-customers')
  }
  useEffect(()=>{
    // getCustomer();
    getIdentity();
    getStatement();
    getTransactions();
    // eslint-disable-next-line
  },[])
  // const key =  Object.keys(customerData)[0]
  // const balanceResult = customerData[key] 
  // const transList = customerData[key]?.transactionList
  
  const getCustomer = async ()=>{
   
    try{ 
      const headers = {  
        "api_key": currentUser?.user?.merchantKey, 
        "sweetapi": currentUser?.user?.merchantCode  
      }

      
      const payload ={
        bvn:account?.bvn,
        CustomerCode:currentUser?.user?.merchantCode
      }
      
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-single-customer';
      let res = await axios.post(url,{...payload},{
        headers:headers
      })
      
      const newValue = res?.data
      console.log("customer single:",newValue)
      const {summary,data} = res?.data
      setCustomerSummary(res.data?.summary)
      const result = await formatSingleCustomerData(newValue)
      
      setCustomer(result);
       
      return result;
      
    }catch(err){
      
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error"
     

    }
  }

  const getIdentity = async ()=>{
   
    try{
     
      const headers = {  
        "api_key": currentUser?.user?.merchantKey, 
        "sweetapi": currentUser?.user?.merchantCode  
      }
      
      const customer = await getCustomer()
      
      
      const data ={
        accountNumber: customer[0]?.nuban,
        CustomerCode:currentUser?.user?.merchantCode
      }
      
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/linked-identity';
      let res = await axios.post(url,{...data},{
        headers:headers
      })
      
      const newValue = res?.data
       
     
     setIdentity(res.data?.data)
      
    }catch(err){ 
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error"
    }
  }

  const getStatement = async ()=>{
 
    try{
     
      const headers = {  
        "api_key": currentUser?.user?.merchantKey, 
        "sweetapi": currentUser?.user?.merchantCode  
      }

      const data ={
        bvn:account?.bvn,
        CustomerCode:currentUser?.user?.merchantCode
      }
      
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/linked-customer-statement';
      let res = await axios.post(url,{...data},{
        headers:headers
      })
      
      const newValue = res?.data?.data
       
     console.log("statements:",newValue);
     setStatement(newValue);
      
    }catch(err){
      
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error"
     
    }
  }
  const getTransactions = async ()=>{
 
    try{
     
      const headers = {  
        "api_key": currentUser?.user?.merchantKey, 
        "sweetapi": currentUser?.user?.merchantCode  
      }

      const data ={
        bvn:account?.bvn,
        CustomerCode:currentUser?.user?.merchantCode
      }
      
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/linked-customer-transactions';
      let res = await axios.post(url,{...data},{
        headers:headers
      })
      
      const newValue = res?.data?.data
       
     console.log("transactions:",newValue);
     setTransactions(newValue);
      
    }catch(err){
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error"
    }
  }
 

  
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const renderContent = ()=>{
     switch(activeTab){
        case 'account':
            return  <CustomerAccount customer={customer} account={account} />;
        case 'identity':
            return <CustomerIdentity identity={identity} account={account} />;
        case 'transaction':
            return <CustomerTransaction identity={identity} account={account} transactions={transactions}/>;
        case 'statement':
            return <CustomerStatement statement={statement} account={account} />;
        default:
            return <CustomerAccount customer={customer} account={account} />;
     }
     
  }
  return (
    <div className='main-inner'>
     
        <div className='top_bar'>
            <div style={{cursor:"pointer"}} className="customer-back" onClick={handleBack} >
                <img src={process.env.PUBLIC_URL+"/images/arrowBack.svg"} alt="arrow back"/>
                <h1 className='customer-arrowBack'>Go Back</h1>
            </div>
            <div className='data-nav-btn'>
              <span onClick={() => handleTabClick('account')} className={`dataItembtn ${activeTab === 'account' ? 'active' : ''}`} >Account</span>
              <span onClick={() => handleTabClick('identity')} className={`dataItembtn ${activeTab === 'identity' ? 'active' : ''}`}>Identity</span>
              <span onClick={() => handleTabClick('transaction')} className={`dataItembtn ${activeTab === 'transaction' ? 'active' : ''}`}>Transactions</span>
              <span onClick={() => handleTabClick('statement')} className={`dataItembtn ${activeTab === 'statement' ? 'active' : ''}`} >Statement</span>
            </div>
        </div>
       
        {renderContent()}
        
    </div>
  )
}

export default DataCustomer
