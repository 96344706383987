import React, { useState } from 'react'
import '../data/data.scss'
import { BeatLoader } from "react-spinners";

function CreatePayLinkForm({setIsPayLink,setPayLinkInput,payLinkInput,addStatementLink, isLoading}) {
   const [textValue, setTextValue] = useState('');
   const [selectedOption, setSelectedOption] = useState('');
   const [showAdvanced, setShowAdvanced] = useState(false);
  

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };
  const handlePropagation = (e)=>{
    e.stopPropagation(); 
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPayLinkInput({
      ...payLinkInput,
      [name]: value,
    });
  };
  const handleSubmit = (e)=>{
    e.preventDefault();
    addStatementLink()
  }

  const toggleAdvancedOptions = () => {
    setShowAdvanced(!showAdvanced);
  };
  return (
    <div className='link-modal' onClick={setIsPayLink.bind(this,false)}>
        <div className='link-box' onClick={handlePropagation}>
            <div className='link-box-wrapper'>
                <img onClick={setIsPayLink.bind(this,false)} className='link-modal-close' src='/images/x-close.svg' alt='f'/>
                <div className='link-title' style={{marginBottom:"0px"}}>
                   
                    <h3>Create payment link</h3>
                    <p>Generate a payment link for a one-time customer payment, and adjust the currency as necessary.</p>
                </div>

                <form onSubmit={handleSubmit}>
                  <div className='statement_link' style={{marginTop:"14px"}}>
                    <div className='payment-link'>
                    <div className='link-form-item'>
                        <select 
                        onChange={handleChange} 
                        className='input-link'
                        name='productType'
                        value={payLinkInput.productType}
                        required
                        >
                          <option value="" selected disabled >Select Product Type</option>
                         
                          <option value="oneTime">Checkout</option>
                        </select>
                      </div>
                      <div className='link-form-item'>
                        <input 
                        type='text' 
                        name="pageName" 
                        placeholder='Payment link name'
                        className='input-link' 
                        value={payLinkInput.pageName}
                        onChange={handleChange}
                        required
                        />
                      </div>
                      <div className='link-form-item'>
                        <input 
                        type='text' 
                        name='description' 
                        placeholder='Description'
                        className='input-link' 
                        value={payLinkInput.description}
                        onChange={handleChange}
                        required
                        />
                        {/* <p className='info-text'>Remaining 30 characters</p> */}
                      </div>

                      <div className='link-form-item'>
                            <div style={{display:"flex", gap:"6px"}}>
                              <select 
                                  // className='link_select_custom'
                                  className='input-link'
                                  name="currency"
                                  onChange={handleChange} 
                                  value={payLinkInput.currency}
                                  required>

                                  <option value="" selected disabled >Currency collected</option>

                                  <option value="NGN">&#x1F1F3;&#x1F1EC; NGN </option>
                                  <option value="GHS">&#x1F1EC;&#x1F1ED; GHS</option>
                                  <option value="KES">&#x1F1F0;&#x1F1EA; KES</option>
                                  <option value="USD">&#x1F1FA;&#x1F1F8; USD </option>
                                  <option value="CAD">&#x1F1E8;&#x1F1E6; CAD </option>
                                  <option value="EUR">&#x1F1EA;&#x1F1FA; EUR </option>
                                  <option value="GBP">&#x1F1EC;&#x1F1E7; GBP </option>
                              </select>

                              <select 
                                    onChange={handleChange} 
                                    className='input-link'
                                    name='currency_settled'
                                    value={payLinkInput.currency_settled}
                                    required
                                    >
                                      <option value="" selected disabled >Currency settled</option>
                                    
                                      <option value="NGN">&#x1F1F3;&#x1F1EC; NGN </option>
                                      <option value="USD">&#x1F1FA;&#x1F1F8; USD </option>
                                      <option value="EUR">&#x1F1EA;&#x1F1FA; EUR </option>
                                      <option value="GBP">&#x1F1EC;&#x1F1E7; GBP </option>
                                      <option value="GHS">&#x1F1EC;&#x1F1ED; GHS</option>
                                      <option value="KES">&#x1F1F0;&#x1F1EA; KES</option>
                                    </select>

                            </div>

                          <div className='link-form-item' style={{marginTop:"20px"}}>
                          <p className='info-text'>Leave blank for flexible amounts</p>
                          <input 
                              type='text'  
                              name="amount" 
                              placeholder='amount' 
                              value={payLinkInput.amount}
                              onChange={handleChange}
                              className='link_select_custom_value'
                            />

                          </div>

                      </div>
                      {showAdvanced && (
                        <div className='advance-options'>
                            <div className='link-form-item'>
                        <div  className='input-link' style={{gap:"0px",padding:"0px",paddingLeft:"12px",paddingRight:"5px"}} >
                          <input type='text' value="checkout.getflick.co" className='link_input_custom-url' />
                          <input 
                          type='text'  
                          name="customLink" 
                          placeholder='Your URL' 
                          value={payLinkInput.customLink}
                          onChange={handleChange}
                          className='link_input_custom_value'
                          />
                        </div>
                      </div>
                      <div className='link-form-item'>
                        <input 
                        type='text'  
                        name="redirectLink" 
                        value={payLinkInput.redirectLink}
                        placeholder='https://getflick.co' 
                        className='input-link' 
                        onChange={handleChange}
                        />
                      </div>

                      <div className='link-form-item'>
                        <input 
                        type='text'  
                        name="successmsg" 
                        placeholder='Success Message' 
                        className='input-link' 
                        value={payLinkInput.successmsg}
                        onChange={handleChange}
                        />
                      </div>
                        </div>
                      )}
                      </div>


                      
                      <div className='advance-btn'>
                     <img src={showAdvanced ? '/images/hide-advance.svg' : '/images/show-advance.svg'} alt="f" onClick={toggleAdvancedOptions} style={{ marginBottom:"5%", width:"100%" }} />

                      <button className='btn' style={{width:"100%", display:"flex", alignContent:"center", justifyContent:"center", gap:"10px", padding:"14px"}}>

                            {
                              isLoading ? (
                                <BeatLoader
                                color="#FFF"
                                cssOverride={{}}
                              />
                                
                              ) : (
                                <>       
                                 Create Link
                                </>
                              )
                            }
                      </button>
                      </div>
                  </div>
                </form>
            </div>

        </div>
    </div>
  )
}

export default CreatePayLinkForm
