import React from 'react'

function CustomPagination({ totalRows, rowsPerPage, currentPage, setCurrentPage }) {
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const maxVisiblePages = 5;
  
  

  const getVisiblePageNumbers = () => {
    if (totalPages <= maxVisiblePages + 1) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= maxVisiblePages) {
      return [...Array.from({ length: maxVisiblePages }, (_, i) => i + 1), '...', totalPages];
    }

    if (currentPage > totalPages - maxVisiblePages) {
      return [1, '...', ...Array.from({ length: maxVisiblePages }, (_, i) => totalPages - maxVisiblePages + i + 1)];
    }

    return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
  };

   const prePage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const changeCpage = (pageNumber) => {
    if (pageNumber === '...') return;
    setCurrentPage(pageNumber);
  };

  const visiblePageNumbers = getVisiblePageNumbers();

  return (
    <div className='pagination_container'>
      <div className='page-item' onClick={prePage}>
        <img src='/images/previous.svg' alt='Previous' />
        <p className='Page_prev'>Previous</p>
      </div>
      <div className='pageNumbers'>
        {visiblePageNumbers.map((n, i) => (
          <span
            key={i}
            className={`page_num ${currentPage === n ? 'active' : ''}`}
            onClick={() => changeCpage(n)}
          >
            {n}
          </span>
        ))}
      </div>
      <div className='page-item' onClick={nextPage}>
        <p className='Page_prev'>Next</p>
        <img src='/images/next.svg' alt='Next' />
      </div>
    </div>
  );
}

export default CustomPagination