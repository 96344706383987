import React, { useRef } from 'react'
import './payment.scss'
import Status from '../common/Status'
import { copyToClipboard } from '../utilities/clipboardUtils';

function CollectionModal({toggleModal,data}) {
    const inputRef = useRef(null);
    
  const handletoggle =(e)=>{
    e.stopPropagation();
    toggleModal()
  }
  const handlePropagation = (e)=>{
    e.stopPropagation();
  }

  const isoData = (date)=>{
    const newData = new Date(date);
    return newData;
  }
  function convertDate(dateString){
    const date = new Date(dateString);
    const dateFormat = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    const formattedDate = new Intl.DateTimeFormat('en-US', dateFormat).format(date);
    return formattedDate;
}
  return (
    <div className='payment-modal' onClick={handletoggle}>
       
      <div className='modal-box' onClick={handlePropagation}>
        <div className='modal-box-wrapper' onClick={handlePropagation}>
            <img onClick={handletoggle} className='payment-modal-close' src='/images/x-close.svg' alt='f'/>
            <div className='payment-model-title'>

                <h3>Transaction Summary</h3>
                <p>Here is an overview of your transaction</p>
            </div>
            <div  className='payment-model-line'></div>

            <div className='payment-model-amount'>
                <p>Transaction amount</p>
                <h4> {`${data.currency_settled==="NGN"? "₦":data.currency_settled==="USD"? "$" :data.currency_settled} ${Number(data.total_amount)/100}`}</h4>
            </div>

            <div  className='payment-model-line'></div>

            <div className='payment-model-overview'>
                <div className='payment-model-content'>
                    <p className='payment-model-th'>Email</p>
                    <p className='payment-model-th'>Bank</p>
                </div>
                <div className='payment-model-content'>
                    <p className='payment-modal-td'>{data.email}</p>
                    <p className='payment-modal-td' >{data?.beneficiary_bank}</p>
                </div>

                <div className='payment-model-content space'>
                    <p className='payment-model-th'>Status</p>
                    <p className='payment-model-th'>{data.channel ==="Nuban"? "Account number":"Card number"}</p>
                </div>
                <div className='payment-model-content '>
                  <Status 
                   icon="/images/success.svg"
                   mgs={data?.status}
                  />
                    <p className='payment-modal-td'>{data.currency_settled}</p>
                </div>

                <div className='payment-model-content space'>
                    <p className='payment-model-th'>type</p>
                    <p className='payment-model-th'>Date & Time</p>
                </div>
                <div className='payment-model-content '>
                    {
                        // data.type ==="onetime" ?
                    <Status 
                      bg="#FDF2FA"
                     mgs={data.type}
                     color="#C11574"
                     border="#FCCEEE"
                    />
                    // :
                    // <p className='payment-type'>Recurring</p>
                    }
                    <p className='payment-modal-td'>{convertDate(data.dated)}</p>
                </div>

                <div className='payment-model-content space'>
                    <p className='payment-model-th'>Charges</p>
                    <p className='payment-model-th'>Settled Amount</p>
                </div>

                <div className='payment-model-content '>
                    <p className='payment-modal-td'>{Number(data?.fee_charged)/100}</p>
                    <p className='payment-modal-td'>{data?.currency_settled}  {Number(data?.settled_amount)/100}</p>
                    
                </div>

                <div className='payment-model-content space'>
                    <p className='payment-model-th'>Channel</p>  
                    {
                        data.channel ==="card" && 
                        <p className='payment-model-th'>Card type</p>  
                    }
                     <p className='payment-model-th'>Reference</p>
                </div>


                <div className='payment-model-content '>
                    <p className='payment-modal-td'>{data.channel}</p>  
                    <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                      <input ref={inputRef} className='link_table' style={{width:"50",textAlign:"right"}}  value={data.transactionid}    readOnly/>
                      <img 
                      style={{display:"inline"}} 
                      src='/images/copy.svg' 
                      alt='payment' 
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipboard(inputRef);
                      }}
                      />
                    </div>
                </div>


               
            </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionModal
