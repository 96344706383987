import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';


function SecKeyOtp({isOtp,setIsOtp,reviewApiKey,setInputOtp, setIsKeyLoading,iskeyLoading,msg}) {
    const handleReview = ()=>{
        reviewApiKey();
    }
    const handleChange = (e)=>{
        setInputOtp(e.target.value)
    }
  return (
    <div className="overlay"> 
    <div className="enter-amount-modal">
      <div className="modal-wrapper">
        <div>
        <img
            src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"}
            alt="icon"
            style={{cursor:"pointer"}}
            onClick={setIsOtp.bind(this,false)}
          />
        </div>

        <div className="modal-title">
          <p className="enter-amount">Enter Transaction OTP</p>

          <p className="enter-an-amount">
         {msg}
          </p>
        </div>

        <div className='pin_container'>
           <input type='password' className='input_otps' onChange={handleChange} placeholder='xxxxxxxx'/>
        </div>
        {/* <div className='informations'>
          <img src='/images/customers/information.svg' alt='information'/>
          <p className='informations_desc'>
          You are about to withdraw 4,000 from 0224048917 - GTB
          </p>
        </div> */}
        <div className="modal-btn">
          <button  onClick={setIsOtp.bind(this,false)} className="first-btn">
            Cancel
          </button>
          <button  onClick={handleReview} className="btn">
          {   
            iskeyLoading ? <BeatLoader color="#fff" size={20} /> :
              <>
              Confirm
              </>
            }
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default SecKeyOtp