import React, { useEffect, useState } from 'react'
import NameControl from '../components/common/NameControl'
import '../components/getStarted/GetStarted.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Agreement from './Agreement';
import { logout } from '../redux/userSlice';

function GetStartedTest() {
    useEffect(()=>{
        getFormDetails(); 
        // eslint-disable-next-line
    },[])
    const dispatch = useDispatch()

    const navigate = useNavigate();
    const {currentUser} = useSelector((state)=>state?.user)
    const {kycComplete} = useLocation?.state || {}
    const [formInfo, setFormInfo] = useState({});
    const [isAgreement, setIsAgreement] = useState(true);
    const [isApprove,setIsApproved] = useState(false)
    const [isLoading,setIsLoading]  = useState(false)

    const handleKyc = ()=>{
        navigate('/complete-kyc')
    }


    const handleClose = ()=>{

    }

    const handleCancelAgreement =()=>{
        dispatch(logout())
        navigate('/')
    }
    
    const getFormDetails = async (e)=>{
   
        try{
    
          const data = {
            CustomerCode:currentUser.user.merchantCode,
            token:currentUser.user.token,
          }
         
          const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc-details';
          let res = await axios.post(url,{...data});
          
          const obj ={
            business_name:res.data?.data?.business_name,
            address:res.data?.data?.address,
            date:res.data?.data?.date_approved

          }
          setFormInfo(obj)
          console.log("The user",currentUser)
          
        }catch(err){
          console.log(err)
          const errorMessage = err?.response?.data?.msg || "unable to save"
          // toast.error(errorMessage,{
          //   position: "top-left", pauseOnHover: true
          // })
        }
       
      }

      const approveAgrement = async()=>{
        setIsLoading(true)
        
        setIsApproved(true)
        
        try{

            const payload = {
                sla:true,
                CustomerCode:currentUser.user.merchantCode,
                token:currentUser.user.token,
            }
            
            const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc';
            let res = await axios.post(url,{...payload});
            const data = res.data
            console.log("data before redirect: ",data)
            setIsLoading(false);
            navigate('/confirm-agreement',{state:{data}})
        }catch(err){
            setIsLoading(false);
            console.log(err)

        }
      }
  return (
    <div className='main-inner' style={{marginTop:`${!currentUser?.user?.isVerified ? "0px":""}`}}>
        {
            
            (currentUser?.kycStatus ==="processing"  && !currentUser?.user?.isLive ) &&
            <div className='test_mode_warning'>
                <div className='warning_content'>
                    <img src='/images/warning.svg' alt='warning' />
                    <p className='warning_text'>You are currently on “Test Mode”. To get access to “Live Mode”, you need to complete your KYC process.<span onClick={handleKyc} className='complete_kyc_link'>Complete KYC</span> </p>
                </div>
                <img onClick={handleClose} src='/images/x-close.svg' alt='close'/>
            </div>
        }
        {
            (currentUser?.kycStatus ==="pending" && currentUser?.user?.isLive !==true) &&
            <div className='test_mode_warning'>
                <div className='warning_content'>
                    <img src='/images/warning.svg' alt='warning' />
                    <p className='warning_text'>You are currently on “Test Mode”. Your document is being processed.</p>
                </div>
                <img onClick={handleClose} src='/images/x-close.svg' alt='close'/>
            </div>
        }
        {
             ((currentUser?.kycStatus ==="completed" && currentUser?.user?.isLive !==true) && isAgreement) &&
             <Agreement handleCancelAgreement={handleCancelAgreement} formInfo={formInfo} approveAgrement={approveAgrement} isLoading={isLoading}/>
        }
       
        <NameControl 
        btn
        image
        name="You’re Getting Started"
        desc="Take a few minutes to explore Flick!"
        />

       <div className='section1'>
            <div className="section1_left">
                <h1 className='sec1_api'>One API powering all your <br/> innovations</h1>
                <p className='sec1_data'>Seamless end-to-end integration for identity, <br/> financial data and payments</p>
                {/* eslint-disable-next-line */}
                <a href='https://demo.getflick.co/' target='_blank' className='sec1_explore'>
                Explore Sandbox
                <img style={{display:"inline",marginLeft:"5px",cursor:"pointer"}} src="/images/arrowUpward.svg" alt='arrow'/>
                </a>
            </div>
            <div className="section1_right">
                <img className='sideImga' src="/images/getStartedRight.svg" alt='start' />
            </div>
       </div>

       <div className='section2'>
            <div className='sec2_left'>
                <div className='sec2_left_content'>
                    <h3 className='sec2_news'>Our Latest Announcements and News</h3>
                    <p className='sec2_api'>Find relevant guides and resources to access and build with Flick's suite of APIs</p>
                    <p className='sec1_explore' style={{marginBottom:"21px"}}>
                    Show me
                    <img  style={{display:"inline",marginLeft:"5px",cursor:"pointer"}} src="/images/arrowUpward.svg" alt='arrow'/>
                    </p>
                </div>
                <img className='get_img' src='/images/girlNews.svg' alt='side' />
            </div>

            <div className='sec2_right'>
                <img src="/images/roadmap.svg" alt='right' />
                <div className='sec2_content'>
                    <h1 className='sec2_resource'>Helpful Resources</h1>
                    <p className='sec2_p'>Find relevant guides and resources <br/> to access and build with Flick's suite of APIs</p>
                    <a href='https://docs.getflick.co/' target='_blank' className='sec1_explore' rel="noreferrer" >
                    Show me
                    <img style={{display:"inline",marginLeft:"5px",cursor:"pointer"}} src="/images/arrowUpward.svg" alt='arrow'/>
                    </a>
                </div>
            </div>
       </div>
      
    </div>
  )
}

export default GetStartedTest
