import React, { useEffect, useState } from 'react'
import '../components/login/Login.scss'
import './emailVerify.scss'
import { Link, useNavigate } from 'react-router-dom';
import AsideView from '../components/login/AsideView';
import { BeatLoader, DotLoader } from 'react-spinners';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
function SetNewPassword() {
  const initialState ={
    confirmPassword:"",
    password:""
  }
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [success,setSuccess] = useState(false)
  const [formData,setFormData] = useState(initialState);
  const [token,setToken] = useState('')
  const navigate = useNavigate()
  useEffect(() => { 
    verifyToken() 
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const  handleChange = (event)=>{
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  const verifyToken = async ()=>{

   setIsLoading(true)

   const fullUrl = window.location.href;
   const urlParts = fullUrl.split('/');
   const token = urlParts[urlParts.length - 1];
   const url =  `https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/validate-password-token/${token}`
   
   try{
       const res = await axios.get(url)
      
       setToken(res.data?.emailtoken);
       setIsLoading(false)
       setSuccess(true)
       // if(res.data.status==="success"){
       //     navigate('/');
       // }
   }catch(err){
       setIsLoading(false)
       console.log(err)
       toast.error("verification failed: please try again..")
       navigate('/forgot-password')
   }

  }
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleToggleNewPassword = () => {
   setNewShowPassword(!showNewPassword)
  };
 
  const handleValidation = () => {
    const { password, confirmPassword} = formData;
    if ( password !== confirmPassword) {
      toast.error(
        "Password and confirmPassword should be the same.",
      );
      return false;
    }  else if (password.length < 8) {
      toast.error(
        "Password must be above 7 characters.",
      );
      return false;
    } 
    return true;
    

  };



  const submitRest = async (e)=>{
    e.preventDefault();
    setIsLoading(true);
    try{
  
       
       
        const data = {
          password: formData.password.trim(),
          confirmPassword: formData.confirmPassword.trim(),
          token:token
        }
       
       
        const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/change-password'
        const res = await axios.post(url,{...data})
        
        setIsLoading(false);
        navigate('/password-reset');
      
        setFormData(initialState);
        
      
    }catch(err){
      setIsLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.msg || "failed to reset password"
      toast.error(errorMessage)
    }
  }
  
  
  return (
    <>
    {
      success ?
      <div className="dashboard-container">
        <div className="aside-container">
          <AsideView />
        </div>
        <div className="dashboard-main">
        <div className="sign-up-wrapper">
        <p className="sign-up-title">Set new password</p>
          <form onSubmit={submitRest} className="sign-up-form">
              <div className="sign-up-inner">
                <div className="input_container" style={{width:"100%"}}>
                  <div className="input-group" style={{justifyContent:"space-between"}}>
                    <input type={showNewPassword ? 'text' : 'password'} placeholder="Password" name="password" className="input-name" onChange={handleChange} required/>
                    <img src="/images/eye.svg" alt="card" style={{width:"16px", height:"16px"}} onClick={handleToggleNewPassword}/>
                  </div>
                  <p className="input-label">Password</p>
                </div>
                
                <div className="input_container" style={{width:"100%"}}>
                  <div className="input-group" style={{justifyContent:"space-between"}}>
                    <input type={showPassword ? 'text' : 'password'} placeholder="Confirm Password" name="confirmPassword" className="input-name" onChange={handleChange} required/>
                    <img src="/images/eye.svg" alt="card" style={{width:"16px", height:"16px"}} onClick={handleTogglePassword}/>
                  </div>
                  <p className="input-label">Confirm Password</p>
                </div>
              </div>
              {
                isLoading ? 
              <button className="btn" style={{background:"#2EBDB6"}}>
                
                  <BeatLoader
                  color="#FFF"
                  cssOverride={{}}
                />
               
              </button>:
              <button className="btn" style={{background:"#2EBDB6"}} onClick={submitRest}>
                  Confirm 
                  <img src={process.env.PUBLIC_URL + "/images/arrow-forward.svg"} alt="arrow btn" style={{ display: "inline", marginLeft: "5px" }}/>
              </button>
              }
            
              <p className="dont-have-account">
              Don’t have an account? 
                <Link to={"/signUp"} className="signIn-link">Sign In
                  <img style={{ display: "inline" }} src={process.env.PUBLIC_URL + "/images/coloredArrowForward.svg"} alt="colored"/>
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>:
      <div className="verify_page">
      <div className='overlay_verify'></div>
      <div className='content_email'>  
          <DotLoader color="#259792" />
      </div>
    </div>
    }
    <ToastContainer />
    </>
  )
}

export default SetNewPassword
