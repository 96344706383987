import React, { useEffect, useState } from "react";
import "../components/sidebar/Sidebar.scss";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function NewBusinessPage() {
  const navigate = useNavigate();
  useEffect(() => {
    getBusinesses();
    // eslint-disable-next-line
  }, []);
  const [selectedOption, setSelectedOption] = useState("existing");
  const [businessList, setBusinessList] = useState([]);
  const [createNewBusiness, setCreateNewBusiness] = useState();
  const [isCreate, setIsCreate] = useState(false);
  const [isDuplicateExisting, setIsDuplicateExisting] = useState(false);
  const {currentUser} = useSelector((state)=>state?.user)
  const [existingData,setExistingData] = useState({})
  const [isLoading,setIsLoading] = useState(false)

  const initialState = {
    existing_business: "",
    existing_business_name: "",
    country: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [isInputClicked, setIsInputClicked] = useState({
    existingBusiness: false,
    existingBusinessName: false,
  });

 

  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setIsDuplicateExisting(value === "existing");
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };
  const handleCancel = () => {};

  const handleCreate = () => {

    // if (isDuplicateExisting) {
    //   createBusiness();
    // }
    // navigate("/business-document", {
    //   state: { businessData: createNewBusiness },
    // });
  };

  const createBusiness = async () => {
    // console.log("selected: ",selectedOption)

    // console.log("data: ",formData)
    setIsLoading(true)
    let data;
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      if(selectedOption==="existing"){
        data ={
          business_name: formData.existing_business_name,
          merchantCode: formData.existing_business,
          country:formData.country
        }
      }else if(selectedOption === "new"){
        data ={
          business_name: formData.existing_business_name,
          merchantCode: formData.existing_business,
          country:formData.country
        }
      }

      // console.log("payload: ",data)
      const url = "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/add-business";
      let res = await axios.post(url,{ ...data },{headers: headers});

      setIsLoading(false)
      const result = res.data
      setExistingData(result)

      if(selectedOption==="existing"){
        navigate("/business-document",{state:{result}})
      }else if(selectedOption === "new"){
        // navigate("/complete-kyc")
        const data = res.data
        navigate("/switch-new-business",{state:{data}})
        // console.log("return value",res.data)
      }
    
    } catch (err) {
      setIsLoading(false)
      console.log(err);
      const errorMessage = err?.response?.data?.message || "enable to create business"
      toast.error(errorMessage)
    }
  };

  const getBusinesses = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-all-businesses",
        headers: headers,
      };

      const response = await axios.request(config);
     

      const newValue = response?.data?.data;

      
      setBusinessList(newValue);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="new-business-page">
      <div className="business-new">
        <p className="new-business-text">New Business</p>
        <p className="create-business">
          You can now create new business or simply duplicate your current
          business.{" "}
        </p>
      </div>
      <div className="new-second">
        <div className="create-duplicate">
          <div className="business-option">
            <input
              type="radio"
              name="options"
              value="new"
              checked={selectedOption === "new"}
              onChange={handleOptionChange}
            />
            <p className="text">Create a new busniess</p>
          </div>
          <div className="business-option">
            <input
              type="radio"
              name="options"
              value="existing"
              checked={selectedOption === "existing"}
              onChange={handleOptionChange}
            />
            <p className="text">Duplicate an existing business</p>
          </div>
        </div>
        <div className="business-input">
          <div
            className="input_container"
            onBlur={() => handleInputBlur("existingBusiness")}
          >
            <div
              className={`input-group ${
                isInputClicked.existingBusiness ? "clicked" : ""
              }`}
              onClick={() => handleInputContainerClick("existingBusiness")}
              onBlur={() => handleInputBlur("existingBusiness")}
              style={{ padding: "0px 16px 10px 16px" }}
            >
              {/* <input  onChange={handleInputChange} type="text" name="existing_business" placeholder="" className="input-name" required/> */}
              <select
                className="input-name"
                name="existing_business"
                onChange={handleInputChange}
                style={{ marginTop: "16px", width: "100%" }}
              >
                <option value="" selected disabled></option>
                {businessList.map((business, index) => (
                  <option key={index} value={business.merchantCode}>
                    {business.business_name}
                  </option>
                ))}
              </select>
            </div>
            <p
              className={`input-label ${
                isInputClicked.existingBusiness ? "clicked" : ""
              }`}
            >
              Existing Business
            </p>
          </div>
          <div
            className="input_container"
            onBlur={() => handleInputBlur("existingBusinessName")}
          >
            <div
              className={`input-group ${
                isInputClicked.existingBusinessName ? "clicked" : ""
              }`}
              onClick={() => handleInputContainerClick("existingBusinessName")}
              onBlur={() => handleInputBlur("existingBusinessName")}
            >
              <input
                onChange={handleInputChange}
                type="text"
                name="existing_business_name"
                placeholder=""
                className="input-name"
                required
              />
            </div>
            <p
              className={`input-label ${
                isInputClicked.existingBusinessName ? "clicked" : ""
              }`}
            >
              Business Name
            </p>
          </div>
          {selectedOption === "new" && (
            <div
              className="input_container"
              onBlur={() => handleInputBlur("country")}
            >
              <div
                className={`input-group ${
                  isInputClicked.country ? "clicked" : ""
                }`}
                onClick={() => handleInputContainerClick("country")}
                onBlur={() => handleInputBlur("country")}
                style={{ padding: "0px 16px 10px 16px" }}
              >
                {/* <input  onChange={handleInputChange} type="text" name="existing_business" placeholder="" className="input-name" required/> */}
                <select
                  className="input-name"
                  name="country"
                  onChange={handleInputChange}
                  style={{ marginTop: "16px", width: "100%" }}
                >
                  <option value="" disabled selected hidden>
                    Country
                  </option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="Uganda">Uganda</option>
                  <option value="Kenya">Kenya</option>
                  <option value="Ghana">Ghana</option>
                </select>
              </div>
              <p
                className={`input-label ${
                  isInputClicked.country ? "clicked" : ""
                }`}
              >
                Country
              </p>
            </div>
          )}
        </div>
        <div className="business-btn">
          <button
            onClick={handleCancel}
            style={{
              color: "#161925",
              background: "#FFF",
              border: "1px solid #EAECF0",
            }}
            className="btn"
          >
            Cancel
          </button>
          {/* {!isCreate ? (
            <button onClick={handleCreate} className="btn">
              Create Business
            </button>
          ) : (
            <button className="btn">
              <BeatLoader color="#FFF" cssOverride={{}} />
            </button>
          )} */}

            <button onClick={createBusiness} className="btn">
              {
                isLoading ?(
                  <BeatLoader color="#FFF" cssOverride={{}} />
                ):(
                  <>
                  Create Business
                  </>
                )
              }
            </button>

        </div>
      </div>
    </div>
  );
}

export default NewBusinessPage;
