import React, { useState } from 'react'
import '../common/tables.scss'
import TableHeaderSearch from '../common/TableHeaderSearch'
import Status from '../common/Status'
import CustomerInflowOverview from './CustomerInflowOverview'
import { formatDate } from '../utilities/formatDate'
import { changeCpage, nextPage, prePage } from '../utilities/pagination/pagination'
function CustomerOutFlow({customerData,item,customerTableData}) {
  const [selectedValue,setSelectedValue] = useState('NGN')
  const [currentPage,setCurrentPage] = useState(1)

 
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = customerTableData?.slice(firstIndex,lastIndex);
  const nPage = Math.ceil(customerTableData?.length/recordsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1)
  return (
    <div className='balance-overview'>
     <CustomerInflowOverview customerData={customerData} item={item}/>
     <table className='tbl' style={{marginBottom:"32px"}}>
        <tr>
          <td colSpan="5" className='th-text'>Details</td>
        </tr>

        <tr >
          <td className='td-text' >
            <div>
                <p className='detail_desc_text'>BVN</p>
                <h6 className='customer_detail_text'>{item?.bvn || "N/A"}</h6>
            </div>
          </td>
          <td className='td-text'>
            <div>
                <p className='detail_desc_text'>NIN</p>
                <h6 className='customer_detail_text'>{item?.nin || "N/A"}</h6>
            </div>
          </td>
          <td className='td-text'>
            <div>
                <p className='detail_desc_text'>Email</p>
                <h6 className='customer_detail_text'>{item?.email || "N/A"}</h6>
            </div>
          </td>
          <td className='td-text'>
            <div>
                <p className='detail_desc_text'>Phone</p>
                <h6 className='customer_detail_text'>{item?.mobile || "N/A"}</h6>
            </div>
          </td>
          <td className='td-text'>
            <div>
                <p className='detail_desc_text'>Created On</p>
                <h6 className='customer_detail_text'>{item?.dated_created && formatDate(item?.dated_created)}</h6>
            </div>
          </td>
        </tr>
      </table>

    <table className='tbl'>
      <tr>
          <td colSpan="7" className='tbl-heading'>
              <TableHeaderSearch 
              title="Transactions"
              placeholder="Search by name, acc num or reff..."
              />
          </td>
      </tr>
      <tr>
          <th className='th-text'>Timestamp</th>
          <th className='th-text'>Recipient Account</th>
          <th className='th-text'>Amount</th>
          <th className='th-text'>Transaction Details</th>
          <th className='th-text'>Status</th>
      </tr>

      {
          Array.isArray(customerTableData) && customerTableData.length > 0 && records.map((item,index)=>(
            <tr>
                <td className='td-text'>2 hours ago</td>
                <td className='td-text'>
                    <div className='tbl-account'>
                        <img className='account-bank-logo' src={process.env.PUBLIC_URL+"/images/paymentBank.svg"} alt="" />
                        <div className='payment-account-text'>
                            <h1 className='td-text'>GTBank</h1>
                            <span className='td-text'>|0130086553</span>
                        </div>
                    </div>
                </td>
                <td className='td-text' style={{color:"#101828"}}>NGN 848,67.06</td>
                
                <td className='td-text'>FEE|NDAKO GAGAMU SHOLA</td>
                <td>
                    <div className='payment-status'>
                        <Status 
                        icon="/images/success.svg"
                        mgs="Successful "
                        />

                        <img src={process.env.PUBLIC_URL+"/images/eye.svg"} alt='eye'/>
                        
                    </div>
                </td>

            </tr>
            ))
      }
      {/* duplicate */}

      {
        customerTableData.length <= 0 &&
        <tr>
            <td  colSpan={7} style={{width:"100%"}}>
            <div className='empty_tbl'>
                <img src='/images/emptyPage.svg' alt='emptuy' />
                <h3 className='num_trans'>No Customer Details Yet</h3>
                {/* <p className='num_desc'> it is necessary to perform transactions on your Flick account</p> */}
            </div>
            </td>
        </tr>
        }
      
      {/* <tr>
          <td className='td-export' colspan="7">
              <div className='tbl-export'>
                  <h6 className='tbl-export-text'>Export Data</h6>
                  <img src={process.env.PUBLIC_URL+"/images/export.svg"} alt='export' />               
              </div>
          </td>
      </tr> */}


{
            numbers?.length >0 &&
            <div className='pagination_container'>
                <div className='page-item' onClick={()=>prePage(currentPage,setCurrentPage)}>
                    <img src='/images/pageLeft.svg' alt='' />
                    <p className='Page_prev'>Previous</p>
                </div>   

                <div className='pageNumbers'>
                    {
                    numbers?.length > 0 && numbers.map((n,i)=>(
                        <span key={i} 
                        className={`page_num ${currentPage === n ? 'active':''}`}
                        onClick={()=>changeCpage(setCurrentPage,n)}
                        >
                        {n}
                        </span>
                    ))
                    }

                </div> 

                <div className='page-item' onClick={()=>nextPage(currentPage,setCurrentPage,nPage)}>
                    <img src='/images/pageRight.svg' alt='' />
                    <p className='Page_prev'>Next</p>
                </div> 
            </div>
        }
      
    </table>
  </div>
  )
}

export default CustomerOutFlow
