import React from "react";
import '../../modals/Modal.scss'

function RemoveMember({setIsOpen, setIsRemoveMember, setIsMemberDeleted}) {
   

    const handleCancel = () => {
        setIsRemoveMember(false)
        setIsOpen(true)
    }

    const handleRemove = ()=>{
        setIsRemoveMember(false)
        setIsMemberDeleted(true)
    }



  return (
    <div className="overlay">
    <div className="enter-amount-modal" style={{maxWidth:"498px"}}>
      <div className="modal-wrapper" style={{width:"100%"}}>
        <div style={{display:"flex", justifyContent:"flex-end"}}>
            <img style={{cursor:"pointer"}} onClick={handleCancel} src={process.env.PUBLIC_URL + "/images/x-close.svg"} alt="icon" />
        </div>
            <div className="member-role" style={{paddingBottom:"16px"}}>
                <p className="change-member">Delete member</p>
                <p className="manually">Manually authenticate KYCs in real-time.</p>
            </div>

            <div className="confirmation">
                <p className="confirm-text">You’re about to remove a member from your tem, they will no longer have access to this dashboard. This action cannot be undone.</p>
            </div>
            <button onClick={handleRemove} className="btn-" style={{width:"50%", background:"#ED1C24"}}>
            Remove member
            </button>
        </div>
    </div>
    </div>
  );
}

export default RemoveMember;
