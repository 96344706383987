import React, { useEffect, useState } from 'react'
import '../common/tables.scss'
import TableHeaderSearch from '../common/TableHeaderSearch'
import Status from '../common/Status'
import { changeCpage, nextPage, prePage } from '../utilities/pagination/pagination'
import DetailsOverview from './DetailsOverview'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'


function DirectDebitDetails() {
  const { currentUser } = useSelector((state) => state?.user);
  const navigate = useNavigate();
  const { transactionId } = useParams();
  const location = useLocation();
  const { name } = location.state || {};
  const [copiedText, setCopiedText] = useState('');

  useEffect(()=>{
    getDirectDebitDetails();
// eslint-disable-next-line
  },[])
  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setCopiedText(text);
    toast.success('Copied successfully');
};
  const formatDate = (dateString) => {
    if (!dateString) {
      return 'N/A'; 
    }
    
    const date = new Date(dateString);
    
    if (isNaN(date.getTime())) {
      return 'N/A'; 
    }
  
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    }).format(date);
  };

  const formattedDate = (dateString) => {
    const date = new Date(dateString.replace(' ', 'T'));
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const [directDebitDetailsData, setDirectDebitDetailsData] = useState([]);
  const [currentPage,setCurrentPage] = useState(1)

 
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = directDebitDetailsData?.matdate_transaction?.slice(firstIndex,lastIndex);
  const nPage = Math.ceil(directDebitDetailsData?.matdate_transaction?.length/recordsPerPage);
  const numbers = nPage > 0 ? [...Array(nPage + 1).keys()].slice(1) : [];

  const handleBack = ()=>{
    navigate("/direct-debit")
  }

  const getDirectDebitDetails = async ()=>{
    // setIsDirectDebitLoading(true);
  try{
    
    const headers = {  
      "api_key": currentUser.user.merchantKey, 
      // "api_key": 'pk-U2FsdGVkX1+G0GIBy/v01n8QXOIpa8UY0TowXc0uOLCbVZcSo6qDBKUKoKjXljThxyNpswIBPzBonqGqqMk5YqXq4rGvoTCiQeJH1mv324Or+tmuTPej23p42JWdxmWS', 
    }

    const url = `https://flick-app.com/direct-debit/mandate-details?transactionId=${transactionId}`;
    let res = await axios.get(url,{
      headers:headers
    })

    console.log ("Debit::", res)
    setDirectDebitDetailsData(res?.data || [])
  }catch(err){
    console.log(err)
    const errorMessage = err?.message || "unable to fetch data"
  }
}
  return (
    <div className='balance-overview'>
     <div onClick={handleBack} className='btnData' style={{marginBottom:"32px", cursor:"pointer"}}>
        <img src={process.env.PUBLIC_URL+"/images/arrowBack.svg"} alt='arrow' />
        <p>Go Back</p>
     </div>
     <DetailsOverview data={directDebitDetailsData} transactionId={transactionId} name={name}/>
     <table className='tbl' style={{marginBottom:"32px"}}>
        <tr>
          <td colSpan="7" className='th-text'>Details</td>
        </tr>

        <tr >
          <td className='td-text' >
            <div>
                <p className='detail_desc_text'>BVN</p>
                <h6 className='customer_detail_text'>{directDebitDetailsData?.mandate_data?.bvn}</h6>
            </div>
          </td>
          <td className='td-text'>
            <div>
                <p className='detail_desc_text'>Email</p>
                <h6 className='customer_detail_text'>{directDebitDetailsData.mandate_data?.email}</h6>
            </div>
          </td>
          <td className='td-text'>
            <div>
                <p className='detail_desc_text'>Phone</p>
                <h6 className='customer_detail_text'>{directDebitDetailsData.mandate_data?.phone}</h6>
            </div>
          </td>
          <td className='td-text'>
            <div>
                <p className='detail_desc_text'>Linked accounts</p>
                <h6 className='customer_detail_text'>{directDebitDetailsData.mandate_data?.account_count}</h6>
            </div>
          </td>
          <td className='td-text'>
            <div>
                <p className='detail_desc_text'>Date Initiated</p>
                <h6 className='customer_detail_text'>{formatDate(directDebitDetailsData.mandate_data?.dated)}</h6>
            </div>
          </td>
          <td className='td-text'>
            <div>
                <p className='detail_desc_text'>Status</p>
                {
                    directDebitDetailsData.mandate_data?.mandate_status === "active" &&
                    <Status 
                    mgs="Active"
                    bg="#ECFDF3"
                    border="#ABEFC6"
                    color="#067647"
                    />
                }
                  {
                    directDebitDetailsData.mandate_data?.mandate_status === "expired" || directDebitDetailsData.mandate_data?.mandate_status === "pending" || directDebitDetailsData.mandate_data?.mandate_status === "deactivated" &&
                    <Status 
                    mgs={directDebitDetailsData.mandate_data?.mandate_status}
                    bg="#F9FAFB"
                    border="#EAECF0"
                    color="#344054"
                    />
                }
            </div>
          </td>
        </tr>
      </table>

    <table className='tbl'>
      <tr>
          <td colSpan="7" className='tbl-heading'>
              <TableHeaderSearch 
              title="All Transactions"
              placeholder="Search by name..."
              numUsers={directDebitDetailsData?.matdate_transaction?.length || 0}
              users=" total"
              btnFirst
              />
          </td>
      </tr>
      <tr>
          <th className='th-text'>Date</th>
          <th className='th-text'>Session ID</th>
          <th className='th-text'>Bank/Account No</th>
          <th className='th-text'>Amount</th>
          <th className='th-text'>Status</th>
      </tr>

      {
          Array.isArray(directDebitDetailsData?.matdate_transaction) && directDebitDetailsData?.matdate_transaction.length > 0 && records.map((item,index)=>( 
            <tr key={index}>
                <td className='td-text'>{formattedDate(item.date_raw)}</td>
                {/* <td className='td-text'>{item.sessionId}</td> */}
                <td className='td-text'>
                  <div style={{display:"flex",gap:"5px"}}>
                      <input className='link_table'  value={item.sessionId}  hidden  readOnly/>
                      <h1 className='td-text'>{item.payment_status == false? item.sessionId :(item.sessionId).length > 10 ?String(item.sessionId).slice(0, 11) + "..." :item.sessionId}</h1>
                     {item.payment_status == false ? "" : <img
                      src={process.env.PUBLIC_URL + "/images/copy-key-icon.svg"}
                      alt="ic" onClick={() => handleCopyToClipboard(item.sessionId)} style={{ cursor: 'pointer' }}/> }
                  </div>
                </td>
                <td className='td-text'>
                    <div className='tbl-account'>
                        <img className='account-bank-logo' src={item.bank_image} alt="" />
                        <div className='payment-account-text'>
                            <h1 className='td-text'>{item.bank}</h1>
                            <span className='td-text'>|{item.account_no}</span>
                        </div>
                    </div>
                </td>
                <td className='td-text' style={{color:"#101828"}}>NGN {item.amount}</td>
                <td>
                    <div className='payment-status'>
                        {
                            item.status === "success" &&
                            <Status 
                            mgs="Successful"
                            bg="#ECFDF3"
                            border="#ABEFC6"
                            color="#067647"
                            />
                        }
                          {
                            item.status === "expired" &&
                            <Status 
                            mgs="Pending"
                            bg="#F9FAFB"
                            border="#EAECF0"
                            color="#344054"
                            />
                        } 
                          {
                            item.status === "not-initiated" &&
                            <Status 
                            mgs={item.status}
                            bg="#F9FAFB"
                            border="#EAECF0"
                            color="#344054"
                            />
                        } 
                          {
                            item.status === "failed" &&
                            <Status 
                            mgs={item.status}
                            bg="#F9FAFB"
                            border="#EAECF0"
                            color="#344054"
                            />
                        } 
                        
                          {
                            item.status === "canceled" &&
                            <Status 
                            mgs={item.status}
                            bg="#F9FAFB"
                            border="#EAECF0"
                            color="#344054"
                            />
                        } 
                    </div>
                </td>

            </tr>
           )) 
    } 
      {/* duplicate */}
{/* 
      {
        directDebitDetailsData.length <= 0 &&
        <tr>
            <td  colSpan={7} style={{width:"100%"}}>
            <div className='empty_tbl'>
                <img src='/images/emptyPage.svg' alt='emptuy' />
                <h3 className='num_trans'>No Transactions Yet</h3> */}
                {/* <p className='num_desc'> it is necessary to perform transactions on your Flick account</p> */}
            {/* </div>
            </td>
        </tr>
        } */}


{
            numbers?.length >0 &&
            <div className='pagination_container'>
                <div className='page-item' onClick={()=>prePage(currentPage,setCurrentPage)}>
                    <img src='/images/pageLeft.svg' alt='' />
                    <p className='Page_prev'>Previous</p>
                </div>   

                <div className='pageNumbers'>
                    {
                    numbers?.length > 0 && numbers.map((n,i)=>(
                        <span key={i} 
                        className={`page_num ${currentPage === n ? 'active':''}`}
                        onClick={()=>changeCpage(setCurrentPage,n)}
                        >
                        {n}
                        </span>
                    ))
                    }

                </div> 

                <div className='page-item' onClick={()=>nextPage(currentPage,setCurrentPage,nPage)}>
                    <img src='/images/pageRight.svg' alt='' />
                    <p className='Page_prev'>Next</p>
                </div> 
            </div>
        }
      
    </table>
  </div>
  )
}

export default DirectDebitDetails
