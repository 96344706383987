import React, { useState } from "react";
import './Modal.scss'
import { useSelector } from 'react-redux';
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

function FundOtherBalance({setIsCardMethod,getLinkedCards,setIsCommingSoon,payoutCurrency,setIsApiPayout,setIsOtherBalance,setIsAmountNormal,setIsPayment,setIsBalanceOpen}) {
    const [selectedOption, setSelectedOption] = useState('');
    const { currentUser } = useSelector((state) => state?.user);
    const [isLoading,setIsLoading] = useState(false);
  
    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
      
    };

    const handleClose = ()=>{
      setIsOtherBalance(false);
      setIsAmountNormal(false);
      setIsPayment(false)
        if(setIsBalanceOpen){
            setIsBalanceOpen(false)
        }

    }
    const handlePayout = (e)=>{
      
      e.preventDefault();
      setIsCommingSoon(true);
    }
  
    const handleApiWallet = (e)=>{
      e.preventDefault();
      
      setIsApiPayout(true)
    }
  
    const handleCard = ()=>{
     
      setIsCardMethod(true);
    }
    
   
  
    const callPaymentOption = ()=>{
      if(selectedOption === 'card') return  getLinkedCards();
      if(selectedOption === 'payout') return 
    }
  return (
    <div className="overlay">
    <div className="enter-amount-modal">
      <div className="modal-wrapper">

        <div>
        <img onClick={setIsOtherBalance.bind(this,false)} style={{cursor:"pointer"}} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          <p className="enter-amount">Fund Payout Balance</p>
          <p className="enter-an-amount">Select your payment method</p>
        </div>
        <div className="fund-options">
        {
            payoutCurrency !== "USD" &&
            <>    
              <div className={`card-option ${selectedOption === 'card' ? 'selected' : ''}`} onClick={() => setSelectedOption('card')}>
                  <div className="icon-bank">
                  <img src={process.env.PUBLIC_URL + "/images/balanceTransfer.svg"} alt="icon" />
                  <div className="transfer-text">
                      <p className="card-payment">Card Payment</p>
                      <p className="card-top-up">Top up your balance via your card.</p>
                  </div>
                  </div>
                  <input 
                  type="radio" 
                  name="options"
                  value="card"
                  checked={selectedOption === 'card'}
                  onChange={handleOptionChange}
                  />
              </div>
              
            </>
          }
          <div className={`card-option ${selectedOption === 'payout' ? 'selected' : ''}`} onClick={() => setSelectedOption('payout')}>
              <div className="icon-bank">
              <img src={process.env.PUBLIC_URL + "/images/balanceTransfer.svg"} alt="icon" />
              <div className="transfer-text">
                  <p className="card-payment">Other Balances</p>
                  <p className="card-top-up">Fund your wallet from your payout balance</p>
              </div>
              </div>
              <input 
              type="radio" 
              name="options"
              value="payout"
              checked={selectedOption === 'payout'}
              onChange={handleOptionChange}
              />
          </div>
          

        
        
        </div>


        <div className="modal-btn">
          <button onClick={handleClose} className="sec-btn">
            Cancel
          </button>
          <button onClick={selectedOption === 'card'? handleCard:selectedOption === 'payout'? handlePayout:"" } className="btn">
            {/* {
              isLoading ?
              <BeatLoader
                color="#FFF"
                cssOverride={{}}
              />:
              <>
              Confirm
              </>
            } */}
            Confirm
          </button>
          
        </div>
        </div>
    </div>
    </div>
  )
}

export default FundOtherBalance