import React, { useEffect, useState } from 'react'
import NameControl from '../components/common/NameControl'
import '../components/common/Pages.scss'
import CustomerOverView from '../components/common/CustomerOverView';
import CustomerInFlow from '../components/payment/CustomerInFlow';
import CustomerOutFlow from '../components/payment/CustomerOutFlow';
import CustomerInflowOverview from '../components/payment/CustomerInflowOverview';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import TableLoader from '../components/common/TableLoader';
import { useSelector } from 'react-redux';
function PaymentCustomer() {
    const navigate = useNavigate();
    const { currentUser } = useSelector((state) => state?.user);
    const [btnActive,setBtnActive] = useState(false);
    const [selectedButton, setSelectedButton] = useState("Inflow");
    const [isCustomerLoading,setIsCustomerLoading] = useState(false);
    const [customerData,setCustomerData] = useState({});
    const [customerTableData,setCustomerTableData] = useState([]);
   
    
    const location = useLocation();
    const { item } = location.state || {};

    
    useEffect(() => {
      if (!item) {
          // If item is not present, redirect to the previous page
          navigate(-1); // Go back to the previous page
          return;
    }

      // Fetch the customer wallet data if item is present
      getSingleCustomerWallet();
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [item]); // Dependency array includes `item` to rerun effect if `item` changes
   
    const handleButtonClick = (button) => {
      setSelectedButton(button);
    };

    const getSingleCustomerWallet = async()=>{
      setIsCustomerLoading(true);
      try{
      
        const headers = {  
          "api_key": currentUser.user.merchantKey, 
          // "api_key": "pk-U2FsdGVkX1+i2IXLRQDL4rNPqeJGDhpdHJ/jSzDf8AiVsrEh6BB2tQHvbI3pVkXY4lPonli/Xuscb0QDSS/+JfIex2Gi8dABLmyvXSRTZf7xYn3xUWp1TjT42ZneiicW", 
         
        }
  
        const url = `https://flick-app.com/wallets/wallet-transactions?account_id=${item?.account_id}`;
        let res = await axios.get(url,{
          headers:headers
        })
  
        const newValue = res.data.data
        
        // console.log("single value",newValue)
        setCustomerData(newValue || {});
        setCustomerTableData(res.data.data.data);
        setIsCustomerLoading(false)
         
      }catch(err){
        console.log(err)
        setIsCustomerLoading(false)
        const errorMessage = err?.message || "unable to fetch data"
       
      }
    }

    const renderContent = () => {
        if(selectedButton === 'Inflow') return <CustomerInFlow customerData={customerData} item={item} customerTableData={customerTableData}/>;
        if(selectedButton ==='Outflow') return <CustomerOutFlow customerData={customerData} item={item} customerTableData={customerTableData}/>;
    };
  return (
   
            <div className='main-inner'>
               
                <NameControl 
                customer
                btnFirst="Inflow"
                btnSecond="Outflow"
                btnActive={btnActive} 
                handleButtonClick ={handleButtonClick}
                backbtnPath="/payment-customers"
                />

                {/* <CustomerInflowOverview /> */}

               {renderContent()}

               {
                isCustomerLoading && 
                <TableLoader msg />
               }
            </div>
     
  )
}

export default PaymentCustomer
