import { useState, useEffect } from 'react';

function useFilterAndPagination(data, rowsPerPage = 10) {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [dateRange, setDateRange] = useState({
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    });
    const [isDateRangeChanged, setIsDateRangeChanged] = useState(false);
    const [isSearchTermChanged, setIsSearchTermChanged] = useState(false);
  
    useEffect(() => {
      if (isDateRangeChanged || isSearchTermChanged) {
        setCurrentPage(1); // Reset to first page when filters change
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange, searchTerm]);
  
    const handleDateChange = (ranges) => {
      setDateRange({
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
        key: 'selection'
      });
      setIsDateRangeChanged(true);
    };
  
    const handlePageChange = (page) => {
      setCurrentPage(page);
    };
  
    let filteredRecords = data;

    if (isDateRangeChanged) {
      filteredRecords = filteredRecords.filter(record => {
        const recordDate = new Date(record.dated);
        return recordDate >= dateRange.startDate && recordDate <= dateRange.endDate;
      });
    }
  
    if (isSearchTermChanged) {
      filteredRecords = filteredRecords.filter(record => {
        if (!record) return false; // Add a check to skip undefined or null records
        const lowerCaseTerm = searchTerm.toLowerCase();
        return (
          (record.transactionid && record.transactionid.toLowerCase().includes(lowerCaseTerm)) ||
          (record.narration && record.narration.toLowerCase().includes(lowerCaseTerm)) ||
          (record.fullname && record.fullname.toLowerCase().includes(lowerCaseTerm))||
          (record.idType && record.idType.toLowerCase().includes(lowerCaseTerm)) ||
          (record.pageName && record.pageName.toLowerCase().includes(lowerCaseTerm)) ||
          (record.type && record.type.toLowerCase().includes(lowerCaseTerm)) ||
          (record.status && record.status.toLowerCase().includes(lowerCaseTerm)) ||
          (record.name && record.name.toLowerCase().includes(lowerCaseTerm))||
          (record.transactionId && record.transactionId.toLowerCase().includes(lowerCaseTerm))
        );
      });
    }
  
    const paginatedRecords = filteredRecords.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
  
    
    return {
      currentPage,
      setCurrentPage,
      searchTerm,
      setSearchTerm,
      setIsSearchTermChanged,
      dateRange,
      handleDateChange,
      handlePageChange,
      filteredRecords,
      paginatedRecords,
      totalRecords: filteredRecords.length
    };

    
}

export default useFilterAndPagination