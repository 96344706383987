import React from 'react'

function CurrencyBalancesSuccess({setIsTransferSuccess,setIsConfirmTransfer,setIsOpenPopup,setIsPayout}) {
    const handleCancel = ()=>{
        setIsPayout()
        // setIsOpenPopup(false);
        setIsTransferSuccess(false)
        setIsConfirmTransfer(false);
        // setNGNPortfolioAmount(false)
        // setIsTransferPortfolioAmount(false)
        // setIsTransferPortfolio(false)
        // setIsTransferOption(false);
        // setIsBalanceOpen(false)
    }
  return (
<div className='balance_modal' >
       <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>

        <div style={{marginTop: "16px"}}>
        <img src={process.env.PUBLIC_URL + "/images/iconSuccess.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          <p className="enter-amount">Transaction Successful</p>
          
          <p className="enter-an-amount">Your transaction has been initiated, check your balance</p>
        </div>

        <div className="transact-amount">
          <p className="t-amount">Transaction amount</p>
          <p className="amount">N4,000.00</p>
        </div>
        <div className="portfolio-btn">
          <button onClick={handleCancel} className="sec-btn">
            Cancel
          </button>
          <button className="btn" >
            Download Receipt
          </button>
        </div>
        </div>
    </div>
    </div>
  )
}

export default CurrencyBalancesSuccess



