
import React, { useState } from "react";
import './Modal.scss'
import { useSelector } from 'react-redux';
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
function ApiPayoutBalance({setIsApiPayout,balanceData,setApiCurrency,isApiFundLoading,handleApiOtherBalance}) {
  const [selectedOption, setSelectedOption] = useState('');
  const { currentUser } = useSelector((state) => state?.user);
  const [isLoading,setIsLoading] = useState(false)
  const handleOptionChange = (event) => {
    setApiCurrency(event.target.value)
    setSelectedOption(event.target.value);
  };
  const handleNaira = (e)=>{
    handleApiOtherBalance(selectedOption);
  }
  const handleDollar = (e)=>{
    handleApiOtherBalance(selectedOption)
  }
  const handleCanle =()=>{
    
    setIsApiPayout(false)
  }
  return (
    <div className="overlay">
        <div className="enter-amount-modal">
            <div className="modal-wrapper">

            <div>
            <img onClick={setIsApiPayout.bind(this,false)} style={{cursor:"pointer"}} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
            </div>
            
            <div className="modal-title">
                <p className="enter-amount">Select Balance To Pay from</p>
            </div>
            <div className="fund-options">
            <div className={`card-option ${selectedOption === 'NGN' ? 'selected' : ''}`} onClick={() => setSelectedOption('NGN')}>
                <div className="icon-bank">
                <img src={process.env.PUBLIC_URL + "/images/amount.svg"} alt="icon" />
                <div className="transfer-text">
                    <p className="card-payment" style={{fontSize:"1.5rem"}}>{ balanceData[0]?.payout_balance ? (balanceData[0]?.payout_balance)/100:"0"}</p>
                    <p className="card-top-up">Fund your wallet from your Naira Payout balance</p>
                </div>
                </div>
                <input 
                type="radio" 
                name="options"
                value="NGN"
                checked={selectedOption === 'NGN'}
                onChange={handleOptionChange}
                />
            </div>

                   
            <div className={`card-option ${selectedOption === 'USD' ? 'selected' : ''}`} onClick={() => setSelectedOption('USD')}>
                <div className="icon-bank">
                <img src={process.env.PUBLIC_URL + "/images/amountDollar.svg"} alt="icon" />
                <div className="transfer-text">
                    <p className="card-payment" style={{fontSize:"1.5rem"}}> {balanceData[1]?.payout_balance ? (balanceData[1]?.payout_balance)/100:"0"}</p>
                    <p className="card-top-up">Fund your wallet from your Dollar payout balance</p>
                </div>
                </div>
                <input 
                type="radio" 
                name="options"
                value="USD"
                checked={selectedOption === 'USD'}
                onChange={handleOptionChange}
                />
            </div>
            
            </div>

            <div className="modal-btn">
                <button onClick={handleCanle} className="first-btn">
                    Cancel
                </button>
                <button onClick={selectedOption === 'NGN' ? handleNaira:selectedOption === 'USD'? handleDollar:""} className="btn">
                    {
                    isApiFundLoading ?
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />:
                    <>
                    Confirm
                    </>
                    }
                    
                </button>
            
            </div>
            </div>
        </div>
    </div>
  )
}

export default ApiPayoutBalance