import React from 'react'

function CurrencyBalancesTransfer({setIsTransferSuccess,setIsConfirmTransfer,setIsOpenPopup,setIsPayout}) {
 const handleCancel = ()=>{
     setIsPayout(false);
    setIsConfirmTransfer(false);
 }
 const  handleInputChange = (event)=>{
    
 }
 const handleBox =(e)=>{
   e.stopPropagation();
   setIsConfirmTransfer(false)
 }
 const handleSuccess =(e)=>{
  e.stopPropagation();
  setIsTransferSuccess(true)
}
  return (
    <div className='balance_modal' >
       <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>

            <div style={{display:"flex", justifyContent:"space-between"}}>
            <img style={{marginTop: "16px"}} src={process.env.PUBLIC_URL + "/images/confirm-transfer.svg"} alt="icon" />
            <img style={{cursor:"pointer"}} onClick={handleBox} src={process.env.PUBLIC_URL + "/images/x-close.svg"} alt="icon" />
            </div>
            
            <div className="modal-title">
            <p className="enter-amount">Confirm Transfer</p>
            
            <p className="enter-an-amount">You are about to transfer  <span style={{color:"#151F32",fontWeight:"700"}}>USD 5.00 </span> to <span style={{color:"#151F32",fontWeight:"700"}}>  QRABA LIMITED,</span> 100018799.</p>
            </div>

            <div className="api_wallet_input" style={{background:"none"}}>
                <p className="portfolio_enter_amount" >Enter the confirmation code sent to <br/><span style={{color:"#151F32",fontWeight:"500"}}>ayomide@getflick.app</span></p>
                <input  name='amount' className='api_input_amounts'  placeholder="Enter code" onChange={handleInputChange}/>

                <p className="enter-an-amount" style={{textAlign:"center",marginTop:"16px"}}><span className='api_resend'> <span className='get-a-code'>Didn’t get a code?</span> Resend code <img src="/images/resend.svg" alt='resend' style={{display:"inline"}}/></span></p>
                <div className="portfolio-btn">
                    <button onClick={handleCancel} className="sec-btn">
                        Cancel
                    </button>
                    <button onClick={handleSuccess} className="btn">
                    Confirm
                    </button>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default CurrencyBalancesTransfer
