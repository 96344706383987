import React, { useEffect, useState } from 'react'
import Transactions from '../components/data/Transactions'
import { useSelector } from 'react-redux';
import axios from 'axios';

function DataTransactions() {
  useEffect(()=>{
    getTransactions();
    // eslint-disable-next-line
  },[])
  const [transactionData,setTransactionData] = useState([]);
  const {currentUser} = useSelector((state)=>state?.user)

  const [isTransactionLoading,setIsTransactionLoading]= useState(false)

  const getTransactions = async ()=>{  
    setIsTransactionLoading(true)
    try{
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        "sweetapi": currentUser.user.merchantCode  
      }
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/linked-transaction-data';
      let res = await axios.get(url,{
        headers:headers
      })
      const data = res?.data?.data
      setTransactionData(data);
      setIsTransactionLoading(false)
      // setBalanceData(newValue || []);
       
    }catch(err){
      setIsTransactionLoading(false)
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
     
    }
  }
  return (
    
    <div className='main-inner'>
        <Transactions isTransactionLoading={isTransactionLoading} transactionData={transactionData} />
    </div>
      
  )
}

export default DataTransactions
