import React, {useState} from 'react'
import Payment from './productPricing/Payment';
import Identity from './productPricing/Identity';
import FinancialData from './productPricing/FinancialData';

function ProductPricing() {

    const [activeTab, setActiveTab] = useState("payment");

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };

  return (
    <div className="header-form-upload">
      <div className="header-border">
        <div className="settings-header">
          <p
            className={activeTab === "payment" ? "active" : ""}
            onClick={() => handleTabClick("payment")}
          >
            Payment
          </p>
          <p
            className={activeTab === "financial" ? "active" : ""}
            onClick={() => handleTabClick("financial")}
          >
            Financial Data
          </p>
          <p
            className={activeTab === "identity" ? "active" : ""}
            onClick={() => handleTabClick("identity")}
          >
            Identity
          </p>
        </div>
      </div>
      <div className="form-upload">
        {activeTab === "payment" && <Payment />}
        {activeTab === "financial" && <FinancialData /> }
        {activeTab === "identity" && <Identity /> }
      </div>
  </div>
  )
}

export default ProductPricing
