import React from 'react'
import '../components/common/tables.scss'
import TableHeaderSearch from '../components/common/TableHeaderSearch'
import { useNavigate } from 'react-router-dom'
import { format } from 'timeago.js';

function ApiWalletHistory({collectionData}) {
    const navigate = useNavigate();
  const handleNavigate = ()=>{
    navigate('/overview')
  }
  return (
    <div className='main-inner'>
      {console.log("collectionData: ",collectionData)}
      <div onClick={handleNavigate} className='btnData' style={{marginBottom:"32px"}} >
        <img src={process.env.PUBLIC_URL+"/images/arrowBack.svg"} alt='arrow' />
        <p>Go Back</p>
      </div>
      <div className='api_wallet_top'>
        <h1 className='bar_wallet_texts'>API Wallet history</h1>
      </div>
      <div className='balance-overview'>
      <table className='tbl'>
        <tr>
            <td colSpan="7" className='tbl-heading'>
                <TableHeaderSearch
                title="Transactions" 
                placeholder="Search by name or date..."
                />
            
            </td>
        </tr>
        {
          Array.isArray(collectionData) && collectionData.length > 0  &&
          <tr>
            <th className='th-text'>Timestamp</th>
            <th className='th-text'>Amount</th>
            <th className='th-text'>Balance Before</th>
            <th className='th-text'>Balance After</th>
            <th className='th-text'>Transaction Details</th>
          </tr>
        }

        {
           Array.isArray(collectionData) && collectionData.length > 0 && collectionData.map((item,index)=>(
            <tr key={item.transactionid}>
                <td className='td-text'>{format(item?.dated)}</td> 
                <td className='td-text amount_text'>NGN {item?.total_amount}</td>
                <td className='td-text'>{item?.balance_before}</td>
                <td className='td-text'>{item?.balance_after}</td>
                <td className='td-text'>{item?.narration}</td>
            </tr>
           ))
        }
        {
           Array.isArray(collectionData) && collectionData.length > 0 &&
          <tr>
              <td className='td-export' colspan="7">
                  <div className='tbl-export'>
                      <h6 className='tbl-export-text'>Export Data</h6>
                      <img src={process.env.PUBLIC_URL+"/images/export.svg"} alt='export' />               
                  </div>
              </td>
          </tr>
        }
        {
            collectionData.length <= 0 &&
            <tr>
             <td  colSpan={7} style={{width:"100%"}}>
                <div className='empty_tbl'>
                    <img src='/images/emptyPage.svg' alt='emptuy' />
                    <h3 className='num_trans'>No Wallet History Yet</h3>
                    <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                </div>
             </td>
            </tr>
           }
      </table>
    </div>
    </div>
  )
}

export default ApiWalletHistory
