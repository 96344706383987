import React from 'react'
import './data.scss'
import Status from '../common/Status'
import { formatDate } from '../utilities/formatDate'

function BankVerificationNumber({setVerificationResult,identityResponse}) {
    
  return (
    <div className='overlay' onClick={setVerificationResult.bind(this,null)}>
        <div className='verify-box'>
            <div className='verify-box-wrapper'>
                <img onClick={setVerificationResult.bind(this,null)} className='verify-modal-close' src='/images/x-close.svg' alt='f'/>
            <div className='verify-title'>
                <img src='/images/verify-check-circle.svg' alt='f'/>
                <h3>Bank Verification Number</h3>
                <p>Verification Status: <span><Status
                        icon="/images/success.svg"
                        mgs="Successful"
                        /></span>
                </p>
            </div>
                <div  className='verify-line'></div>
                <div className='verify-image'>
                    {/* <div className='avater_template'> */}
                        {/* <span>{`${String(identityResponse?.firstname).slice(0,1)} ${String(identityResponse?.lastname).slice(0,1)}`}</span> */}
                        <img className='identity_img' src={identityResponse?.image} alt='f'/>
                    {/* </div> */}
                    {/* <img className='verify-img' src='/images/tope.svg' alt='f'/> */}
                </div>

                <div className='verify-overview'>
                    <div className='verify-content'>
                        <div className='verify-content-title'>
                            <p className='verify-title-th'>BVN</p>
                            <p className='verify-title-th'>NIN</p>
                        </div>
                        <div className='verify-content-title'> 
                            <p className='verify-title-td'>{identityResponse?.full_details?.bvn || "Not Available"}</p>
                            <p className='verify-title-td'>Not Available</p>
                        </div>
                    </div>

                    <div className='verify-content'>
                        <div className='verify-content-title'>
                            <p className='verify-title-th'>Full name</p>
                            <p className='verify-title-th'>Gender</p>
                        </div>
                        <div className='verify-content-title'> 
                            <p className='verify-title-td'>{`${identityResponse?.firstname || identityResponse?.full_details?.first_name}  ${identityResponse?.lastname || identityResponse?.full_details?.last_name}`}</p>
                            <p className='verify-title-td'>{identityResponse?.full_details?.gender || "Not Available"}</p>
                        </div>
                    </div>

                    <div className='verify-content'>
                        <div className='verify-content-title'>
                            <p className='verify-title-th'>Date of birth</p>
                            <p className='verify-title-th'>Email</p>
                        </div>
                        <div className='verify-content-title'> 
                            <p className='verify-title-td'>{identityResponse?.full_details?.date_of_birth || "Not Available"}</p>
                           <p className='verify-title-td'>{identityResponse?.email || identityResponse?.full_details?.email || "Not Available"}</p>
                        </div>
                    </div>

                    <div className='verify-content'>
                        <div className='verify-content-title'>
                            <p className='verify-title-th'>Residential address</p>
                            <p className='verify-title-th'>Marital status</p>
                        </div>
                        <div className='verify-content-title'> 
                            <p className='verify-title-td'>{identityResponse?.address || identityResponse?.full_details?.address || "Not Available" }</p>
                            <p className='verify-title-td'>{identityResponse?.full_details?.marital_status || "Not Available"}</p>
                        </div>
                    </div>

                    <div className='verify-content'>
                        <div className='verify-content-title'>
                            <p className='verify-title-th'>Phone number</p>
                            <p className='verify-title-th'>Origin location</p>
                        </div>
                        <div className='verify-content-title'> 
                            <p className='verify-title-td'>{identityResponse?.full_details?.phone_number1 || identityResponse?.phone_1 || "Not Available"}</p>
                            <p className='verify-title-td'>{identityResponse?.full_details?.origin_location || "Not Available"}</p>
                        </div>
                    </div>

                    <div className='verify-content'>
                        <div className='verify-content-title'>
                            <p className='verify-title-th'>Registered on</p>
                            <p className='verify-title-th'>Name on card</p>
                        </div>
                        <div className='verify-content-title'> 
                            <p className='verify-title-td'>{identityResponse?.created_at}</p>
                            <p className='verify-title-td'>{`${identityResponse?.firstname || identityResponse?.full_details?.first_name}  ${identityResponse?.lastname || identityResponse?.full_details?.last_name}`}</p>
                        </div>
                    </div>

                    <div className='verify-content'>
                        <div className='verify-content-title'>
                            <p className='verify-title-th'>Nationality</p>
                            <p className='verify-title-th'>Watchlisted</p>
                        </div>
                        <div className='verify-content-title'> 
                            <p className='verify-title-td'>{identityResponse?.nationality || "Not Available"}</p>
                            <p className='verify-title-td'>N/A</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default BankVerificationNumber