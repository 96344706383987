import React from 'react'

function Timer({time="23 sec"}) {
  return (
    <div className='time_count'>
        <p className='time_count_desc'>This rate expires in</p>
        <img src='/images/otc/clock.jpg' alt=''/>
        <span className='time_count_value'>{time}</span>
    </div>
  )
}

export default Timer