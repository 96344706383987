import React from 'react'

function SelectCurrency({selectedValue,handleSelectChange,ml,children}) {
  return (
    <div className='chart-select-currency' style={{marginRight:ml, marginTop:"0"}}>
             

    {/* <img src={`/images/${selectedValue==="USD"?"USD":selectedValue==="NGN"?"NGN":selectedValue==="KES"?"KES":selectedValue==="all"?"all":selectedValue}.svg`} alt='currency'/> */}
    <img src={`/images/${selectedValue === undefined ? "NGN" : selectedValue }.svg`} alt='icon' />

    <select value={selectedValue} className='sel_drop' onChange={handleSelectChange}>
      {/* {
        data.length > 0 && data.map((item)=>(
        <option value={item.currency}>
          {item.currency}
        </option>
        ))
        
      } */}
      {children}
    </select>
  
</div>
  )
}

export default SelectCurrency