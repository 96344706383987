import React, { useRef } from 'react'
import './Pages.scss'
import CustomerOverViewItem from '../payment/CustomerOverViewItem'
import Active from './Active'
import { useSelector } from 'react-redux';
import TransactionItem from './TransactionItem';
function TransactionOverview({outflow,inflow,name,transData}) {
    const {currentUser} = useSelector((state)=>state?.user)

    const refLink = useRef(null);

    function convertDate(dateString){
        const date = new Date(dateString);
        const dateFormat = {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        };
        const formattedDate = new Intl.DateTimeFormat('en-US', dateFormat).format(date);
        return formattedDate;
    }
    return (
      <div className='customer-overview'>
        
          <div className='customer-profile'>
              {/* <img className='customer-profile-avater' src={process.env.PUBLIC_URL+"/images/tope.svg"} alt='customer'/> */}
              <div className='avater_template'>         
                 <span>{transData?.customer ?(`${String(String(transData?.customer).split(" ")[0]).slice(0,1)} ${String(String(transData?.customer).split(" ")[1]).slice(0,1)}`):"N/A"}</span>
               </div>
              <div className='customer-profile-content'>
                  <h3 className='customer-profile-name' style={{fontSize:"20px"}}>{name}</h3>
                  <p className='customer-profile-email'>{transData?.email || "N/A"}</p>
              </div>
          </div>
         
      
          <div className='customer-line'></div>
          <TransactionItem 
          icon="/images/inflow.svg"
          desc="Total inflows"
          amount={inflow}
          />
   
          <div className='customer-line'></div>
          <TransactionItem
          icon="/images/outflow.svg"
          desc="Total inflows"
          amount={outflow}
          />
  
          <div className='customer-line'></div>
          
            <div className='customer-date'>
                <Active
                title="Active"
                />
                <p className='customer-date-text'>{transData?.dateCollected ? convertDate(transData?.dateCollected):"N/A"}</p>
            </div>
          
      </div>
    )
 }
export default TransactionOverview
