import React from 'react'
import Status from '../common/Status'

function BvnReciept({identity}) {
  return (
    <div className='identityVerify'>
     
          <div className='verify-top'>
              <h3>{identity?.firstname && identity?.firstname } {identity?.lastname && identity?.lastname}</h3>
              <div style={{display:'flex',gap:"5px"}}>
                <p>Verification Status:</p>
                <Status 
                icon="/images/success.svg"
                mgs="Successful"
                />
              </div>
          </div>

          <div className='verify-img'>
              <img src={identity?.image} alt="" />
          </div>
          <div className='verify-content'>
              <table className='tbl'>
                <tr>
                  <td className='verify-text-sm'>BVN</td>
                  <td className='verify-text-sm left-mg'>NIN</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.full_details?.bvn || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.full_details?.nin || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Full name</td>
                  <td className='verify-text-sm left-mg mg-top'>Gender</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.firstname + " " + identity?.middlename + " " + identity?.lastname}</td>
                  <td className='verify-text-md left-mg'>{identity?.full_details?.gender || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Date of birth</td>
                  <td className='verify-text-sm left-mg mg-top'>Email</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.full_details?.date_of_birth || "Not Available" }</td>
                  <td className='verify-text-md left-mg'>{identity?.full_details?.email || "Not Available" }</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Residential address</td>
                  <td className='verify-text-sm left-mg mg-top'>Marital status</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.address || identity?.full_details?.address || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.marital_status || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Phone number</td>
                  <td className='verify-text-sm left-mg mg-top'>Origin location</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.phone_1 || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.lga_of_origin || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Registered on</td>
                  <td className='verify-text-sm left-mg mg-top'>Name on card</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.enrollment?.registration_date || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.fullname || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Nationality</td>
                  <td className='verify-text-sm left-mg mg-top'>Watchlisted</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.nationality || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.watchlisted || "Not Available"}</td>
                </tr>
              </table>
          </div>
    </div>
  )
}

export default BvnReciept