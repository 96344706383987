import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { DotLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import './emailVerify.scss'
import { useDispatch } from 'react-redux';
import { loginSuccess, logout } from '../redux/userSlice';
function SwitchLivePage() {
  
  const [isLoading,setIsLoading] = useState(false);
  const [success,setSuccess] = useState(false)
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {data} = location.state || {}

  useEffect(()=>{
    if(!data) return navigate('/');
    LogOutLoginUser();
    // eslint-disable-next-line
  },[])


  const LogOutLoginUser = ()=>{
        setIsLoading(true)
        // setIsAgreement(false)
        dispatch(logout())

        dispatch(loginSuccess(data))
        setTimeout(()=>{

          navigate('/getstarted')
        },3000)
        setIsLoading(false)

  }

  return (
    <div className="verify_page"> 
      <div className='overlay_verify'></div>
      <div className='content_email'>
        {/* {
          isLoading &&
          <DotLoader color="#259792" /> 
        } */}
         <div className='verify_content'>
          <h1 className='verify_success' style={{textAlign:"center"}}>KYC Approved Successfully; <br/>Your are now on Live Mode</h1>
        </div>
      </div>
    </div>
  )
}

export default SwitchLivePage