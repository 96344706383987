import React, { useState } from 'react'
import { format } from 'timeago.js';

import '../common/tables.scss'
import TableHeaderSearch from '../common/TableHeaderSearch'
import { useNavigate } from 'react-router-dom'
import { changeCpage, nextPage, prePage } from '../utilities/pagination/pagination';
function Customers({btnFirst,users,numUsers,customersWalletData}) {
 const navigate = useNavigate();
 const [customers,setCustomers] = useState([])
 const [currentPage,setCurrentPage] = useState(1)

 
    const recordsPerPage = 10;
    const lastIndex = currentPage * recordsPerPage;
    const firstIndex = lastIndex - recordsPerPage;
    const records = customersWalletData?.slice(firstIndex,lastIndex);
    const nPage = Math.ceil(customersWalletData?.length/recordsPerPage);
    const numbers = [...Array(nPage + 1).keys()].slice(1)
 
 const handleCustomer = (item)=>{
    navigate('/payment-customer',{state:{item}})
 }
  return (
    <div className='balance-overview'>
       
        <table className='tbl'>
            <tr>
                <td colSpan="7" className='tbl-heading'>
                    <TableHeaderSearch 
                    title="Customers"
                    placeholder="Search by name or date..."
                    users={users}
                    numUsers={customersWalletData?.length}
                    btnFirst
                    />
                    
                </td>
            </tr>
            <tr>
               
                <th className='th-text'>Name</th>
                <th className='th-text'>Status</th>
                <th className='th-text'>Phone number</th>
                <th className='th-text'>Date added</th>
                <th className='th-text'>NGN Balance</th>
                <th className='th-text'>USD Balance</th>
            </tr>
            {
                Array.isArray(customersWalletData) && customersWalletData.length > 0 && records.map((item,index)=>(
                    <tr>
                        <td>
                            <div style={{display:"flex",alignItems:"center",gap:"16px"}}>
                                    <div className='ben_initial'>
                                        <p className='ben_initial_val'> {`${item.firstname.slice(0, 1).toUpperCase()} ${item.lastname.slice(0, 1).toUpperCase()}`}</p>
                                    </div>   
                                    <p className='td-text'>{`${item.firstname} ${item.lastname}`}</p>
                            </div>
                        </td>

                        <td>
                            <div className='customer-active'>
                                    <img className='customer-active-img' src={process.env.PUBLIC_URL+"/images/avataOnlineIndicator.svg"} alt="customerActive" />
                                    <p className='td-text'>active</p>
            
                            </div>
                        </td>

                        <td>{item?.mobile || "N/A"}</td>
                        <td className='td-text'>{item?.dated_created ? format(item.dated_created) : "N/A"}</td>
                        <td className='td-text'>{item.wallet && `${item.wallet[0].currency} ${item.wallet[0].payout_balance}`}</td>
                        <td className='td-text'> 
                            <div className='customer-email'>
                                <p className='td-text'>{item.wallet && `${item.wallet[1].currency} ${item.wallet[1].payout_balance}`}</p>
                                <img onClick={()=>handleCustomer(item)} src={process.env.PUBLIC_URL+"/images/eye.svg"} alt="eye" />
                            </div>
                        </td>
                    
                    </tr>
                ))
            }
           
            {
           (customersWalletData.length <= 0)  &&
                <tr>
                    <td  colSpan={7} style={{width:"100%"}}>
                        <div className='empty_tbl'>
                            <img src='/images/emptyPage.svg' alt='emptuy' />
                            <h3 className='num_trans'>No Customer Data Yet</h3>
                           
                        </div>
                    </td>
                </tr>

           }
        </table>

        {
            numbers?.length >0 &&
            <div className='pagination_container'>
                <div className='page-item' onClick={()=>prePage(currentPage,setCurrentPage)}>
                    <img src='/images/pageLeft.svg' alt='' />
                    <p className='Page_prev'>Previous</p>
                </div>   

                <div className='pageNumbers'>
                    {
                    numbers?.length > 0 && numbers.map((n,i)=>(
                        <span key={i} 
                        className={`page_num ${currentPage === n ? 'active':''}`}
                        onClick={()=>changeCpage(setCurrentPage,n)}
                        >
                        {n}
                        </span>
                    ))
                    }

                </div> 

                <div className='page-item' onClick={()=>nextPage(currentPage,setCurrentPage,nPage)}>
                    <img src='/images/pageRight.svg' alt='' />
                    <p className='Page_prev'>Next</p>
                </div> 
            </div>
        }
    </div>
  )
}

export default Customers
