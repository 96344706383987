import React from 'react'
import './inputerror.css'
function DoubleInputGroup({label1,label2,name1,name2,handleChange,select,doubleSelect,placeholder1,placeholder2,children,children2,readOnly,value,error,validateField}) {
  return (
    <div className='input_group_otc_double'>
        <div className='input_group_double'>
            <p className='input_text'>{label1}</p>
            {
                select ?  <select 
                className='half_input' 
                name={name1}
                onChange={handleChange}
               
                
                >
                    {children}
        
                </select> :

                <input className='half_input' name={name1} onChange={handleChange} placeholder={placeholder1} readOnly={readOnly} value={value}/>
            }
            {error[name1] && <p className='error'>{error[name1]}</p>}
            <p></p>
        </div>
        {
                doubleSelect ?
                <>
                <select 
                className='half_input' 
                name={name2}
                onChange={handleChange}
                
                >
                    {children2}
        
                </select>
                {error[name2] && <p className='error'>{error[name2]}</p>}
                </>
                 :

                <div className='input_group_double'>
                    <p className='input_text'>{label2}</p>
                    <input className='half_input'  name={name2} onChange={handleChange} placeholder={placeholder2} />
                    {error[name2] && <p className='error'>{error[name2]}</p>}
                </div>
        }
    </div>
  )

}
export default DoubleInputGroup