import React from 'react'
import '../../components/otc/otc.scss'
import { useNavigate } from 'react-router-dom'

function PaymentSubmitted() {
    
  return (
    <div className='weblink'>
        <div className='weblink_log'>
            <img className='otc_flick_logo' src='/images/otc/flickLogo.svg' alt=''/>
        </div>
        <div className='weblink_container'>
            <div className='weblink_box'>
                <div className='weblink_box_wrapper'>
                    
                   {/*  */}
                   <div className='otc_payout_logo'>
                        <img src='/images/otc/flickRound.svg' alt='' />
                        <p className='payout_logo_title'>Payment Submitted</p>
                        <p className='payout_logo_desc'>Your payment is being processed and should be credited in beneficiary account within 12-business hours</p>
                        <button className='btn' style={{width:"210px",marginTop:"24px"}}>Done</button>
                   </div>

                </div>
            </div>
        </div>
    </div>
  )
}

export default PaymentSubmitted