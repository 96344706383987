import React, {useEffect, useState} from "react";
import Status from "../../common/Status";
import ChangeRoleModal from "./ChangeRoleModal";
import ChangeRole from "./ChangeRole";
import ConfirmChange from "./ConfirmChange";
import RoleUpdated from "./RoleUpdated";
import RemoveMember from "./RemoveMember";
import MemberDeleted from "./MemberDeleted";
import { useSelector } from "react-redux";
import axios from "axios";
import TableLoader from "../../common/TableLoader";

function TeamPermissions({setSendInvite}) {
  const {currentUser} = useSelector((state)=>state?.user)
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const [isChangeRole, setIsChangeRole] = useState(false)
  const [isConfirmChange, setIsConfirmChange] = useState(false)
  const [isRoleUpdated, setIsRoleUpdated] = useState(false)
  const [isRemoveMember, setIsRemoveMember] = useState(false)
  const [isMemberDeleted, setIsMemberDeleted] = useState(false)
  const [isTeamLoading,setIsTeamLoading] = useState(false);
  const [teamsData, setTeamsData] = useState([]);
  const [selectedTeamMemberEmail, setSelectedTeamMemberEmail] = useState("");
  const [selectedTeamMemberRole, setSelectedTeamMemberRole] = useState("");



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest('.change_modal') === null) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getTeamList();
    // eslint-disable-next-line
  }, []);

  const getTeamList = async ()=>{
    setIsTeamLoading(true);
  try{
    
    const headers = {  
      // api_key: currentUser?.user?.merchantKey,
      // sweetapi: currentUser?.user?.merchantCode,
      // token: currentUser.user.token, 
      "content-type": "application/json",
      // // "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImZlbGl4dGVtaWtvdGFuODlAZ21haWwuY29tIiwiaWF0IjoxNzE4MDA5MjQ4fQ.Wkr4DIDg408MtsDWWQZvUCHnPyPZhX-POB-qVy6yaBc", 
    }
    const data = {
      token: currentUser.user.token, 
    }
    console.log("payload", data)

    const url = 'https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/get-all-team-members';
    let res = await axios.post(url, {...data},
      {
      headers:headers
    })

    console.log (res)
    const response = res.data.data
    const reversedData = response.reverse();
    setTeamsData(reversedData || [])
    setIsTeamLoading(false)
  }catch(err){
    setIsTeamLoading(false);
    console.log(err)
    const errorMessage = err?.message || "unable to fetch data"
  }
}
  const toggleModal = (email, role) => {
    setSelectedTeamMemberEmail(email);
    setSelectedTeamMemberRole(role);
    setIsOpen(!isOpen);
  };
    const handleInvite = () => {
        setSendInvite(true)
    }
  return (
    <div className="settlement">
      {
            isTeamLoading &&
            <TableLoader />
        }
      <div className="settlement-border">
        <div className="settlement-header">
          <div className="settlement-account">
            <h2 className="account-text">Team members</h2>
            <span className="eleven-accounts">{teamsData.length} {(teamsData.length <=1)?"team":"teams"}</span>
            {/* <span className="eleven-accounts">5 teams</span> */}
          </div>
          <div style={{display:"flex", gap:"12px", alignItems:"center"}}>
            <div style={{marginTop:"20px"}}>
            <img src={process.env.PUBLIC_URL + "/images/filt.svg"} alt="filter"/>
            </div>
            <button className="settlement-btn" onClick={handleInvite} style={{background:"#259792", border:"none"}}>New members <img src={process.env.PUBLIC_URL + "/images/settlement-plus.svg"} alt="plus"/></button>
          </div>
          {/* <SettlementModal addSettlementAccount={addSettlementAccount} setFormData={setFormData} formData={formData} open={openModal} onClose={() => setOpenModal(false)}/> */}
        </div>
        </div>
          <table className="settlement-table">
            {console.log(teamsData)}
          <tbody>
          
            <tr>
                <th className='th-text'>Fullname</th>
                <th className='th-text'>Email address</th>
                <th className='th-text'>
                    <div style={{display:"flex", alignItems:"center", gap:"4px"}}>
                        <p>Roles</p>
                        <img src={process.env.PUBLIC_URL+"/images/currency-arrow.svg"} alt='copy'/>
                    </div>
                </th>
                <th className='th-text'>
                    <div style={{display:"flex", alignItems:"center", gap:"4px"}}>
                        <p>Status</p>
                        <img src={process.env.PUBLIC_URL+"/images/currency-arrow.svg"} alt='copy'/>
                    </div>
                </th>
                <th className='th-text'></th>

            </tr>
            {
               Array.isArray(teamsData) && teamsData.length > 0 && teamsData.map((item,index)=>(
              <tr key={index}>
                <td className='td-text'>
                    <div className='settlement-tbl-account'>
                        <img className='settlement-bank-logo' src={process.env.PUBLIC_URL+ item.avatar} alt="" />
                        <h4 className='small-text'>{item?.name || "N/A"}</h4>
                    </div>
                </td>
                <td className='td-text'>{item?.teamMemberEmail || item?.email || "N/A"}</td>
                <td className='td-text'>
                     
                     {
                        item.teamMemberRole === "owner" &&
                        <Status 
                        mgs="Owner"
                        bg="#ECFDF3"
                        border="#ABEFC6"
                        color="#067647"
                        />
                      }
                      {
                        item.teamMemberRole === "admin" &&
                        <Status 
                        mgs="Administrator"
                        bg="#F0F9FF"
                        border="#B9E6FE"
                        color="#026AA2"
                        />
                      } 
                      {
                        item.teamMemberRole === "user" &&
                        <Status 
                        mgs="User"
                        bg="#EEF4FF"
                        border="#C7D7FE"
                        color="#3538CD"
                        />
                      } 
                      {
                        item.teamMemberRole === "operate" &&
                        <Status 
                        mgs="Operations"
                        bg="#F0F9FF"
                        border="#B9E6FE"
                        color="#026AA2"
                        />
                      }
                      {
                        item.teamMemberRole === "dev" &&
                        <Status 
                        mgs="Developer"
                        bg="#FDF2FA"
                        border="#FCCEEE"
                        color="#C11574"
                        />
                      } 
                      {
                        item.teamMemberRole === "customer" &&
                        <Status 
                        mgs="Customer support"
                        bg="#FEF6EE"
                        border="#FFD6AE"
                        color="#B93815"
                        />
                      } 
                </td>
                <td className='td-text'>
                    {
                        item.acceptedInvite === true &&
                        <Status 
                        mgs="Active"
                        bg="#ECFDF3"
                        border="#ABEFC6"
                        color="#067647"
                        />
                    }
                      {
                        item.acceptedInvite === false &&
                        <Status 
                        mgs="Inactive"
                        bg="#F9FAFB"
                        border="#EAECF0"
                        color="#344054"
                        />
                    }   
                </td>
                <td className='td-text' onClick={() => toggleModal(item.teamMemberEmail, item.teamMemberRole)} style={{cursor:"pointer"}}>
                    <img src={process.env.PUBLIC_URL+"/images/elipsis.svg"} alt="" />
                </td>
                {isOpen && <ChangeRoleModal setIsChangeRole={setIsChangeRole} setIsOpen={setIsOpen} setIsRemoveMember={setIsRemoveMember} selectedTeamMemberEmail={selectedTeamMemberEmail}/>}
                {isChangeRole && <ChangeRole setIsChangeRole={setIsChangeRole} setIsOpen={setIsOpen} setIsConfirmChange={setIsConfirmChange} selectedTeamMemberEmail={selectedTeamMemberEmail} selectedTeamMemberRole={selectedTeamMemberRole}/>}
                {isConfirmChange.show && <ConfirmChange setIsChangeRole={setIsChangeRole} setIsConfirmChange={setIsConfirmChange} setIsRoleUpdated={setIsRoleUpdated} selectedTeamMemberEmail={selectedTeamMemberEmail} prevRole={isConfirmChange.prevRole} newRole={isConfirmChange.newRole} />}
                {isRoleUpdated && <RoleUpdated setIsRoleUpdated={setIsRoleUpdated}/>}
                {isMemberDeleted && <MemberDeleted setIsMemberDeleted={setIsMemberDeleted}/>}
                {isRemoveMember && <RemoveMember setIsOpen={setIsOpen} setIsRemoveMember={setIsRemoveMember} setIsMemberDeleted={setIsMemberDeleted}/>}
            </tr>
            
         ))
         }
           </tbody>
        {teamsData.length <= 0 && (
          <tr>
            <td colSpan={10} style={{ width: "100%" }}>
              <div className='empty_tbl'>
                <img src='/images/emptyPage.svg' alt='empty' />
                <h3 className='num_trans'>No Team Members Yet</h3>
                <p className='num_desc'>You can simply add a new team member using the control button</p>
              </div>
            </td>
          </tr>
        )}
          {/* <div className="sett_empty">
            <div className="set_empty_content">
              <img src="/images/emptyPage.svg" alt="empty" />
              <h1 className="sett_h">No Team Members Yet</h1>
              <p className="sett_p">You can simply add a new team member using the control button</p>
            </div>
          </div>  */}
        
          </table>
    </div>
  );
}

export default TeamPermissions;
