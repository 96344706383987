export function getIconByName(bankName) {
    const bankData = [ { name: 'GTBank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/gtbank-icon.png' },
  { name: 'Access Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/access-bank-icon.png' },
  { name: 'Kuda Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/kuda-bank-icon.png' },
  { name: 'Standard Chartered',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/standard-charter-bank-icon.png' },
  { name: 'Stanbic IBTC Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/stanbic-bank-icon.png' },
  { name: 'First Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/first-bank-icon.png' },
  { name: 'FCMB',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fcmb-bank-icon.png' },
  { name: 'Zenith Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/zenith-bank-icon.png' },
  { name: 'Fidelity Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fidelity-bank-icon.png' },
  { name: 'GTBank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/gtbank-icon.png' },
  { name: 'United Bank For Africa',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/united-bank-africa-icon.png' },
  { name: 'Wallets Africa',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/wallets-icon.png' },
  { name: 'Union Bank of Nigeria',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/union-bank-icon.png' },
  { name: 'Sterling Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/sterling-bank-icon.png' },
  { name: 'Sterling Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/sterling-bank-icon.png' },
  { name: 'ALAT by WEMA',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/alat-bank-icon.png' },
  { name: 'ALAT by WEMA',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/alat-bank-icon.png' },
  { name: 'Opay',
    icon: 'https://institution-icons.withmono.com/mono.connections.opay.png' },
  { name: 'United Bank For Africa',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/united-bank-africa-icon.png' },
  { name: 'Zenith Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/zenith-bank-icon.png' },
  { name: 'Ecobank Nigeria',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/ecobank-icon.png' },
  { name: 'Ecobank Nigeria',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/ecobank-icon.png' },
  { name: 'Access Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/access-bank-icon.png' },
  { name: 'Fidelity Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fidelity-bank-icon.png' },
  { name: 'Flutterwave',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/flutterwave-icon.png' },
  { name: 'Paystack',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/paystack-icon.png' },
  { name: 'FCMB',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fcmb-bank-icon.png' },
  { name: 'First Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/first-bank-icon.png' },
  { name: 'Jaiz Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/jaiz-bank-icon.png' },
  { name: 'Stanbic IBTC Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/stanbic-bank-icon.png' },
  { name: 'Wallets Africa',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/wallets-icon.png' },
  { name: 'Unity Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/unity-bank-icon.png' },
  { name: 'Piggyvest',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/piggyvest-icon.png' },
  { name: 'Cowrywise',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/cowrywise-icon.png' },
  { name: 'VULTe Digital Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/vulte-bank-icon.png' },
  { name: 'Heritage Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/heritage-bank-icon.png' },
  { name: 'Keystone Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/keystone-bank-icon.png' },
  { name: 'gomoney',
    icon: 'https://institution-icons.withmono.com/mono.connections.gomoney_v1.png' },
  { name: 'Providus Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/providus-bank-icon.png' },
  { name: 'Providus Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/providus-bank-icon.png' },
  { name: 'Brass',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/brass_logo.jpeg' },
  { name: 'Globus Bank',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/globus-bank-icon.jpeg' },
  { name: 'Chaka',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/chaka-icon.png' },
  { name: 'Risevest',
    icon: 'https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/risevest-icon.png' } ]

  const foundBank = bankData.find(bank => bank.name === bankName);
  if (foundBank) {
    return foundBank.icon;
  } else {
    return "N/A";
  }
}