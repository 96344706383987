import React from 'react'
import OtcTable from './OtcTable';

function OtcActionBtn({bg="#2EBDB6",border,image,text,textColor,toggleModal}) {
  return (
    <div className='action_btn' style={{background:bg,border:border}} onClick={toggleModal}>
        <img src={image} alt=""/>
        <p className='otc_btn_desc' style={{color:textColor}}>{text}</p>
    </div>
  )
}

export default OtcActionBtn