import React from "react";
import './Modal.scss'

function Successful({setIsLimitSuccess, setIsModalOpen,limitInputValue}) {
//   if (!open) return null;

  return (
    <div className="overlay">
    <div className="modal-container">
      <div className="success-icon">
        <img src={process.env.PUBLIC_URL + "/images/modal-check-circle.svg"} alt="icon" />
        <img onClick={() => { setIsLimitSuccess(false); setIsModalOpen(false); }} src={process.env.PUBLIC_URL + "/images/x-close.svg"} alt="close" />
      </div>


        <div className="success-title">
          <p className="successful">Successful!</p>
          <p className="your-limit">{`Your limit amount has been set (${limitInputValue})`}</p>
        </div>

          <button onClick={() => { setIsLimitSuccess(false); setIsModalOpen(false); }} className="thanks-btn">
            <h2 className="thanks">Done</h2>
          </button>
    </div>
    </div>
  );
}

export default Successful;
