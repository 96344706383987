import React, { useRef } from 'react'
import '../../components/otc/otc.scss'
import '../../components/payment/payment.scss'
import { copyToClipboard } from '../../components/utilities/clipboardUtils'
import DetailText from '../../components/otc/DetailText';
import DetailTextCopy from '../../components/otc/DetailTextCopy';
import { useNavigate } from 'react-router-dom';
function CustomerWebLink() {
    const accRef = useRef(null);
    const IdRef = useRef(null);

    const navigate = useNavigate()

    const handleClick = ()=>{
        navigate('/beneficiary-details')
    }
  return (
    <div className='weblink'>
        <div className='weblink_log'>
            <img className='otc_flick_logo' src='/images/otc/flickLogo.svg' alt=''/>
        </div>
        <div className='weblink_container'>
            <div className='weblink_box'>
                <div className='weblink_box_wrapper'>
                     <div className='weblink_title_container'>
                        <div className='weblink_arrow'>
                            {/* <img src='/images/otc/arrowBack.svg' alt='' /> */}
                            <p className='weblink_title'>Payment Information</p>
                        </div>
                        <img src='/images/otc/step_one.svg' alt='' />
                    </div>
                    <div className='otcform_container' style={{marginTop:"16px"}}>
                        <p className='amount_requested'>Order amount</p>
                        <h6 className='amount_value'>$5,000.00</h6>
                    </div>
                    
                    {/*  */}
                    <div className='otcform_container' style={{marginTop:"16px"}}>
                        <div style={{display:"flex",flexDirection:"column",gap:"20px"}}>
                            <DetailText desc1="Currency Pair" value1="NGN - USD" desc2="Rate" value2="N1,790"/>
                            <DetailTextCopy desc1="Pay" value1="N8950,000" desc2="Account Name" value2="Flick - BLOOMCORE" ref={IdRef} />
                            <DetailTextCopy desc1="Account number" value1="0277890126" desc2="Bank Name" value2="GTBank" ref={accRef} />
                            <DetailTextCopy current desc1="Reference ID (input as description)" value1="2351RA" desc2="Account Type" value2="Current" ref={accRef} />
                        </div>
                    </div>
                    <button className='btn' style={{marginTop:"15px"}} onClick={handleClick}>I have sent the money</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CustomerWebLink