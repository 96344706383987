import React, { useEffect, useState } from 'react'
import './balanceModal.scss'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { CircleLoader } from 'react-spinners';
import { BeatLoader } from "react-spinners";
import { toast } from 'react-toastify';


function BankAccountModal({setIsOpenPopup,transferBankInput,setTransferBankInput,setIsBeneficiary,setIsConfirmPortfolio,balanceData,banks,payoutId,setPayoutId,setPayoutResult}) {

  // const accRef1 = useRef(null);
  // const accRef2 = useRef(null);
  // const accRef3 = useRef(null);



  const [page,setPage] = useState("")
  const [shouldFetchAccountName, setShouldFetchAccountName] = useState(false);
  const { currentUser } = useSelector((state) => state?.user);
  const [isLoading,setIsLoading] = useState(false);
  const [isPayout,setIsPayout] = useState(false);
  const [amount,setAmount] = useState('')



  useEffect(() => {
    if (shouldFetchAccountName) {
      fetchAccountName();
    }
    // eslint-disable-next-line
  }, [shouldFetchAccountName]);

  const handleCancel = ()=>{
      setIsOpenPopup(false);
  }

    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
        
        if (name === 'amount') {
            // Update the state with the formatted value
            const valueWithoutCommas = value.replace(/,/g, '');
            setAmount(valueWithoutCommas);
            // Add commas
            const formattedValue = valueWithoutCommas.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            
            // Update the state with the formatted value
            setTransferBankInput((prevFormData) => ({
            ...prevFormData,
            [name]: formattedValue,
            }));
            
          } else {
            // For other fields, just update the state normally
            setTransferBankInput((prevFormData) => ({
              ...prevFormData,
              [name]: value,
            }));
          }

        if (name === 'account_number' && value.length === 10) {
            setIsLoading(true);
            setShouldFetchAccountName(true);
        } else {
        setShouldFetchAccountName(false);
        }
    }
    
    const handleLoading = (e)=>{
        e.preventDefault();
    }

    const fetchAccountName = async () => {
        try {

            const headers = {
                api_key: currentUser?.user?.merchantKey,
                sweetapi: currentUser?.user?.merchantCode,
            };
            const data = {
                account_number:transferBankInput.account_number,
                bank_code:transferBankInput.bank_code
            }
            
          // Make an API call to fetch accountName using bankCode and accountNumber.
            const url = "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/name-enquiry";
            let res = await axios.post(url,{...data},{ headers: headers});
            
    
          // Update the accountName in your state with the fetched data.
            
            setTransferBankInput((prevFormData) => ({
                ...prevFormData,
                beneficiary_name: res.data?.data?.account_name,
            }));
           
            setIsLoading(false)
            
        } catch (error) {
          // Handle errors, e.g., show an error message.
          setIsLoading(false)
          console.error('Error fetching accountName:', error);
        }
    };

    const handleSubmit = async (e)=>{
        e.preventDefault();
        setIsPayout(true);
        try{
            const headers = {
                'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
            };
            console.log("amount: ",amount)
            const data = {
                amount: `${Number(amount) * 100}`,
                currency: transferBankInput.currency,
                bank_code: transferBankInput.bank_code,
                narration: transferBankInput.narration,
                account_number: transferBankInput.account_number,
                beneficiary_name:transferBankInput.beneficiary_name,
            }
           
          // Make an API call to fetch accountName using bankCode and accountNumber.
            console.log("payLoad: ",data)
            const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/payout";
            let res = await axios.post(url,{...data},{ headers: headers});
           
            toast.success(res?.data?.message,{position: "top-left", pauseOnHover: true})
            setPayoutId(res.data?.Id)
            setPayoutResult(res?.data)
            setIsPayout(false);
            setIsConfirmPortfolio(true)
            
            // setIsLoading(false)
        }catch(err){
            setIsPayout(false);
            const errorMessage = err.response?.data?.message || "An error has occured"
            
            console.log(err)
            toast.error(errorMessage,{position: "top-left", pauseOnHover: true})
        }
        
    }
    const handleConfirm =(e)=>{
        e.preventDefault()
        setIsConfirmPortfolio(true)
    }

    const handleBeneficiary = (e)=>{
        e.preventDefault()
        setIsBeneficiary(true)
    }

  return (
    <div className='balance_modal'  onClick={setIsOpenPopup.bind(this, false)} >
       <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsOpenPopup.bind(this, false)}
              className='verify-close' src="/images/x-close.svg" alt='close' 
              /> 
            <div className="modal-title">
                <p className="enter-amount">Transfer to Bank Account</p>
                <p className="enter-an-amount">Here is an overview of your transaction</p>
            </div>

            <form onSubmit={handleSubmit}>
                <div className="api_wallet_input">
                    {/*  */}

                    <div className='input_banks_container'>
                        <div 
                        // onClick={() => changeActive("card_num")}
                        className={`input_group ${page==="card_num" ? 'clicked' : ''}`} 
                        >
                         <select 
                            className='balance_select' 
                            style={{background:"transparent"}}
                            name='balance'
                            value={transferBankInput.balance} 
                            onChange={handleInputChange}>
                                 <option value="NGN">
                                    {`${balanceData[0]?.currency} ${Number(balanceData[0]?.payout_balance)/100}`}
                                    {/* <NumericFormat value={Number((balanceData[0]?.payout_balance/100)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={`${balanceData[0]?.currency} `} 
                                    renderText={(value) => <p className='td-text'>{value}</p>}
                                    /> */}
                                </option>
                            </select>
                        </div>
                        <p className='card_label'>Choose balance to transfer from</p>
                    </div>
                    
                    <div className='api_select_container' style={{marginTop:"16px"}}>
                        <div className='api_wallet_select_input'>
                            {/* <img src={`/images/${transferBankInput.currency}.svg`} alt="ng"/>     */}
                            <img src="/images/NGN.svg" alt=""/>    
                            <select 
                            className='api_select_val' 
                            name='currency'
                            value={transferBankInput.currency} 
                            onChange={handleInputChange}>
                                <option value="NGN">NGN</option>
                            </select>
                        </div>
                        <input  name='amount' value={transferBankInput.amount} className='api_input_amountss'  placeholder="5.00" onChange={handleInputChange}/>
                    </div> 
                    <select 
                    required
                    className='portfolio_amount_input' 
                    name='bank_code'
                    onChange={handleInputChange}
                    style={{marginTop:"16px"}}>
                        <option value="" selected disabled>Bank name</option>
                        {
                            banks.map((bank,index)=>(
                                <option key={index} value={bank?.bank_code}>{bank?.bank_name}</option>
                            ))
                        }
                    
                    </select>
                    <p className='select_exist' style={{cursor:"pointer"}} onClick={handleBeneficiary}>Or select existing beneficiary  <img style={{display:"inline",marginLeft:"8px"}} src='/images/selectBeneficiary.svg' alt='ben'/></p>       
                    <input className='portfolio_amount_input' placeholder='Account number' name='account_number' onChange={handleInputChange} style={{marginTop:"16px"}} required/>
                    <div className='account_loader'>
                        {
                            isLoading ?
                            <div className='loader_overlay'>  
                               <CircleLoader
                                color="#259792"
                                size={15}
                                />
                            </div>:
                            <input value={transferBankInput.beneficiary_name} className='portfolio_amount_input' placeholder='Account name' name='beneficiary_name' onChange={handleInputChange} style={{marginTop:"16px"}} readOnly/>
                        }
                    </div>
                    <input className='portfolio_amount_input' placeholder='Description (optional)' name='narration' onChange={handleInputChange} style={{marginTop:"16px"}}/>
                </div>
                
                <div className="modal-btn" style={{display:"flex", gap:"12px", marginTop:"32px"}}>
                    <button onClick={handleCancel} className="sec-btn">
                        Cancel
                    </button>
                    {
                        !isPayout ?
                    <button  className="btn" onClick={handleSubmit}>   
                        Confirm transfer
                    </button>:
                    <p className="btn">
                        <BeatLoader
                            color="#FFF"
                            cssOverride={{}}
                        />
                    </p>
                    }

                </div>
            </form>
          </div>
        </div>
    </div>
  )
}

export default BankAccountModal

