import React from "react";
import "../data/data.scss";

function TestModePayment() {

  return (
    <div className="main-inner">
      <div className="flick-data-unlock">
        <div className="flick-data">
          <img
            src={process.env.PUBLIC_URL + "/images/flick-payment.svg"}
            alt="arrow back"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/request-access.svg"}
            alt="arrow back"
          />
        </div>
        <div className="unlock-the-power">
          <p className="unlock">Power the future of payments with Flick </p>
          <p className="easily-access">
          Access all you need to carry out seamless payments activities. Build better payment flows and smooth online transaction experience with Flick payment products 
          </p>
        </div>
      </div>

      <div className="explore-products">
        <div className="products-image">
        <img
            src={process.env.PUBLIC_URL + "./images/payment-phone.svg"}
            alt="basket"
          />
          <div className="products-data">
          <p className="explore-data">Explore our core products</p>
          <div className="payment-explore">
          <div className="explore-payment">
              <div className="pay-explore" style={{borderBottom:"1px solid #EAECF0"}}>
                    <img src={process.env.PUBLIC_URL + "./images/payment-collection.svg"} alt="bank" />
                    <div className="title-desc">
                    <p className="product-title">Collections</p>
                    <p className="product-desc">Accept payments via direct bank accounts, cards and bank transfers </p>
                    </div>
                </div>
                <div className="pay-explore" style={{borderBottom:"1px solid #EAECF0"}}>
                    <img src={process.env.PUBLIC_URL + "./images/payment-recurring.svg"} alt="bank" />
                    <div className="title-desc">
                    <p className="product-title">Recurring Payments</p>
                    <p className="product-desc">Accept one-time and recurring payments in multiple currencies</p>
                    </div>
              </div>
              <div className="pay-explore">
                    <img src={process.env.PUBLIC_URL + "./images/payment-multi.svg"} alt="bank" />
                    <div className="title-desc">
                    <p className="product-title">Multi-Currency</p>
                    <p className="product-desc">Accept payment and manage balances  across several currencies</p>
                    </div>
                </div>
          </div>
          <div className="explore-payment">
              <div className="pay-explore" style={{borderBottom:"1px solid #EAECF0"}}>
                    <img src={process.env.PUBLIC_URL + "./images/payment-payout.svg"} alt="bank" />
                    <div className="title-desc">
                    <p className="product-title">Pay-Outs</p>
                    <p className="product-desc">Easy and efficient payouts and transfers to local banks</p>
                    </div>
                </div>
                <div className="pay-explore" style={{borderBottom:"1px solid #EAECF0"}}>
                    <img src={process.env.PUBLIC_URL + "./images/payment-instant.svg"} alt="bank" />
                    <div className="title-desc">
                    <p className="product-title">Instant Settlement</p>
                    <p className="product-desc">Quick and automated settlements into wallets or bank accounts</p>
                    </div>
              </div>
              <div className="pay-explore">
                    <img src={process.env.PUBLIC_URL + "./images/payment-links.svg"} alt="bank" />
                    <div className="title-desc">
                    <p className="product-title">Payment Links</p>
                    <p className="product-desc">Receive payments without a single line of code</p>
                    </div>
                </div>
          </div>
          </div>
          <div className="explore-api">
          <img
            src={process.env.PUBLIC_URL + "./images/explore-api.svg"}
            alt="basket"
            
          />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestModePayment;
