import React from 'react'
import TableHeaderSearch from '../common/TableHeaderSearch'
import Status from '../common/Status'
import AccountActions from './AccountActions'
import { format } from 'timeago.js'
import { getIconByName } from '../utilities/getBankIcon'
import TableLoader from '../common/TableLoader'

function AccountBalance({isAction,ToggleAction,accountData,isAccountLoading}) {
  
  const handleAccountAction = ()=>{
    ToggleAction();
  }
  return (
    <div className='balance-overview' style={{position:"relative"}}>
      {
            isAccountLoading &&
            <TableLoader />
        }
      <table className='tbl'>
        <tr>
            <td colSpan="7" className='tbl-heading'>
                <TableHeaderSearch 
                title="Transactions"
                placeholder="Search by name or date..."
                />     
            </td>
        </tr>
        {
           Array.isArray(accountData) && accountData.length > 0  &&
          <tr className='account_actions'>
            <th className='th-text'>Name</th>
            <th className='th-text'>Timestamp</th>
            <th className='th-text'>Account</th>
            <th className='th-text'>Balance</th>
            <th className='th-text'>Status</th>
            
          </tr>
        }
        {
          Array.isArray(accountData) && accountData.length > 0 && accountData.map((account,index)=>(
            <tr key={account?.bvn}>
                <td >
              
                    <div className='customer-name'>
                        <div className='avater_template'>
                            {/* <span>{`${((String(account?.firstName)).slice(0,1)).toUpperCase()}${((String(account?.lastName)).slice(0,1)).toUpperCase()}`}</span> */}
                              <span>{account?.fullName ?(`${String(String(account?.fullName).split(" ")[0]).slice(0,1)} ${String(String(account?.fullName).split(" ")[1]).slice(0,1)}`):"N/A"}</span>
                        </div>
                        <h2 className='customer-name-text'>{account?.fullName || "N/A"}</h2>
                    </div>
                </td> 
                <td className='td-text'>{format(account?.dated)}</td>
                <td className='td-text'>
                    <div className='tbl-account'>
                        <img className='account-bank-logo' src={getIconByName(account?.BankName)} alt="" />
                        <div className='payment-account-text'>
                            <h1 className='td-text'>{account?.BankName || "N/A"}</h1>
                            <span className='td-text'> | {account?.Nuban}</span>
                        </div>
                    </div>
                </td>
                
                <td className='td-text' style={{color:"#101828"}}>{account?.Balance}</td>
                

                <td >

                  <div style={{display:"flex",justifyContent:"space-between",alignContent:"center"}}>
                    <Status 
                    icon="/images/success.svg"
                    mgs="Successful"
                    />
                    <img onClick={handleAccountAction}  src={process.env.PUBLIC_URL+"/images/threeDot.svg"} alt="verified" />
                  </div>
                
                </td>
            </tr>
          ))
        }
        {
           Array.isArray(accountData) && accountData.length > 0 &&
            <tr>
                <td className='td-export' colspan="7">
                    <div className='tbl-export'>
                        <h6 className='tbl-export-text'>Export Data</h6>
                        <img src={process.env.PUBLIC_URL+"/images/export.svg"} alt='export' />               
                    </div>
                </td>
            </tr>
        }
        {isAction && <AccountActions />}
        {
          !isAccountLoading && accountData.length <= 0 &&
            <tr>
             <td  colSpan={7} style={{width:"100%"}}>
                <div className='empty_tbl'>
                    <img src='/images/emptyPage.svg' alt='emptuy' />
                    <h3 className='num_trans'>No Account Yet</h3>
                    <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                </div>
             </td>
            </tr>
           }
      </table>
    </div>
  )
}

export default AccountBalance
