import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Timer from './Timer';
import { formatTime } from '../utilities/formateTime';

function ConfirmConvertModal({setConfirmConvert,setIsConvertSuccess,setIsConvert,initiatData,getBalance,setIsPayout,convertInput}) {
    const handleClose =()=>{
        setConfirmConvert(false)
        setIsPayout(false)
    }
    const {currentUser} = useSelector((state)=>state?.user)

    const [isLoading,setIsLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState(initiatData?.validity);
    const [timerID, setTimerID] = useState(null);
    const [isTimeExpired, setIsTimeExpired] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(prevTime => prevTime - 1);
        }, 1000);

        setTimerID(timer);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (remainingTime <= 0) {
            clearInterval(timerID);
            setIsTimeExpired(true);
        }
    }, [remainingTime, timerID]);

    useEffect(() => {
        if (isTimeExpired) {
            handleClose(); 
        }
       // eslint-disable-next-line
    }, [isTimeExpired]);

    // const formatTime = (time) => {
    //     const minutes = Math.floor(time / 60);
    //     const seconds = time % 60;
    //     return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    // };

    const handleProceed = async (e)=>{
        e.preventDefault();
        setIsLoading(true);
        try{
            const headers = {  
                "api_key": currentUser?.user?.merchantKey,
                // "api_key": "pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso", 
            }
            
        
            
            const url = `https://flick-app.com/otc-dash/complete_exchange?transactionId=${initiatData?.exchange_id}`;
            // console.log(url)
            let res = await axios.get(url,{ headers: headers});

            
            setIsLoading(false)
            setIsConvertSuccess(true);
            getBalance();

        }catch(err){
            setIsLoading(false);
            console.log(err);
            const errorMessage = err?.response?.data?.data?.message || err?.response?.data?.message || "error has occured" 
            toast.error(errorMessage,{ position: "top-left", pauseOnHover: true})
        }
    }

  return (
    <div className='balance_modal'  onClick={setConfirmConvert.bind(this, false)} >
       
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setConfirmConvert.bind(this, false)}
              className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
              />

            <div className="modal-title">
                <p className="enter-amount">Confirm Conversion</p>
                {/* <p className="enter-an-amount">Please confirm the following details regarding this conversion.Note: conversion takes 1-2 hours to be processed successfully.</p> */}
                <p className="enter-an-amount">Please confirm the following details regarding this conversion.</p>
            </div>

            <div className='otc_info_container'>
                <img src='/images/otc/info-circle.svg' alt='' />
                <p className='otc_info'>No changes are possible after proceeding</p>
            </div>

            

            <Timer time={formatTime(remainingTime)}/>

            <div className='otcform_container'>
                <p className='otc_rate'>Rate</p>
                <div className='oct_rate_container'>
                    <p className='oct_rate_text'>&nbsp; {convertInput.to_currency=="USD" ? "$" : convertInput.to_currency=="GBP" ? "£" : convertInput.to_currency=="CAD" ? "C$" : convertInput.to_currency=="EUR" ? "€" : ""}1.00</p>
                    <span className='oct_rate_text'> = </span>
                    <p className='oct_rate_text'> {convertInput.from_currency==="NGN"?"₦":convertInput.from_currency==="KES"?"KSh":convertInput.from_currency==="GHS"?"₵":convertInput.from_currency}{initiatData?.rate}</p>
                </div>
                <p className='otc_rate m_t'>Source Amount</p>
                <p className='oct_rate_text'> {convertInput.from_currency==="NGN"?"₦":convertInput.from_currency==="KES"?"KSh":convertInput.from_currency==="GHS"?"₵":convertInput.from_currency} {initiatData?.from_amount}</p>
                <p className='otc_rate m_t'>destination Amount</p>
                <p className='oct_rate_text'>&nbsp; {convertInput.to_currency=="USD" ? "$" : convertInput.to_currency=="GBP" ? "£" : convertInput.to_currency=="CAD" ? "C$" : convertInput.to_currency=="EUR" ? "€" : ""} {initiatData?.to_amount}</p>
                
            </div>

            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={handleClose} >
                    Cancel
                </button>
                {
                    isLoading ?
                <button className='btn' >
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    /> 
                </button>:
                <button className='btn' onClick={handleProceed} >
                    Proceed
                </button>
                }
            </div>
          </div>
        </div>
    </div>
  )
}

export default ConfirmConvertModal