import React from 'react'
import Details from '../../common/Details'
import '../../common/Pages.scss'
import TableHeaderSearch from '../../common/TableHeaderSearch'
import CustomerOverView from '../../common/CustomerOverView'
import CustomerDetails from '../../common/CustomerDetails'
import { format } from 'timeago.js'
import { getIconByName } from '../../utilities/getBankIcon'
import { formatDate } from '../../utilities/formatDate'
function CustomerAccount({account,customer}) {
  return (
    <div className='data_customer'>
        {console.log("account: ",account)}
        {console.log("customer: ",customer)}
        <CustomerOverView data customer account={account}/>
        <CustomerDetails 
        email={account ? account.email? account.email:"N/A": "N/A"}
        phone={account ? account.phone? account.phone: "N/A" : "N/A"}
        linked={account ? account.accountCounts ? account.accountCounts:"N/A" : "N/A"}
        date={account ? account.dated? formatDate(account.dated):"N/A": "N/A"}
        synced={account ? account.dateSynced? formatDate(account.dateSynced):"N/A": "N/A"}
        />
        
       <div className='balance-overview'>
          
          <table className='tbl'>
            <tr>
                <td colSpan="7" className='tbl-heading'>
                    <TableHeaderSearch
                    title="Customers"
                    placeholder="Search by bank..."
                    numUsers={customer?.length}
                    users={"connected"}
                    btnFirst
                    sync
                    />     
                </td>
            </tr>
            <tr>
                <th className='th-text'>Account</th>
                <th className='th-text'>Total Balance</th>
                <th className='th-text'>Total Income</th>
                <th className='th-text'>Currency</th>
                <th className='th-text'>Transactions</th>
                <th className='th-text'>Balance</th>
            </tr>
            {
               Array.isArray(customer) && customer.length > 0 && customer.map((item,index)=>(
                  <tr>
                    <td>
                        <div className='customer-name'>
                            <img className='customer-name-img' src={getIconByName(item?.bankName)} alt="" />
                            <h2 className='customer-name-text'>{item?.nuban || "N/A"}</h2>
                        </div>
                    </td> 

                    <td className='customer-name-text'>{item?.balance}</td>
                    <td className='td-text'>{item?.totalCredit}</td>

                    <td>
                        <div className='flag'>
                          <img src={process.env.PUBLIC_URL+`/images/${item?.currency?item.currency:"NGN"}.svg`} alt="flag" />
                          <h1 className='ng'>NGN</h1>
                        </div>
                    </td>
                    <td className='td-text'>{item?.totalTransaction || "N/A"}</td>
                    <td className='td-text'>{item?.balance}</td>
                  </tr>
               ))
            }
          </table>
        </div>
    </div>
  )
}

export default CustomerAccount
