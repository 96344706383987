import React, { useState } from 'react'
import DropDownBeneficiaryItem from './DropDownBeneficiaryItem';
import './otc.scss'
import { toast } from 'react-toastify';

function DropDownBeneficiary({setSelectedOption,toggleModal,setIsConfirmBankDetial,setIsBenAdd,addBeneficiary,beneficiaryData,transferBankInput}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBank, setSelectedBank] = useState(""); // State to hold the selected bank
    const openModal = () => {
       
        setIsModalOpen(!isModalOpen);
    };
    
    const closeModal = () => {
      setIsModalOpen(false);
    };
    const handleBankSelection = (bank) => {
       if(transferBankInput.amount === "") return toast.error("amount must not be empty",{position: "top-left", pauseOnHover: true})
       
      setSelectedBank(bank.id);
      setSelectedOption(bank);
    //   toggleModal();
      closeModal();
      setIsConfirmBankDetial(true);
    };
    return (
      <div>
        <div className="selectInput_container_dropdown" onClick={openModal}>
            {/* {selectedBank ? (
                <>
              
                <h3 className="bank-name current_text">{selectedBank}</h3>
                </>
            ) : (
                <span className='current_text'>Choose beneficiary here</span>
            )} */}
            <span className='current_text'>Choose beneficiary here</span>
            <img
                src={process.env.PUBLIC_URL + "./images/arrow-down.svg"}
                alt="arrow"
                style={{marginLeft:"auto"}}
            />
        </div>
  
        {isModalOpen && (
        //   <DropDownItem
        //       handleBankSelection={handleBankSelection}
        //       closeModal={closeModal}
        //       selectedBank={selectedBank}
        //   />
        <DropDownBeneficiaryItem handleBankSelection={handleBankSelection} selectedBank={selectedBank} setIsBenAdd={setIsBenAdd} addBeneficiary={addBeneficiary} beneficiaryData={beneficiaryData}/>
        
        )}
      </div>
    )
}

export default DropDownBeneficiary