import React, { useState } from 'react'
import DetailText from './DetailText'
import Timer from './Timer';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';

function ConfirmBankDetailModal({setIsConfirmBankDetial,setIsPayoutSubmited,selectedBeneficiary,fxPayout,isFxPayoutLoading,transferBankInput}) {
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked); // Toggle the checked state
      };

    const handleUsdPayout = ()=>{
        if(!isChecked) return  toast.error("check the box below to consent to flick's Policy",{position: "top-left", pauseOnHover: true})
        fxPayout();
       
    }
  return (
    <div className='balance_modal'  onClick={setIsConfirmBankDetial.bind(this, false)} >
       
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
          <div className='balance_modal_wrapper'>
              <img 
              onClick={setIsConfirmBankDetial.bind(this, false)}
              className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
              />

            <div className="modal-title">
                <p className="enter-amount">Confirm Details</p>
                <p className="enter-an-amount">Please confirm the following details regarding this conversion and ensure all information provided is correct.</p>
            </div>

          

            <div className='otcform_container' style={{marginTop:"30px"}}>
                {console.log("beneficiary",selectedBeneficiary)}
                <div className='desc_container'>
                    <DetailText desc1="Wire Type" value1='' desc2="Amount" value2={`${transferBankInput.currency === "NGN" ? "₦" : transferBankInput.currency === "USD" ? "$" :transferBankInput.currency === "EUR" ? "€": transferBankInput.currency === "GBP" ? "£":transferBankInput.currency==="CAD"?"C$":transferBankInput.currency} ${transferBankInput.amount}`} color="#101828" textBold="bold" fontWeight="700" fontSize="18px"/>
                    <DetailText desc1="Account Number" value1={selectedBeneficiary?.account_no || "N/A"} desc2="Routing Number" value2={selectedBeneficiary?.routing}/>
                    <DetailText desc1="Beneficiary Account Name" value1={selectedBeneficiary?.beneficiary_name || "N/A"} desc2="Bank Name" value2={selectedBeneficiary?.bank_name}/>
                    <DetailText desc1="Bank Address" value1={selectedBeneficiary?.bank_address_1+ " " +selectedBeneficiary?.bank_city+ " " +selectedBeneficiary?.bank_state+ " " +selectedBeneficiary?.bank_country+ " " +selectedBeneficiary?.bank_postal_code}  />
                    <DetailText desc1="Beneficiary Address " value1={selectedBeneficiary?.beneficiary_address_1 + " " +selectedBeneficiary?.beneficiary_city+ " " +selectedBeneficiary?.beneficiary_state+ " " +selectedBeneficiary?.beneficiary_country+ " " +selectedBeneficiary?.beneficiary_postal_code}/>
                    <DetailText desc1="Description" value1={transferBankInput?.narration}/>
                </div>
            </div>
            <div className='consent_to'>
                <input 
                type="checkbox" 
                checked={isChecked} 
                onChange={handleCheckboxChange} 
                />
                 {/* eslint-disable-next-line */}
                <p className='confirm_desc'>I confirm the above payment details are accurate and consent to Flick's <a href='#'>Policy</a> </p>
            </div>

            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={setIsConfirmBankDetial.bind(this,false)} >
                    Cancel
                </button>
                {
                    isFxPayoutLoading?
                <button className='btn'>
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />
                </button>:
                <button className='btn' onClick={handleUsdPayout} >
                    Proceed
                </button>
                }
            </div>
          </div>
        </div>
    </div>
  )
}

export default ConfirmBankDetailModal