 export function formatDate(timestamp) {
    // Parse the timestamp into a Date object
    const date = new Date(timestamp);
  
    // Define the options for formatting
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
  
    // Format the date using Intl.DateTimeFormat
    const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  
    return formattedDate;
}