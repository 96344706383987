import React, { useEffect, useRef, useState } from 'react'
import NameControl from '../components/common/NameControl'
import OverViewPayment from '../components/overview/OverViewPayment';
import { useSelector } from 'react-redux';
import OverviewData from '../components/overview/OverviewData';
import SetLimit from '../components/modals/SetLimit';
import FundBalance from '../components/modals/FundBalance';
import EnterAmount from '../components/modals/EnterAmount';
import VirtualAccount from '../components/modals/VirtualAccount';
import CardDetails from '../components/modals/CardDetails';
import SelectPayment from '../components/modals/SelectPayment';
import TransactionOtp from '../components/modals/TransactionOtp';
import TransactionSuccessful from '../components/modals/TransactionSuccessful';
import Success from '../components/modals/Success';
import AvailableBalance from '../components/modals/AvailableBalance';
import axios from 'axios';
import BalanceModal from '../components/balance/modal/BalanceModal';
import TableLoader from '../components/common/TableLoader';
import { capitalizeFirstLetter } from '../components/utilities/capitalizeWords';
import PinOtp from '../components/modals/PinOtp';
import ComingSoonPop from '../components/modals/ComingSoonPop';
import ApiPayoutBalance from '../components/modals/ApiPayoutBalance';
import ApiWalletOtp from '../components/modals/ApiWalletOtp';
import VirtualConfirm from '../components/modals/VirtualConfirm';
import PaymentMethod from '../components/modals/PaymentMethod';
import AmountNormal from '../components/modals/AmountNormal';
import FundOtherBalance from '../components/modals/FundOtherBalance';
import { v4 as uuidv4 } from 'uuid';
import OtcDashboardContent from './otcDashboard/OtcDashboardContent';
import CollectionModal from '../components/payment/CollectionModal';
import PayoutOverviewModal from '../components/payment/PayoutOverviewModal';


function OverView({balanceData,setBalanceData,graphData,setGraphCurrency,setSelectedValue,selectedValue,getGraph}) {
  
  useEffect(()=>{
    getBalance(); 
    getBalances();
    getCollection();
    getEncKey();
    getLinkedCards();
    getPermanentNuban();
    // eslint-disable-next-line 
  },[])

    const templateRef = useRef(null);
    const {currentUser} = useSelector((state)=>state?.user)
    const [btnActive,setBtnActive] = useState(false);
    const [selectedButton, setSelectedButton] = useState("Payments");
    const [isSetLimit, setIsSetLimit] = useState(false);
    const [isPaymentMethod, setIsPaymentMethod] = useState(false);
    const [isVertual, setIsVertual] = useState(false);
    const [isCardDetails, setIsCardDetails] = useState(false);
    const [isCardMethod, setIsCardMethod] = useState(false);
    const [isOtp, setIsOtp] = useState(false);
    const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false)
    const [isAmount,setIsAmount] = useState(false);
    const [isBalanceOpen, setIsBalanceOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [balanceModal,setBalanceModal] = useState({})
    const [collectionData,setCollectionData] = useState([]);


    const [amountInput,setAmountInput]=  useState("");
    const [isAmountLoading,SetIsAmountLoading] = useState(false);
    const [inputCardDetails,setInputCardDetails] = useState({
      name:"",
      card_number:"",
      card_date:"",
      card_cvv:""
    });
    const [transactionId,setTransactionid] = useState('')
    const [isCardsLoading,setIsCardLoading] = useState(false);
    const [LinkedCardData,setLinkedCardData] = useState([]);
    const [livePublicEncKey,setLivePublicEncKey] = useState("");
    const [isPinOtp,setIsPinOtp] = useState(false);
    const [isOtpLoading,setIsOtpLoading] = useState(false)
    const [isPinLoading,setIsPinLoading] = useState(false)
    const [nubanData,setNubanData] = useState([]);
    const [isCommingSoon,setIsCommingSoon] = useState(false);
    const [payoutCurrency,setPayoutCurrency] = useState('')
    const [isApiWallet,setIsApiWallet] = useState(false)
    const [isApiPayout,setIsApiPayout] = useState(false)
    const [apiWallet,setApiWallet] = useState('')
    const [isApiFundLoading,setIsApiFundLoading] = useState(false)
    const [isApiOtp,setIsApiOtp] = useState(false)
    const [apiData,setApiData] = useState({})
    const [apiInputData,setApiInputData] = useState({})
    const [isCardApi,setIsCardApi] =  useState(false)
    const [isVirtualConfirm,setIsVirtualConfirm] = useState(false)
    const [nubanId,setNubanId] = useState('')
    const [nubTemInput,setNubTempInput] = useState({});
    const [nubanTempData,setNubanTempData] = useState({});
    const [isAmountNormal,setIsAmountNormal] = useState(false)
    const [isPayment,setIsPayment] = useState(false)
    const [isOtherBalance,setIsOtherBalance] = useState(false)
    const [selectedCurrency,setSelectedCurrency] = useState("NGN")
    const [isCollectionLoading,setIsCollectionLoading] = useState(false)
    // const [selectedValue,setSelectedValue] = useState('NGN')


    //======== transaction History receipt

    const [isTransactionHistory,setIsTransactionHistroy] = useState(false)
    const [selectedRowData, setSelectedRowData] = useState(null);


    const [balanceDatas,setBalanceDatas] = useState([])
    const sortedData = graphData.slice().sort((a, b) => a.monthid - b.monthid);

    const data = sortedData.map(item => ({
      name: item.month,
      Total: item.amount/ 100,
    }));
   

    const toggleTransactionHistory =()=>{
      setIsTransactionHistroy(!isTransactionHistory)
    }
  
    const getBalance = async ()=>{
      
      try{
        
        const headers = {  
          "api_key": currentUser.user.merchantKey, 
          "sweetapi": currentUser.user.merchantCode  
        }
        const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/balances';
        let res = await axios.get(url,{
          headers:headers
        })
  
        
        const newValue = res.data.data
       setBalanceData(newValue || []);
         
      }catch(err){
        console.log(err)
        const errorMessage = err?.message || "unable to fetch data"
       
      }
    }

    const getBalances = async ()=>{
      
      try{
        
        const headers = {  
          "api_key": currentUser.user.merchantKey, 
          "sweetapi": currentUser.user.merchantCode  
        }
        const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/balances?IsDash=1';
        let res = await axios.get(url,{
          headers:headers
        })
  
        
        const newValue = res.data.data
       setBalanceDatas(newValue || []);
         
      }catch(err){
        console.log(err)
        const errorMessage = err?.message || "unable to fetch data"
       
      }
    }
    //collection
    const getCollection = async (currency="NGN")=>{
      setIsCollectionLoading(true)
      try{
       
        const headers = {  
          "api_key": currentUser?.user?.merchantKey, 
          "sweetapi": currentUser?.user?.merchantCode  
        }
  
        const data = {
          category: "all", //required  
          currency: currency, // required
          limit:200 // optional
        }
        
        const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transactions';
        let res = await axios.post(url,{...data},{
          headers:headers
        })
      
        const newValue = res?.data?.data
       
        setCollectionData(newValue);
        setIsCollectionLoading(false);
     
        
        // toast.success(res.data.message);
      }catch(err){
        console.log(err);
        setIsCollectionLoading(false)
        const errorMessage = err?.response?.data?.data?.message || "Server error"
      }
    }
    
  const iniateCharge = async (e)=>{
      // console.log("amount input",amountInput)
      SetIsAmountLoading(true)
      try{

          const headers = {  
              Authorization:`Bearer ${currentUser?.user?.merchantKey}`
          }

          const amount = {
            amount: `${amountInput * 100}`,
            url:"https://checkout.paywithflick.co/pages/"
          }

          console.log(amount)
          const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/create-charge"
          let res = await axios.post(url,amount,{
            headers:headers
          });
          
          console.log("initiate charge",res.data)
          setTransactionid(res.data?.data?.transactionId);
           SetIsAmountLoading(false);
           setIsPaymentMethod(true);
          
          
      }catch(err){
          SetIsAmountLoading(false)
          console.log(err)
      }

  }

  const iniateChargeOtherBalance = async (e)=>{
    let amount
    SetIsAmountLoading(true)
    try{

        const headers = {  
            Authorization:`Bearer ${currentUser?.user?.merchantKey}`
        }
       
        amount = {
          amount: `${amountInput * 100}`,
          url:"https://checkout.paywithflick.co/pages/"
        }
        console.log("amount Payload",amount)
        const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/create-charge"
        let res = await axios.post(url,amount,{
          headers:headers
        });
        
        
        const transId = res?.data?.data?.transactionId || res.data?.transactionId
        setTransactionid(transId);
         SetIsAmountLoading(false);
         setIsOtherBalance(true);

        
    }catch(err){
        SetIsAmountLoading(false)
        console.log(err)
    }

  }

  const getLinkedCards = async ()=>{
    
    try{
      
        const headers = {
          api_key: currentUser?.user?.merchantKey,
          sweetapi: currentUser?.user?.merchantCode,
        };

        const url ="https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-all-payment-card"
        let res = await axios.get(url,{
          headers:headers
        });
        const data = res?.data?.data
        setLinkedCardData(data);    
    }catch(err){
        console.log(err)
    }
  }

  const getEncKey = async ()=>{
    
    try{
        const headers = {
          api_key: currentUser?.user?.merchantKey,
          sweetapi: currentUser?.user?.merchantCode,
        };

        const url ="https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-encryption-key"
        let res = await axios.get(url,{
          headers:headers
        });
       
        const data = res?.data?.data?.livePublicEncKey
        setLivePublicEncKey(data);

    }catch(err){
        console.log(err)
    }
  }
    
  const handleApiOtherBalance = async (currency)=>{
   
    setIsApiFundLoading(true);

    try{
        const headers = {
            'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
        };
        const data = {
            amount:  `${amountInput * 100}`,
            currency: currency,
            bank_code: "apiwallet",
            currency_settled: currency || "NGN",
        }
       
      // Make an API call to fetch accountName using bankCode and accountNumber.
        
        setApiInputData(data)
        const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/payout";
        let res = await axios.post(url,{...data},{ headers: headers});
        console.log("response Data: ",res.data)
        setApiData(res.data)
        setIsApiFundLoading(false);
        setIsApiOtp(true)
        // toast.success(res?.data?.message,{position: "top-left", pauseOnHover: true})
        // setIsLoading(false)
    }catch(err){
      setIsApiFundLoading(false);
      console.log(err)
    }
    
  }

  const getPermanentNuban = async ()=>{
   
    const id = uuidv4(); 
    try{
      const headers = {  
        Authorization:`Bearer ${currentUser?.user?.merchantKey}`
        }
       
        const payLoad = { 
            transactionId: id,
            nubanType: "business"
        }
       
      // Make an API call to fetch accountName using bankCode and accountNumber.
        const url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-create-merchant"
        let res = await axios.post(url,payLoad,{
          headers:headers
        });
        console.log("nuban Data: ",res.data);
        setNubanData(res.data.data);
     
    }catch(err){
      console.log(err)
    }
    
  }


    const handleBtnActive = ()=>{
        setBtnActive(!btnActive)
    }
    const handleButtonClick = (button) => {
      setSelectedButton(button);
    };
    const toggleSetLimitModal = () => {
      setIsModalOpen(!isModalOpen)
    };
    const balanceNav = ()=>{
      setIsBalanceOpen(!isBalanceOpen)
    }
    const confirmLimit =()=>{
      console.log("limit value : ")
    }
    const renderContent = () => {
      if(selectedButton === 'Payments') return <OverViewPayment 
      collectionData={collectionData}
      balanceNav={balanceNav} 
      data={data} 
      balanceData={balanceData}
      balanceDatas={balanceDatas}
      setGraphCurrency={setGraphCurrency}
      setBalanceModal={setBalanceModal}
      setApiWallet={setApiWallet}
      setIsPayment={setIsPayment}
      selectedValue={selectedValue}
      setSelectedValue = {setSelectedValue}
      getGraph={getGraph}
      selectedCurrency={selectedCurrency}
      setSelectedCurrency={setSelectedCurrency}
      getCollection={getCollection}
      isCollectionLoading={isCollectionLoading}
      toggleTransactionHistory={toggleTransactionHistory}
      setSelectedRowData={setSelectedRowData}
      />;
      
      if(selectedButton ==='Data') return <OverviewData  
      data={data} 
      balanceData={balanceData}
      setIsAmount = {setIsAmount}
      setIsSetLimit={setIsSetLimit}  
      setIsPaymentMethod={setIsPaymentMethod}
      setIsCommingSoon={setIsCommingSoon}
      setPayoutCurrency={setPayoutCurrency}
      setApiWallet={setApiWallet}
      
      />;
     
      };


  const { user } = currentUser;
  const is_otc = user.is_otc ?? false;
  const is_data = user.is_data ?? false;
  const is_payment = user.is_payment ?? false;
  const is_identity_only = user.is_identity_only ?? false;

  const shouldShowOtcDashboard = is_otc && !is_payment && !is_data && !is_identity_only;

  // ===== renderTransactionHistory ==

  const renderTransactionHistory = ()=>{
    const {type} = selectedRowData
   
    switch(type){
      case "Inflow":
        return <CollectionModal 
         data={selectedRowData}
         toggleModal={toggleTransactionHistory}
        />
      case "Outflow":
        return <PayoutOverviewModal 

         data={selectedRowData}
         toggleModal={toggleTransactionHistory}
         templateRef={templateRef}
        />
    }
  }

  return (
   <>
   
   {shouldShowOtcDashboard ? (
     <OtcDashboardContent />
   ):
    <div className='main-inner'>
      
      
        <NameControl 
        
        name ={currentUser.user.name && capitalizeFirstLetter(currentUser.user.name)}
        image
        desc={`Here’s how ${currentUser.user.business_name} is performing`}
        btnFirst="Payments"
        btnSecond="Data"
        btnActive={btnActive}
        handleButtonClick={handleButtonClick}
        balanceData={balanceData}
        />
        {renderContent()}
        {isBalanceOpen && <AvailableBalance
          balance
          setIsBalanceOpen={setIsBalanceOpen} 
          setIsPaymentMethod={setIsPaymentMethod} 
          setIsModalOpen={setIsModalOpen}
          title={balanceModal?.currency === "NGN"?"Nigerian Naira":balanceModal?.currency==="USD"?"United States Dollar":balanceModal?.currency==="KES"? "Kenyan Shilling":balanceModal?.currency}
          amount={balanceModal?.payout_balance/100}
          icon={balanceModal?.currency}
          setIsAmount = {setIsAmount}
          setIsPayment={setIsPayment}
        />}
        {isModalOpen && <SetLimit 
          open={true} onClose={toggleSetLimitModal}
          setIsModalOpen ={setIsModalOpen}
          title="Set low limit"
          desc="Enter a minimum threshold to be notified"
          confirmLimit={confirmLimit}
        />}

         {isAmount && <EnterAmount 
          balance
          setIsAmount = {setIsAmount}
          setIsPaymentMethod ={setIsPaymentMethod}
          setAmountInput={setAmountInput}
          iniateCharge={iniateCharge}
          isAmountLoading = {isAmountLoading}
          isApiWallet = {isApiWallet}
          setApiWallet={setApiWallet}
          />}

          {
            isPayment &&
            <PaymentMethod 
              setIsPayment = {setIsPayment}
              setIsAmountNormal = {setIsAmountNormal}
              setIsAmount = {setIsAmount}
              setIsVertual ={setIsVertual}
              setIsCardMethod={setIsCardMethod}
              getLinkedCards={getLinkedCards}
              transactionId={transactionId}
              setNubanData={setNubanData}
              setIsCommingSoon={setIsCommingSoon}
              payoutCurrency={payoutCurrency}
              apiWallet = {apiWallet}
              setApiWallet ={setApiWallet}
              setIsApiPayout={setIsApiPayout}
              amountInput={amountInput}
              setNubanTempData={setNubanTempData}
              setIsVirtualConfirm = {setIsVirtualConfirm}
              setNubanId = {setNubanId}
              setNubTempInput={setNubTempInput}
              nubanData={nubanData}
            />
          }


          {isPaymentMethod && <FundBalance 
          balance
          setIsPaymentMethod={setIsPaymentMethod} 
          setIsAmount = {setIsAmount}
          setIsVertual ={setIsVertual}
          setIsCardMethod={setIsCardMethod}
          getLinkedCards={getLinkedCards}
          transactionId={transactionId}
          setNubanData={setNubanData}
          balanceData={balanceData}
          setIsCommingSoon={setIsCommingSoon}
          payoutCurrency={payoutCurrency}
          apiWallet = {apiWallet}
          setApiWallet ={setApiWallet}
          setIsApiPayout={setIsApiPayout}
          setIsCardApi={setIsCardApi}
          amountInput={amountInput}
          setIsVirtualConfirm = {setIsVirtualConfirm}
          setNubanId = {setNubanId}
          setNubTempInput={setNubTempInput}
          setNubanTempData={setNubanTempData}
          />}

          {
            isAmountNormal &&
            <AmountNormal 
            setIsAmountNormal = {setIsAmountNormal}
            setIsOtherBalance ={setIsOtherBalance}
            setIsPaymentMethod ={setIsPaymentMethod}
            setAmountInput={setAmountInput}
            iniateCharge={iniateCharge}
            iniateChargeOtherBalance={iniateChargeOtherBalance}
            isAmountLoading = {isAmountLoading}
            isApiWallet = {isApiWallet}
            setApiWallet={setApiWallet}
            setIsBalanceOpen={setIsBalanceOpen}
            setIsPayment = {setIsPayment}
            />
          }

{
            isOtherBalance &&
            <FundOtherBalance 
            setIsOtherBalance ={setIsOtherBalance}
            setIsAmount = {setIsAmount}
            setIsAmountNormal={setIsAmountNormal}
            setIsVertual ={setIsVertual}
            setIsCardMethod={setIsCardMethod}
            getLinkedCards={getLinkedCards}
            transactionId={transactionId}
            setNubanData={setNubanData}
            setIsCommingSoon={setIsCommingSoon}
            payoutCurrency={payoutCurrency}
            apiWallet = {apiWallet}
            setApiWallet ={setApiWallet}
            setIsApiPayout={setIsApiPayout}
            amountInput={amountInput}
            setNubanTempData={setNubanTempData}
            setIsVirtualConfirm = {setIsVirtualConfirm}
            setNubanId = {setNubanId}
            setNubTempInput={setNubTempInput}
            setIsBalanceOpen={setIsBalanceOpen}
            setIsPayment = {setIsPayment}
            />
          }

          {
            isApiPayout && <ApiPayoutBalance 
            setIsApiPayout={setIsApiPayout}
            balanceData={balanceData}
            handleApiOtherBalance={handleApiOtherBalance}
            isApiFundLoading={isApiFundLoading}
            />
          }

          { 
          isApiOtp &&
            <ApiWalletOtp 
            setIsApiOtp={setIsApiOtp}
            setIsApiPayout={setIsApiPayout}
            apiData ={apiData}
            apiInputData={apiInputData}
            setIsPaymentMethod={setIsPaymentMethod}
            setIsAmount = {setIsAmount} 
            getBalance ={getBalance}
            />
          }

          {
          isVertual && <VirtualAccount
          setIsPaymentMethod={setIsPaymentMethod} 
          setIsVertual ={setIsVertual}
          setIsSuccess = {setIsSuccess}
          transactionId={transactionId}
          nubanData={nubanData}
          apiWallet={apiWallet}
          nubanTempData={nubanTempData}
          setIsVirtualConfirm = {setIsVirtualConfirm}
          setIsPayment={setIsPayment}
          setIsBalanceOpen={setIsBalanceOpen} 
          />
          }

          {
            isVirtualConfirm &&
            <VirtualConfirm 
            setIsVirtualConfirm = {setIsVirtualConfirm}
            isVirtualConfirm = {isVirtualConfirm}
            transactionId = {nubanId}
            nubTemInput={nubTemInput}
            getBalance={getBalance}
            />
          }

          {
          isCardMethod && <SelectPayment 
           setIsCardMethod={setIsCardMethod}
           setIsOtp={setIsOtp}
           setIsCardDetails={setIsCardDetails}
           LinkedCardData ={LinkedCardData}
           setIsPinOtp={setIsPinOtp}
           transactionId={transactionId}
           apiWallet = {apiWallet}
           setIsAmountNormal={setIsAmountNormal}
           setIsPayment = {setIsPayment}
           setIsOtherBalance ={setIsOtherBalance}
           setIsBalanceOpen={setIsBalanceOpen}
           
           />
           }

          {
          isCardDetails && <CardDetails 
             setIsCardDetails={setIsCardDetails}
             setIsCardMethod ={setIsCardMethod}
             transactionId={transactionId}
             livePublicEncKey={livePublicEncKey}
             setIsOtp={setIsOtp}
             setIsPinOtp={setIsPinOtp}
             inputCardDetails={inputCardDetails}
             setInputCardDetails ={setInputCardDetails}
             setIsCardLoading={setIsCardLoading}
             apiWallet = {apiWallet}
             setIsAmountNormal={setIsAmountNormal}
             setIsPayment = {setIsPayment}
             setIsOtherBalance ={setIsOtherBalance}
             setIsBalanceOpen={setIsBalanceOpen}
           />
           }
          {isOtp && <TransactionOtp 
            setIsPinLoading = {setIsPinLoading}
            setIsOtp={setIsOtp}
            transactionId={transactionId}
            setIsTransactionSuccess={setIsTransactionSuccess}
            setIsPinOtp={setIsPinOtp}
            setIsAmountNormal={setIsAmountNormal}
            setIsPayment = {setIsPayment}
            setIsOtherBalance ={setIsOtherBalance}
            setIsCardDetails={setIsCardDetails}
            setIsCardMethod={setIsCardMethod}
            setIsBalanceOpen={setIsBalanceOpen}
          />
          }
          {
            isPinOtp && <PinOtp 
            setIsPinOtp={setIsPinOtp}
            setIsOtpLoading={setIsOtpLoading}
            setIsOtp={setIsOtp}
            setIsCardMethod={setIsCardMethod}
            setIsTransactionSuccess={setIsTransactionSuccess}
            transactionId={transactionId}
            getBalance={getBalance}
            setIsAmountNormal={setIsAmountNormal}
             setIsPayment = {setIsPayment}
             setIsOtherBalance ={setIsOtherBalance}
             setIsBalanceOpen={setIsBalanceOpen}
             setIsCardDetails={setIsCardDetails}
            />
          }
          {isTransactionSuccess && <TransactionSuccessful 
           setIsTransactionSuccess={setIsTransactionSuccess}
           inputCardDetails={inputCardDetails}
           amountInput={amountInput}
           transactionId = {transactionId}
           setIsAmount = {setIsAmount}
           setIsPaymentMethod={setIsPaymentMethod}
           setIsCardMethod={setIsCardMethod}
           setIsCardDetails={setIsCardDetails}
           setIsOtp={setIsOtp}
           setIsPinOtp={setIsPinOtp}
            setIsAmountNormal={setIsAmountNormal}
            setIsPayment = {setIsPayment}
            setIsOtherBalance ={setIsOtherBalance}
            setIsBalanceOpen={setIsBalanceOpen}
             
          />}
          {isSuccess && <Success
           setIsTransactionSuccess={setIsTransactionSuccess}
           setIsSuccess= {setIsSuccess}
          />}

            {
              isTransactionHistory &&
              renderTransactionHistory()
            }

           {
            isCommingSoon &&
            <ComingSoonPop setIsCommingSoon={setIsCommingSoon}/>
          }
          {
            (isAmountLoading || isCardsLoading || isOtpLoading || isPinLoading) &&
            <TableLoader msg/>
          }
    </div>
   
        
        }
   </>
           
  )
}

export default OverView
