import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { toast } from 'react-toastify';
function ApiWalletOtp({setIsApiOtp,payoutId,apiData,apiInputData,setIsApiPayout,setIsPaymentMethod,setIsAmount,getBalance}) {
 const { currentUser } = useSelector((state) => state?.user);
  const [otpInput,setOtpInput] = useState('');
  const [isLoading,setIsLoading] = useState(false);
  const handleClose = ()=>{
    setIsApiOtp(false)
    setIsApiPayout(false)
    setIsPaymentMethod(false)
    setIsAmount(false)
  }
  const handleChange = (e)=>{
    setOtpInput(e.target.value);
   }
  const handleOtp = ()=>{
   
  }

  const handleSuccess = async (e)=>{
    e.stopPropagation();
    setIsLoading(true);
    try{

      const headers = {
        'Authorization': `Bearer ${currentUser?.user?.merchantKey}`
      };

      const data = {
        Id:apiData.Id,
        token: otpInput,   
      }
  
      // Make an API call to fetch accountName using bankCode and accountNumber.
      const url = "https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/complete-payout";

      let res = await axios.post(url,{...data},{ headers: headers});
      setIsLoading(false);
      setIsApiOtp(false)
      setIsApiPayout(false)
      setIsPaymentMethod(false)
      setIsAmount(false)
      toast.success("payout successfull")
      getBalance()
      }catch(err){
        setIsLoading(false);
        console.log(err);
        toast.error("unable to confirm transfer",{ position: "top-left", pauseOnHover: true})
      }
  }

  return (
    <div className="overlay"> 
      <div className="enter-amount-modal">
        <div className="modal-wrapper">
          <div>
          <img
              src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"}
              alt="icon"
              style={{cursor:"pointer"}}
              onClick={setIsApiOtp.bind(this,false)}
            />
          </div>

          <div className="modal-title">
            <p className="enter-amount">Confirm Payout</p>
            <p className="enter-an-amount">
             Enter the confirmation code sent to <span style={{color:"#151F32",fontWeight:"700"}}>{apiData?.email}</span> or <span style={{color:"#151F32",fontWeight:"500"}}> {`+${apiData?.phone}`}</span>
            </p>
          </div>

          <div className='pin_container'>
             <input type='password' className='input_otps' onChange={handleChange} placeholder='xxxxxxxx'/>
          </div>
         
            
            <p className='enter-an-amount' style={{fontSize:"14px"}}>
            You are about to payout {apiInputData?.currency}<span style={{color:"#151F32",fontWeight:"700"}}>{apiInputData?.amount? (apiInputData?.amount)/100:""}</span> to <span style={{color:"#151F32",fontWeight:"700"}}>{apiData?.bank_name}</span>
            </p>

          <div className="modal-btn">
            <button  onClick={handleClose} className="first-btn">
              Cancel
            </button>
            <button  onClick={handleSuccess} className="btn">
            {
                    isLoading ?
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />:
                    <>
                    Confirm
                    </>
                    }
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApiWalletOtp