import React, { useEffect, useState } from 'react'
import '../components/data/data.scss'
import Status from '../components/common/Status'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { useSelector } from 'react-redux';
import BvnReciept from '../components/data/BvnReciept';
import CacReciept from '../components/data/CacReciept';
import NinReciept from '../components/data/NinReciept';
import TableLoader from '../components/common/TableLoader';
import LicenseReceipt from '../components/data/LicenseReceipt';




function DataIdentityDetail() {

  const navigate = useNavigate();
  const {currentUser} = useSelector((state)=>state?.user)
  const location = useLocation()
  const  {item} =  location?.state || {}
  const [identity,setIdentity] = useState({})
  const [cacResult,setCacResult] = useState({})
  const [isLoading,setIsLoading] = useState(false)
  const handleNavigate = ()=>{
    navigate("/data-identity")
  }
  useEffect(()=>{
    SubmitIdentity();
     // eslint-disable-next-line
  },[])

  let cacValString = "CAC - Business Basic";
  let cacVal = cacValString.toLowerCase()
  const identityData = async()=>{
    let result = '';
    let data = '';
    
    let idType = String(item?.idType).toLowerCase();
    switch (idType) {
      case 'vnin':
        result = 'vnin';
        data = item?.IdNumber || "";
        break;
      case 'bvn':
        result = "bvn"
        data = item?.IdNumber || "";
        break;
      case 'nin':
        result = "nin"
        data = item?.IdNumber || "";
        break;
      case 'nuban':
        result = 'nuban';
        data = item?.IdNumber || "";
        break;
      case 'phone':
        result = 'phone';
        data = item?.IdNumber || "";
        break;
      case 'license':
        result ="license";
        data = item?.IdNumber || "";
        break;
      case cacVal:
        result = cacVal;
        data = item?.IdNumber || "";
        break;
      case 'passport':
        result ="passport";
        data = item?.IdNumber;
        break;
      default:
        result = '';
    }
    return  {
      data_type:result, 
      data: data
    }
  }

  const SubmitIdentity = async ()=>{
    let url;
    let bodydata;
    setIsLoading(true);
    try{
      const headers = {  
        "authorization": currentUser?.user?.merchantKey, 
      }
      const result = await identityData()
      if(result.data_type === "bvn"){

        url = 'https://ocrf32y464.execute-api.us-east-2.amazonaws.com/production/identity';
        bodydata = {
          data_type:result.data_type, 
          data: result.data,
         
        };
      }else if(result.data_type === cacVal){
        bodydata = {
          rcNumber:result.data
        };
        url = 'https://ocrf32y464.execute-api.us-east-2.amazonaws.com/production/biz-basic';
      }else if(result.data_type === "nin"){
        url = 'https://ocrf32y464.execute-api.us-east-2.amazonaws.com/production/identity';
        bodydata = {
          data_type:result.data_type,
          nin:result.data,
          
        };
      }
      // else if(result.data_type === "license"){
      //   url = 'https://ocrf32y464.execute-api.us-east-2.amazonaws.com/production/identity';
      //   bodydata = {
      //     data_type:result.data_type,
      //     nin:result.data,
          
      //   };
      // }

      let res = await axios.post(url,{...bodydata},{
        headers:headers
      });
      setIsLoading(false);
      
      if(result.data_type==="bvn"){
        const newValue = res?.data?.data?.identity?.data;
        setIdentity(newValue);
      }else if(result.data_type===cacVal){
        const newValue = res?.data?.data?.identity?.data;
        setCacResult(newValue);
      }else if(result.data_type === 'nin'){
        const newValue = res?.data?.data?.identity?.data?.full_details;
        
        setIdentity(newValue);
      }
      
    }catch(err){
      setIsLoading(false);
      console.log(err)
    }
    
  } 
  
  return (
    <div className='main-inner'>
     
      <div onClick={handleNavigate} className='btnData' >
        <img src={process.env.PUBLIC_URL+"/images/arrowBack.svg"} alt='arrow' />
        <p>Go Back</p>
      </div>

      {/* <div className='identityVerify'>
          <div className='verify-top'>
              <h3>{identity?.firstname + " " + identity?.lastname}</h3>
              <div style={{display:'flex',gap:"5px"}}>
                <p>Verification Status:</p>
                <Status 
                icon="/images/success.svg"
                mgs="Successful"
                />
              </div>
          </div>

          <div className='verify-img'>
              <img src={identity?.image} alt="" />
          </div>

          <div className='verify-content'>
              <table className='tbl'>
                <tr>
                  <td className='verify-text-sm'>BVN</td>
                  <td className='verify-text-sm left-mg'>NIN</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{item?.IdNumber || identity?.full_details?.bvn || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.full_details?.nin || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Full name</td>
                  <td className='verify-text-sm left-mg mg-top'>Gender</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.firstname + " " + identity?.middlename + " " + identity?.lastname}</td>
                  <td className='verify-text-md left-mg'>{identity?.full_details?.gender || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Date of birth</td>
                  <td className='verify-text-sm left-mg mg-top'>Email</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.full_details?.date_of_birth || "Not Available" }</td>
                  <td className='verify-text-md left-mg'>{identity?.full_details?.email || "Not Available" }</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Residential address</td>
                  <td className='verify-text-sm left-mg mg-top'>Marital status</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.address || identity?.full_details?.address || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.marital_status || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Phone number</td>
                  <td className='verify-text-sm left-mg mg-top'>Origin location</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.phone_1 || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.lga_of_origin || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Registered on</td>
                  <td className='verify-text-sm left-mg mg-top'>Name on card</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.enrollment?.registration_date || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.fullname || "Not Available"}</td>
                </tr>
                <tr>
                  <td className='verify-text-sm mg-top'>Nationality</td>
                  <td className='verify-text-sm left-mg mg-top'>Watchlisted</td>
                </tr>
                <tr>
                  <td className='verify-text-md'>{identity?.nationality || "Not Available"}</td>
                  <td className='verify-text-md left-mg'>{identity?.watchlisted || "Not Available"}</td>
                </tr>
              </table>
          </div>
      </div> */}
      {!isLoading && String(item?.idType).toLowerCase() === "bvn" && (
        <BvnReciept identity={identity} />
      )}
      
      {
       !isLoading &&  item?.idType === "CAC - Business Basic" &&
       (
         <CacReciept 
         identity={cacResult}
         />
       )
      }
      {
       !isLoading &&  item?.idType === "nin" &&(
          <NinReciept 
          identity={identity}
          />

        )
      }
      {
        !isLoading && item?.idType === "license" &&(

          <LicenseReceipt 
          identity={identity}
          />
        )
      }
      {
        isLoading &&
        <TableLoader msg />
      }
    </div>
  )
}

export default DataIdentityDetail
