import React, { useState } from "react";
import banks from '../../utilities/banks.json'
import DoubleInputGroup from "../../otc/DoubleInputGroup";
import SingleInputGroup from "../../otc/SingleInputGroup";
import BusinessAddress from "../../otc/BusinessAddress";
import SettlementBankDetail from "./SettlementBankDetail";
function SettlementModal({ open, onClose,setFormData,formData,addSettlementAccount }) {
  const [country, setCountry] = useState("");
  const bankData = banks;
  const [showOTC, setShowOTC] = useState(false); 
  const [showNigeria, setShowNigeria] = useState(true); 
  const [showAddNigeria, setShowAddNigeria] = useState(true);
  const [showSettlementDetail, setShowSettlementDetail] = useState(false); 

  
  if (!open) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    if (name === "country") {
      if (value === "Nigeria") {
        setShowOTC(false);
        setShowNigeria(true);
        setShowAddNigeria(true);
        setFormData((prevFormData) => ({
          ...prevFormData,
          currency: "NGN",
        }));
      } else if (value === "USA" || value === "UK") {
        setShowOTC(true);
        setShowNigeria(false);
        setShowAddNigeria(false);
        setFormData((prevFormData) => ({
          ...prevFormData,
          currency: value === "USA" ? "USD" : "GBP",
        }));
      } else {
        setShowOTC(false);
        setShowNigeria(true);
        setShowAddNigeria(true);
        setFormData((prevFormData) => ({
          ...prevFormData,
          currency: "",
        }));
      }
    }
  };

  const handleSubmit = (e)=>{
    e.preventDefault();
    addSettlementAccount();
  }
  

  const openSettlementDetail = () => {
    setShowSettlementDetail(true);
  };

  const closeSettlementDetail = () => {
    onClose();
    setShowSettlementDetail(false);
  };
  
  return (
    <div className="settlement-overlay" onClick={onClose}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="settlement-set-modal"
      >
        <div className="settlement-modal-inner" style={{width:"89%"}}>
          <div className="close-icon" style={{justifyContent:"flex-start", marginTop:"24px", marginBottom:"16px"}}>
            <img
              onClick={onClose}
              src={process.env.PUBLIC_URL + "/images/back-arrow.svg"}
              alt=""
            />
          </div>
          <div className="add-account" style={{borderBottom:"none", marginBottom:"0"}}>
            <div style={{display:"flex", justifyContent:"space-between"}}>
            <h2 className="add-account-text">Add Account Details</h2>
            {showOTC && <img src='/images/otc/step_one.svg' alt='' />}
            </div>
            <h5 className="authenticate">
            Please provide the details of beneficiary settlement <br/> account 
            </h5>
          </div>
          <form onSubmit={handleSubmit} className="settlement-form">
            <div className="settlement-form-wrapper">
              <div className="settlement-form-groups">
                <label className="lbl">Select country</label>
                <div className="form-select-group">
                <select
                  className="input-select"
                  value={formData.country}
                  name="country"
                  onChange={handleInputChange}
                 >
            
                  <option value="" disabled>Nigeria</option>
                  <option value="Nigeria">Nigeria</option>
                  <option value="USA">USA</option>
                  <option value="UK">UK</option>
                </select>
                </div>
              </div>
              <div className="settlement-form-groups">
                <label className="lbl">Account currency</label>
                <div className="form-select-group">
                  <select
                    className="input-select"
                    value={formData.currency}
                    name="currency"
                    onChange={handleInputChange}
                    disabled
                  >
                    <option value="" disabled>NGN</option>
                    <option value="NGN">NGN</option>
                    <option value="USD">USD</option>
                    <option value="GBP">GBP</option>
                  </select>
                </div>
              </div>

              {showNigeria && (
                <div className="nigeria">
                  <div className="settlement-form-groups">
                    <label className="lbl">Bank name</label>
                    <div className="form-select-group">
                      <select
                        className="input-select"
                        value={formData.bankCode}
                        name="bankCode"
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>Select bank..</option>
                        {bankData.map((bank, index) => (
                          <option
                            key={index}
                            value={bank.code}
                          >
                            {bank.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="settlement-form-groups">
                    <label className="lbl">Account number</label>
                    <input
                      type="number"
                      name="accountNumber"
                      placeholder="678909876235"
                      className="form-control"
                      value={formData.accountNumber}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              )}
            </div>
            {showAddNigeria && (
              <div className="add-nigeria">
                <div className="settlement-checkbox">
                  <input type="checkbox" className="custom-checkbox" />
                  <h2 className="settlement-checkbox-text">
                    Make this my primary settlement account{" "}
                  </h2>
                </div>
                <button className="btn" style={{ width: "47%" }}>Add account</button>
              </div>
            )}

            {showOTC && (
              <div className="o-t-c">
                <div className='otcform_container'>
                  <DoubleInputGroup label1="Account Number" label2="Routing Number" handleChange={handleInputChange} name1="accountNumber" name2="routingNumber" placeholder1="22488136902667" placeholder2="23690867" />
                  <SingleInputGroup label="Beneficiary Account Name" handleChange={handleInputChange} name="beneficiaryName" placeholder="Ayomide Ajibade" />
                  <BusinessAddress label="Beneficiary Address" />
                  <div style={{ display: "flex", flexDirection: "column", marginTop: "20px" }}>
                    <DoubleInputGroup label1="Address Line 1" label2="Address Line 2" handleChange={handleInputChange} name1="address1" name2="address2" />
                    <DoubleInputGroup label1="City" label2="State" handleChange={handleInputChange} name1="city" name2="state" />
                    <DoubleInputGroup select label1="Country" label2="Postal Code" handleChange={handleInputChange} name1="city" name2="state">
                      <option value="" disabled>USA</option>
                      <option value="USD">USA</option>
                    </DoubleInputGroup>
                  </div>
                </div>
              </div>
            )}
          </form>
          {showOTC && (
          <div className='otc_btn_modal' style={{paddingBottom:"32px"}}>
                  <button className='btn otc_btn_cancel' onClick={onClose}>
                    Cancel
                  </button>
                  <button className='btn' onClick={openSettlementDetail}>
                    Confirm
                  </button>
          </div>
          )}
        </div>
      </div>
          {showSettlementDetail && <SettlementBankDetail onClose={closeSettlementDetail} open={showSettlementDetail} />}
    </div>
  );
}

export default SettlementModal;
