import React from 'react'

function DailRejected({setIsRejected}) {
    const handleClose = ()=>{
        setIsRejected(false)
    }
  return (
    <div className='balance_modal'  onClick={setIsRejected.bind(this, false)} >
       
    <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
      <div className='balance_modal_wrapper'>
          <img 
          onClick={setIsRejected.bind(this, false)}
          className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
          />

        <div className="modal-title">
            <p className="enter-amount">Reject Deal</p>
            <p className="enter-an-amount">You are about to cancel this order</p>
        </div>

        <div className='otc_info_container'>
            <img src='/images/otc/info-circle.svg' alt='' />
            <p className='otc_info'>No changes are possible after proceeding</p>
        </div>

        

        <div className='otc_btn_modal'>
            <button className='btn otc_btn_cancel' onClick={handleClose} >
                Cancel
            </button>
            <button className='btn reject_deal_btn' onClick={setIsRejected.bind(this,true)} >
                Reject Deal
            </button>
        </div>
      </div>
    </div>
</div>
  )
}

export default DailRejected