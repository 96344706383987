import React, { useState } from 'react'
import DataContext from './DataContext'

function DataProvider({children}) {
    const [customerData,setCustomerData] = useState([]);
    const [balanceResult,setBalanceResult] = useState([])
    const [singleCustomer,setSingleCustomer] = useState([])
    const [customerSummary,setCustomerSummary] = useState({})
  return (
    <DataContext.Provider value={{customerData,setCustomerData,balanceResult,setBalanceResult,setSingleCustomer,singleCustomer,setCustomerSummary,customerSummary}}>
        {children}
    </DataContext.Provider>   
  )
}

export default DataProvider  