import React, { useRef } from 'react'
import Details from '../../common/Details';
import { copyToClipboard } from '../../utilities/clipboardUtils';
import { formatDate } from '../../utilities/formatDate';
import { useSelector } from 'react-redux'

function CustomerIdentity({identity,account}) {
  const {currentUser} = useSelector((state)=>state?.user);

    const refLink = useRef(null);
  return (
    <div className='data_customer'>
      <div className='topbar_identity'>
        <div className='link-bar-left'>
            <img src="/images/Avatar.svg" alt='avater' />
            <div className='link-right'>
                <h5 className='text_h3'>{account?.fullName}</h5>
                <div className='link-right-copy'>
                    <input ref={refLink} className='input_link_copy' type='text' value={identity?.email} />
                    <img onClick={copyToClipboard.bind(null,refLink)} src='/images/copy.svg' alt="copy" />
                </div> 
            </div>
        </div>
      </div>
        
      <Details
       fullName={account?.fullName || "N/A"} 
       email={identity?.email || "N/A"}
       phone={identity?.phone || "N/A"}
       linked={identity?.linkedAccounts || "N/A"}
       date={identity?.dateAdded ? formatDate(identity?.dateAdded) : "N/A"}
       synced={identity?.dateUpdated && identity?.dateUpdated !=="N/A"  ? formatDate(identity?.dateUpdated) : "N/A"}
       state={identity?.state || "N/A"}
       bvn={identity?.bvn || "N/A"}
       nin={identity?.nin || "N/A"}
       nationality={identity?.nationality || "N/A"}
       address={identity?.address ?identity?.address !=="undefined undefined"?identity?.address:"N/A" : "N/A"}
       income={identity?.income || "N/A"}
        />
      
    </div>
  ) 
}

export default CustomerIdentity
