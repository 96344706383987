import React, { useEffect, useState } from "react";
import "../data/data.scss";
import { BeatLoader } from "react-spinners";

function CreateDirectDebitForm({isLoading,directLinkInput,setDirectLinkInput,setIsDirectDebitLink,createDirectDebitLink,setIsDirectDebitCreated}) {
  const [textValue, setTextValue] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  useEffect(() => {
    setDirectLinkInput({
      ...directLinkInput
      
    });
    // eslint-disable-next-line 
  }, []);


  const handleOptionChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setDirectLinkInput({
      ...directLinkInput,
      productType: value,
    });
  };
  const handleTextChange = (event) => {
    setTextValue(event.target.value);
  };
  const handlePropagation = (e) => {
    e.stopPropagation();
  };
  const handleChange = (e) => {
    const { name, value,type } = e.target;
    console.log("handleChange",name, value)
  // Convert radio button value to boolean if name is is_open_banking
    const inputValue = type === 'radio' && name === 'is_open_banking' 
      ? value === "true" 
      : value;

    setDirectLinkInput({
      ...directLinkInput,
      [name]: inputValue,
    });
    if(name==="productType"){
      
      setSelectedOption(value);
    }
  };
  const [isInputClicked, setIsInputClicked] = useState({
    productType: false,
    transactionId: false,
    startDate: false,
    endDate: false,
    changeDate: false,
    email: false,
    phoneNumber: false,
    currency: false,
    amount: false,
    frequency: false,
    redirectLink: false,
    webhookUrl:false,
  });
  const handleInputContainerClick = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: true,
    }));
  };
  const handleInputBlur = (inputName) => {
    setIsInputClicked((prevState) => ({
      ...prevState,
      [inputName]: false,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    createDirectDebitLink();
    // setIsDirectDebitCreated(true)
  };

  return (
    <div
      className="link-modal"
      onClick={setIsDirectDebitLink.bind(this, false)}
    >
      <div className="link-box" onClick={handlePropagation}>
        <div className="link-box-wrapper">
          <img onClick={setIsDirectDebitLink.bind(this, false)} className="link-modal-close" src="/images/x-close.svg" alt="f"/>
          <div className="link-title" style={{ marginBottom: "0px" }}>
            <h3>Create a direct debit link</h3>
            <p>Create a direct debit for a customer payment.</p>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="statement_link" style={{ marginTop: "14px" }}>
              <div className="payment-link">

              <div className="input_container" onBlur={() => handleInputBlur("pagename")}>
                  <div className={`input-group ${ isInputClicked.pagename ? "clicked" : ""}`} onClick={() => handleInputContainerClick("pagename")} onBlur={() => handleInputBlur("pagename")}>
                    <input
                      onChange={handleChange}
                      type="text"
                      name="pagename"
                      value={directLinkInput.pagename}
                      placeholder="sholas's link"
                      className="input-name"
                      required
                      // required={selectedOption === "onetime"}
                    />
                  </div>
                  <p className={`input-label ${isInputClicked.pagename ? "clicked" : ""}`}>Page name </p>
                </div>
                
                <div className="input_container" onBlur={() => handleInputBlur("productType")}>
                  <div className={`input-group ${ isInputClicked.productType ? "clicked" : "" }`} onClick={() => handleInputContainerClick("productType")}>
                    <select
                      onChange={handleChange}
                      className="input-name"
                      name="productType"
                      value={selectedOption}
                      required
                      style={{ width: "100%" }}>
                      <option value="">debit type</option>
                      <option value="recurring">Re-Occurring</option>
                      <option value="onetime">One-Time</option>
                    </select>
                  </div>
                  <p className={`input-label ${ isInputClicked.productType ? "clicked" : ""}`}>Select Debit Type</p>
                </div>

          

            {selectedOption !== "onetime" && (
                <div className="double-input-container">
                  <div className="input_container" onBlur={() => handleInputBlur("startDate")}>
                    <div className={`input-group ${ isInputClicked.startDate ? "clicked" : ""}`} onClick={() => handleInputContainerClick("startDate")} onBlur={() => handleInputBlur("startDate")}>
                      <input
                        onChange={handleChange}
                        type="text"
                        placeholder="YYYY-MM-DD"
                        name="startDate"
                        value={directLinkInput.startDate}
                        className="input-name"
                        required
                      />
                    </div>
                    <p className={`input-label ${isInputClicked.startDate ? "clicked" : ""}`}>Start date</p>
                  </div>
                  <div className="input_container" onBlur={() => handleInputBlur("endDate")}>
                    <div className={`input-group ${ isInputClicked.endDate ? "clicked" : ""}`} onClick={() => handleInputContainerClick("endDate")} onBlur={() => handleInputBlur("endDate")}>
                      <input
                        onChange={handleChange}
                        type="text"
                        placeholder="YYYY-MM-DD"
                        name="endDate"
                        value={directLinkInput.endDate}
                        className="input-name"
                        required
                      />
                    </div>
                    <p className={`input-label ${isInputClicked.endDate ? "clicked" : ""}`}>End date</p>
                  </div>
                </div>
            )}
            {selectedOption === "onetime" && (
                <div className="input_container" onBlur={() => handleInputBlur("changeDate")}>
                    <div className={`input-group ${ isInputClicked.changeDate ? "clicked" : ""}`} onClick={() => handleInputContainerClick("changeDate")} onBlur={() => handleInputBlur("changeDate")}>
                      <input
                        onChange={handleChange}
                        type="text"
                        placeholder="YYYY-MM-DD"
                        name="changeDate"
                        value={directLinkInput.changeDate}
                        className="input-name"
                        required
                      />
                    </div>
                    <p className={`input-label ${isInputClicked.changeDate ? "clicked" : ""}`}>Change date</p>
                </div>
            )}
                <div className="double-input-container">
                 
                </div>
               
                <div className="link-form-item">
                  <div style={{ display: "flex", gap: "6px" }}>
                    <div className="input_container" style={{width:"25%"}} onBlur={() => handleInputBlur("currency")}>
                        <div className={`input-group ${ isInputClicked.currency ? "clicked" : "" }`} style={{padding: "0"}} onClick={() => handleInputContainerClick("currency")}>
                            <select
                            onChange={handleChange}
                            className="input-name"
                            name="currency"
                            value={directLinkInput.currency}
                            required
                            >
                            <option value="NGN">&#x1F1F3;&#x1F1EC; NGN </option>
                            <option value="USD">&#x1F1FA;&#x1F1F8; USD </option>
                            <option value="CAD">&#x1F1E8;&#x1F1E6; CAD </option>
                            <option value="EUR">&#x1F1EA;&#x1F1FA; EUR </option>
                            <option value="GBP">&#x1F1EC;&#x1F1E7; GBP </option>
                            </select>
                        </div>
                    </div>
                    <div className="input_container" onBlur={() => handleInputBlur("amount")}>
                        <div className={`input-group ${ isInputClicked.amount ? "clicked" : ""}`} onClick={() => handleInputContainerClick("amount")} onBlur={() => handleInputBlur("amount")}>
                        <input
                            onChange={handleChange}
                            type="text"
                            placeholder="0.50"
                            name="amount"
                            value={directLinkInput.amount}
                            className="input-name"
                            required
                        />
                        </div>
                    </div>
                  </div>
                </div>
                {selectedOption !== "onetime" && (
                    <div className="input_container" onBlur={() => handleInputBlur("frequency")}>
                            <div className={`input-group ${ isInputClicked.frequency ? "clicked" : "" }`} onClick={() => handleInputContainerClick("frequency")}>
                                <select
                                onChange={handleChange}
                                className="input-name"
                                name="frequency"
                                value={directLinkInput.frequency}
                                required
                                style={{ width: "100%" }}
                                >
                                  <option value="" selected disabled>Frequency</option>
                                  <option value="daily">Daily</option>
                                  <option value="weekly">Weekly</option>
                                  <option value="monthly">Monthly</option>
                                </select>
                            </div>
                    </div>
               )}
               

               {selectedOption !== "onetime" && (
                  <>
                   
                   <div className="input_container">
                      <label>Open Banking</label>
                      <div>
                        <label>
                          <input
                            type="radio"
                            name="is_open_banking"
                            value={true}
                            checked={directLinkInput.is_open_banking === true}
                            onChange={handleChange}
                          />
                          Yes
                        </label>
                        <label >
                          <input
                            type="radio"
                            name="is_open_banking"
                            value={false}
                            checked={directLinkInput.is_open_banking === false}
                            onChange={handleChange}
                          />
                          No
                        </label>
                      </div>
                   </div>
                  

                    <div className="input_container" onBlur={() => handleInputBlur("no_of_accounts")}>
                      <div className={`input-group ${isInputClicked.no_of_accounts ? "clicked" : ""}`} onClick={() => handleInputContainerClick("no_of_accounts")}>
                        <select
                          onChange={handleChange}
                          className="input-name"
                          name="no_of_accounts"
                          value={directLinkInput.no_of_accounts}
                          style={{ width: "100%" }}
                        >
                          <option value="">Select number of accounts</option>
                          <option value="1">1 Account</option>
                          <option value="2">2 Accounts</option>
                          <option value="3">3 Accounts</option>
                        </select>
                      </div>
                      <p className={`input-label ${isInputClicked.no_of_accounts ? "clicked" : ""}`}>Number of Accounts</p>
                    </div>
                  </>
                )}
               
                

              </div>
                <button className="btn">
                  {isLoading ? (
                    <BeatLoader color="#FFF" cssOverride={{}} />
                  ) : (
                    <>Create Link</>
                  )}
                </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default CreateDirectDebitForm;
