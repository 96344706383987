import React, { useState } from 'react'
import '../common/tables.scss'
import TableHeaderSearch from '../common/TableHeaderSearch'
import Status from '../common/Status'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { NumericFormat } from 'react-number-format'
import { format } from 'timeago.js'
import { changeCpage, nextPage, prePage } from '../utilities/pagination/pagination'
function BalanceHistoryTable({balanceHistoryData,getCollection,selectedCurrency,setSelectedCurrency,balanceData,collectionData}) {
  const navigate = useNavigate()
  const [copiedText, setCopiedText] = useState('');
  const [selectedValue,setSelectedValue] = useState('all')

  const [currentPage,setCurrentPage] = useState(1)

 
  const recordsPerPage = 20;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = collectionData?.slice(firstIndex,lastIndex);
  const nPage = Math.ceil(collectionData?.length/recordsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1)

   const handleCopyToClipboard = (text) => {
       navigator.clipboard.writeText(text);
       setCopiedText(text);
       toast.success('Copied successfully');
   };
       const handleNavigate =()=>{
       navigate("/payment-collections")
   }
  return (
    <div className='balance-overview'>
     
      <table className='tbl'>
        <tr>
            <td colspan="5" className='tbl-heading'>
                <TableHeaderSearch
                title="Transactions"
                placeholder="Search..."
                setSelectedCurrency={setSelectedCurrency}
                getCollection={getCollection}       
                balanceData={balanceData}
                setSelectedValue={setSelectedValue}
                selectedCurrency={selectedCurrency}
                />
            </td>  
        </tr>
       
        <tr>
            <th className='th-text'>Timestamp</th>
            <th className='th-text'>Amount</th>
            <th className='th-text'>Balance Before</th>
            <th className='th-text'>Balance After</th>
            <th className='th-text'>Transaction Details</th>
            
        </tr>
      
        {
          Array.isArray(collectionData) && collectionData.length > 0 && records.map((item,index)=>(
                <tr key={item.transactionid}>
                    <td className='td-text'>{format(item?.dated)}</td>

                   
                    <td className='td-text' style={{color:"#101828"}}>
                    <NumericFormat value={Number((item?.total_amount/100)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={`${item.currency_settled} `} 
                        renderText={(value) => <p className='td-text'>{value}</p>}
                    />
                    </td>

                    <td className='td-text' style={{color:"#101828"}}>
                    <NumericFormat value={Number((item?.balance_before/100)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={`${item.currency_settled} `} 
                        renderText={(value) => <p className='td-text'>{value}</p>}
                    />
                    </td>
                    <td className='td-text' style={{color:"#101828"}}>
                    <NumericFormat value={Number((item?.balance_after/100)).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={`${item.currency_settled} `} 
                        renderText={(value) => <p className='td-text'>{value}</p>}
                    />
                    </td>
                    <td className='td-text'>{(item.narration).length > 30 ?String(item.narration).slice(0, 31) + "..." :item.narration }</td>
                    

                </tr>
          ))
        }
    
        {
             Array.isArray(collectionData) && collectionData.length > 0 &&
            <tr>
                <td className='td-export' colspan="7">
                    <div className='tbl-export' onClick={handleNavigate}>
                        <h6 className='tbl-export-text'>View More</h6>
                        <img src={process.env.PUBLIC_URL+"/images/export.svg"} alt='export' />               
                    </div>
                </td>
            </tr>
        }

          {
          collectionData.length <= 0 &&
          <tr>
              <td  colSpan={7} style={{width:"100%"}}>
              <div className='empty_tbl'>
                  <img src='/images/emptyPage.svg' alt='emptuy' />
                  <h3 className='num_trans'>No Transactions Yet</h3>
                  <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
              </div>
              </td>
          </tr>
          }
      </table>


      <div className='pagination_container'>
          <div className='page-item' onClick={()=>prePage(currentPage,setCurrentPage)}>
            <img src='/images/pageLeft.svg' alt='' />
            <p className='Page_prev'>Previous</p>
          </div>   

          <div className='pageNumbers'>
            {
              numbers?.length > 0 && numbers.map((n,i)=>(
                <span key={i} 
                className={`page_num ${currentPage === n ? 'active':''}`}
                onClick={()=>changeCpage(setCurrentPage,n)}
                >
                  {n}
                </span>
              ))
            }

          </div> 

          <div className='page-item' onClick={()=>nextPage(currentPage,setCurrentPage,nPage)}>
            <img src='/images/pageRight.svg' alt='' />
            <p className='Page_prev'>Next</p>
          </div> 
      </div>
    </div>
  )
}

export default BalanceHistoryTable
