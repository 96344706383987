import React, { useRef } from 'react'
import DetailText from './DetailText'
import DetailTextCopy from './DetailTextCopy'

function FundWalletOtc({setIsFundBalance,setIsWalletBeneficiary,setIsClientResponse,setIsOrderSubmited,setIsOrder}) {
    const accRef = useRef(null);
    const IdRef = useRef(null);
    const handleClose = ()=>{
        setIsFundBalance(false)
        setIsClientResponse(false)
        setIsOrderSubmited(false)
        setIsOrder(false)
    }
  return (
    <div className='balance_modal'  onClick={setIsFundBalance.bind(this, false)} >
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
            <div className='balance_modal_wrapper'>
                <img 
                onClick={setIsFundBalance.bind(this, false)}
                className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
                />

                <div className="modal-title">
                    <p className="enter-amount">Fund Wallet</p>
                    {/* <p className="enter-an-amount">Please enter the amount above $5,000</p> */}
                </div>

            

                <div className='otcform_container' style={{marginTop:"16px"}}>
                        <p className='amount_requested'>Amount to pay</p>
                        <h6 className='amount_value'>N8,950,000</h6>
                </div>
                    
                <div className='otcform_container' style={{marginTop:"16px"}}>
                    <div style={{display:"flex",flexDirection:"column",gap:"20px"}}>
                        <DetailTextCopy desc1="Pay" value1="N8950,000" desc2="Account Name" value2="Flick - BLOOMCORE" ref={IdRef} />
                        <DetailTextCopy desc1="Account number" value1="0277890126" desc2="Bank Name" value2="GTBank" ref={accRef} />
                        <DetailTextCopy current desc1="Reference ID" value1="2351RA" desc2="Account Type" value2="Current" ref={accRef} />
                    </div>
                </div>

                <div className='otc_btn_modal'>
                    <button className='btn otc_btn_cancel' onClick={handleClose} >
                        Cancel
                    </button>
                    {/* <button className='btn' onClick={setIsWalletBeneficiary.bind(this,true)}>
                    I have sent the money
                    </button> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default FundWalletOtc