import React, { useEffect, useState } from 'react'
import NameControl from '../components/common/NameControl'
import Identities from '../components/data/Identities'
import VerifyIdentity from '../components/modals/data/VerifyIdentity';
import BankVerificationNumber from '../components/data/BankVerificationNumber';
import VirtualNIN from '../components/data/VirtualNIN';
import NUBANVerification from '../components/data/NUBANVerification';
import PhoneNumber from '../components/data/PhoneNumber';
import DriversLicense from '../components/data/DriversLicense';
import CACVerification from '../components/data/CACVerification';
import PassportVerification from '../components/data/PassportVerification';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import NinModal from '../components/data/NinModal';

function DataIdentity() {
  useEffect(()=>{
    getAllIdentity();
    // eslint-disable-next-line
  },[])
  const {currentUser} = useSelector((state)=>state?.user)
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [verificationResult, setVerificationResult] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [isLoading,setIsLoading] = useState(false)
  const [identityResponse,SetIdentityResponse] = useState()
  const [cacResponse,setCacResponse] = useState()
  const [ninResponse,setNinResponse] = useState({})
  const [licenseResponse,setLicenseResponse] = useState({})
  const [identityResult, setIdentityResult]  = useState([]);
  const [formData, setFormData] = useState({
    vnin: '',
    nin:'',
    bvn: '',
    nuban: '',
    country: '',
    phone:'',
    license:"",
    cac:'',
    passport:'',
    bureau:'',
    companyName:'',
    firstName:'',
    lastName:'',
    dob:'',

});
const [isIdentityLoading, setIsIdentityLoading] = useState(false);

const getAllIdentity = async ()=>{
  setIsIdentityLoading(true)
  try{
    const headers = {  
      "api_key": currentUser?.user?.merchantKey, 
      "sweetapi": currentUser?.user?.merchantCode  
    }
    const url = "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-identity-record"
    let res = await axios.get(url,{headers:headers});
    const result = res.data?.data
    // console.log("identity Data",result);
    setIdentityResult(result);
    setIsIdentityLoading(false)
  }catch(err){
    setIsIdentityLoading(false)
    console.log(err)
  }
}
const identityData = async(selectedOption)=>{

  let result = '';
  let data = '';

  switch (selectedOption) {
    case 'vnin':
      result = 'vnin';
      data = formData?.vnin || "";
      break;
    case 'bvn':
      result = "bvn"
      data = formData?.bvn || "";
      break;
    case 'nin':
      result = "nin"
      data = formData?.nin || "";
      break;
    case 'nuban':
      result = 'nuban';
      data = formData?.nuban || "";
      break;
    case 'phone':
      result = 'phone';
      data = formData?.phone || "";
      break;
    case 'license':
      result ="license";
      data = formData?.license || "";
  
      break;
    case 'cac':
      result = "cac";
      data = formData?.cac || "";
      
      break;
    case 'passport':
      result ="passport";
      data = formData?.passport;
     
      break;
      
    default:
      result = '';
  }
  return  {
    data_type:result, 
    data: data,
  }
}

  const verifyIdentity = () =>{
    setIsOpenPopup(true);
  }
  
  const SubmitIdentity = async ()=>{
    let url;
    let bodydata;
    setIsLoading(true);
    try{
      const headers = {  
        "authorization": currentUser?.user?.merchantKey, 
       
      }
      
      const result = await identityData(selectedOption)
      
      if(result.data_type==="bvn" ){
        bodydata = {
          data_type:result.data_type, 
          data: result.data
        };
        url = 'https://ocrf32y464.execute-api.us-east-2.amazonaws.com/production/identity';

      }else if(result.data_type==="cac"){
        bodydata = {
          rcNumber:result.data
        };
        url = 'https://ocrf32y464.execute-api.us-east-2.amazonaws.com/production/biz-basic';
      }else if(result.data_type==="license"){
        bodydata={
          dob: formData.dob,
          first_name: formData.firstName,
          last_name: formData.lastName,
          drivers_license: formData.license,
          
        }
        url = 'https://ocrf32y464.execute-api.us-east-2.amazonaws.com/production/identity';
      }else if(result.data_type==="nin"){
        bodydata={
          data_type:result.data_type,
          nin:result.data,
        }
        url = 'https://ocrf32y464.execute-api.us-east-2.amazonaws.com/production/identity';
      }
      
      // const url = 'https://ocrf32y464.execute-api.us-east-2.amazonaws.com/production/identity';
      let res = await axios.post(url,{...bodydata},{
        headers:headers
      })
      // console.log("response: ",res.data)
      if(result.data_type==="bvn"){
        const newValue = res?.data?.data?.identity?.data;
        SetIdentityResponse(newValue);
      }else if(result.data_type==="cac"){
        const newValue = res?.data?.data?.identity?.data;
        setCacResponse(newValue);
      }
      
      else if(result.data_type==="nin"){
        const newValue = res?.data?.data?.identity?.data;
        setNinResponse(newValue);
      }
      else if(result.data_type==="license"){
        const newValue = res?.data?.data?.frsc_data;
        setLicenseResponse(newValue);
      }
      getAllIdentity();
      
      setIsLoading(false);
      setVerificationResult(result.data_type);
      setIsOpenPopup(false);
    }catch(err){
      setIsLoading(false); 
      console.log(err)
      const errorMessage = err.response?.data?.message  || err?.message || 'Network error'
      toast.error(errorMessage)
    }
    
  } 

  const renderVerifyIdentityModal = () => {
    if (verificationResult === "bvn") return <BankVerificationNumber setVerificationResult={setVerificationResult} identityResponse={identityResponse}/>
    if (verificationResult === "nin") return <NinModal setVerificationResult={setVerificationResult} identityResponse={ninResponse}/>
    if (verificationResult ==="vnin") return <VirtualNIN setVerificationResult={setVerificationResult}/>
    if (verificationResult ==="nuban") return <NUBANVerification setVerificationResult={setVerificationResult} />
    if (verificationResult ==="phone") return <PhoneNumber setVerificationResult={setVerificationResult}/>
    if (verificationResult ==="license") return <DriversLicense setVerificationResult={setVerificationResult} licenseResponse={licenseResponse}/>
    if (verificationResult ==="cac") return <CACVerification setVerificationResult={setVerificationResult} cacResponse={cacResponse}/>
    if (verificationResult ==="passport") return <PassportVerification setVerificationResult={setVerificationResult}/>
  }
  return (
    
      <div className='main-inner'>
        
          <NameControl 
          identity
          data
          name="Identity"
          desc="Validate names, BVNs, phone numbers, addresses, and more to optimize your KYC process."
          btntext="Quick check"
          verifyIdentity = {verifyIdentity}
          />

          {/* table for verification */}

          <Identities  identityResult={identityResult} isLoading={isLoading} isIdentityLoading={isIdentityLoading} identityResponse={identityResponse} users="users" btnFirst numUsers="100"/>

          {/* pop up to for verification  */}
          {isOpenPopup && <VerifyIdentity 
          setVerificationResult={setVerificationResult} 
          setIsOpenPopup={setIsOpenPopup} 
          formData={formData} 
          setFormData={setFormData}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          SubmitIdentity ={SubmitIdentity}
          isLoading ={isLoading}
          />
          }
          {/* the pop up after verification */}
          {verificationResult && renderVerifyIdentityModal()}
      </div>
        
  )
}

export default DataIdentity
