import React from 'react'
import './otc.scss'
function ConvertSuccess({setIsConvertSuccess,setConfirmConvert,setIsConvert,initiatData,setIsPayout}) {
    const handleClose = ()=>{
        setIsConvertSuccess(false)
        setConfirmConvert(false)
        setIsPayout(false)
    }
  return (
    <div className='balance_modal'  onClick={setIsConvertSuccess.bind(this, false)} >
       
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
            <div className='balance_modal_wrapper'>
                <img 
                    onClick={handleClose}
                    className='verify-close' src="images/otc/x-close.svg" alt='close' 
                />
                <img 
                    onClick={setIsConvertSuccess.bind(this, false)}
                    className='otc_rembo' src="images/otc/rembo.svg" alt='close' 
                />


                <div className="modal-title">
                    <p className="enter-amount">Confirm Conversion</p>
                    <p className="enter-an-amount">Your transaction has been initiated, check your balance</p>
                </div>
                
                <div className='otc_rule lin_margin'></div>
                <p className='otc_trans'>Transaction amount</p>
                <div className='otc_equivalent'>
                    <p className='amount1'>N{initiatData.from_amount}</p>
                    <img src='/images/otc/doubleArrow.svg' alt='' />
                    <p className='amount1'>${initiatData?.to_amount}</p>
                </div>

                <div className='otc_rule'></div>
               

                

                <div className='otc_btn_modal' style={{ display:"flex",justifyContent:"center"}}>
                    <button className='btn otc_btn_cancel' onClick={handleClose}>
                         Close
                    </button>
                    {/* <button className='btn'>
                        Download receipt
                    </button> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default ConvertSuccess