import React from 'react'

function AcceptSuccessModal({setIsAcceptSuccess,setIsAccept,setIsClientResponse,setIsOrderSubmited,setIsOrder}) {
    const handleClose = ()=>{
        setIsAcceptSuccess(false)
        setIsAccept(false)
        setIsClientResponse(false)
        setIsOrderSubmited(false)
        setIsOrder(false)
    }
  return (
    <div className='balance_modal'  onClick={setIsAcceptSuccess.bind(this, false)} >
       
    <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
        <div className='balance_modal_wrapper'>
            <img 
                onClick={setIsAcceptSuccess.bind(this, false)}
                className='verify-close' src="images/otc/x-close.svg" alt='close' 
            />
            <img 
                onClick={setIsAcceptSuccess.bind(this, false)}
                className='otc_rembo' src="images/otc/rembo.svg" alt='close' 
            />


            <div className="modal-title">
                <p className="enter-amount">Successful</p>
                <p className="enter-an-amount">A link has been shared to (mide**@getflick.app)</p>
            </div>

            <div className='otcform_container'>
                <p className='amount_requested'>Order amount</p>
                <h6 className='amount_value'>$5,000.00</h6>
            </div>
            
            {/* <div className='otc_rule lin_margin'></div> */}
           

            {/* <div className='otc_rule'></div> */}
            
            <button className='btn otc_btn_cancel' onClick={handleClose} style={{width:"100%",marginTop:"46px"}}>
                    Close
            </button>
        </div>
    </div>
</div>
  )
}

export default AcceptSuccessModal