import React, { useEffect, useRef, useState, createRef } from "react";
import "../components/getStarted/GetStarted.scss";
import "../components/sidebar/Sidebar.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
// import { loginSuccess, logout } from '../../redux/userSlice';
import Progress from "../components/utilities/Progress";

function ExistingKyc() {
  const location = useLocation();
  const {result} = location?.state || {};
  const navigate = useNavigate();
  const [isActiveLoading,setIsActiveLoading] = useState(false)
  const handleBack = () => {
    navigate("/getstarted");
  };

  useEffect(() => {
    // loadCountries();
    // getFormDetails();
    // eslint-disable-next-line
  }, []);
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state?.user);
  const [isLoading, setIsLoading] = useState(false);
  const [isDoc, setIsDoc] = useState(false);

  const lineRefs = React.useRef([]);

  const [additionPercentage, setAdditionPercentage] = useState(0);
  const [DirectorID, setDirectorID] = useState(null);
  const [shareholderID, setShareholderID] = useState(null);
  const [IncCertificate, setInCertificate] = useState(null);

  const [articleOfAssoc, setArticleAssoc] = useState(null);
  const [opBusUtilBill, setUtilityBill] = useState(null);
  const [co, setCo] = useState(null);
  const [coSeven, setCoSeven] = useState(null);
  const [addition, setAddition] = useState(null);
  const [additionFile, setAdditionFile] = useState(null);

  const [Dpreview, setDPreview] = useState();
  const [shareholderPreview, setShareholderPreview] = useState();
  const [businessInfo, setBusinessInfo] = useState({
    business_name: "",
    business_model: "",
    doInc: "",
    tin: "",
    zipCode: "",
    city: "",
    company_type: "",
    incNumber: "",
    coInc: "",
    address: "",
    company_state: "",
    biz_website: "",
  });

  const [additionDoc, setAdditionDoc] = useState([{ doc: "" }]);

  lineRefs.current = additionDoc?.map(
    (_, i) => lineRefs.current[i] ?? createRef()
  );

  const [payIn, setPayIn] = useState({
    virtual_accounts: false,
    checkout_card: false,
    checkout_bank: false,
    multi_currency: false,
  });
  const [payOut, setPayOut] = useState({
    local_pay_outs: false,
    international_pay_outs: false,
  });

  const [agency, setAgency] = useState({
    transaction: false,
    statement: false,
    balance: false,
    credit_report: false,
  });
  const [payment, setPayment] = useState({
    kyc: false,
    kyb: false,
    widget: false,
    api: false,
  });

  const [conversion, setConversion] = useState({
    nigeria: false,
    ghana: false,
    kenya: false,
    southAfrica: false,
  });

  // Director
  useEffect(() => {
    if (!DirectorID) {
      setDPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(DirectorID);
    setDPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [DirectorID]);

  useEffect(() => {
    if (!shareholderID) {
      setShareholderPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(shareholderID);
    setShareholderPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [shareholderID]);

  // bussiness  info
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      [name]: value,
    }));
  };

  // Additional documents
  const handleAdditionDragOver = (e, index) => {
    e.preventDefault();
  };
  const addOptionalDoc = () => {
    let object = { doc: "" };
    setAdditionDoc([...additionDoc, object]);
  };

  const removeDoc = (index) => {
    let data = [...additionDoc];
    data.splice(index, 1);
    setAdditionDoc(data);
  };

  const handleAdditionDrop = async (e, index) => {
    const { files } = e.dataTransfer;
    e.preventDefault();

    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) {
      setAddition(undefined);
      return;
    }
    const file = files[0];
    const form = new FormData();
    form.append("file", file);
    try {
      const url = "https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test";
      let res = await axios.post(url, form, {
        onUploadProgress: (progressEvent) => {
          setAdditionPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        },
      });
      let data = [...additionDoc];
      data[index]["doc"] = res?.data?.url;
      setAdditionDoc(data);
      setTimeout(() => setAdditionPercentage(0), 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAdditional = async (e, index) => {
    const { name, files } = e.target;
    try {
      if (!e.target.files || e.target.files.length === 0) {
        setAddition(undefined);
        return;
      }

      const file = files[0];
      setAdditionFile(file);
      const form = new FormData();
      form.append("file", file);

      const url =
        "https://flick-payments-3623f34e4cf7.herokuapp.com/file-upload-test";
      let res = await axios.post(url, form, {
        onUploadProgress: (progressEvent) => {
          setAdditionPercentage(
            parseInt(
              Math.round((progressEvent.loaded * 100) / progressEvent.total)
            )
          );
        },
      });
      let data = [...additionDoc];
      data[index][name] = res?.data?.url;
      setAdditionDoc(data);
      setTimeout(() => setAdditionPercentage(0), 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const handlePayIn = (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setPayIn((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  };

  const handlePayOut = (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setPayOut((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  };

  const handleAgency = (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setAgency((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  };

  // payment checkbos
  const handlePayment = (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setPayment((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  };
  // payment checkbos
  const handleConversion = (event) => {
    const checkboxName = event.target.name;
    const newValue = event.target.checked;

    setConversion((prevValues) => ({
      ...prevValues,
      [checkboxName]: newValue,
    }));
  };

  const handleForm = (e) => {
    e.preventDefault();
  };

  const saveDocuments = async (e) => {
    e.preventDefault();

    // const formData = new FormData();

    setIsDoc(true);

    console.log("additional Do: ", additionDoc);

    try {
      const data = {
        incCert: IncCertificate,
        article_of_assoc: articleOfAssoc,
        opBusUtilBill: opBusUtilBill,
        co2: co,
        co7: coSeven,
        additional_documents: additionDoc,
        CustomerCode: currentUser?.user?.merchantCode,
        token: currentUser?.user?.token,
      };

      console.log("doc Data: ", data);
      const url =
        "https://zunpla6tnl.execute-api.us-east-2.amazonaws.com/production/merchant-kyc";
      // const url = 'http://localhost:5500/upload';
      let res = await axios.post(url, data);
      setIsDoc(false);
      toast.success(res?.data?.msg, {
        position: "top-left", pauseOnHover: true,
      });
    } catch (err) {
      setIsDoc(false);
      console.log(err);
      const errorMessage = err?.response?.data?.msg || "Unable to save";
      toast.error(errorMessage, {
        position: "top-left", pauseOnHover: true,
      });
    }
  };

  const [activeSession, setActiveSession] = useState("introduction");
  const handleNextClick = () => {
    const sessionOrder = [
      "introduction",
      "utilise",
      "directors",
      "person",
      "document",
    ];

    const currentIndex = sessionOrder.indexOf(activeSession);

    if (currentIndex < sessionOrder.length - 1) {
      setActiveSession(sessionOrder[currentIndex + 1]);
    }
  };

  const handleBackClick = () => {
    const sessionOrder = [
      "introduction",
      "utilise",
      "directors",
      "person",
      "document",
    ];

    const currentIndex = sessionOrder.indexOf(activeSession);

    if (currentIndex > 0) {
      setActiveSession(sessionOrder[currentIndex - 1]);
    }
  };

  const handleMenuClick = (session) => {
    setActiveSession(session);
  };

  const handleActivate = async (e)=>{
    e.preventDefault()
    setIsActiveLoading(true)
    try{
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

     

      let payload = {
        merchantCode:result?.kyc?.CustomerCode,
        addtionalDocs: additionDoc
      }

      // if(selectedOption==="existing"){
      //   data ={
      //     business_name: formData.existing_business_name,
      //     merchantCode: formData.existing_business,
      //     country:formData.country
      //   }
      // }else if(selectedOption === "new"){
      //   data ={
      //     business_name: formData.existing_business_name,
      //     merchantCode: formData.existing_business,
      //     country:formData.country
      //   }
      // }

      const url = "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/activate-account";
      let res = await axios.post(url,{ ...payload },{headers: headers});
      setIsActiveLoading(false)
      
      let data = res?.data
      // toast.success("Business added successfully")
      navigate('/switch-business',{state:{data}})
      // setTimeout(()=>navigate('/getstarted'),1000);
      
      // console.log("response data: ",res.data)
      
    }catch(err){
      setIsActiveLoading(false)
      console.log(err)
    }
  }

  return (
    <div className="back-introduce">
      {/* {console.log("result: ",result)} */}
      <div className="business-new">
        <p className="new-business-text">Activate {`"${result?.data?.business_name}"`}</p>
        <p className="create-business">
          You can now create new business or simply duplicate your current
          business.{" "}
        </p>
      </div>

      <div className="introduces">
        {/* <BusinessOverview /> */}
        <div className="business-overview">
          <div className="business-overview-inner">
            <div className="progress-business-menu">
              <img
                src={process.env.PUBLIC_URL + "/images/progress-icon.svg"}
                alt="progress"
              />
              <div className="business-menu">
                {/*  eslint-disable-next-line */}
                <a href="#" className="business-overview-text">
                  Business OverView
                </a>
                <a
                  href="#introduction"
                  className={`text ${
                    activeSession === "introduction" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("introduction")}
                >
                  - Introduce Your Business
                </a>
                <a
                  href="#utilise"
                  className={`text ${
                    activeSession === "utilise" ? "active" : ""
                  }`}
                  onClick={() => handleMenuClick("utilise")}
                >
                  - Utilise Flick{" "}
                </a>
              </div>
            </div>
            <div className="icon-menu">
              <img
                src={process.env.PUBLIC_URL + "/images/short-progress-icon.svg"}
                alt="progress"
              />
              <a
                href="#director"
                className={`item ${
                  activeSession === "directors" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("directors")}
              >
                Directors
              </a>
            </div>
            <div className="icon-menu">
              <img
                src={process.env.PUBLIC_URL + "/images/short-progress-icon.svg"}
                alt="progress"
              />
              <a
                href="#person"
                className={`item ${activeSession === "person" ? "active" : ""}`}
                onClick={() => handleMenuClick("person")}
              >
                Shareholders
              </a>
            </div>
           
            <div className="icon-menu">
              <img
                src={
                  process.env.PUBLIC_URL + "/images/single-progress-icon.svg"
                }
                alt="progress"
              />
              <a
                href="#document"
                className={`item ${
                  activeSession === "document" ? "active" : ""
                }`}
                onClick={() => handleMenuClick("document")}
              >
                Documents
              </a>
            </div>
          </div>
        </div>

        <div className="overview-session">
          <session
            className="new-business"
            id="introduction"
            style={{
              display: activeSession === "introduction" ? "block" : "none",
            }}
          >
            <div className="new-business-wrapper">
              {/* form data */}
              <form onSubmit={handleForm} className="info-form">
                <div className="info-form-left">
                  <div className="form-groups">
                    <label className="lbl">Business Name*</label>
                    <input
                      value={result?.kyc?.business_name}
                      type="text"
                      name="business_name"
                      placeholder="Flick"
                      className="form-control"
                      style={{ background: "#F9FAFB" }}
                      readOnly
                    />
                  </div>
                  <div className="form-groups">
                    <label className="lbl">Business Model*</label>
                    <input
                      value={result?.kyc?.business_model}
                      type="text"
                      name="business_model"
                      placeholder="Flick"
                      className="form-control"
                      style={{ background: "#F9FAFB" }}
                      readOnly
                    />
                  </div>
                </div>

                <div className="info-form-right">
                  <div className="form-groups">
                    <label className="lbl">Company Type*</label>
                    <input
                      value={result?.kyc?.company_type}
                      type="text"
                      name="company_type"
                      placeholder="Enter your name"
                      className="form-control"
                      style={{ background: "#F9FAFB" }}
                      readOnly
                    />
                  </div>
                  <div className="form-groups">
                    <label className="lbl">Incorporation Number*</label>
                    <input
                      value={result?.kyc?.incNumber}
                      type="text"
                      name="incNumber"
                      placeholder="GHJDC67Y4HFBNDJ4RF4"
                      className="form-control"
                      style={{ background: "#F9FAFB" }}
                      readOnly
                    />
                  </div>
                </div>
              </form>

              {/*  */}
            </div>
            <div className="business-btn">
              <button
                style={{
                  color: "#161925",
                  background: "#FFF",
                  border: "1px solid #EAECF0",
                }}
                className="btn"
              >
                Cancel
              </button>
              <button onClick={handleNextClick} className="btn">
                {isLoading ? (
                  <BeatLoader color="#FFF" cssOverride={{}} />
                ) : (
                  <>Next</>
                )}
              </button>
            </div>
          </session>

          <session
            className="new-business"
            id="utilise"
            style={{ display: activeSession === "utilise" ? "block" : "none" }}
          >
            <div className="new-business-wrapper">
              <div className="utilise_flick_title">
                <h1 className="utilize_h">How do you plan to utilise Flick?</h1>
                <p className="utiliz">
                  This information will assist us in customizing a better
                  experience for your business.
                </p>
              </div>

              <div className="payout_container">
                {/* Pay-In */}
                <div className="pays_in">
                  <h1 className="pay_ins_title">Collections</h1>
                  <p className="pay_in_desc">
                    Receive direct payments from businesses and individuals.
                  </p>
                  <div className="pay_line"></div>

                  <div className="pay_in_checks">
                    <div className="check_div">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="virtual_accounts"
                        checked={result?.kyc?.collections?.virtual_accounts}
                        onChange={handlePayIn}
                      />
                      <h2 className="text_check">Virtual Nuban (transfer)</h2>
                    </div>

                    <div className="check_div">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="checkout_card"
                        checked={result?.kyc?.collections?.checkout_card}
                        onChange={handlePayIn}
                      />
                      <h2 className="text_check">Checkout (Cards)</h2>
                    </div>

                    <div className="check_div">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="checkout_bank"
                        checked={result?.kyc?.collections?.checkout_bank}
                        onChange={handlePayIn}
                      />
                      <h2 className="text_check">Pay by Bank</h2>
                    </div>
                    <div className="check_div">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="multi_currency"
                        checked={result?.kyc?.collections?.multi_currency}
                        onChange={handlePayIn}
                      />
                      <h2 className="text_check">Multi-Currency</h2>
                    </div>
                  </div>
                </div>

                {/* pay-outs */}
                <div className="pays_in">
                  <h1 className="pay_ins_title">Pay-Outs</h1>
                  <p className="pay_in_desc">
                    Directly transfer funds to beneficiary accounts.
                  </p>
                  <div className="pay_line"></div>

                  <div className="pay_in_checks">
                    <div className="check_div">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="local_pay_outs"
                        checked={result?.kyc?.pay_outs?.local_pay_outs}
                        onChange={handlePayOut}
                      />
                      <h2 className="text_check">Local Pay-Outs</h2>
                    </div>

                    <div className="check_div">
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="international_pay_outs"
                        checked={result?.kyc?.pay_outs?.international_pay_outs}
                        onChange={handlePayOut}
                      />
                      <h2 className="text_check">International Pay-outs</h2>
                    </div>
                  </div>
                </div>

                {/* Agency Banking */}

                <div className="pays_in">
                  <h1 className="pay_ins_title">Financial Data</h1>
                  <p className="pay_in_desc">
                    Access high-quality financial data
                  </p>
                  <div className="pay_line"></div>

                  <div className="pay_in_checks">
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="transaction"
                        checked={result?.kyc?.finantial_data?.transaction}
                        onChange={handleAgency}
                      />
                      <h2 className="text_check">Transactions</h2>
                    </div>
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="statement"
                        checked={result?.kyc?.finantial_data?.statement}
                        onChange={handleAgency}
                      />
                      <h2 className="text_check">Statement</h2>
                    </div>
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="balance"
                        checked={result?.kyc?.finantial_data?.balance}
                        onChange={handleAgency}
                      />
                      <h2 className="text_check">Balance</h2>
                    </div>
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="credit_report"
                        checked={result?.kyc?.finantial_data?.credit_report}
                        onChange={handleAgency}
                      />
                      <h2 className="text_check">Credit Report</h2>
                    </div>
                  </div>
                </div>

                {/* in-store payments */}

                <div className="pays_in">
                  <h1 className="pay_ins_title">Identity/Onboarding</h1>
                  <p className="pay_in_desc">
                    Verify identiy easily and more efficiently
                  </p>
                  <div className="pay_line"></div>

                  <div className="pay_in_checks">
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="kyc"
                        checked={result?.kyc?.identity?.kyc}
                        onChange={handlePayment}
                      />
                      <h2 className="text_check">Individual (KYC)</h2>
                    </div>
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="kyb"
                        checked={result?.kyc?.identity?.kyb}
                        onChange={handlePayment}
                      />
                      <h2 className="text_check">Business (KYB)</h2>
                    </div>
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="widget"
                        checked={result?.kyc?.identity?.widget}
                        onChange={handlePayment}
                      />
                      <h2 className="text_check">Widget</h2>
                    </div>
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="api"
                        checked={result?.kyc?.identity?.api}
                        onChange={handlePayment}
                      />
                      <h2 className="text_check">API</h2>
                    </div>
                  </div>
                </div>

                {/* Conversions */}

                <div className="pays_in">
                  <h1 className="pay_ins_title">Country</h1>
                  <p className="pay_in_desc">Select preferred Country</p>
                  <div className="pay_line"></div>

                  <div className="pay_in_checks">
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="nigeria"
                        checked={result?.kyc?.country?.nigeria}
                        onChange={handleConversion}
                      />
                      <h2 className="text_check">Nigeria</h2>
                    </div>
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="ghana"
                        checked={result?.kyc?.country?.ghana}
                        onChange={handleConversion}
                      />
                      <h2 className="text_check">Ghana</h2>
                    </div>
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="kenya"
                        checked={result?.kyc?.country?.kenya}
                        onChange={handleConversion}
                      />
                      <h2 className="text_check">Kenya</h2>
                    </div>
                    <div
                      className="check_div"
                      style={{ alignItems: "flex-start" }}
                    >
                      <input
                        type="checkbox"
                        className="checkbox"
                        name="southAfrica"
                        checked={result?.kyc?.country?.southAfrica}
                        onChange={handleConversion}
                      />
                      <h2 className="text_check">South Africa</h2>
                    </div>
                  </div>
                </div>

                {/*  */}
                <div className="pattern_form">
                  <h1 className="parttern_text">
                    Register your business <br /> in 1 week &nbsp;
                    {/* eslint-disable-next-line */}
                    <a className="biz_reg" href="#" target="_blank">
                      Register
                    </a>
                  </h1>
                  <img
                    className="img_partt"
                    src="/images/bgLineParttern.svg"
                    alt="parttern"
                  />
                </div>
              </div>
            </div>
            <div className="business-btn">
              <button
                style={{
                  color: "#161925",
                  background: "#FFF",
                  border: "1px solid #EAECF0",
                }}
                className="btn"
                onClick={handleBackClick}
              >
                Back
              </button>
              <button onClick={handleNextClick} className="btn">
                {isLoading ? (
                  <BeatLoader color="#FFF" cssOverride={{}} />
                ) : (
                  <>Next</>
                )}
              </button>
            </div>
          </session>

          {/* Director */}
          <session
            className="new-business"
            id="directors"
            style={{
              display: activeSession === "directors" ? "block" : "none",
            }}
          >
            <div className="new-business-wrapper">
              <div className="info-form-title">
                <p className="provide-info" style={{ paddingBottom: "16px" }}>
                  Director ({(result?.kyc?.directors)?.length})
                </p>
              </div>
              {
                (result?.kyc?.directors)?.length > 0 && (result?.kyc?.directors).map((item,index)=>(
                    <div className="document-title">
                      <div className="avatar-name">
                        <div className='avater_template'>
                            <span>{(`${String(item.firstName).slice(0,1)} ${String(item.lastName).slice(0,1)}`)}</span>
                        </div>
                        <p className="document-name">{`${item.firstName} ${item.lastName}`}</p>
                      </div>
                      <div className="detail">
                        <p className="document-detail">{item.position}</p>
                      </div>
                    </div>
                ))
              }

            </div>
            <div className="business-btn">
              <button
                style={{
                  color: "#161925",
                  background: "#FFF",
                  border: "1px solid #EAECF0",
                }}
                className="btn"
                onClick={handleBackClick}
              >
                Back
              </button>
              <button onClick={handleNextClick} className="btn">
                {isLoading ? (
                  <BeatLoader color="#FFF" cssOverride={{}} />
                ) : (
                  <>Next</>
                )}
              </button>
            </div>
          </session>

          {/* ShareHolder */}
          <session
            className="new-business"
            id="person"
            style={{ display: activeSession === "person" ? "block" : "none" }}
          >
            <div className="new-business-wrapper">
              <div className="info-form-title">
                <p className="provide-info" style={{ paddingBottom: "16px" }}>
                  Shareholders ({(result?.kyc?.shareholders)?.length})
                </p>
              </div>
              {
                (result?.kyc?.shareholders)?.length > 0 && (result?.kyc?.shareholders).map((item,index)=>(
                <div className="document-title">
                  <div className="avatar-name">
                        <div className='avater_template'>
                            <span>{(`${String(item.firstName).slice(0,1)} ${String(item.lastName).slice(0,1)}`)}</span>
                        </div>
                        <p className="document-name">{`${item.firstName} ${item.lastName}`}</p>
                  </div>
                  <div className="detail">
                    <p className="document-detail">{`${item.percOfShares} Ownership`}</p>
                  </div>
                </div>
                ))
              }
            </div>
            <div className="business-btn">
              <button
                style={{
                  color: "#161925",
                  background: "#FFF",
                  border: "1px solid #EAECF0",
                }}
                className="btn"
                onClick={handleBackClick}
              >
                Back
              </button>
              <button onClick={handleNextClick} className="btn">
                {isLoading ? (
                  <BeatLoader color="#FFF" cssOverride={{}} />
                ) : (
                  <>Next</>
                )}
              </button>
            </div>
          </session>

          <session
            className="new-business"
            id="document"
            style={{ display: activeSession === "document" ? "block" : "none" }}
            v
          >
            <div className="new-business-wrapper">
              <div className="info-form-title">
                <p className="provide-info" style={{ paddingBottom: "16px" }}>
                  Document ({Object.keys(result?.kyc?.documents).length})
                </p>
              </div>
              {
                Object.keys(result?.kyc?.documents).length >  0 && Object.keys(result?.kyc?.documents).map((item,index)=>(
                  <div key={index} className="form-groups">
                    <label className="lbl">
                      {
                      item==="incCert"?"Business Registration/Incorporation Certificate*"
                      :item==="article_of_assoc" ? "Article of Association*"
                      :item === "opBusUtilBill" ? "Operating Business Utility Bill*"
                      :item
                      }
                      </label>
                    <div className="uploaded-document">
                     
                      <img src="/images/pdf-icon.svg" alt="pdf" />
                      <div className="name-document">
                        {/* <img style={{height:"100px"}} src={result?.kyc?.documents[item]} alt=""/> */}
                         <p className="named-document">{item}</p>
                        {/* <p className="document-size">200KB</p>  */}
                      </div>
                    </div>
                  </div>
                ))
              }

              {/* Additional documents  */}

              <div
                className="form-groups"
                style={{ borderTop: "1px solid #eaecf0" }}
              >
                <label className="lbl">
                  Additional documents{" "}
                  <span style={{ color: "#D0D5DD" }}>(optional)</span>
                </label>
                {additionDoc.map((form, index) => {
                  const currentRef = lineRefs.current[index];

                  return (
                    <div
                      className="click-to-upload"
                      onDragOver={(e) => handleAdditionDragOver(e, index)}
                      onDrop={(e) => handleAdditionDrop(e, index)}
                    >
                      <input
                        type="file"
                        onChange={(e) => handleAdditional(e, index)}
                        name="doc"
                        hidden
                        ref={currentRef}
                      />
                      <img
                        onClick={() => currentRef?.current.click()}
                        src={process.env.PUBLIC_URL + "/images/upload-icon.svg"}
                        alt="upload"
                        style={{ cursor: "pointer" }}
                      />
                      {form?.doc ? (
                        <>
                          <p>
                            {
                              form?.doc?.split("/")[
                                form?.doc?.split("/").length - 1
                              ]
                            }
                          </p>
                          <p
                            className="file-type"
                            style={{ marginTop: "10px" }}
                          >
                            Click on the icon to change ID
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="click">
                            <span
                              onClick={() => currentRef?.current.click()}
                              style={{ color: "#1C716D" }}
                            >
                              Click to upload
                            </span>
                            or drag and drop
                          </p>
                          <p className="file-type">
                            SVG, PNG, JPG or GIF (max. 800x400px){" "}
                          </p>
                        </>
                      )}
                      {
                        index > 0 && (
                          <img
                            src="/images/trash.svg"
                            alt=""
                            style={{ marginRight: "5px", color: "#FFF" }}
                            className="deletePep"
                            onClick={() => removeDoc(index)}
                          />
                        )
                        // <p style={{background:"#ED1C24", marginRight:"5px",color:"#FFF"}} className='deletePep' onClick={() => removeDoc(index) }>
                        //   Delete Document
                        // </p>
                      }
                    </div>
                  );
                })}
                <Progress percentage={additionPercentage} />
              </div>

              <div 
              style={{width:"fit-content"}}  
              className="add_btn" 
              onClick={addOptionalDoc}  
              >
                    <img
                      src={process.env.PUBLIC_URL + "/images/plus.svg"}
                      alt="plus"
                    />
                    <p className="add_text">Add Additional Documents</p>
              </div>
            </div>  

        
            <div className="business-btn">
              <button
                style={{
                  color: "#161925",
                  background: "#FFF",
                  border: "1px solid #EAECF0",
                }}
                className="btn"
                onClick={handleBackClick}
              >
                Back
              </button>
              <button className="btn" onClick={handleActivate}>
                {isActiveLoading ? (
                  <BeatLoader color="#FFF" cssOverride={{}} />
                ) : (
                  <>Activate account</>
                )}
              </button>
            </div>
          </session>
        </div>
      </div>
    </div>
  );
}

export default ExistingKyc;
