import React,{useState} from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
function SideBarMobileMenu({item, isActive, onClick, activeItem, setActiveItem, mode, MobileMenuHandler}) {
    const { currentUser } = useSelector((state) => state?.user);

  const [subnav, setSubnav] = useState(false);
  const [activeSubtitle, setActiveSubtitle] = useState(null);

  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    if (item.subNav && !(mode === "Test Mode" && (item.title === "Data" || item.title === "Payment"))) {
      if (activeItem === item.title) {
        // Close the subnav if it's already open
        setSubnav(false);
        setActiveItem(null);
      } else {
        // Open the subnav and set it as the active item
        setSubnav(true);
        setActiveItem(item.title);
      }
    } else {
      if (item.title === "Data" && mode === "Test Mode") {
        navigate("/test-mode-data");
      } else if (item.title === "Payment" && mode === "Test Mode") {
        navigate("/test-mode-payment");
      } else if (item.title === "Balance" && mode === "Test Mode") {
        navigate("/test-mode-payment");
      } else {
        navigate(item.path);
      }
      MobileMenuHandler(); // Close the mobile menu
    }
    onClick(); // Update the active index
  };

  const handleSubmenuClick = (e) => {
    e.stopPropagation(); // Prevent the click event from propagating to the parent
  };

  const handleSubtitleClick = (subItem) => {
    setActiveSubtitle(subItem.subTitle);
    navigate(subItem.path);
    MobileMenuHandler(); // Close the mobile menu when a sub-item is clicked
  };

  return (
    <div className={`sidebar-menu ${subnav ? "" : "hover-effect"} ${isActive && !subnav ? "menuactive" : ""}`} onClick={handleClick}>
      <Link className='sidebar-menu-item'>
        <div className='sidebar-menu-content'>
          {!isActive ? <img src={process.env.PUBLIC_URL + item.icon} alt='user' /> :
            <img src={process.env.PUBLIC_URL + item.iconActive} alt='user' />}
          <h2 className={`sidebar-text ${isActive ? "text-active" : ""}`}>{item.title}</h2>
        </div>
        {item.subNav &&
          <img src={subnav ? process.env.PUBLIC_URL + "images/subNavDownArrow.svg" : process.env.PUBLIC_URL + "images/subNavSideArrow.svg"} alt='arrow' />
        }
      </Link>
      {activeItem === item.title && subnav && (
        <div className='sidebar-submenu-container' onClick={handleSubmenuClick}>
          <img src={item.subIcon} alt="sub-Icon" />
          <div className='sidebar-submenu-text'>
            {item.subNav.map((subItem, index) => (
              <div key={index} className={`subtitle-text-container ${activeSubtitle === subItem.subTitle ? 'active' : ''}`} onClick={() => handleSubtitleClick(subItem)}>
                <p className='sidebar-subtitle'>{subItem.subTitle}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SideBarMobileMenu