import React, { useState } from 'react'
import PaymentBalance from '../overview/PaymentBalance';
import OtcTable from './OtcTable';
import './otc.scss'
import { changeCpage, nextPage, prePage } from '../utilities/pagination/pagination';
import OtcActionBtn from './OtcActionBtn';
import OtcPaymentBalance from './OtcPaymentBalance';
import { useSelector } from 'react-redux';
import axios from 'axios';

function OtcOverview({collectionData,toggleConvert,toggleOrder,togglePayout,setIsFundBalance,balanceData,setRateMsg,getExchange,setIsVertualNuban,setSelectedFxCurrency,selectedFxCurrency,selectedCurrency,setSelectedCurrency,getCollection,isOtcCollectionLoading,setIsPayoutBenSubmited,balanceDatas}) {
  const {currentUser} = useSelector((state)=>state?.user)
  const [currentPage,setCurrentPage] = useState(1)
 
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = collectionData?.slice(firstIndex,lastIndex);
  const nPage = Math.ceil(collectionData?.length/recordsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1)
  
  
  const handleConvert =  ()=>{
    getExchange();
    toggleConvert()
  }
  
  const handleSelect = (e) => {
    setSelectedFxCurrency(e.target.value);
  };

  
  return (
    <div className='overview-payment'>
     
    <div className='payment-balance-container'>
      <div className=' otc_balance_title'>
        <h1 className='balance-title'>Your balances</h1>
        <div className='oct_balance_select_container'>
         
          <img src={`/images/${selectedFxCurrency==="USD"?"USD":selectedFxCurrency==="NGN"?"NGN":selectedFxCurrency==="KES"?"KES":selectedFxCurrency==="all"?"all":selectedFxCurrency}.svg`} alt='currency'/>

          <select className='sel_fx_drop' value={selectedFxCurrency} onChange={handleSelect}>
            
            <option value="NGN">NGN</option>
            <option value="USD">USD</option>
            
          </select>
          <div className='otc_fundbalance' onClick={setIsVertualNuban.bind(this,true)}>
              <p className='otc_fundTitle'>Fund balance</p>
              {/* <img src='/images/otc/plus.svg' alt=''/> */}
          </div>
        </div>
      </div>
      {/*  */}
      <div className='otc_balance_wrapper'>
          
          {
            balanceDatas.length>0 && balanceDatas.map((balance,index)=>(
              <OtcPaymentBalance 
                key={index}
                flag={`/images/${balance.currency==="NGN"?"NigeriaFlag":balance.currency==="USD"?"UsFlag":balance.currency==="KES"?"kenyaFlag": balance.currency==="GBP"?"GBP": balance.currency==="CAD"?"CAD":balance.currency}.svg`}
                country={balance.currency==="NGN"? "Nigerian Naira":balance.currency==="USD"?"United States Dollar":balance.currency==="KES"? "Kenyan Shilling":balance.currency==="GHS"? "Ghanaian Cedi": balance.currency==="CAD"? "Canadian Dollar": balance.currency==="GBP"? "British pounds": balance.currency==="EUR"? "Euro": balance.currency}
                symbole= {balance.currency==="NGN"? "₦" : balance.currency==="USD"?"$":balance.currency==="KES"? "K": balance.currency==="CAD"?"C$": balance.currency==="GBP"?"£": balance.currency==="EUR"?"€":balance.currency==="GHS"?"₵": balance.currency }
                amount={Number(balance?.payout_balance)/100}
                bgColor={balance.currency==="NGN"? "#FEF4F1":balance.currency==="USD"?"#F4FBFB":balance.currency==="KES"? "#FEF0F5":balance.currency==="GBP"? "#FEF0F5":balance.currency==="CAD"? "#FEF4F1":"#FEF4F1"}
              />
             ))
          }

          {
             balanceDatas.length <= 0 &&
             <>
             <OtcPaymentBalance 
             flag="/images/NigeriaFlag.svg"
             country="Nigerian Naira"
             symbole="&#8358;"
             amount="0.00"
             bgColor="#FEF4F1"
           
             />
             <OtcPaymentBalance 
             flag="/images/UsFlag.svg"
             country="United States Dollar"
             symbole="&#36; "
             amount="0.00"
             bgColor="#F4FBFB"
             />
           
             </>
          }
       
          <div className='octActions'>
         
            <OtcActionBtn text="Convert" image="/images/otc/convert.svg" toggleModal={handleConvert} />
            <OtcActionBtn text="Order OTC" image="/images/otc/orderArrow.svg" bg='#EAFAF9' textColor="#2EBDB6" toggleModal={toggleOrder} />
            <OtcActionBtn text="Payout" image="/images/otc/payout.svg" bg='#ffffff' textColor="#1D2939" border="1px solid #E7EAEE" toggleModal={togglePayout} />
           
          </div>
      </div>
          <div className='mobile_octActions'>
         
            <OtcActionBtn text="Convert" image="/images/otc/convert.svg" toggleModal={handleConvert} />
            <OtcActionBtn text="Order OTC" image="/images/otc/orderArrow.svg" bg='#EAFAF9' textColor="#2EBDB6" toggleModal={toggleOrder} />
            <OtcActionBtn text="Payout" image="/images/otc/payout.svg" bg='#ffffff' textColor="#1D2939" border="1px solid #E7EAEE" toggleModal={togglePayout} setIsPayoutBenSubmited={setIsPayoutBenSubmited}/>
           
          </div>

      {/*  */}
    </div>

    <OtcTable collectionData={collectionData}  records={records} setSelectedCurrency={setSelectedCurrency}  getCollection={getCollection} selectedCurrency={selectedCurrency}  isOtcCollectionLoading={isOtcCollectionLoading} balanceData={balanceData}/>

    {/* <div className='pagination_container'>
        <div className='page-item' onClick={()=>prePage(currentPage,setCurrentPage)}>
          <img src='/images/pageLeft.svg' alt='' />
          <p className='Page_prev'>Previous</p>
        </div>   

        <div className='pageNumbers'>
          {
            numbers?.length > 0 && numbers.map((n,i)=>(
              <span key={i} 
              className={`page_num ${currentPage === n ? 'active':''}`}
              onClick={()=>changeCpage(setCurrentPage,n)}
              >
                {n}
              </span>
            ))
          }

        </div> 

        <div className='page-item' onClick={()=>nextPage(currentPage,setCurrentPage,nPage)}>
          <img src='/images/pageRight.svg' alt='' />
          <p className='Page_prev'>Next</p>
        </div> 
    </div> */}
  </div>
  )
}

export default OtcOverview