export const prePage =(currentPage,setCurrentPage)=>{
    if(currentPage !== 1){
        setCurrentPage(currentPage-1)
      
    }
   
}
export const nextPage =(currentPage,setCurrentPage,nPage)=>{
    if(currentPage !== nPage){
        setCurrentPage(currentPage+1)
        return
    }
    
   
}

export const changeCpage =(setCurrentPage,id)=>{ 
    setCurrentPage(id)
}