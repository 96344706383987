import React from 'react'
// import '../../components/otc/otc.scss'

function SettlementPaymentDone({onClose}) {
    const handleDone= () => {
        onClose();
    }
    
    return (
        <div className="settlement-overlay" onClick={onClose}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className="settlement-set-modal"
          >
            <div className="settlement-modal-inner" style={{width:"89%"}}>
            <div className='otc_payout_logo' style={{marginTop:"32px"}}>
                <img src='/images/otc/flickRound.svg' alt='' />
                <p className='payout_logo_title'>Successful</p>
                <p className='payout_logo_desc' style={{width:"80%"}}>Your account has been successfully added; payments can now be settled into your bank account</p>
                <button className='btn' onClick={handleDone} style={{width:"210px",marginTop:"24px"}}>Done</button>
            </div>
            </div>
          </div>
        </div>
      );
}

export default SettlementPaymentDone