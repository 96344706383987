import React from "react";
import '../../modals/Modal.scss';

function ConfirmChange({ setIsChangeRole, setIsConfirmChange, setIsRoleUpdated, selectedTeamMemberEmail, prevRole, newRole }) {
  const handleCancel = () => {
    setIsConfirmChange({ show: false, prevRole: '', newRole: '' });
    setIsChangeRole(true);
  };

  const handleConfirm = () => {
    setIsConfirmChange({ show: false, prevRole: '', newRole: '' });
    setIsRoleUpdated(true);
  };

  const formatRole = (role) => {
    switch (role) {
      case "admin":
        return "Administrator";
      case "user":
        return "User";
      case "operate":
        return "Operations";
      case "dev":
        return "Developer";
      case "customer":
        return "Customer support";
      default:
        return "";
    }
  };

  return (
    <div className="overlay">
      <div className="enter-amount-modal" style={{ maxWidth: "498px" }}>
        <div className="modal-wrapper" style={{ width: "100%" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <img style={{ cursor: "pointer" }} onClick={handleCancel} src={process.env.PUBLIC_URL + "/images/x-close.svg"} alt="icon" />
          </div>
          <div className="member-role" style={{ paddingBottom: "16px" }}>
            <p className="change-member">Change member role</p>
            <p className="manually">Manually authenticate KYCs in real-time.</p>
          </div>
          <div className="confirmation">
            <p className="confirm-text">
              You’re about to change the role for <span style={{ color: "#151F32" }}>{selectedTeamMemberEmail}</span> 
              {" "}from “{formatRole(prevRole)}” to “{formatRole(newRole)}”. Doing this change the permissions available to this member. 
              Would you like to proceed?
            </p>
          </div>
          <button onClick={handleConfirm} className="btn" style={{ width: "50%" }}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConfirmChange;