import React from 'react'

function SingleInputGroup({label,handleChange,error,...otherProps}) {
  return (
    <div className='input_group_otc' style={{marginTop:"10px"}}>
        <p className='input_text'>{label}</p>
        <input className='otc_select_full' onChange={handleChange} {...otherProps} />
        {error && <p className='error'>This field is required</p>}
    </div>
  )
}

export default SingleInputGroup