import React, { useEffect, useState } from 'react'
import NameControl from '../components/common/NameControl'
import Customers from '../components/payment/Customers'
import { useSelector } from 'react-redux';
import axios from 'axios';
import TableLoader from '../components/common/TableLoader';

function PaymentCustomers() {
  useEffect(()=>{
    getCustomerWallet(); 
    
    // eslint-disable-next-line 
  },[])
  const { currentUser } = useSelector((state) => state?.user);
  const [customersWalletData,setCustomersWalletData] = useState([])
  const [isCustomerLoading,setIsCustomerLoading] = useState(false)

  const getCustomerWallet = async ()=>{
    setIsCustomerLoading(true)
    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        // "api_key": "pk-U2FsdGVkX1+i2IXLRQDL4rNPqeJGDhpdHJ/jSzDf8AiVsrEh6BB2tQHvbI3pVkXY4lPonli/Xuscb0QDSS/+JfIex2Gi8dABLmyvXSRTZf7xYn3xUWp1TjT42ZneiicW", 
       
      }

      const url = 'https://flick-app.com/wallets/wallet-list';
      let res = await axios.get(url,{
        headers:headers
      })

      const newValue = res.data.data
      // console.log("customer_wallet: ",newValue)
      setCustomersWalletData(newValue || []);
      setIsCustomerLoading(false)
       
    }catch(err){
      console.log(err)
      setIsCustomerLoading(false)
      const errorMessage = err?.message || "unable to fetch data"
     
    }
  }

  return (
    
    <div className='main-inner'>
        <NameControl 
        btn
        name={currentUser?.user?.business_name}
        desc="Manage all your settlements here"
        />
        <Customers 
        btnFirst 
        numUsers={100} 
        users={"users"}
        customersWalletData={customersWalletData}
        isCustomerLoading={isCustomerLoading}
        />
        {
          isCustomerLoading &&
          <TableLoader msg/>
        }
    </div>
     
  )
}

export default PaymentCustomers
