import React from "react";
import NameControl from "../common/NameControl";
import ProductsData from "./Products.json";
import "./data.scss";

function TestModeData() {
  const allProductsData = ProductsData;
  return (
    <div className="main-inner">
      <div className="flick-data-unlock">
        <div className="flick-data">
          <img
            src={process.env.PUBLIC_URL + "/images/flick-data.svg"}
            alt="arrow back"
          />
          <img
            src={process.env.PUBLIC_URL + "/images/request-access.svg"}
            alt="arrow back"
          />
        </div>
        <div className="unlock-the-power">
          <p className="unlock">Unlock the power of data with Flick</p>
          <p className="easily-access">
            Easily access identities and financial information for your
            business. Our core products are designed to empower your business,
            enhance your customer experiences, and drive data-driven decisions.
          </p>
        </div>
      </div>

      <div className="explore-products">
        <div className="product-explore">
          <p className="explore-data">Explore our core products</p>
          <img
            src={process.env.PUBLIC_URL + "./images/see-products.svg"}
            alt="bank"
          />
        </div>
        <div className="products-image">
          <div className="products-data">
            {allProductsData.map((products, index) => (
              <div className="explore" key={index}>
                <img src={process.env.PUBLIC_URL + products.icon} alt="bank" />
                <div className="title-desc">
                  <p className="product-title">{products.title}</p>
                  <p className="product-desc">{products.desc}</p>
                </div>
              </div>
            ))}
          </div>
          <img
            src={process.env.PUBLIC_URL + "./images/data-basket.svg"}
            alt="basket"
          />
        </div>
      </div>
    </div>
  );
}

export default TestModeData;
