import React, { useState } from "react";
import './Modal.scss'
import { useSelector } from 'react-redux';
import axios from "axios";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';

function PaymentMethod({setIsAmount,setIsPaymentMethod,setIsVertual,setIsCardMethod,getLinkedCards,balance,transactionId,setNubanData,setIsCommingSoon,payoutCurrency,apiWallet,setApiWallet,setIsApiPayout,amountInput,setNubanTempData,setIsVirtualConfirm,setNubanId,setNubTempInput,setIsPayment,setIsAmountNormal,nubanData}) {
  const [selectedOption, setSelectedOption] = useState('');
  const { currentUser } = useSelector((state) => state?.user);
  const [isLoading,setIsLoading] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };


  const handlePayout = (e)=>{
    e.preventDefault();
    setIsAmountNormal(true);
  }


  const handleVirtualNuban = async ()=>{
    setIsLoading(true)
    let payLoad;
    let url;
    try{

      const headers = {  
          Authorization:`Bearer ${currentUser?.user?.merchantKey}`
      }
      
      if(apiWallet==="api"){
        const id = uuidv4(); 
        setNubanId(id);
        setNubanId(id)
         payLoad = { 
          transactionId: id,
          nubanType: "temporary",
          amount: amountInput,
          currency_settled:"NGN",
          email:currentUser?.user?.business_email,
          bank:"gtbank",
          is_api_wallet:true
        }
        setNubTempInput(payLoad);
        url ="https://flick-app.com/collection/nuban-create"
        let res = await axios.post(url,payLoad,{
          headers:headers
        });
        setNubanTempData(res.data?.responseData?.data)
      }else{

        if(!nubanData){
          const id = uuidv4(); 
           payLoad = { 
              transactionId: id,
              nubanType: "business"
          }
          url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-create-merchant"
          let res = await axios.post(url,payLoad,{
            headers:headers
          });
          // console.log("nuban Data: ",res.data);
          setNubanData(res.data.data);
        }
      }
      // setNubTempInput(payLoad);
     
      if(apiWallet==="api"){
        // url ="https://flick-app.com/collection/nuban-create"
        // let res = await axios.post(url,payLoad,{
        //   headers:headers
        // });
        // setNubanTempData(res.data?.responseData?.data)
        // console.log("nuban Data: ",res.data?.responseData?.data);
      }else{
        // url ="https://e59kqicjn1.execute-api.us-east-2.amazonaws.com/production/nuban-create-merchant"
        // let res = await axios.post(url,payLoad,{
        //   headers:headers
        // });
        // console.log("nuban Data: ",res.data);
        // setNubanData(res.data.data);
      }
      
     
      setIsLoading(false)
      setIsVertual(true)
      
       
    
      
  }catch(err){
      setIsLoading(false)
      console.log(err)
      const errorMessage = err.response?.data?.message || "Unable to generate Nuban; try again"
      toast.error(errorMessage);
  }
    
  }

  const callPaymentOption = ()=>{
    if(selectedOption === 'card') return  getLinkedCards();
    if(selectedOption === 'payout') return 
  }

  return (
    <div className="overlay">
    <div className="enter-amount-modal">
      <div className="modal-wrapper">

        <div>
        <img onClick={setIsPayment.bind(this,false)} style={{cursor:"pointer"}} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
        </div>
        
        <div className="modal-title">
          <p className="enter-amount">Fund Payout Balance</p>
          <p className="enter-an-amount">Select your payment method</p>
        </div>
        <div className="fund-options">
            
              
            <div className={`card-option ${selectedOption === 'bank' ? 'selected' : ''}`} onClick={() => setSelectedOption('bank')}>
                <div className="icon-bank">
                <img src={process.env.PUBLIC_URL + "/images/balanceTransfer.svg"} alt="icon" />
                <div className="transfer-text">
                    <p className="card-payment">Bank Transfer</p>
                    <p className="card-top-up">Top up your balance via generated virtual account.</p>
                </div>
                </div>
                  <input 
                  type="radio" 
                  name="options"
                  value="bank"
                  checked={selectedOption === 'bank'}
                  onChange={handleOptionChange}
                  />
            </div>

            <div className={`card-option ${selectedOption === 'payout' ? 'selected' : ''}`} onClick={() => setSelectedOption('payout')}>
              <div className="icon-bank">
              <img src={process.env.PUBLIC_URL + "/images/balanceTransfer.svg"} alt="icon" />
              <div className="transfer-text">
                  <p className="card-payment">Other Options</p>
                  <p className="card-top-up">Fund your wallet via Card or Payout balance</p>
              </div>
              </div>
              <input 
              type="radio" 
              name="options"
              value="payout"
              checked={selectedOption === 'payout'}
              onChange={handleOptionChange}
              />
            </div>
        </div>


        <div className="modal-btn">
          <button onClick={() => {setIsPayment(false)}} className="sec-btn">
            Cancel
          </button>

          <button onClick={selectedOption === 'bank' ? handleVirtualNuban:selectedOption === 'payout'? handlePayout:"" } className="btn">
            {
              isLoading ?
              <BeatLoader
                color="#FFF"
                cssOverride={{}}
              />:
              <>
              Confirm
              </>
            }
            
          </button> 
        </div>
        </div>
    </div>
    </div>
  )
}

export default PaymentMethod