import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import SideBar from '../sidebar/SideBar'
import Nav from '../sidebar/Nav'
import { useSelector } from 'react-redux'
import Notification from '../sidebar/Notification'
import ProfileModal from '../sidebar/ProfileModal'
import { useNavigate } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { logout } from '../../redux/userSlice'
import DataProvider from '../context/DataProvider'
import axios from 'axios'
import SideBarMobile from '../sidebar/SideBarMobile'
import NewBusinessModal from '../sidebar/NewBusinessModal'
import PayoutModal from '../otc/PayoutModal'
import FlickAccountModal from '../otc/FlickAccountModal'
import ConvertModal from '../otc/ConvertModal'
import ConfirmConvertModal from '../otc/ConfirmConvertModal'
import ConvertSuccess from '../otc/ConvertSuccess'
import PayoutBenSumbited from '../otc/PayoutBenSumbited'
import PayoutSumbited from '../otc/PayoutSumbited'
import ConfirmBankDetailModal from '../otc/ConfirmBankDetailModal'
import BankDetailModal from '../otc/BankDetailModal'
import PayoutBeneficiaryAdd from '../otc/PayoutBeneficiaryAdd'
import { toast } from 'react-toastify'
import PortfolioSuccess from '../balance/modal/PortfolioSuccess'
import PortFolioConfirm from '../balance/modal/PortFolioConfirm'
import CreatePayLinkForm from '../payment/CreatePayLinkForm'
import DirectDebitCreated from '../payment/DirectDebitCreated'
import CreateDirectDebitForm from '../payment/CreateDirectDebitForm'
import ConfirmFlickPayout from '../otc/ConfirmFlickPayout'

const Container = styled.div`
display:flex;
position:relative
background-color: #F6F7F9;

`
const Main = styled.div`
background-color: #F6F7F9;
margin: 0;
width:100%;
margin-left:280px;
margin-top:83px;
@media (max-width:1200px){
  margin:0;
}
`

const Wrapper = styled.div`
  width:94%;
  margin:0 auto;
`

function Layout() {
  useEffect(() => {
    getExchange(convertInput.from_currency, convertInput.to_currency);
    // eslint-disable-next-line
  }, []);
  useEffect(()=>{
    getBalance();
    getBanks();
    loadCountries();
    getBeneficiary()
 // eslint-disable-next-line
  },[])
  const {currentUser} = useSelector((state)=>state?.user)
  const mode = currentUser.user.isVerified ? "Live Mode" : "Test Mode";
  const [isNotification,setIsNotification] = useState(false);
  const [isProfile,setIsProfile] = useState(false);
  const [loggedIn, setLoggedIn] = useState(true);
  const [avatar,setAvatar]  = useState(null)
  const [show,setShow] = useState(false)
  const navigate = useNavigate()
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showNewBusinessModal, setShowNewBusinessModal] = useState(false);

  //============== Move Money ===================
  const loadCountries = () => {
    axios.get('https://api.countrystatecity.in/v1/countries', {
        headers: { 'X-CSCAPI-KEY': 'NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==' }
    })
    .then(response => {
        const filteredCountries = response.data.filter(country => 
            !excludedCountries.includes(country.name)
        );
        setCountries(filteredCountries);
    })
    .catch(error => console.error('Error loading countries:', error));
  };
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  const [isTransferPortfolioAmount,setIsTransferPortfolioAmount] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const [selectedOption, setSelectedOption] = useState(''); 
  const [isBeneficiary, setIsBeneficiary] = useState(false);
  const [isConfirmPortfolio, setIsConfirmPortfolio] = useState(false);
  const [isPortfolioSuccess, setIsPortfolioSuccess] = useState(false);
  const [banks,setBank] = useState([])
  const [payoutId, setPayoutId] = useState('');
  const [payoutToken,setToken] = useState('');
  const [payoutResult, setPayoutResult] = useState({})
  const [isBankLoading,setIsBankLoading] = useState(false)
  const [balanceData,setBalanceData] = useState([])
            //  payout State 
  const [isPayout,setIsPayout] = useState(false)
  const [isBankDetial,setIsBankDetail] = useState(false)
  const [isConfirmBankDetial,setIsConfirmBankDetial] = useState(false)
  const [isPayoutSubmitted,setIsPayoutSubmited] = useState(false)
  const [isPayoutBenSubmitted,setIsPayoutBenSubmited] = useState(false)
  const [selectedBeneficiary, setSelectedBeneficiary] = useState({}); 
  const [beneficiaryData,setBeneficiaryData] = useState([]);
  const [isBenAdd,setIsBenAdd] = useState(false);
  const [isAddBeneficiary,setIsAddBeneficiary] = useState(false)
  const [isGetBeneficiary,setIsGetBeneficiary] = useState(false)
  const [countries, setCountries] = useState([]);
  const [isFxPayoutLoading,setIsFxPayoutLoading] = useState(false)
  const [fxAmount,setFxAmount] = useState('')
  const [collectionData,setCollectionData] = useState([]);

  // === flick payout =======
  const [isConfirmFlickPayout,setIsConfirmFlickPayout] = useState(false)

  // =========== Convert States =================
  const [rateMsg,setRateMsg] = useState('')
  const [isRateLoading, setIsRateLoading] = useState(false);
  const [initiatData,setInitiateData] = useState({});
  const [rateInitial,setRateInitial] = useState({})
  const [isConvert,setIsConvert] = useState(false)
  const [isConfirmConvert,setConfirmConvert] = useState(false)
  const [isConvertSuccess,setIsConvertSuccess] = useState(false)
  const [convertInput,setConvertInput] = useState({
    
      from_currency:"NGN",
      from_currency_amount:"",
      to_currency:"USD",
      to_currency_amount:""
  });
  const [rateInfo, setRateInfo] = useState(null);

  // ========= Payout Input =============
  const excludedCountries = ['Cuba', 'Iran', 'North Korea', 'Syria', 'Russia', 'Belarus', 'Ukraine','United Kingdom','United States','China'];
  const [beneficiaryInput, setBeneficiaryInput] = useState({
    account_no:"",
    routing:"",
    beneficiary_name:"",
    beneficiary_address_1:"",
    beneficiary_city:"",
    beneficiary_state:"",
    beneficiary_country:"United States",
    beneficiary_postal_code:"",
    bank_name:"Wells Fargo",
    bank_address_1:"",
    bank_address_2:"",
    bank_city:"",
    bank_state:"",
    bank_country:"United States",
    bank_postal_code:"",
    transfer_type:"",
    iban:"",
    swift:""
  })

  const [payoutInput,setPayoutInput] = useState({
      wireType:"",
      accountNumber:"",
      routingNumber:"",
      beneficiaryAccName:"",
      address1:"",
      address2:"",
      city:"",
      state:"",
      country:"",
      postalCode:"",
      currency:""
  });
  const [bankDetailInput,setBankDetailInput] = useState({
    bankName:"",
    address1:"",
    address2:"",
    city:"",
    state:"",
    country:"",
    postalCode:""
  });
  
  const [walletBeneficiaryInput,setWalletBeneficiaryInput] = useState({
    wireType:"",
    accountNumber:"",
    routingNumber:"",
    beneficiaryAccName:"",
    address1:"",
    address2:"",
    city:"",
    state:"",
    country:"",
    postalCode:""
  });
  const [transferBankInput, setTransferBankInput] = useState({
    balance:"NGN",
    amount:"",
    currency: "NGN",
    bank_code: "",
    narration: "",
    account_number: "",
    beneficiary_name:"",
  });
  const [flickPayoutInput, setFlickPayoutInput] = useState({
    balance:"NGN",
    amount:"",
    currency: "NGN",
    beneBusinessCode: "",
    beneficiary_name: "", 
  });
  
  // ======= end Payout=========

  // ===== PayLink ====
  const [isPayLink,setIsPayLink] = useState(false) 
  const [isLoading, setIsLoading] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [payLinkInput,setPayLinkInput] = useState({
    pageName:"", 
    productType:"",
    // currency:"NGN",
    currency:"",
    amount:"",
    successmsg:"",
    description:"",
    customLink:"",
    redirectLink:"",

  })
  const [isPayLinkLoading,setIsPayLinkLoading] = useState(false);

  // ====== PayLink end  ======

  // ======== Direct Debit ==========
  const [directDebitData, setDirectDebitData] = useState([]);
  const [transactionsData, setTransactionsData] = useState([]);
  const [isDirectDebitLink,setIsDirectDebitLink] = useState(false) 
  const [isDirectDebitCreated,setIsDirectDebitCreated] = useState(false) 
  const [directLinkInput,setDirectLinkInput] = useState({
    productType: "",
    transactionId: "",
    startDate: "",
    endDate: "",
    changeDate: "",
    email: "",
    phoneNumber: "",
    currency: "NGN",
    amount: "",
    frequency: "",
    redirectLink: "",
    currency_collected:"",
    currency_settled:"",
    is_open_banking:false,
    no_of_accounts:""

  })
  const [isDirectDebitLoading,setIsDirectDebitLoading] = useState(false);
  const [isTransactionLoading,setIsTransactionLoading] = useState(false);
 

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const dispatch = useDispatch();

  const handleNewBusinessClick = () => {
    setShowNewBusinessModal(true);
  };
  const handleCloseModal = () => {
    setShowNewBusinessModal(false);
  };

  useEffect(()=>{
    getMerchantInfo();
    // eslint-disable-next-line
  },[])
  //function to check for inactivity and log out

  const checkForInactivity = ()=>{
    //get expire time from localstorage

    const expireTime = localStorage.getItem("expireTime");

    //if expire time is earlier than now, log out

    if(expireTime < Date.now()){
      // console.log("log out")
      setLoggedIn(false);
      dispatch(logout())
      navigate('/')
    }

  }


  //function to update expire time

  const updateExpireTime = ()=>{
    //set expire time to 1hr from now

    const expireTime = Date.now() + 3600000;

    //set expire tiem in local storage
    localStorage.setItem('expireTime',expireTime);
  }
  //use Effect to set interval to check for inactivity
  useEffect(()=>{
    //check for inactivity every 5 sec
    const interval = setInterval (()=>{
      checkForInactivity();
    },5000);

    // clear interval onn unmount


    return ()=> clearInterval(interval)
    // eslint-disable-next-line 
  },[]);


  //update expire time on any user activity

  
  useEffect(()=>{
    //set initial expire time

    updateExpireTime();

    //set event listeners

    window.addEventListener("click",updateExpireTime);
    window.addEventListener("keypress",updateExpireTime)
    window.addEventListener("scroll",updateExpireTime);
    window.addEventListener("mousemove",updateExpireTime)
    
      //clean up

    return ()=>{
      window.removeEventListener("click",updateExpireTime)
      window.removeEventListener("keypress",updateExpireTime)
      window.removeEventListener("scroll",updateExpireTime);
      window.removeEventListener("mousemove",updateExpireTime)
    }
  // eslint-disable-next-line 
  },[])


  const toggleProfile = ()=>{
    setIsProfile(!isProfile);
  }

  const getMerchantInfo = async()=>{
  
    try{
     
      const headers = {  
        "api_key": currentUser?.user?.merchantKey, 
        "sweetapi": currentUser?.user?.merchantCode  
      }
       const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/merchant-info';
       let res = await axios.get(url,{
        headers:headers
      })
     
      setAvatar(res.data?.data?.avatar)
      
      
      
         
    }catch(err){
      console.log(err);
      const errorMessage = err?.message || "unable to fetch data"
    }
  }

  const MobileMenuHandler = ()=>{
    setShow(!show)
  }

  const openWhatsAppChat = () => {
    window.open('https://wa.me/+23470000035425', '_blank');
  };

  const openInstagram = () => {
    window.open('https://www.instagram.com/getflick.africa/', '_blank');
  };

  const openEmail = () => {
    window.open('mailto:support@getflick.co', '_blank');
  };

  const openSlack = () => {
    window.open('https://join.slack.com/t/flickdev/shared_invite/zt-2gq9i0pr6-IRBcrB55q5nJ9CTpq98P~Q', '_blank');
  };


  // const toggleMenu = () => {
  //   document.querySelector('.menu-toggle').classList.toggle('open');
  //   document.querySelector('.menu-round').classList.toggle('open');
  //   document.querySelector('.menu-line').classList.toggle('open');
  // };



  //======== Render Move Money modals logics

  const toggleModal = () => {
    setIsPayout(!isPayout)
    
  };

  const getBanks = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      

      const url =
        "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/banks";
      let res = await axios.get(url,{ headers: headers});
    
      setBank(res?.data?.data)
      
    } catch (err) {
      console.log(err);
      const errorMessage = err?.response?.data?.data?.message || "Server error";
    }
  };

  const addBeneficiary = async () => {
    setIsAddBeneficiary(true);
    try {
      const headers = {  
        // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
        api_key: currentUser?.user?.merchantKey,
      }
  
      const data = {
        beneficiary_country:beneficiaryInput.beneficiary_country,
        transfer_type:beneficiaryInput.transfer_type,
        account_no:beneficiaryInput.account_no,
        routing:beneficiaryInput.routing,
        beneficiary_name:beneficiaryInput.beneficiary_name,
        beneficiary_address_1:beneficiaryInput.beneficiary_address_1,
        beneficiary_city:beneficiaryInput.beneficiary_city,
        beneficiary_state:beneficiaryInput.beneficiary_state,

        beneficiary_postal_code:beneficiaryInput.beneficiary_postal_code,
        bank_name:beneficiaryInput.bank_name,
        bank_address_1:beneficiaryInput.bank_address_1,
        bank_address_2:beneficiaryInput.bank_address_2,
        bank_city:beneficiaryInput.bank_city,
        bank_state:beneficiaryInput.bank_state,
        bank_country:beneficiaryInput.bank_country,
        bank_postal_code:beneficiaryInput.bank_postal_code
      }
  
      

        
      const url = "https://flick-app.com/merchant/save-beneficiary"
      // console.log("url :",url)
  
      
      let res = await axios.post(url,{...data},{
        headers:headers
      })

      
  
    
      getBeneficiary();
      // setIsAddBeneficiary(false);
   
  
      // setIsAddBeneficiary(false);
    } catch (err) {
      setIsAddBeneficiary(false);
      
      const errorMessage = err?.response?.data?.message ;
    
      toast.error(errorMessage,{ position: "top-left", pauseOnHover: true});
      
    } 
  };

  const getBalance = async ()=>{
    setIsBankLoading(true)
    try{
      
      const headers = {  
        "api_key": currentUser.user.merchantKey, 
        "sweetapi": currentUser.user.merchantCode  
      }
      const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/balances';
      let res = await axios.get(url,{
        headers:headers
      });
      const newValue = res.data.data
    
      setBalanceData(newValue);
     
      setIsBankLoading(false)
       
    }catch(err){
      setIsBankLoading(false)
      console.log(err)
      const errorMessage = err?.message || "unable to fetch data"
     
    }
  }
  const getBeneficiary = async () => {
    // setIsGetBeneficiary(true);
    try {
      const headers = {  
        // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
        api_key: currentUser?.user?.merchantKey,
      }
        
      const url = "https://flick-app.com/merchant/get-beneficiary";
    
      let res = await axios.get(url, { headers });
      // console.log("beneficiarys: ",res.data.data)
      setBeneficiaryData(res.data.data);
      // setIsGetBeneficiary(false);
    } catch (err) {
      setIsGetBeneficiary(false);
      console.log("error from get: ",err);
      const errorMessage = err?.response?.data?.message || "Network error";
      // toast.error(errorMessage);
    } 
  };
  // =========== Convert functions ===========

const getExchange = async (fromCurrency, toCurrency) => {
  setIsRateLoading(true);
  try {
    const headers = {  
      // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
      api_key: currentUser?.user?.merchantKey,
    }
      
    const url = `https://flick-app.com/otc-dash/exchange_rate?from_currency=${fromCurrency}&to_currency=${toCurrency}`;
    console.log("url :",url)
    let res = await axios.get(url, { headers });
   
    setRateInitial(res.data.data)
    setRateInfo(res.data?.data);
    setRateMsg(res.data.data?.rate);
  } catch (err) {
    console.log(err);
    const errorMessage = err?.response?.data?.data?.message || err?.response?.data?.message || "error has occured" 
    // toast.error(errorMessage,{ position: "top-left", pauseOnHover: true})
  } finally {
    setIsRateLoading(false);
  }
};

const fxPayout = async () => {
  setIsFxPayoutLoading(true);
  try {
    const headers = {  
      // "api_key": 'pk-U2FsdGVkX19iPl/y6gXujKJxADmLhMYg/bkHtqPFKFY0gu1jo//Qlp4p79VI7vuxa9ss5FVlx5JK7ujiYOVLqNvS9wrt8KXNApURpTsAvwTNw9tDQROBV8R45o5n5Fso', 
      api_key: currentUser?.user?.merchantKey,
    }
    // console.log("selected ben: ",selectedBeneficiary);

    const data = {
      from_currency: transferBankInput.currency,
      benficiary_id: selectedBeneficiary.beneficiary_id,
      amount: fxAmount
    }

   

    // console.log("input data: ",data)
    const url = "https://flick-app.com/merchant/complete-payout"
    
    let res = await axios.post(url,{...data},{
      headers:headers
    })
    // console.log("response: ",res.data)
    setIsFxPayoutLoading(false)
    setIsPayoutSubmited(true);
  } catch (err) {
    setIsFxPayoutLoading(false);
    console.log(err)
    const errorMessage = err.response?.data?.message || "An error has occured"   
    toast.error(errorMessage,{position: "top-left"})
  } finally {
    setIsFxPayoutLoading(false);
  }
};

// ============ PayLink ===========

const getStatementLink = async ()=>{
  setIsPayLinkLoading(true);
  try{
    
    const headers = {  
      "api_key": currentUser.user.merchantKey, 
      "sweetapi": currentUser.user.merchantCode  
    }

    const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-payment-pages';
    let res = await axios.get(url,{
      headers:headers
    })

    
    setPaymentData(res.data?.data || [])
    setIsPayLinkLoading(false)
  }catch(err){
    setIsPayLinkLoading(false);
    console.log(err)
    const errorMessage = err?.message || "unable to fetch data"
  }
}
const addStatementLink = async ()=>{

  try{
    setIsLoading(true);

    const headers = {  
      "api_key": currentUser?.user?.merchantKey, 
      "sweetapi": currentUser?.user?.merchantCode  
    }
    const data = {
      ...payLinkInput,
      amount: `${Number(payLinkInput?.amount) * 100}`,
      currency:payLinkInput?.currency
    }
    console.log("datas: ",data)
    
    const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/create-payment-page';
    let res = await axios.post(url,{...data},{
      headers:headers
    })


    

   
    setIsLoading(false);
    setPayLinkInput(false);
    toast.success(res.data.message,{position: "top-left", pauseOnHover: true})
    toggleModal();

    navigate('/payment-recurring');

    
  }catch(err){
    setIsLoading(false);
    console.log(err);
    const errorMessage = err?.response?.data?.message || "Server error"
    toast.error(errorMessage,{position: "top-left", pauseOnHover: true});

  }
 
}

  const renderModal = () => {
   
    switch (selectedOption) {
      case 'Convert':
        return<ConvertModal  
        setIsPayout={setIsPayout} 
        convertInput={convertInput} 
        setConvertInput={setConvertInput} 
        setConfirmConvert={setConfirmConvert} 
        rateMsg={rateMsg}
        setRateMsg={setRateMsg}
        setInitiateData={setInitiateData}
        isRateLoading={isRateLoading}
        setIsRateLoading={setIsRateLoading}
        getExchange={getExchange}
        countries={countries}
        rateInfo={rateInfo}
        rateInitial={rateInitial}
        balanceData={balanceData}
        />

      case 'Payout to Bank':

        return <PayoutModal 
        setIsPayout={setIsPayout} 
        payoutInput={payoutInput} 
        setPayoutInput={setPayoutInput} 
        setIsBankDetail={setIsBankDetail}
        transferBankInput={transferBankInput}
        setTransferBankInput={setTransferBankInput}
        setIsBeneficiary={setIsBeneficiary}
        setIsConfirmPortfolio={setIsConfirmPortfolio}
        setIsPortfolioSuccess={setIsPortfolioSuccess}
        balanceData={balanceData}
        banks={banks}
        setPayoutId={setPayoutId}
        setPayoutResult={setPayoutResult}
        setSelectedBeneficiary={setSelectedBeneficiary}
        setIsConfirmBankDetial={setIsConfirmBankDetial}
        setIsBenAdd={setIsBenAdd}
        addBeneficiary={addBeneficiary}
        beneficiaryData={beneficiaryData}
        countries={countries}
        setFxAmount ={setFxAmount}
        
      />
      case 'Transfer to Flick Account':
        return <FlickAccountModal 
        setIsPayout={setIsPayout} 
        flickPayoutInput={flickPayoutInput} 
        setFlickPayoutInput={setFlickPayoutInput} 
       
        setIsConfirmFlickPayout ={setIsConfirmFlickPayout}
        setIsBeneficiary={setIsBeneficiary}
        setIsConfirmPortfolio={setIsConfirmPortfolio}
        setIsPortfolioSuccess={setIsPortfolioSuccess}
        balanceData={balanceData}
        banks={banks}
        setPayoutId={setPayoutId}
        setPayoutResult={setPayoutResult}
        setSelectedBeneficiary={setSelectedBeneficiary}
        setIsConfirmBankDetial={setIsConfirmBankDetial}
        setIsBenAdd={setIsBenAdd}
        addBeneficiary={addBeneficiary}
        beneficiaryData={beneficiaryData}
        countries={countries}
        setFxAmount ={setFxAmount}
        
      />
      case 'Create PayLink':
        return <CreatePayLinkForm 
        setIsPayout={setIsPayout} 
        payLinkInput={payLinkInput} 
        setPayLinkInput={setPayLinkInput} 
        setIsPayLink={setIsPayout} 
        isLoading={isLoading} 
        addStatementLink={addStatementLink}
        />


      case 'Create direct-debit Link':
        return <CreateDirectDebitForm 
        directLinkInput={directLinkInput} 
        setDirectLinkInput={setDirectLinkInput} 
        setIsDirectDebitLink={setIsPayout} 
        isLoading={isDirectDebitLoading} 
        createDirectDebitLink={createDirectDebitLink} 
        setIsDirectDebitCreated={setIsDirectDebitCreated}
        />
     
     
      default:
        return null; // No modal to display
    }
  };

  //===== Direct Debit


  const getDirectDebitList = async ()=>{
      setIsDirectDebitLoading(true);
      try{
        
        const headers = {  
          "api_key": currentUser.user.merchantKey, 
          // "api_key": 'pk-U2FsdGVkX1+i2IXLRQDL4rNPqeJGDhpdHJ/jSzDf8AiVsrEh6BB2tQHvbI3pVkXY4lPonli/Xuscb0QDSS/+JfIex2Gi8dABLmyvXSRTZf7xYn3xUWp1TjT42ZneiicW', 
        }

        const url = 'https://flick-app.com/direct-debit/listLinks';
        let res = await axios.get(url,{
          headers:headers
        })

        setDirectDebitData(res.data?.data || [])
        setIsDirectDebitLoading(false)
      }catch(err){
        setIsDirectDebitLoading(false);
        console.log(err)
        const errorMessage = err?.message || "unable to fetch data"
      }
  }


  const createDirectDebitLink = async ()=>{
    setIsDirectDebitLoading(true)
    let data;
    try{
      const headers = {  
        "api_key": currentUser?.user?.merchantKey, 
        'Content-Type': 'application/json' 
      }

      if(directLinkInput.productType === "onetime"){
        data = {
          amount:`${Number(directLinkInput?.amount) * 100}`,
          currency: directLinkInput.currency,
          // redirectUrl: directLinkInput.redirectLink,
          // webhookUrl:directLinkInput.webhookUrl,
          pagename: directLinkInput.pagename,
          type: directLinkInput.productType,
          charge_date: directLinkInput.changeDate
        }
      }else{
        
        data = {
          amount: `${Number(directLinkInput?.amount) * 100}`,
          start_date:directLinkInput.startDate,
          end_date: directLinkInput.endDate,
          frequency: directLinkInput.frequency,
          currency: directLinkInput.currency,
          // redirectUrl: directLinkInput.redirectLink,
          // webhookUrl:directLinkInput.webhookUrl,
          pagename: directLinkInput.pagename,
          type: directLinkInput.productType,
          is_open_banking: directLinkInput.is_open_banking,
          no_of_accounts: directLinkInput.no_of_accounts
        }
      }
       
      // console.log("directLinkInput", directLinkInput)
      // console.log("initialize-dashboard data", data)
      const url = 'https://flick-app.com/direct-debit/initialize-dashboard';
      let res = await axios.post(url,{...data},{
        headers:headers
      })
     
      getDirectDebitList();
      setIsDirectDebitLoading(false)
      setDirectLinkInput(false);
      toast.success(res.data.message);
      setIsDirectDebitLink(false);
      toggleModal();

      // getDirectDebitList();
      // setIsLoading(false);
      // setDirectLinkInput(false);
      // setIsDirectDebitLink(false);

      
      navigate('/payment-recurring', { state: { recurring: true } })

      // setIsDirectDebitCreated(true)
      
    }catch(err){
      setIsDirectDebitLoading(false);
      console.log(err);
      const errorMessage = err?.response?.data?.message || "Server error"
      toast.error(errorMessage,{position: "top-left", pauseOnHover: true});

    }
   
  }

  return (
      <DataProvider>
          <Container>
           
          <>
          
          
          <SideBar 
          name={currentUser.user.business_name}
          email={currentUser.user.businessId}
          // avatar={avatar ? avatar: currentUser.user.avatar? currentUser.user.avatar: '/images/avatarimage.jpeg'}
          mode={mode}
          avatar={currentUser.user.business_name?(`${String(String(currentUser.user.business_name).split(" ")[0]).slice(0,1)}`):"N/A"}
          showNewBusinessModal={showNewBusinessModal}
          handleNewBusinessClick={handleNewBusinessClick}
          />
          <SideBarMobile show={show}  MobileMenuHandler={MobileMenuHandler} />
          </>

          <Main>
            <Nav
            name={currentUser.user.name}
            email={currentUser.user.business_email}
            avatar={avatar ? avatar: currentUser.user.avatar? currentUser.user.avatar: '/images/avatarimage.jpeg'}
            setIsNotification={setIsNotification}
            toggleProfile={toggleProfile}
            MobileMenuHandler={MobileMenuHandler}
            setSelectedOption={setSelectedOption}
            toggleModal={toggleModal}
            />
            <Wrapper>
              <Outlet /> 
            </Wrapper>
          </Main>

          {isProfile && <ProfileModal />}
          {isNotification && <Notification setIsNotification={setIsNotification} />}
          {showNewBusinessModal && <NewBusinessModal onClose={handleCloseModal} />}

          {isPayout && renderModal()}

                   {/*======== Payout modals ====== */}
          {  isBenAdd && <PayoutBeneficiaryAdd  setIsBenAdd={setIsBenAdd} setIsPayout={setIsPayout} bankDetailInput={bankDetailInput} setBankDetailInput={setBankDetailInput} setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial}  beneficiaryInput={beneficiaryInput} setBeneficiaryInput={setBeneficiaryInput} countries={countries}  transferBankInput={transferBankInput} setIsPayoutBenSubmited={setIsPayoutBenSubmited} getBeneficiary={getBeneficiary}/>}
          {  isBankDetial && <BankDetailModal setIsPayout={setIsPayout} bankDetailInput={bankDetailInput} setBankDetailInput={setBankDetailInput} setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsBenAdd={setIsBenAdd} setBeneficiaryInput={setBeneficiaryInput} addBeneficiary={addBeneficiary} isAddBeneficiary={isAddBeneficiary} beneficiaryInput={beneficiaryInput} countries={countries} setIsPayoutBenSubmited={setIsPayoutBenSubmited}/>}
          {  isConfirmBankDetial && <ConfirmBankDetailModal setIsPayout={setIsPayout}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutSubmited={setIsPayoutSubmited} selectedBeneficiary={selectedBeneficiary} fxPayout={fxPayout} isFxPayoutLoading={isFxPayoutLoading} transferBankInput={transferBankInput}/>}
          {  isPayoutSubmitted && <PayoutSumbited setIsPayout={setIsPayout}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutSubmited={setIsPayoutSubmited} />}
          {  isPayoutBenSubmitted && <PayoutBenSumbited setIsPayout={setIsPayout} setIsBenAdd={setIsBenAdd}  setIsBankDetail={setIsBankDetail} setIsConfirmBankDetial={setIsConfirmBankDetial} setIsPayoutBenSubmited={setIsPayoutBenSubmited} />}



            {/* ====== Convert Modal ====== */}

            
              
          {isConfirmConvert && <ConfirmConvertModal  setConfirmConvert={setConfirmConvert} setIsConvertSuccess = {setIsConvertSuccess}  setIsConvert={setIsConvert} setIsPayout={setIsPayout} initiatData={initiatData} getBalance={getBalance} convertInput={convertInput}/>  }
          {isConvertSuccess && <ConvertSuccess setConfirmConvert={setConfirmConvert} setIsConvertSuccess = {setIsConvertSuccess}  setIsConvert={setIsConvert} initiatData={initiatData} setIsPayout={setIsPayout}/>}

          {isConfirmPortfolio && (
            <PortFolioConfirm
            setIsBeneficiary={setIsBeneficiary}
            setIsPortfolioSuccess={setIsPortfolioSuccess}
            setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
            setIsConfirmPortfolio={setIsConfirmPortfolio}
            setIsTransactionSuccess={setIsTransactionSuccess}
            setIsOpenPopup={setIsOpenPopup}
            setIsPayout={setIsPayout}
            setToken={setToken}
            payoutId={payoutId}
            payoutToken={payoutToken}
            transferBankInput={transferBankInput}
            payoutResult ={payoutResult}
          />
          )}

      {
        isConfirmFlickPayout &&
        <ConfirmFlickPayout  
        setIsBeneficiary={setIsBeneficiary}
        setIsPortfolioSuccess={setIsPortfolioSuccess}
        setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
        setIsConfirmFlickPayout={setIsConfirmFlickPayout}
        setIsTransactionSuccess={setIsTransactionSuccess}
        setIsOpenPopup={setIsOpenPopup}
        setIsPayout={setIsPayout}
        setToken={setToken}
        payoutId={payoutId}
        payoutToken={payoutToken}
        flickPayoutInput={flickPayoutInput}
        payoutResult ={payoutResult}
        />
      }
      {isPortfolioSuccess && (
        <PortfolioSuccess
        setIsTransactionSuccess={setIsTransactionSuccess}
        setIsPortfolioSuccess={setIsPortfolioSuccess}
        setIsTransferPortfolioAmount={setIsTransferPortfolioAmount}
        setIsConfirmPortfolio={setIsConfirmPortfolio}
        setIsOpenPopup={setIsOpenPopup}
        setIsPayout={setIsPayout}
        transferBankInput={transferBankInput}
        payoutResult ={payoutResult}
        />
      )}

      {/* Direct Debit */}
      {isDirectDebitCreated && 
      <DirectDebitCreated 
      setIsDirectDebitCreated={setIsDirectDebitCreated} 
      setIsDirectDebitLink={setIsPayout}
       />
      }


          <div className="container" onClick={toggleMenu}>
            <div className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} >
              <i className="fa fa-plus"></i>
            </div>

            <div className={`menu-round ${isMenuOpen ? 'open' : ''}`}>
              <div className="btn-app">
                <i className="fa" onClick={openWhatsAppChat}>
                <img src={process.env.PUBLIC_URL+"/images/whatsapp.svg"} alt="ig" />
                </i>
              </div>
              <div className="btn-app">
                <i className="fa" onClick={openEmail}>
                <img src={process.env.PUBLIC_URL+"/images/email.svg"} alt="ig" />
                </i>
              </div>
              <div className="btn-app">
                <i className="fa" onClick={openInstagram}>
                <img src={process.env.PUBLIC_URL+"/images/instagram_icon.svg"} alt="ig" />

                </i>
              </div>
              <div className="btn-app">
                <i className="fa" onClick={openSlack}>
                <img src={process.env.PUBLIC_URL+"/images/slack.svg"} alt="ig" />

                </i>
              </div>
            </div>

            {/* <div class="menu-line"> */}
            {/* <div className={`menu-line ${isMenuOpen ? 'open' : ''}`}>
            <div className="btn-app">
                <i className="fa" onClick={openWhatsAppChat}>
                <img src={process.env.PUBLIC_URL+"/images/whatsapp.svg"} alt="ig" />
                </i>
              </div>
              <div className="btn-app">
                <i className="fa" onClick={openEmail}>
                <img src={process.env.PUBLIC_URL+"/images/email.svg"} alt="ig" />
                </i>
              </div>
              <div className="btn-app">
                <i className="fa" onClick={openInstagram}>
                <img src={process.env.PUBLIC_URL+"/images/instagram_icon.svg"} alt="ig" />

                </i>
              </div>
              <div className="btn-app">
                <i className="fa" onClick={openSlack}>
                <img src={process.env.PUBLIC_URL+"/images/slack.svg"} alt="ig" />

                </i>
              </div>
            </div> */}
          </div>
          	
          

      {/* <script>
        {`
          function openWhatsAppChat() {
            window.open('https://wa.me/+23470000035425', '_blank');
          }

          function openInstagram() {
            window.open('https://www.instagram.com/getflick.africa/', '_blank');
          }

          function openEmail() {
            window.open('mailto:support@getflick.co', '_blank');
          }

          function toggleMenu() {
            document.querySelector(".menu-toggle").classList.toggle("open");
            document.querySelector(".menu-round").classList.toggle("open");
            document.querySelector(".menu-line").classList.toggle("open");
          }
        `}
      </script> */}
          </Container>
      </DataProvider>

  )
}

export default Layout
