import React, { useState } from 'react'
import '../../components/otc/otc.scss'
import SelectInputGroup from '../../components/otc/SelectInputGroup';
import SingleInputGroup from '../../components/otc/SingleInputGroup';
import BusinessAddress from '../../components/otc/BusinessAddress';
import DoubleInputGroup from '../../components/otc/DoubleInputGroup';
import { useNavigate } from 'react-router-dom';

function BeneficiaryDetails() {

    const navigate = useNavigate();
    const handleClick = ()=>{
        navigate('/bank-details')
    }
    const handleBack = ()=>{
        navigate('/customer-link')
    }
    const [payoutInput,setPayoutInput] = useState({
        wireType:"",
        accountNumber:"",
        routingNumber:"",
        beneficiaryAccName:"",
        address1:"",
        address2:"",
        city:"",
        state:"",
        country:"",
        postalCode:""
    });
    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
        setPayoutInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
    }
  return (
    <div className='weblink'>
    <div className='weblink_log'>
        <img className='otc_flick_logo' src='/images/otc/flickLogo.svg' alt=''/>
    </div>
    <div className='weblink_container'>
        <div className='weblink_box'>
            <div className='weblink_box_wrapper'>
                <div className='weblink_title_container'>
                    <div className='weblink_arrow'>
                        <img src='/images/otc/arrowBack.svg' alt='' onClick={handleBack} />
                        <p className='weblink_title'>Beneficiary Details</p>
                    </div>
                    <img src='/images/otc/step_two.svg' alt='' />
                </div>
                
                
                {/*  */}
                <div className='otcform_container'>
                    <form onSubmit={handleSubmit} >
            
                                <SelectInputGroup
                                name="wireType"
                                handleChange={handleInputChange}
                                label="Wire Type"
                                >
                                    <option value="" disabled>Domestic U.S wire (same/next day)</option>
                                        <option value="NGN">Domestic U.S wire (same/next day)</option>
                                </SelectInputGroup>
                                <DoubleInputGroup label1="Account Number" label2="Routing Number" handleChange={handleInputChange} name1="accountNumber" name2="routingNumber" placeholder1="22488136902667" placeholder2="23690867"/>
                                
                                <SingleInputGroup label="Beneficiary Account Name" handleChange={handleInputChange} name="beneficiaryName" placeholder="Ayomide Ajibade"/>
                                <BusinessAddress label="Beneficiary Address" />
                                <div style={{display:"flex",flexDirection:"column",marginTop:"20px"}}>
                                    <DoubleInputGroup label1="Address Line 1" label2="Address Line 2" handleChange={handleInputChange} name1="address1" name2="address2" />
                                    <DoubleInputGroup label1="City" label2="State" handleChange={handleInputChange} name1="city" name2="state" />
                                    <DoubleInputGroup select label1="Country" label2="Postal Code" handleChange={handleInputChange} name1="city" name2="state">
                                        <option value="" disabled>USA</option>
                                        <option value="USD">USA</option>
                                    </DoubleInputGroup>
                                </div>

                    </form>
                </div>

                <button className='btn' style={{marginTop:"15px"}} onClick={handleClick}>Confirm</button>
            </div>
        </div>
    </div>
</div>
  )
}

export default BeneficiaryDetails