import React, { useEffect, useState } from 'react'
import '../balance/modal/balanceModal.scss'
import './otc.scss'
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BeatLoader, CircleLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import Timer from './Timer';
import { formatTime } from '../utilities/formateTime';

function ConvertModal({ setIsConvert, convertInput, setConvertInput, setConfirmConvert, rateMsg, setRateMsg, setInitiateData, isRateLoading, setIsRateLoading, getExchange, countries, rateInfo, rateInitial, setIsPayout, balanceData }) {
    const { currentUser } = useSelector((state) => state?.user)
    const [isLoading, setIsLoading] = useState(false);
    const [isFromCurrencyDisabled, setIsFromCurrencyDisabled] = useState(false);
    const [isToCurrencyDisabled, setIsToCurrencyDisabled] = useState(false);
    const [isInputsDisabled, setIsInputsDisabled] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [remainingTime, setRemainingTime] = useState(rateInitial?.validity || 0);
    const [isTimeExpired, setIsTimeExpired] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            setRemainingTime(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (remainingTime <= 0) {
            setIsTimeExpired(true);
        }
    }, [remainingTime]);

    useEffect(() => {
        if (isTimeExpired) {
            fetchExchangeRate(convertInput.from_currency, convertInput.to_currency);
            setIsTimeExpired(false);
            setRemainingTime(rateInitial?.validity || 0); // Reset timer after fetching new rate
        }
        // eslint-disable-next-line
    }, [isTimeExpired, convertInput.from_currency, convertInput.to_currency, rateInitial?.validity]);

    const fetchExchangeRate = async (fromCurrency, toCurrency) => {
        setIsInputsDisabled(true);
        setIsRateLoading(true);
        try {
            await getExchange(fromCurrency, toCurrency);
        } catch (error) {
            console.error("Failed to fetch exchange rate:", error);
        } finally {
            setIsInputsDisabled(false);
            setIsRateLoading(false);
        }
    };

    useEffect(() => {
        if (convertInput.from_currency && convertInput.to_currency) {
            fetchExchangeRate(convertInput.from_currency, convertInput.to_currency);
        }
        // eslint-disable-next-line
    }, [convertInput.from_currency, convertInput.to_currency]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let fromCurrency = convertInput.from_currency;
        let toCurrency = convertInput.to_currency;
        let fromAmount, toAmount;

        if (name === 'from_currency_amount') {
            fromAmount = parseFloat(value);
            toAmount = (fromAmount / parseFloat(rateMsg)).toFixed(2);
        } else if (name === 'to_currency_amount') {
            toAmount = parseFloat(value);
            fromAmount = (toAmount * parseFloat(rateMsg)).toFixed(2);
        } else if (name === 'to_currency') {
            toCurrency = value;
            fetchExchangeRate(fromCurrency, toCurrency);
            fromAmount = convertInput.from_currency_amount;
            toAmount = (fromAmount / parseFloat(rateMsg)).toFixed(2);
        } else if (name === 'from_currency') {
            fromCurrency = value;
            fetchExchangeRate(fromCurrency, toCurrency);
            fromAmount = convertInput.from_currency_amount;
            toAmount = (fromAmount / parseFloat(rateMsg)).toFixed(2);
        }

        setConvertInput({
            from_currency: fromCurrency,
            from_currency_amount: fromAmount,
            to_currency: toCurrency,
            to_currency_amount: toAmount
        });
    };

    const updateCurrencyAmounts = (name, value) => {
        const fromCurrency = convertInput.from_currency;
        const toCurrency = convertInput.to_currency;
        const rate = parseFloat(rateMsg);
        let newFromCurrencyAmount = parseFloat(convertInput.from_currency_amount);
        let newToCurrencyAmount = parseFloat(convertInput.to_currency_amount);

        if (name === 'from_currency_amount') {
            newFromCurrencyAmount = parseFloat(value);
            newToCurrencyAmount = fromCurrency === 'NGN'
                ? (newFromCurrencyAmount / rate).toFixed(2)
                : (newFromCurrencyAmount * rate).toFixed(2);
        } else if (name === 'to_currency_amount') {
            newToCurrencyAmount = parseFloat(value);
            newFromCurrencyAmount = toCurrency === 'NGN'
                ? (newToCurrencyAmount * rate).toFixed(2)
                : (newToCurrencyAmount / rate).toFixed(2);
        }

        setConvertInput((prevFormData) => ({
            ...prevFormData,
            from_currency_amount: newFromCurrencyAmount,
            to_currency_amount: newToCurrencyAmount,
        }));
    };

    const handleProceed = async (e) => {
        e.preventDefault();
        const { from_currency_amount, from_currency, to_currency } = convertInput
        if (from_currency_amount === "" || from_currency_amount === null) return toast.error("Amount must not be empty", { position: "top-left", pauseOnHover: true })
        if (from_currency === "") return toast.error("source currency must not be empty", { position: "top-left", pauseOnHover: true })
        if (to_currency === "") return toast.error("destination currency must not be empty", { position: "top-left", pauseOnHover: true })

        setIsLoading(true);
        try {
            const headers = {
                api_key: currentUser?.user?.merchantKey,
            }

            const url = `https://flick-app.com/otc-dash/initiate_exchange?from_currency=${convertInput.from_currency}&to_currency=${convertInput.to_currency}&from_currency_amount=${convertInput.from_currency_amount}`;
            let res = await axios.get(url, { headers: headers });

            setInitiateData(res.data.data);
            setIsLoading(false)
            setConfirmConvert(true);
        } catch (err) {
            setIsLoading(false);
            console.log(err);
            const errorMessage = err?.response?.data?.data?.message || err?.response?.data?.message || "error has occured"
            toast.error(errorMessage, { position: "top-left", pauseOnHover: true })
        }
    }

    return (
        <div className='balance_modal' onClick={setIsPayout.bind(this, false)} >
            <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
                <div className='balance_modal_wrapper'>
                    <img
                        onClick={setIsPayout.bind(this, false)}
                        className='otc_back' src="/images/otc/backIcon.svg" alt='close'
                    />

                    <div className="modal-title">
                        <p className="enter-amount">Convert</p>
                        <p className="enter-an-amount">Please enter the amount you would like to convert.</p>
                    </div>
                    {/* timer */}

                    <Timer time={formatTime(remainingTime)} />

                    <div className='otcform_container'>
                        <form onSubmit={handleProceed}>
                            <p className='otcForm_desc'>I want to send</p>
                            <div className='api_select_container' style={{ marginTop: "16px" }}>
                                <div className='api_wallet_select_input'>
                                    <img className='flagIcon' src={`/images/${convertInput.from_currency}.svg`} alt="" />
                                    <select
                                        className='ot_api_select_val'
                                        name='from_currency'
                                        value={convertInput.from_currency}
                                        onChange={handleInputChange}
                                        disabled={isFromCurrencyDisabled}
                                    >
                                        <option value="NGN">NGN</option>
                                        <option value="KES">KES</option>
                                        <option value="GHS">GHS</option>
                                    </select>
                                </div>

                                <input
                                    name='from_currency_amount'
                                    className='api_input_amountss'
                                    placeholder="5.00"
                                    onChange={handleInputChange}
                                    value={convertInput.from_currency_amount}
                                    type='number'
                                    disabled={isInputsDisabled}
                                />
                            </div>

                            <div className="otc_exchange_rate">
                                <div className='otc_fx_values'>
                                    <img src='/images/otc/exchangeLine.svg' alt='' />
                                    <p className='fx_amount_desc'>Current Rate: </p>
                                    <p className='fx_amount_text'>&nbsp; {convertInput.to_currency == "USD" ? "$" : convertInput.to_currency == "GBP" ? "£" : convertInput.to_currency == "CAD" ? "C$" : ""}1.00 </p>
                                    <span className='fx_amount_text'>&nbsp;=&nbsp;</span>
                                    <p className='fx_amount_text'> {convertInput.from_currency==="NGN"?"₦":convertInput.from_currency==="KES"?"KSh":convertInput.from_currency==="GHS"?"₵":convertInput.from_currency}{rateMsg}</p>
                                </div>

                                {
                                    isRateLoading ?
                                        <CircleLoader
                                            color="#2EBDB6"
                                            cssOverride={{ with: 12.75 }}
                                            size={15}
                                        /> :
                                        <img src='/images/otc/otcExchangeCircle.svg' alt='' />
                                }
                            </div>

                            <p className='otcForm_desc' style={{ marginTop: "18px" }}>Recipient gets</p>

                            <div className='api_select_container' style={{ marginTop: "16px" }}>
                                <div className='api_wallet_select_input'>
                                    <img className='flagIcon' src={`/images/${convertInput.to_currency}.svg`} alt="" />
                                    <select
                                        className='ot_api_select_val'
                                        name='to_currency'
                                        value={convertInput.to_currency}
                                        onChange={handleInputChange}
                                        disabled={isInputsDisabled}
                                    >
                                        <option value='USD'>USD</option>
                                        <option value='CAD'>CAD</option>
                                        <option value='GBP'>GBP</option>
                                        <option value='EUR'>EUR</option>
                                    </select>
                                </div>
                                <input
                                    name='to_currency_amount'
                                    className='api_input_amountss'
                                    placeholder="40,000"
                                    onChange={handleInputChange}
                                    value={convertInput.to_currency_amount}
                                    type='number'
                                />
                            </div>

                            <div className='otc_btn_modal'>
                                <button className='btn otc_btn_cancel' onClick={setIsPayout.bind(this, false)} >
                                    Cancel
                                </button>
                                {
                                    isLoading ?
                                        <button className='btn'>
                                            <BeatLoader
                                                color="#FFF"
                                                cssOverride={{}}
                                            />
                                        </button> :
                                        <button className='btn' onClick={handleProceed} disabled={isInputsDisabled} >
                                            Proceed
                                        </button>
                                }

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConvertModal
