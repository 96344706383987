import React from 'react'
import TableHeaderSearch from '../common/TableHeaderSearch'
import Status from '../common/Status'
import { useNavigate } from 'react-router-dom'
import { format } from 'timeago.js';
import { getIconByName } from '../utilities/getBankIcon';
import TableLoader from '../common/TableLoader';

function Transactions({transactionData,isTransactionLoading}) {
  const navigate = useNavigate();
  const handleTransaction = (item)=>{

     navigate("/data-transaction",{state:{item}})
  }

  const handleSync = (e)=>{
    e.stopPropagation();
  }

  return (
    <div className='balance-overview collection_load'>
       {
            isTransactionLoading &&
            <TableLoader />
        }
        {
          console.log("transactionData: ",transactionData)
        }
      <table className='tbl'>
        <tr >
            <td  colSpan="7" className='tbl-heading'>
                <TableHeaderSearch 
                title="All transactions"
                placeholder="Search by name or date..."
                />     
            </td>
        </tr>
        {
            Array.isArray(transactionData) && transactionData.length > 0  &&
          <tr>
            <th className='th-text'>Customer</th>
            <th className='th-text'>Account</th>
            <th className='th-text'>Count</th>
            <th className='th-text'>Date Synced</th>
            <th className='th-text'>Status</th>
          </tr>
        }

        {
           Array.isArray(transactionData) && transactionData.length > 0 && transactionData.map((item,index)=>(

          <tr key={item?.bvn} onClick={()=>handleTransaction(item)}>
              <td className='td-text'>{item?.customer ? item?.customer:"N/A"}</td> 
              <td className='td-text'>
                  <div className='tbl-account'>
                      <img className='account-bank-logo' src={getIconByName(item?.bank)} alt="" />
                      <div className='payment-account-text'>
                          <h1 className='td-text'>{item?.bankName?item?.bankName:"N/A"}</h1>
                          <span className='td-text'>| {item?.Nuban ? item?.Nuban:"N/A"}</span>
                      </div>
                  </div>
              </td>
              <td className='td-text'>{item?.transactionCount?item.transactionCount:"N/A"}</td>
              <td className='td-text'>{format(item?.dated) ? format(item.dated):"N/A"}</td>
              
              <td>
                <div style={{display:"flex",justifyContent:"space-between",alignContent:"center"}}>
                  {
                    item?.status ?item?.status :"N/A"
                  }
                  {/* <Status 
                  icon="/images/success.svg"
                  mgs="Credit"
                  /> */}
                  <img  src='/images/eye.svg' style={{cursor:"pointer"}} alt='display' />
                  <img onClick={handleSync}  style={{cursor:"pointer"}}  src={process.env.PUBLIC_URL+"/images/SyncBadge.svg"} alt="verified" />
                </div>
              </td>
              

          </tr>
           ))
        }
        {
           Array.isArray(transactionData) && transactionData.length > 0 &&
          <tr>
              <td className='td-export' colspan="7">
                  <div className='tbl-export'>
                      <h6 className='tbl-export-text'>Export Data</h6>

                      <img src={process.env.PUBLIC_URL+"/images/export.svg"} alt='export' />               
                  </div>
              </td>
          </tr>
        }
        {
           (!isTransactionLoading && (!Array.isArray(transactionData) || transactionData.length <= 0))  &&
            <tr>
             <td  colSpan={7} style={{width:"100%"}}>
                <div className='empty_tbl'>
                    <img src='/images/emptyPage.svg' alt='emptuy' />
                    <h3 className='num_trans'>No Transactions Yet</h3>
                    <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                </div>
             </td>
            </tr>
           }
      </table>
    </div>
  )
}

export default Transactions
