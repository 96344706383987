import React, { useState } from "react";
import DoubleInputGroup from "../../otc/DoubleInputGroup";
import BusinessAddress from "../../otc/BusinessAddress";
import SelectInputGroup from "../../otc/SelectInputGroup";
import SettlementConfirmDetails from "./SettlementConfirmDetails";
function SettlementBankDetail({ open, onClose,setFormData,formData,addSettlementAccount, closeSettlementDetail }) {
    const [showSettlementConfirmDetails, setShowSettlementConfirmDetails] = useState(false);
  
  if (!open) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e)=>{
    e.preventDefault();
    // addSettlementAccount();
  }
  const handleProceed = () => {
    setShowSettlementConfirmDetails(true);
  };

  const closeSettlementConfirmDetails = () => {
    onClose();
    setShowSettlementConfirmDetails(false);
  };
  
  return (
    <div className="settlement-overlay" onClick={onClose}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="settlement-set-modal"
      >
        <div className="settlement-modal-inner" style={{width:"89%"}}>
          <div className="close-icon" style={{justifyContent:"flex-start", marginTop:"24px", marginBottom:"16px"}}>
            <img
              onClick={onClose}
              src={process.env.PUBLIC_URL + "/images/back-arrow.svg"}
              alt=""
            />
          </div>
          <div className="add-account" style={{borderBottom:"none", marginBottom:"0"}}>
            <div style={{display:"flex", justifyContent:"space-between"}}>
            <h2 className="add-account-text">Bank Details</h2>
            <img src='/images/otc/step_two.svg' alt='' />
            </div>
            <h5 className="authenticate">
            {/* Please enter the amount above $5,000  */}
            </h5>
          </div>
          <div className='otcform_container'>
            <form onSubmit={handleSubmit} >
    
                        <SelectInputGroup
                        name="bankName"
                        handleChange={handleInputChange}
                        label="Bank Name"
                        >
                            <option value="" disabled>Wells Fargo</option>
                                <option value="WEL">Wells Fargo</option>
                        </SelectInputGroup>
                       
                        <BusinessAddress label="Bank Address" />

                        <div style={{display:"flex",flexDirection:"column",marginTop:"20px"}}>
                            <DoubleInputGroup label1="Address Line 1" label2="Address Line 2" handleChange={handleInputChange} name1="address1" name2="address2" />
                            <DoubleInputGroup label1="City" label2="State" handleChange={handleInputChange} name1="city" name2="state" />
                            <DoubleInputGroup select label1="Country" label2="Postal Code" handleChange={handleInputChange} name1="city" name2="state">
                                <option value="" disabled>USA</option>
                                <option value="USD">USA</option>
                            </DoubleInputGroup>
                        </div>

            </form>

                
            </div>

            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={onClose} >
                    Cancel
                </button>
                <button className='btn' onClick={handleProceed} >
                    Proceed
                </button>
            </div>
        </div>
      </div>
      {showSettlementConfirmDetails && (
            <SettlementConfirmDetails onClose={closeSettlementConfirmDetails} />
          )}

    </div>
  );
}

export default SettlementBankDetail;
