import React, { useRef } from 'react'
import '../modals/Modal.scss'
import './Disputes.scss'
import '../otc/otc.scss'
import DetailText from '../otc/DetailText'
import DetailTextDisput from './DetailTextDisput'

function CollectionDisputeModal({setIsCollectionModal}) {
  const ref = useRef();
  return (
    <div className="overlay" >
    <div className="enter-amount-modal">
      <div className="modal-wrapper">
        <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}> 
          <p className='disput_title'>Collection Dispute</p>
          <img style={{cursor:"pointer"}} className='verify-close' onClick={setIsCollectionModal.bind(this,false)} src={process.env.PUBLIC_URL + "/images/x-close.svg"} alt="icon" />
        </div>
        <p className='disput_desc'>Your transaction has been initiated, check your balance</p>

        <div className='line_dispute'>
          <p className='col_descs'>Transaction amount</p>
          <h4 className='col_titles'>$4,000.00</h4>
        </div>
        
        <div className='otcform_container'>
            <div className='desc_container'>
                <DetailTextDisput desc1="Customer" value1="Mide Ajibade" desc2="Recipient bank" value2="GTBank" image="/images/gtbDispute.svg"/>
                <DetailTextDisput desc1="Customer" value1="Mide Ajibade" desc2="Recipient bank" value2="GTBank" image="/images/gtbDispute.svg" status="Resolved"/>
                <DetailTextDisput desc1="Session ID" value1="dvdsv425sswsd523f3..." desc2="Channel" value2="Transfer"  ref={ref} copy/> 
                <DetailTextDisput desc1="Messages" value1="Please refund the money. Thank you so much guys!" /> 
            </div>
        </div>
         
       

      </div>
    </div>
    </div>
  )
}

export default CollectionDisputeModal