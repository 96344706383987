import React, { useEffect, useRef, useState } from 'react'
import NameControl from '../components/common/NameControl'
import Transaction from '../components/data/Transaction'
import '../components/data/data.scss'
import axios from 'axios';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { copyToClipboard } from '../components/utilities/clipboardUtils'
import TableHeaderSearch from '../components/common/TableHeaderSearch'
import { useLocation, useNavigate } from 'react-router-dom'
function DataLinkPage() {
    useEffect(()=>{
        getStatement();
        // eslint-disable-next-line
    },[])
    const {currentUser} = useSelector((state)=>state?.user)
    const [isStatementLoading, setIsStatementLoading] = useState(false);
    const [statement,setStatement] = useState([])
    const location = useLocation()
    const navigate = useNavigate()
    const handleditForm =()=>{

    }
    
    const {item} = location?.state || {}

    const getStatement = async ()=>{
        setIsStatementLoading(true)
        try{
          
          const headers = {  
            "api_key": currentUser.user.merchantKey, 
            "sweetapi": currentUser.user.merchantCode  
          }
          const url = `https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-all-statement-details-via-link/${item?.linkId}`;
          let res = await axios.get(url,{
            headers:headers
          })
  
  
          
          const data = res?.data
          console.log("statement result: ",data)
          setStatement(data)
        //   setStatementData(data);
        //   setIsStatementLoading(false)
          // setBalanceData(newValue || []);
          
        }catch(err){
        //   setIsStatementLoading(false)
          console.log(err)
          const errorMessage = err?.message || "unable to fetch data"
        
        }
      }

    const handlePreview = ()=>{
        navigate('/link-details')
    }
    const refLink = useRef(null);
  return (
    <div className='main-inner'>
       {console.log("single Statement: ",item)}
        <NameControl 
        backbtnPath="/data-statements"
        customer
        data
        btntext="View customer profile"
        trans
        />
        <div className='link-bar'>
            <div className='link-bar-wrapper'>
                <div className='link-bar-right'>
                    <img src="/images/Avatar.svg" alt='avater' />
                    <div className='link-right'>
                        <p>{item?.pageName || "N/A"}</p>
                        <div className='link-right-copy'>
                           <input ref={refLink} className='input_link_copy' type='text' value={item?.merchantLink || "N/A"} />
                           <img onClick={copyToClipboard.bind(null,refLink)} src='/images/copy.svg' alt="copy" />
                        </div>
                    </div>
                </div>
                <div className='btn_right'>
                   <div style={{cursor:"pointer"}} className='btn_items' onClick={handleditForm}>
                        <img src="/images/editPencile.svg" alt='ban' />
                        <p className='btn_text'>Edit</p>
                   </div>
                   <div style={{cursor:"pointer"}} className='btn_items' onClick={handlePreview}>
                        <img src="/images/arrowUpward.svg" alt='ban' />
                        <a href={item?.merchantLink} className='btn_text' target="_blank" rel="noopener noreferrer">Preview link</a>
                        {/* <p className='btn_text'></p> */}
                   </div>
                </div>
            </div>
        </div>

        <div className='table-section'>

            <div className='tbl-content'>
                <table className='tbl'>
                    <tr>
                    <td style={{borderRadius:"12px"}} colSpan="3" className='tbl-heading'>
                        <TableHeaderSearch 
                        title="Statements"
                        placeholder="Search..."
                        linkProfile
                        />
                     </td>
                    </tr>
                    <tr>
                        <th className='th-text'>Customer</th>
                        <th className='th-text'>Date Created</th>
                        <th className='th-text'>Account</th>
                    </tr>
                    {
                      Array.isArray(statement) && statement.length > 0 && statement.map((item,index)=>(
                        <tr key={index}>
                            <td className='td-text'>{item?.Customer}</td>
                            
                            <td className='td-text'>{item?.dated || "N/A"}</td>
                            <td className='td-text'>
                                <div className='tbl-account'>
                                    <img className='account-bank-logo' src={process.env.PUBLIC_URL+"/images/paymentBank.svg"} alt="" />
                                    <div className='payment-account-text'>
                                        <h1 className='td-text'>GTBank</h1>
                                        <span className='td-text'>{item?.nuban || "N/A"}</span>
                                    </div>
                                    <img  src={process.env.PUBLIC_URL+"/images/eye.svg"} alt='display'/>
                                </div>
                            </td>
                            
                        </tr>
                      ))
                    }
                    {
                        (!statement && (!Array.isArray(statement) || statement.length <1))  &&
                        <tr>
                        <td  colSpan={7} style={{width:"100%"}}>
                            <div className='empty_tbl'>
                                <img src='/images/emptyPage.svg' alt='emptuy' />
                                <h3 className='num_trans'>No Statement Yet</h3>
                                <p className='num_desc'> it is necessary to perform transactions on your Flick account</p>
                            </div>
                        </td>
                        </tr>
                    }

                    {/* <tr>
                        <td className='td-text'>Dipo Gbadebo</td>
                        
                        <td className='td-text'>2 hours ago</td>
                        <td className='td-text'>
                            <div className='tbl-account'>
                                <img className='account-bank-logo' src={process.env.PUBLIC_URL+"/images/paymentBank.svg"} alt="" />
                                <div className='payment-account-text'>
                                    <h1 className='td-text'>GTBank</h1>
                                    <span className='td-text'>-0130086553</span>
                                </div>
                                <img  src={process.env.PUBLIC_URL+"/images/eye.svg"} alt='display'/>
                            </div>
                        </td>
                        
                    </tr>
                    <tr>
                        <td className='td-text'>Dipo Gbadebo</td>
                        
                        <td className='td-text'>2 hours ago</td>
                        <td className='td-text'>
                            <div className='tbl-account'>
                                <img className='account-bank-logo' src={process.env.PUBLIC_URL+"/images/paymentBank.svg"} alt="" />
                                <div className='payment-account-text'>
                                    <h1 className='td-text'>GTBank</h1>
                                    <span className='td-text'>-0130086553</span>
                                </div>
                                <img  src={process.env.PUBLIC_URL+"/images/eye.svg"} alt='display'/>
                            </div>
                        </td>
                        
                    </tr>
                    <tr>
                        <td className='td-text'>Dipo Gbadebo</td>
                        
                        <td className='td-text'>2 hours ago</td>
                        <td className='td-text'>
                            <div className='tbl-account'>
                                <img className='account-bank-logo' src={process.env.PUBLIC_URL+"/images/paymentBank.svg"} alt="" />
                                <div className='payment-account-text'>
                                    <h1 className='td-text'>GTBank</h1>
                                    <span className='td-text'>-0130086553</span>
                                </div>
                                <img  src={process.env.PUBLIC_URL+"/images/eye.svg"} alt='display'/>
                            </div>
                        </td>
                        
                    </tr>
                    <tr>
                        <td className='td-text'>Dipo Gbadebo</td>
                        
                        <td className='td-text'>2 hours ago</td>
                        <td className='td-text'>
                            <div className='tbl-account'>
                                <img className='account-bank-logo' src={process.env.PUBLIC_URL+"/images/paymentBank.svg"} alt="" />
                                <div className='payment-account-text'>
                                    <h1 className='td-text'>GTBank</h1>
                                    <span className='td-text'>-0130086553</span>
                                </div>
                                <img  src={process.env.PUBLIC_URL+"/images/eye.svg"} alt='display'/>
                            </div>
                        </td>
                        
                    </tr> */}
                </table>
            </div>

            <div className='table-section-right'>
                <div className='table-right-wrapper'>
                    <h1 className='tbl-sec-title'>Statement details</h1>
                    <p className='tbl-sec-desc'>Here is an overview of your transaction</p>

                    <div className='tbl-sec-content'>
                       
                            <div className='tbl-content-title'>
                                <p className='text_light'>Full name</p>
                                <p className='text_light'>Gender</p>
                            </div>
                            <div className='tbl-content-title'> 
                                <p className='text_bold'>MIDE AJIBADE</p>
                                <p className='text_bold'>Male</p>
                            </div>
                            <div className='tbl-content-title top_space'>
                                <p className='text_light'>Recipient email</p>
                                <p className='text_light'>Page visits</p>
                            </div>
                            <div className='tbl-content-title'> 
                                <p className='text_bold'>ayomide@getf.com</p>
                                <p className='text_bold'>0</p>
                            </div>
                            <div className='tbl-content-title top_space'>
                                <p className='text_light'>Completed process</p>
                                <p className='text_light'>Created on</p>
                            </div>
                            <div className='tbl-content-title'> 
                                <p className='text_bold'>0</p>
                                <p className='text_bold'>Jan 6, 2023, 02:45:23 AM</p>
                            </div>
                            <div className='tbl-content-title top_space'>
                                <p className='text_light'>Redirect URL</p>
                                <p className='text_light'>App</p>
                            </div>
                            <div className='tbl-content-title'> 
                                <p className='text_bold'>N/A</p>
                                <p className='text_bold' style={{color:"#2EBDB6"}}>Flick <img style={{display:"inline"}} src="/images/arrowUpward.svg" alt='flick'/></p>
                            </div>
                        
                    </div>
                </div>
            </div>
        </div>
       
    </div>
  )
}

export default DataLinkPage
