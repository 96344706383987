import React from 'react'
import '../otc/otc.scss'
import './Disputes.scss'
import Status from '../common/Status'
import { copyToClipboard } from '../utilities/clipboardUtils'
function DetailTextDisput({desc1,value1,desc2,value2,ref,image,status,copy,leftCopy}) {
  return (
    <div className='detail_container'>
        <div className='detail_item'>
            <p className='detail_desc '>{desc1}</p>
            {
                status ? <Status bg="#FFF" border="#EAECF0" color="#344054" icon="/images/greenDot.svg" mgs={status}/>:
                copy?
                <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                    <input ref={ref} value={value1} className='detail_desc copyInput' style={{color:"#151F32",fontWeight:"500"}}/>
                    <img 
                    onClick={ copyToClipboard.bind(null,ref)} 
                    style={{display:"inline"}} 
                    src='/images/copy.svg' 
                    alt='payment' 
                    />
                </div>:
                
                   
                <p className='detail_value'>{value1}</p>
                
            }
        </div>

        <div className='detail_item left'>
            <p className='detail_desc left_align'>{desc2}</p>
            <div className='disput_disc'>
                {
                    image ?
                    <img src={image} alt=""/>:
                    
                    leftCopy?
                    <div style={{display:"flex",alignItems:"center",gap:"8px"}}>
                        <input ref={ref} value={value1} className='detail_desc copyInput' style={{color:"#151F32",fontWeight:"500"}}/>
                        <img 
                        onClick={ copyToClipboard.bind(null,ref)} 
                        style={{display:"inline"}} 
                        src='/images/copy.svg' 
                        alt='payment' 
                        />
                    </div>:
                    
                    <p className='detail_value left_align'>{value2}</p>
                }
            </div>
        </div>
    </div>
  )
}

export default DetailTextDisput