import React, { useEffect, useState } from "react";
import "./Sidebar.scss";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import TableLoader from "../common/TableLoader";

function NewBusinessModal({ onClose }) {
  const {currentUser} = useSelector((state)=>state?.user)
  const navigate = useNavigate();
  const [businessList, setBusinessList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setModalVisible] = useState(true);
  const [isActiveLoading,setIsActiveLoading] = useState(false);
  // const [business, setBusiness] = useState([]);

  useEffect(() => {
    getBusinesses();
    // eslint-disable-next-line
  }, []);
  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
  };

  const getBusinesses = async () => {
    try {
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/get-all-businesses",
        headers: headers
      };

      const response = await axios.request(config);
     

      const newValue = response?.data?.data;

      
      setBusinessList(newValue);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddBusiness = () => {
    navigate("/new-business");
    setModalVisible(false);
  };
  const handleCloseModal = () => {
    setModalVisible(false);
    onClose();
  };

  const handleSwitchBusiness = async (business)=>{
    setIsActiveLoading(true)
    try{
      const headers = {
        api_key: currentUser?.user?.merchantKey,
        sweetapi: currentUser?.user?.merchantCode,
      };

      let payload = {
        merchantCode:business?.merchantCode
      }

      const url = "https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/activate-account";
      let res = await axios.post(url,{ ...payload },{headers: headers});
      let data = res?.data
      setIsActiveLoading(false)
      navigate('/switch-business',{state:{data}})
    }catch(err){
      setIsActiveLoading(false)
      console.log(err)
    }
  }

  return (
    isModalVisible && (
      <div className="modal-overlay" onClick={handleCloseModal}>
        <div className="modal-inner" onClick={(e) => e.stopPropagation()}>
          {Array.isArray(businessList) && businessList.length > 0 && (
            <div className="business-search">
              <div className="search-business">
                <input
                  className="search-field"
                  type="text"
                  placeholder="Search for a business"
                  name="search"
                  value={searchTerm}
                  onChange={handleInputChange}
                />
                <img
                  src={process.env.PUBLIC_URL + "/images/search-bank.svg"}
                  alt="search"
                />
              </div>
              <div className="business-list">
                {businessList.map((business, index) => (
                  <div key={index} onClick={()=>handleSwitchBusiness(business)}  className="business_item">
                    <p className="business-name">{business.business_name}</p>
                  </div>
                ))}
              </div>
              <img
                src="/images/add-business.svg"
                alt="add"
                onClick={handleAddBusiness}
                style={{ marginTop: "6px" }}
              />
            </div>
          )}
          {businessList.length <= 0 && (
            <div className="add-business">
              <img src="/images/no-business.svg" alt="add" />
              <p className="no-business">No New Business Yet</p>
              <p className="click-add">Click to add new a new business.</p>
              <img src="/images/add-business.svg" alt="add" />
            </div>
          )}
        </div>
        {
          isActiveLoading &&
          <TableLoader msg="processing.."/>
        }
      </div>
    )
  );
}

export default NewBusinessModal;
