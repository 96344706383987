import React, { useState } from "react";
import DetailText from "../../otc/DetailText";
import SettlementPaymentDone from "./SettlementPaymentDone";

function SettlementConfirmDetails({ open, onClose }) {
  
//   if (!open) return null;
  const [showSettlementPaymentDone, setShowSettlementPaymentDone] = useState(false);

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); 
    };
    const handleAddAccount = () => {
        setShowSettlementPaymentDone(true);
      };
    
      const closeSettlementPaymentDone = () => {
        onClose();
        setShowSettlementPaymentDone(false);
      };
    

  return (
    <div className="settlement-overlay" onClick={onClose}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="settlement-set-modal"
      >
        <div className="settlement-modal-inner" style={{width:"89%"}}>
          <div className="close-icon" style={{justifyContent:"flex-start", marginTop:"24px", marginBottom:"16px"}}>
            <img
              onClick={onClose}
              src={process.env.PUBLIC_URL + "/images/back-arrow.svg"}
              alt=""
            />
          </div>
          <div className="add-account" style={{borderBottom:"none", marginBottom:"0"}}>
            <div style={{display:"flex", justifyContent:"space-between"}}>
            <h2 className="add-account-text">Confirm Details</h2>
            </div>
            <h5 className="authenticate">
            Please confirm the following details regarding this account and ensure all information provided is correct. 
            </h5>
          </div>
          <div className='otc_info_container' style={{marginTop:"0"}}>
                <img src='/images/otc/info-circle.svg' alt='' />
                <p className='otc_info'>No changes are possible after proceeding</p>
            </div>

            <div className='otcform_container'>
                <div className='desc_container'>
                    <DetailText desc1="Wire Type" value1="Domestic U.S wire (same/next day)"/>
                    <DetailText desc1="Account Number" value1="22488136902667" desc2="Routing Number" value2="23690867"/>
                    <DetailText desc1="Beneficiary Account Name" value1="Mide Limited" desc2="Bank Name" value2="Wells Fargo"/>
                    <DetailText desc1="Beneficiary Address " value1="1234 Unity Street Los Angeles, CA 90001"/>
                    <DetailText desc1="Bank Address" value1="567 Carl Street, Los Angeles, CA 93021"/>
                </div>
            </div>
            <div className='consent_to' style={{marginTop:"62px"}}>
                <input 
                type="checkbox" 
                checked={isChecked} 
                onChange={handleCheckboxChange} 
                className="custom-checkbox"
                />
                 {/* eslint-disable-next-line */}
                <p className='confirm_desc'>I confirm the above payment details are accurate and consent to Flick's <a href='#'>Policy</a> </p>
            </div>

            <div className='otc_btn_modal'>
                <button className='btn otc_btn_cancel' onClick={onClose}>
                    Cancel
                </button>
                <button className='btn' onClick={handleAddAccount}>
                Add account
                </button>
            </div>
        </div>
      </div>
      {showSettlementPaymentDone && (
            <SettlementPaymentDone onClose={closeSettlementPaymentDone} />
        )}
    </div>
  );
}

export default SettlementConfirmDetails;
