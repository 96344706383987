import React, { useState } from 'react'
import './otc.scss'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BeatLoader } from 'react-spinners';
function OrderOtcModal({setIsOrder,orderInput,setOrderInput,setIsOrderSubmited,setOrderOtcSuccess,balanceData}) {
    
    const {currentUser} = useSelector((state)=>state?.user)

    const [isLoading,setIsLoading] = useState(false);
    const  handleInputChange = (event)=>{
        const { name, value } = event.target;
        setOrderInput((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        
    }

    const handleValidation = () => {
        const {from,to,amount} = orderInput;
        if ( from === "") {
          toast.error(
            "From currency must not be empty",
            { position: "top-left", pauseOnHover: true}
          );
          return false;
        } else if(to ===""){
          toast.error(
            "To currency must not be empty",
            { position: "top-left", pauseOnHover: true}
          );
          return false;
        }else if(amount ===""){
            toast.error(
              "Amount must not be empty",
              { position: "top-left", pauseOnHover: true}
            );
            return false;
          }
        return true;
    
      };

    const handleSubmit = async(e)=>{
        e.preventDefault();
        setIsLoading(true)
       
        try{
            if(handleValidation()){
                const headers = {  
                    "api_key": currentUser?.user?.merchantKey, 
                }
            
                const data = {
                from_currency: orderInput.from,
                to_currency: orderInput.to,
                amount: orderInput.amount
                }
    
                // console.log("data: ", data)
                const url = 'https://flick-app.com/merchant/create-order';
    
                let res = await axios.post(url,data,{
                headers:headers
                })

                setOrderOtcSuccess(res.data)
                setIsOrderSubmited(true);
                // console.log("success",res.data)

            }
            
            // console.log("response: ",res.data)
            
            // const newValue = res?.data
        }catch(err){
            console.log(err)
            setIsLoading(false);
            const errorMessage = err.response?.data?.message || "An error has occured"
            toast.error(errorMessage,{position: "top-left", pauseOnHover: true})
        }
        // setIsOrderSubmited(true);
    }

  return (
    <div className='balance_modal'  onClick={setIsOrder.bind(this, false)} >
       
    <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
      <div className='balance_modal_wrapper'>
          <img 
          onClick={setIsOrder.bind(this, false)}
          className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
          />

        <div className="modal-title">
            <p className="enter-amount">Order OTC</p>
            <p className="enter-an-amount">Please enter the amount above $100,000</p>
        </div>

        

        <div className='otcform_container'>
                <form onSubmit={handleSubmit}>
                    <div className='api_select_container' style={{marginTop:"16px",justifyContent:"space-between"}}>
                        <div>
                            <p className='otcForm_desc'>From</p>
                            <div className='api_wallet_select_input pair' style={{width:"140px"}}>
                                <img src="/images/NGN.svg" alt=""/>    
                                <select 
                                    className='ot_api_select_val' 
                                    name='from'
                                    value={orderInput.pair} 
                                    onChange={handleInputChange}
                                    required
                                    disabled
                                >
                                    <option value="NGN">NGN</option>
                                    
                                </select>
                            </div>
                        </div>
                        <div>
                            <p className='otcForm_desc'>To</p>
                            <div className='api_wallet_select_input pair' style={{width:"140px"}}>
                                <img src={`${orderInput.to==="USD"?"/images/USD.svg":orderInput.to==="CAD"?"/images/otc/CAD.svg":orderInput.to==="GBP"?"/images/otc/GPB.svg":"/images/USD.svg"}`}alt=""/>    
                                <select 
                                    className='ot_api_select_val' 
                                    name='to'
                                    value={orderInput.pair} 
                                    onChange={handleInputChange}
                                    required
                                >
                                  
                                    <option value="USD">USD</option>
                                    <option value="CAD">CAD</option>
                                    <option value="GBP">GBP</option>
                                    <option value='EUR'>EUR</option> 
                                </select>
                            </div>
                        </div>
                        
                    </div> 

                    <div style={{textAlign:"center",marginTop:"15px"}}>
                        <p className='otcForm_desc'>Enter amount {orderInput.to}</p>
                        <input  
                        name='amount' 
                        className='api_input_amountss'  
                        placeholder="5.00" 
                        onChange={handleInputChange}
                        required
                        />
                    </div>

                    

                    

                  

                    
                </form>
        </div>
        <div className='otc_btn_modal'>
            <button className='btn otc_btn_cancel' onClick={setIsOrder.bind(this,false)} >
                Cancel
            </button>
            {
                isLoading ?

            <button className='btn' >
                    <BeatLoader
                        color="#FFF"
                        cssOverride={{}}
                    />
            </button>:
            <button className='btn' onClick={handleSubmit} >
                            Submit
            </button>
            }
        </div>
      </div>
    </div>
</div>
  )
}

export default OrderOtcModal