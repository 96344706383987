import React from 'react'
import style from 'styled-components'

const Contact = style.div`
width:100%;
max-width:1440px;
height:100vh;
margin:0 auto;
background: #F3FBFB;

`
function ContactUs() {
  return (
    <Contact>
      <h1>Contact Us</h1>
    </Contact>
  )
}

export default ContactUs
