import React, { useEffect } from 'react'
import "../components/signup/SignUp.scss"
import AsideOverView from '../components/login/AsideOverView'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/userSlice'
function KycSuccess() {
   
 const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(()=>{
    handleLogout()
    // eslint-disable-next-line
  },[])
  const handleLogout = () =>{
    dispatch(logout())
    navigate('/kyc-success')
  }
  const handleLogin = ()=>{
    navigate('/');
  }
  return (
    <div className="dashboard-container">
            <div>
                <AsideOverView />
            </div>
            <div className="sign-up-successful">
                <div className='sign-up-success'>
                    <img src={process.env.PUBLIC_URL + "/images/handshake.svg"} alt="hand"/>
                    <p className="signup-success">KYC Completed</p>
                    <p className="check-email">Your profile is currently under review <br/> (you should get a notification within 24-48hours).</p>

                    <button onClick={handleLogin} className='btn' style={{width:"100px",marginTop:"3rem"}}>Login</button>

                </div>
            </div>
    </div>
  )
}

export default KycSuccess