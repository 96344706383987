import React from 'react'

function PayoutSumbited({setIsPayoutSubmited,setIsPayout,setIsConfirmBankDetial}) {
  const handleDone = ()=>{
    setIsPayoutSubmited(false);
    setIsConfirmBankDetial(false);
    setIsPayout(false)
  }
  return (
    <div className='balance_modal'  onClick={setIsPayoutSubmited.bind(this, false)} >
       
        <div onClick={(e) => e.stopPropagation()} className='balance_modal_content'>
        <div className='balance_modal_wrapper'>
            <img 
            onClick={setIsPayoutSubmited.bind(this, false)}
            className='otc_back' src="/images/otc/backIcon.svg" alt='close' 
            />
            <div className='otc_payout_logo'>
                <img src='/images/otc/flickRound.svg' alt='' />
                <p className='payout_logo_title'>Payment Submitted</p>
                <p className='payout_logo_desc'>Your payment is being processed and should be credited in beneficiary account within 0-2 business days</p>
                <button className='btn' style={{width:"210px",marginTop:"24px"}} onClick={handleDone}>Done</button>
            </div>

        </div>
        </div>
    </div>
  )
}

export default PayoutSumbited