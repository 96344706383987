import React,{useEffect, useRef, useState} from 'react'
import NameControl from '../components/common/NameControl'
import InFlowPayment from '../components/payment/InFlowPayment';
import OutFlowPayment from '../components/payment/OutFlowPayment';
import SettlementItem from '../components/payment/SettlementItem';
import '../components/payment/payment.scss'
import CollectionModal from '../components/payment/CollectionModal';
import { copyToClipboard } from '../components/utilities/clipboardUtils';
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';
import axios from 'axios';



function PaymentTransaction() {
  const [selectedCurrency,setSelectedCurrency] = useState("NGN");
  useEffect(()=>{
    getCollection();
    getSettlement(selectedCurrency);
    getBalance();
    // eslint-disable-next-line 
  },[])
 

    const [btnActive,setBtnActive] = useState(false);
    const [selectedButton, setSelectedButton] = useState("Collections");
    const [modalCollections,setModelCollections] = useState(false)
   
    const {currentUser} = useSelector((state)=>state?.user);
    const [selectedRowData, setSelectedRowData] = useState(null);
   
    const [collectionData,setCollectionData] = useState([]);
    const [settlementData,setSettlementData] = useState([]);
    const [isCollectionLoading,setIsCollectionLoading] = useState(false)
    const [isSettlementLoading,setIsSettlementLoading] = useState(false)
    const [balanceData,setBalanceData] = useState([])
   const linkRef = useRef(null);
    const toggleModal =()=>{
      setModelCollections(!modalCollections)
    }
    const handleButtonClick = (button) => {
        setSelectedButton(button);
    };
    
    // 
    const getBalance = async ()=>{
      
      try{
        
        const headers = {  
          "api_key": currentUser.user.merchantKey, 
          "sweetapi": currentUser.user.merchantCode  
        }
        const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/balances';
        let res = await axios.get(url,{
          headers:headers
        })
        const newValue = res.data.data
       
        setBalanceData(newValue || []);
         
      }catch(err){
        console.log(err)
        const errorMessage = err?.message || "unable to fetch data"
      }
    }

      //collection
      const getCollection = async (currency="NGN")=>{
        setIsCollectionLoading(true)
        try{
         
          const headers = {  
            "api_key": currentUser?.user?.merchantKey, 
            "sweetapi": currentUser?.user?.merchantCode  
          }
    
          const data = {
            category: "collection", //required  
            currency: currency, // required
            limit:200 // optional
          }
          
          const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transactions';
          let res = await axios.post(url,{...data},{
            headers:headers
          })
        
          const newValue = res?.data?.data
          setCollectionData(newValue);
       
          setIsCollectionLoading(false);
          // toast.success(res.data.message);
        }catch(err){
          setIsCollectionLoading(false);
          console.log(err);
          const errorMessage = err?.response?.data?.data?.message || "Server error"
        }
      }

      // settlement
      const getSettlement = async ()=>{
        setIsSettlementLoading(true)
        try{
          
    
          const headers = {  
            "api_key": currentUser?.user?.merchantKey, 
            "sweetapi": currentUser?.user?.merchantCode  
          }
          const data = {
            category: "settlement", //required  
            currency: "NGN", // required
            limit:200 // optional
          }
          const url = 'https://rgusthho51.execute-api.us-east-2.amazonaws.com/production/transactions';
          let res = await axios.post(url,{...data},{
            headers:headers
          })
          
          const newValue = res?.data?.data
         
          setSettlementData(newValue)
          setIsSettlementLoading(false);
          // toast.success(res.data.message);
        }catch(err){
          setIsSettlementLoading(false);
          console.log(err);
          // const errorMessage = err?.response?.data?.data?.message || "Server error"
          
        }
      }




    
    const renderContent = () => {
      if(selectedButton === 'Collections') return <InFlowPayment setSelectedRowData={setSelectedRowData} collectionData = {collectionData} toggleModal={toggleModal} isCollectionLoading={isCollectionLoading}/>;
      
      if(selectedButton === 'Settlement') return <OutFlowPayment settlementData={settlementData} isSettlementLoading={isSettlementLoading} />;
   
    };

    

  return (
    
                <div className='main-inner'>
                 
                  {
                    modalCollections && <CollectionModal data={selectedRowData} toggleModal={toggleModal}/>
                  }
                  
                    <NameControl 
                    // name={selectedButton}
                    name="Inflow"
                    setSelectedCurrency={setSelectedCurrency}
                    selectedCurrency={selectedCurrency}
                    desc="list of collections"
                    btnFirst="Collections"
                    btnSecond="Settlement"
                    btnActive={btnActive}
                    handleButtonClick ={handleButtonClick}
                    paymentCurrency
                    getCollection={getCollection}
                    balanceData={balanceData}
                    btn
                    /> 

                    {
                      selectedButton === "Settlement" &&
                         <div className='settlement-summary-container'>
                            <div className='settlement-summary'>
                              <SettlementItem 
                              icon="/images/settlementTotal.svg"
                              title="Total collected"
                              amount="0"
                              transNum="0"
                              bgColor="#F4FBFB"
                              />
                              
                              <SettlementItem 
                              icon="/images/settlementPending.svg"
                              title="Pending settlements"
                              amount="0"
                              transNum="0"
                              bgColor="#FEF0F5"
                              />
                              
                            </div>

                            <div className='settlement-right'>
                              <div className='settlement-wrapper'>
                                 <img src='/images/referLink.svg' alt='ellips' />

                                 <p className='refer-title'>Copy the Link Below</p>
                                 <input 
                                 ref={linkRef} 
                                 type='text' 
                                 className='refer-link-input' 
                                 placeholder='6GSexbTSV73v&BS&BD#VDVdvd...' 
                                 value="6GSexbTSV73v&BS&BD#VDVdvd..." 
                                 />
                                 <img style={{display:"inline"}} onClick={copyToClipboard.bind(null,linkRef)} src="/images/copyRefer.svg" alt="refer" />
                              </div>
                            </div>
                         </div>
                    }   
                    
                    {renderContent()}
                </div>

  )
}

export default PaymentTransaction
