import React, { useState } from 'react'
import { toast } from 'react-toastify';

function FundUSDBalanceModal({setIsVertualNuban}) {
    const [copiedText, setCopiedText] = useState('');
    const [isUsd,setIsUsd] = useState(false)

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
        setCopiedText(text);
        toast.success('Copied successfully');
    };
    const handleClick = ()=>{
        setIsUsd(true);
    }
  return (
    <div className="overlay" >
    <div className="enter-amount-modal">
        
        <div className="modal-wrapper " >

            <div> 
                <img style={{cursor:"pointer"}} onClick={setIsVertualNuban.bind(this,false)} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
            </div>
            
            <div className="modal-title">
                <p className="enter-amount">Fund USD Balance</p>
                <p className="enter-an-amount">Wire Transfer Details</p>
            </div>

            
            {
                isUsd ?
                
                <div>
                    <div className="account-details "  >
                        <div className="number-name">
                            <div className="number">
                                {/* <p className="title" >IBAN/Account number</p> */}
                                <p className="sub-title" style={{fontSize:"2rem"}}>Available Soon</p>
                            </div>
    
                            
                        </div> 

                    </div>
                    <div className="modal-btn">
                        <button onClick={setIsVertualNuban.bind(this,false)} className="first-btn" style={{width:"50%",height:"52px"}}>
                        close
                        </button>
                        
                    </div> 
                 </div>:

                 <div className="modal-btn">
                 <button className='btn' style={{width:"50%",height:"52px"}} onClick={handleClick}>
                     Request Access
                 </button>
             </div>
            }
            
            {/* <div className="modal-btn">
                <button onClick={setIsVertualNuban.bind(this,false)} className="first-btn" style={{width:"50%",height:"52px"}}>
                close
                </button>
                
                <button className='btn' style={{width:"50%",height:"52px"}}>
                Wire Details 
                </button>
            </div> */}
        </div>
         
        {/* <div className="modal-wrapper scrollNuban" >

            <div> 
                <img style={{cursor:"pointer"}} onClick={setIsVertualNuban.bind(this,false)} src={process.env.PUBLIC_URL + "/images/backArrowBalanceModal.svg"} alt="icon" />
            </div>
            
            <div className="modal-title">
                <p className="enter-amount">Fund USD Balance</p>
                <p className="enter-an-amount">Wire Transfer Details</p>
            </div>

            <div className="scrollNuban"  style={{height:"600px"}}>
    
    
                <div className="account-details "  >
                    <div className="number-name">
                        <div className="number">
                            <p className="title" >IBAN/Account number</p>
                            <p className="sub-title">13594623041021 <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} onClick={() => handleCopyToClipboard("13594623041021")} alt="icon" /></p>
                        </div>

                        <div className="name">
                            <p className="title" style={{textAlign:"end"}}>Routing Number</p>
                            <p className="sub-title">219071291 <img onClick={() => handleCopyToClipboard('219071291')} src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} alt="icon" /></p>
                        </div>
                    </div> 

                    <div className="number-name">
                        <div className="number">
                            <p className="title">Bank name</p>
                            <p className="sub-title">Choice Financial Group  <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} onClick={() => handleCopyToClipboard('Choice Financial Group')} alt="icon" /></p>
                        </div>

                        <div className="name">
                            <p className="title">Account type</p>
                            <p className="sub-title">Checking  <img src={process.env.PUBLIC_URL + "/images/modal-copy-icon.svg"} onClick={() => handleCopyToClipboard('Checking')} alt="icon" /></p>
                        </div>
                    </div>

                    <div className="number-name">
                        <div className="number">
                            <p className="title">Bank address</p>
                            <p className="sub-title">4571 25th Avenue S, Fargo, ND 58104 </p>
                        </div>

                        <div className="name" style={{textAlign:"end"}}>
                            <p className="title">Account type</p>
                            <p className="sub-title">2561 Bismal Street, #4764San Francisco, CA 94114 </p>
                        </div>
                    </div>
                    <div className="number-name">
                        <div className="number">
                            <p className="title">Reference</p>
                            <p className="sub-title">JAYUSD102024</p>
                        </div>

                    
                    </div>

                    <div style={{display:"flex",alignItems:"center",gap:"8px",width:"100%"}}>
                        <img src='/images/infoRound.svg' alt=''/>
                        <p className='fx_charge'>Charges 0.5%</p>
                    </div>

                    

                </div>
            
            </div>
            
            
            <div className="modal-btn">
                <button onClick={setIsVertualNuban.bind(this,false)} className="first-btn" style={{width:"50%",height:"52px"}}>
                close
                </button>
                
                <button className='btn' style={{width:"50%",height:"52px"}}>
                Wire Details 
                </button>
            </div>
        </div> */}
       
    </div>
    </div>
  )
}

export default FundUSDBalanceModal