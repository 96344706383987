import React from 'react'
import '../../components/overview/Overview.scss'
import { NumericFormat } from 'react-number-format'
function OtcPaymentBalance({country,flag,symbole,amount,bgColor}) {
    const handleBalance = ()=>{
       
    }
  return (
    <div className='otc_balance_item' style={{backgroundColor:bgColor}} onClick={handleBalance}>     
        <div className='balance-country'>
            <img src={process.env.PUBLIC_URL+flag} alt='flag'/>
            <h6>{country}</h6>
        </div>
        <div className='balance-available'>
            <h1 className='balance-available-title'>AVAILABLE BALANCE</h1>
            <div className='balance-amount'>

                {/* <h4>{symbole}{amount}</h4> */}
            
                <NumericFormat value={Number(amount).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={symbole} 
                renderText={(value) => <h4>{value}</h4>}
                />
                
                {/* <img src={process.env.PUBLIC_URL+"/images/balanceArrow.svg"} alt="balance"/> */}
            </div>
        </div>
    </div>
  )
}

export default OtcPaymentBalance